// BODY STYLE

.theme-style {
  .side-header.main-header {
    background: #e4e3ef;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 1px solid #d4d2e2;
  }

  .app-sidebar {
    background: #e4e3ef;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-right: 1px solid #d4d2e2;
  }

  .app-sidebar.horizontal-main {
    border-right: 0;
    border-bottom: 1px solid #d4d2e2;
  }

  .main-sidebar-header {
    background: #e4e3ef;
    border-bottom: 1px solid #d4d2e2;
    border-right: 1px solid #d4d2e2;
  }

  .side-menu__label {
    color: #151621 !important;
  }

  .side-menu {
    .side-menu__icon {
      color: #151621;
    }

    h3 {
      color: #838498;
    }
  }

  .app-sidebar .slide.active .side-menu__item {
    background: #dbd9ef;
  }

  .side-menu .slide.active {
    .side-menu__label,
    .side-menu__icon {
      color: $primary;
    }
  }

  &.light-mode {
    .main-logo,
    .logo-1,
    .desktop-logo.active.logo-light {
      display: block;
    }

    .main-logo.dark-mode,
    .logo-1,
    .desktop-logo.active.dark-mode {
      display: none;
    }
    .desktop-logo.logo-light {
      display: block;
    }
    .main-sidebar-header .desktop-logo.logo-dark {
      display: none;
    }
    &.sidenav-toggled {
      .desktop-logo.logo-light {
        display: none;
      }
      &.sidenav-toggled.sidenav-toggled-open {
        .desktop-logo.logo-dark,
        .logo-icon.icon-light {
          display: none;
        }
        .desktop-logo.logo-light {
          display: block;
        }
      }
    }
    .slide-menu li a {
      color: $default-color;
    }
  }

  .main-header-center .form-control {
    border: 2px solid #d4d2e2;
    border-width: 1px;
    background-color: #e4e3ef;
  }

  .main-footer {
    background-color: #e4e3ef;
    border-top: 1px solid #d4d2e2;
  }

  .main-header.fixed-header .form-control {
    border: 2px solid #e6e5f1;
    border-width: 1px;
    background-color: $white;
  }

  .side-menu__item {
    &.active .side-menu__icon,
    &:hover .side-menu__icon,
    &:focus .side-menu__icon {
      color: $primary;
    }
  }

  .app-sidebar .side-menu__item:hover .side-menu__label {
    color: $primary;
  }

  .slide.is-expanded a {
    font-weight: 400;
    color: #4d4c50;
  }

  .slide-menu .slide-item:before {
    border-color: #4d4c50;
  }

  &.sidebar-mini .slide.is-expanded a:hover {
    color: $primary !important;
    background: #dbd9ef !important;
  }

  .main-header {
    background-color: transparent;
    box-shadow: none;

    &.fixed-header {
      background-color: $white;
      box-shadow: none;
    }
  }
  @media (min-width: 992px) {
    &.sidebar-mini.sidenav-toggled .main-sidebar-header {
      .mobile-logo.icon-dark {
        display: none;
      }
      .mobile-logo.icon-light {
        display: block;
      }
    }
    &.light-mode .main-sidemenu .slide-left,
    &.light-mode .main-sidemenu .slide-right {
      border-color: $black-2;
      svg {
        fill: $black-5;
      }
    }
    &.hover-submenu,
    &.hover-submenu1 {
      &.sidebar-mini.sidenav-toggled,
      &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
        .main-sidebar-header {
          .logo-icon.icon-dark,
          .desktop-logo.logo-light {
            display: none;
          }
          .logo-icon.mobile-logo.icon-light {
            display: block;
          }
        }
        .slide-menu li a {
          color: $white-6;
        }
        .slide-item.active,
        .slide-item:hover,
        .slide-item:focus {
          color: $white !important;
        }
        .slide-menu .slide-item:before {
          border-color: $white-6;
        }
        &.sidebar-mini .slide.is-expanded a:hover {
          background: transparent !important;
          color: $white !important;
        }
      }
    }
  }
  .slide-item.active,
  .slide-item:hover,
  .slide-item:focus {
    color: $primary !important;
  }
  .slide.is-expanded .side-menu__item {
    background-color: #dbd9ef;
  }
  @media (max-width: 991px) {
    .sub-slide.is-expanded .sub-side-menu__item.active {
      color: $primary;
    }
  }
}
