

.main-content-dashboard {
	padding-top: 20px;
}
@media (min-width: 992px) {
	.main-content-dashboard {
		padding-top: 30px;
	}
}
.main-dashboard-one-title {
	margin-bottom: 20px;
}
@media (min-width: 576px) {
	.main-dashboard-one-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
@media (min-width: 992px) {
	.main-dashboard-one-title {
		margin-bottom: 30px;
	}
}
.main-dashboard-title {
	font-size: 22px;
	font-weight: 700;
	color: $default-color;
	letter-spacing: -1px;
	margin-bottom: 3px;
}
.main-dashboard-text {
	font-size: 13px;
	margin-bottom: 0;
}
.main-dashboard-date {
	display: flex;
	align-items: center;
	margin-top: 20px;
	.date {
		display: flex;
		>div {
			&:first-child {
				font-size: 32px;
				font-family: Arial, sans-serif;
				font-weight: 500;
				color: $default-color;
				line-height: .9;
			}
			&:last-child {
				margin-left: 5px;
				span {
					display: block;
					color: $default-color;
					font-weight: 700;
					font-size: 12px;
					line-height: 1.2;
					letter-spacing: -.5px;
					&:last-child {
						color: #8587a7;
						font-weight: 400;
					}
				}
			}
		}
	}
	>i {
		font-size: 21px;
		line-height: 0;
		display: block;
		margin: 0 15px;
		color: #97a3b9;
	}
	.wallet {
		border-radius: 3px;
		i {
			line-height: 40px;
		}
	}
}
@media (min-width: 576px) {
	.main-dashboard-date {
		margin-top: 0;
	}
}
@media (min-width: 768px) {
	.main-dashboard-date .date>div:first-child {
		font-size: 36px;
	}
}
@media (min-width: 768px) {
	.main-dashboard-date .date>div:last-child span {
		font-size: 13px;
	}
}
@media (min-width: 768px) {
	.main-dashboard-date>i {
		margin: 0 20px;
		font-size: 24px;
	}
}
.main-dashboard-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $border;
	margin-bottom: 20px;
	.nav-link {
		font-size: 12px;
		padding: 5px 10px 8px;
		display: flex;
		align-items: center;
		line-height: 1;
		&:hover, &:focus {
			color: $primary;
		}
		+.nav-link {
			border-left: 1px solid $border;
		}
	}
	.nav {
		&:first-child .nav-link {
			color: $default-color;
			display: none;
			&:hover, &:focus, &.active {
				color: $primary;
			}
			&:first-child {
				padding-left: 0;
				display: block;
			}
			&:last-child {
				display: block;
			}
		}
		&:last-child .nav-link {
			color: #596882;
			display: none;
			&:hover, &:focus {
				color: $primary;
			}
			i {
				font-size: 16px;
				margin-right: 7px;
				line-height: 0;
			}
			&:last-child {
				padding-right: 0;
				display: block;
				i {
					margin-right: 0;
				}
			}
		}
	}
}
@media (min-width: 576px) {
	.main-dashboard-nav .nav-link {
		font-size: 0.875rem;
	}
}
@media (min-width: 992px) {
	.main-dashboard-nav .nav-link {
		padding: 5px 15px 10px;
	}
}
@media (min-width: 576px) {
	.main-dashboard-nav .nav:first-child .nav-link {
		display: block;
	}
}
@media (min-width: 768px) {
	.main-dashboard-nav .nav:last-child .nav-link {
		display: block;
	}
}
@media (min-width: 768px) {
	.main-dashboard-nav .nav:last-child .nav-link:last-child {
		display: none;
	}
}
.card-dashboard-one {
	position: relative;
	border-color: #e8e7ef;
	.card-header {
		padding: 20px;
		background-color: transparent;
		.card-title {
			color: $default-color;
			font-weight: 700;
			font-size: 14px;
			line-height: 1;
			margin-bottom: 3px;
		}
		.card-text {
			margin-bottom: 0;
		}
		.btn-group {
			margin-top: 15px;
			.btn, .sp-container button {
				font-size: 12px;
				font-weight: 500;
				padding: 5px 10px;
				min-height: inherit;
				background-color: $white;
				color: #8587a7;
				border: 1px solid $border;
			}
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-header .btn-group {
		margin-top: 0;
	}
}
.sp-container .card-dashboard-one .card-header .btn-group button {
	font-size: 12px;
	font-weight: 500;
	padding: 5px 10px;
	min-height: inherit;
	background-color: $white;
	color: #8587a7;
	border: 1px solid $border;
}
.card-dashboard-one .card-header .btn-group {
	.btn:hover, .sp-container button:hover {
		background-color: #f4f5f8;
		border-color: #e8e7ef;
	}
}
.sp-container .card-dashboard-one .card-header .btn-group button:hover {
	background-color: #f4f5f8;
	border-color: #e8e7ef;
}
.card-dashboard-one .card-header .btn-group {
	.btn:focus, .sp-container button:focus {
		background-color: #f4f5f8;
		border-color: #e8e7ef;
	}
}
.sp-container .card-dashboard-one .card-header .btn-group button:focus {
	background-color: #f4f5f8;
	border-color: #e8e7ef;
}
.card-dashboard-one .card-header .btn-group {
	.btn+.btn, .sp-container button+.btn {
		margin-left: -1px;
	}
}
.sp-container .card-dashboard-one .card-header .btn-group button+.btn, .card-dashboard-one .card-header .btn-group .sp-container .btn+button, .sp-container .card-dashboard-one .card-header .btn-group .btn+button, .card-dashboard-one .card-header .btn-group .sp-container button+button, .sp-container .card-dashboard-one .card-header .btn-group button+button {
	margin-left: -1px;
}
.card-dashboard-one .card-header .btn-group {
	.btn.active, .sp-container button.active {
		color: $default-color;
		background-color: #e8e7ef;
		border-color: #e8e7ef;
	}
}
.sp-container .card-dashboard-one .card-header .btn-group button.active {
	color: $default-color;
	background-color: #e8e7ef;
	border-color: #e8e7ef;
}
.card-dashboard-one {
	.card-body {
		padding: 10px 0 20px;
		position: relative;
		overflow: hidden;
		.flot-chart-wrapper {
			position: relative;
			margin-left: -28px;
			margin-right: -20px;
		}
		.flot-chart {
			width: 100%;
			height: 150px;
			.flot-y-axis>div {
				transform: translateX(50px);
				text-shadow: 1px 1px rgba(255, 255, 255, 0.75);
				color: #031b4e;
				font-weight: 700;
				font-size: 11px;
			}
			.flot-x-axis>div {
				color: #97a3b9;
				font-weight: 500;
				font-size: 11px;
			}
		}
	}
	.card-body-top {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		padding-left: 20px;
		>div {
			flex-basis: 50%;
		}
		label {
			font-size: 12px;
			margin-bottom: 3px;
		}
		h2 {
			font-size: 20px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body .flot-chart {
		height: 275px;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.card-dashboard-one .card-body .flot-chart {
		height: 252px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body-top {
		position: absolute;
		top: -5px;
		left: 20px;
		z-index: 5;
		padding-left: 0;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body-top>div {
		flex-basis: auto;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body-top>div+div {
		margin-left: 30px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body-top label {
		font-size: 0.875rem;
	}
}
@media (min-width: 576px) {
	.card-dashboard-one .card-body-top h2 {
		font-size: 24px;
		letter-spacing: -.5px;
	}
}
.card-dashboard-two {
	height: 100%;
	border-radius: 0;
	border-color: #e8e7ef;
	background-color: $white-5;
	.card-header {
		background-color: transparent;
		padding: 20px 20px 0;
		h6 {
			font-size: 28px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			display: flex;
			align-items: baseline;
			margin-bottom: 2px;
			line-height: 1;
			letter-spacing: -1px;
			i {
				margin-left: 10px;
				font-size: 24px;
			}
			small {
				font-size: 12px;
				font-weight: 400;
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				margin-left: 5px;
				color: #8587a7;
				letter-spacing: normal;
			}
		}
		p {
			margin-bottom: 0;
			font-size: 13px;
		}
	}
	.card-body {
		padding: 0;
		overflow: hidden;
	}
	.chart-wrapper {
		position: relative;
		margin: 0 -10px -10px;
	}
	.flot-chart {
		width: 100%;
		height: 100px;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.card-dashboard-two .card-header h6 {
		font-size: 24px;
	}
}
.card-dashboard-three {
	height: 150px;
	border-radius: 0;
	border-color: #e8e7ef;
	background-color: $white-5;
	.card-header {
		background-color: transparent;
		padding: 20px;
		position: absolute;
		max-width: 50%;
		h6 {
			font-size: 28px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			display: flex;
			align-items: baseline;
			margin-bottom: 15px;
			line-height: 1;
			letter-spacing: -1px;
			small {
				font-size: 12px;
				font-weight: 400;
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				margin-left: 5px;
				letter-spacing: normal;
			}
		}
		p {
			margin-bottom: 10px;
			font-weight: 700;
			text-transform: uppercase;
			color: $default-color;
		}
		>small {
			font-size: 11px;
			display: none;
			color: #8587a7;
		}
	}
	.card-body {
		padding: 0 0 0 20px;
		display: flex;
		justify-content: flex-end;
	}
	.chart {
		width: 70%;
		height: 150px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-three {
		height: 170px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-three {
		height: 100%;
	}
}
@media (min-width: 1200px) {
	.card-dashboard-three .card-header h6 {
		font-size: 32px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-three .card-header>small {
		display: block;
	}
}
@media (min-width: 375px) {
	.card-dashboard-three .chart {
		width: auto;
	}
}
@media (min-width: 768px) {
	.card-dashboard-three .chart {
		height: 170px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-three .chart {
		height: 200px;
		width: 80%;
	}
}
.card-dashboard-pageviews {
	border-color: #e8e7ef;
	border-radius: 0;
	padding: 20px;
	.card-header {
		background-color: transparent;
		padding: 0 0 10px;
	}
	.card-title {
		font-weight: 700;
		font-size: 14px;
		color: $default-color;
		margin-bottom: 5px;
	}
	.card-text {
		font-size: 13px;
		margin-bottom: 0;
	}
	.card-body {
		padding: 0;
	}
}
.card-dashboard-four {
	border-radius: 0;
	border-color: #e8e7ef;
	padding: 20px;
	height: 100%;
	.card-header {
		padding: 0 0 20px;
		background-color: transparent;
	}
	.card-title {
		font-weight: 700;
		font-size: 14px;
		color: $default-color;
		margin-bottom: 0;
	}
	.card-body {
		padding: 0;
	}
	.chart {
		width: calc(100vw - 80px);
		height: 200px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-four .card-body {
		padding: 0 20px;
	}
}
@media (min-width: 375px) {
	.card-dashboard-four .chart {
		width: auto;
	}
}
@media (min-width: 992px) {
	.card-dashboard-four .chart {
		height: 85%;
	}
}
.card-dashboard-five {
	border-color: #e8e7ef;
	padding: 20px;
	.card-header {
		padding: 0;
		background-color: transparent;
	}
	.card-title {
		font-weight: 700;
		font-size: 14px;
		color: $default-color;
		margin-bottom: 5px;
	}
	.card-text {
		display: block;
		font-size: 12px;
	}
	.card-body {
		padding: 20px 0 5px;
		label {
			display: block;
			margin-bottom: 2px;
			font-size: 12px;
		}
		h4 {
			color: $default-color;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 700;
			letter-spacing: -.5px;
			margin-bottom: 0;
		}
	}
	.card-chart {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-five .card-chart {
		margin-bottom: 0;
		margin-right: 10px;
	}
}
.card-table-one {
	padding: 20px 20px 10px;
	border-color: #e8e7ef;
	.card-title {
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 5px;
	}
	.table-responsive {
		width: calc(100vw - 80px);
	}
	.table {
		margin-bottom: 0;
		thead tr> {
			th, td {
				vertical-align: middle;
				white-space: nowrap;
				padding-right: 2px;
			}
		}
		tbody tr> {
			th, td {
				vertical-align: middle;
				white-space: nowrap;
				padding-right: 2px;
			}
		}
		thead tr> {
			th {
				&:nth-child(3), &:nth-child(4), &:nth-child(5) {
					text-align: right;
				}
			}
			td {
				&:nth-child(3), &:nth-child(4), &:nth-child(5) {
					text-align: right;
				}
			}
		}
		tbody tr> {
			th {
				&:nth-child(3), &:nth-child(4), &:nth-child(5) {
					text-align: right;
				}
			}
			td {
				&:nth-child(3), &:nth-child(4), &:nth-child(5) {
					text-align: right;
				}
			}
		}
		thead tr> {
			th strong, td strong {
				color: $default-color;
			}
		}
		tbody tr> {
			th strong, td strong {
				color: $default-color;
			}
		}
	}
	.flag-icon {
		font-size: 20px;
		line-height: 1;
		border-radius: 100%;
	}
}
@media (min-width: 576px) {
	.card-table-one .table-responsive {
		width: 100%;
	}
}
@media (min-width: 768px) {
	.card-table-one .table {
		thead tr> {
			th, td {
				padding-left: 0;
				padding-right: 0;
			}
		}
		tbody tr> {
			th, td {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
/* ###### 8.2 Dashboard Two  ###### */

.main-content-dashboard-two {
	background-color: $gray-100;
	transition: all 0.4s;
	.main-content-title {
		letter-spacing: -.5px;
	}
	.main-content-body {
		padding: 0 20px;
	}
	.main-header-menu-icon {
		margin-right: 0;
	}
}
@media (prefers-reduced-motion: reduce) {
	.main-content-dashboard-two {
		transition: none;
	}
}
@media (min-width: 992px) {
	.main-content-dashboard-two .main-content-body {
		padding: 0 20px 20px;
	}
}
.main-dashboard-header-right {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	>div {
		label {
			display: block;
			margin-bottom: 5px;
		}
		h5 {
			font-size: 20px;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			margin-bottom: 0;
			line-height: 1;
		}
		&:first-child {
			flex-basis: 100%;
		}
		+div {
			margin-top: 10px;
		}
	}
}
@media (min-width: 576px) {
	.main-dashboard-header-right {
		flex-wrap: nowrap;
	}
}
@media (min-width: 768px) {
	.main-dashboard-header-right {
		margin-top: 0;
	}
}
@media (min-width: 768px) {
	.main-dashboard-header-right>div {
		text-align: right;
	}
}
@media (max-width: 575.98px) {
	.main-dashboard-header-right>div+div {
		flex-basis: 50%;
	}
}
@media (min-width: 576px) {
	.main-dashboard-header-right>div+div {
		margin-left: 25px;
		margin-top: 0;
		flex-shrink: 0;
	}
}
@media (min-width: 992px) {
	.main-dashboard-header-right>div+div {
		margin-left: 50px;
	}
}
.main-star {
	display: flex;
	align-items: center;
	i {
		font-size: 20px;
		line-height: 0;
		color: #97a3b9;
		display: inline-block;
		&.typcn {
			line-height: .9;
		}
		&.active {
			color: $warning;
		}
	}
	span {
		font-size: 13px;
		display: inline-block;
		margin-left: 5px;
	}
}
.card-dashboard-seven {
	border-color: #e8e7ef;
	margin-bottom: 20px;
	width: 100vw;
	border-left-width: 0;
	border-right-width: 0;
	position: relative;
	margin-left: -20px;
	margin-right: -20px;
	.card-header {
		background-color: transparent;
		padding: 20px;
		border-bottom: 1px solid $border;
		background-color: #f4f5f8;
		.media {
			align-items: center;
			>div:first-child {
				line-height: 0;
				font-size: 16px;
				margin-right: 5px;
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 34px;
				background-color: #97a3b9;
			}
		}
		.media-body {
			margin-top: 3px;
		}
		label {
			margin-bottom: 0;
			display: block;
			line-height: 1;
			font-size: 11px;
			color: #97a3b9;
		}
		.date {
			font-weight: 500;
			display: flex;
			align-items: center;
			span {
				display: block;
			}
			a {
				margin-left: 5px;
				font-size: 14px;
				color: #97a3b9;
				display: block;
				i {
					line-height: 0;
					position: relative;
					top: 1px;
				}
			}
		}
	}
	.card-body {
		padding: 20px;
		.row>div+div {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: -1px;
				bottom: 0;
				border-left: 1px dotted #e2e8f5;
				display: none;
			}
		}
		.main-content-label, .card-table-two .card-title {
			text-transform: none;
			color: #031b4e;
			font-weight: 500;
			font-size: 0.875rem;
			margin-bottom: 10px;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-seven {
		margin-bottom: 20px;
		margin-left: 0;
		margin-right: 0;
		width: auto;
		border-left-width: 1px;
		border-right-width: 1px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-seven .card-header {
		padding: 20px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-seven .card-header .media>div:first-child {
		width: 34px;
		font-size: 24px;
		margin-right: 8px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-seven .card-body .row>div+div::before {
		display: block;
	}
}
.card-table-two .card-dashboard-seven .card-body .card-title, .card-dashboard-seven .card-body .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-seven .card-body .card-title {
	text-transform: none;
	color: #031b4e;
	font-weight: 500;
	font-size: 0.875rem;
	margin-bottom: 10px;
}
.card-dashboard-seven .card-body {
	h2 {
		color: $default-color;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-weight: 600;
		font-size: 24px;
		letter-spacing: -1px;
		margin-bottom: 5px;
		line-height: 1;
		span {
			font-weight: 400;
			color: #031b4e;
			letter-spacing: normal;
		}
	}
	.desc {
		display: flex;
		align-items: flex-end;
		margin-bottom: 15px;
		>i {
			line-height: 0;
			font-size: 18px;
		}
		span {
			display: block;
			line-height: 1;
			margin-left: 5px;
			strong {
				color: $default-color;
			}
		}
		&.up>i {
			color: $success;
		}
		&.down>i {
			color: $danger;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-seven .card-body h2 {
		font-size: 32px;
	}
}
.card-dashboard-six {
	border-color: #e8e7ef;
	padding: 20px;
	.card-header {
		padding: 0;
		background-color: transparent;
		margin-bottom: 20px;
	}
	.main-content-label, .card-table-two .card-title {
		margin-bottom: 5px;
		font-size: 0.875rem;
	}
}
@media (min-width: 992px) {
	.card-dashboard-six {
		height: 100%;
	}
}
@media (min-width: 576px) {
	.card-dashboard-six .card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
@media (min-width: 992px) {
	.card-dashboard-six .card-header {
		margin-bottom: 30px;
	}
}
.card-table-two .card-dashboard-six .card-title, .card-dashboard-six .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-six .card-title {
	margin-bottom: 5px;
	font-size: 0.875rem;
}
.card-dashboard-six .chart-legend {
	margin-top: 20px;
	display: flex;
	>div {
		display: flex;
		align-items: center;
		font-size: 11px;
		font-weight: 500;
		+div {
			margin-top: 2px;
		}
		span {
			&:last-child {
				width: 8px;
				height: 8px;
				margin-left: 5px;
			}
			&:first-child {
				width: 100px;
				text-align: right;
			}
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-six .chart-legend {
		display: block;
		margin-top: 0;
	}
}
@media (min-width: 576px) {
	.card-dashboard-six .chart-legend>div span:last-child {
		margin-left: 10px;
		width: 20px;
	}
}
.card-dashboard-map-one {
	border-color: #e8e7ef;
	padding: 20px;
	.main-content-label, .card-table-two .card-title {
		font-size: 0.875rem;
	}
}
@media (min-width: 992px) {
	.card-dashboard-map-one {
		height: 100%;
	}
}
.card-table-two .card-dashboard-map-one .card-title, .card-dashboard-map-one .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-map-one .card-title {
	font-size: 0.875rem;
}
.card-dashboard-map-one .vmap-wrapper {
	height: 200px;
}
@media (min-width: 576px) {
	.card-dashboard-map-one .vmap-wrapper {
		height: 300px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-map-one .vmap-wrapper {
		height: 260px;
	}
}
.card-table-two {
	border-color: #e8e7ef;
	padding: 20px;
	.card-title {
		font-size: 13px;
		margin-bottom: 5px;
	}
	.table-responsive {
		width: calc(100vw - 62px);
	}
}
@media (min-width: 576px) {
	.card-table-two .table-responsive {
		width: 100%;
	}
}
.table-dashboard-two {
	thead>tr> {
		th, td {
			white-space: nowrap;
		}
	}
	tbody>tr> {
		th, td {
			white-space: nowrap;
		}
		th:not(:first-child), td:not(:first-child) {
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 600;
			font-size: 13px;
		}
	}
}
.card-dashboard-eight {
	border-color: #e8e7ef;
	background-color: $white-5;
	padding: 20px;
	.card-title {
		font-size: 13px;
		margin-bottom: 5px;
	}
	.list-group-item {
		padding: 10px 0;
		border-left-width: 0;
		border-right-width: 0;
		border-style: dotted;
		border-color: #e8e7ef;
		background-color: transparent;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		&:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		p {
			margin-bottom: 0;
			margin-left: 10px;
		}
		span {
			display: block;
			margin-left: auto;
			font-weight: 600;
			font-family: 'Archivo', Arial, sans-serif;
			color: $default-color;
		}
	}
	.flag-icon {
		font-size: 24px;
		line-height: 1;
		border-radius: 100%;
	}
}
@media (min-width: 768px) {
	.card-dashboard-eight {
		height: 100%;
	}
}
/* ###### 8.3 Dashboard Three  ###### */

.main-content-dashboard-three {
	position: relative;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #fdfdfd;
}
@media (min-width: 992px) {
	.main-content-dashboard-three {
		border-left: 1px solid $border;
		margin-left: 64px;
	}
}
.main-header-dashboard-three {
	height: auto;
	.container-fluid {
		padding: 15px 20px;
	}
	.main-header-center {
		margin-left: 0;
	}
	.main-header-right {
		margin-left: auto;
	}
}
@media (min-width: 992px) {
	.main-header-dashboard-three .main-header-left {
		display: none;
	}
}
.main-content-body-dashboard-three {
	padding: 20px;
	position: relative;
	width: 100%;
	.main-content-title {
		letter-spacing: -.5px;
	}
}
.card-dashboard-nine {
	border-color: #e8e7ef;
	background-color: $white-5;
	position: relative;
	padding: 20px 20px 15px;
	.card-header {
		position: absolute;
		top: 20px;
		left: 20px;
		right: 20px;
		padding: 0;
		background-color: transparent;
		z-index: 5;
		h1 {
			font-size: 32px;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			margin-bottom: 8px;
			line-height: 1;
			letter-spacing: -.5px;
			color: $default-color;
		}
	}
	.main-content-label, .card-table-two .card-title {
		font-size: 13px;
		margin-bottom: 10px;
		color: #031b4e;
	}
}
.card-table-two .card-dashboard-nine .card-title, .card-dashboard-nine .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-nine .card-title {
	font-size: 13px;
	margin-bottom: 10px;
	color: #031b4e;
}
.card-dashboard-nine {
	.card-title {
		font-weight: 600;
	}
	.main-content-text {
		color: #8587a7;
	}
	.bar-chart {
		position: relative;
		width: auto;
		height: 200px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-nine .bar-chart {
		height: 210px;
	}
}
.card-dashboard-ten {
	border-width: 0;
	padding: 20px;
	+.card-dashboard-ten {
		margin-top: 20px;
	}
	.main-content-label, .card-table-two .card-title {
		font-size: 13px;
		font-weight: 500;
		margin-bottom: 15px;
		line-height: 1.05;
		color: $white;
	}
}
@media (min-width: 576px) {
	.card-dashboard-ten {
		width: calc(50% - 11px);
		display: inline-block;
	}
}
@media (min-width: 992px) {
	.card-dashboard-ten {
		width: auto;
		display: block;
	}
}
@media (min-width: 576px) {
	.card-dashboard-ten+.card-dashboard-ten {
		margin-top: 0;
		margin-left: 18px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-ten+.card-dashboard-ten {
		margin-left: 0;
		margin-top: 20px;
	}
}
.card-table-two .card-dashboard-ten .card-title, .card-dashboard-ten .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-ten .card-title {
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 15px;
	line-height: 1.05;
	color: $white;
}
.card-dashboard-ten .card-body {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	>div {
		flex: 1;
		label {
			font-size: 13px;
			margin-bottom: 0;
			color: rgba(255, 255, 255, 0.75);
		}
		h6 {
			margin-bottom: 2px;
			color: $white;
			font-size: 32px;
			font-weight: 600;
			line-height: .975;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			span {
				font-size: 13px;
			}
			.percent {
				font-size: 20px;
			}
		}
	}
}
@media (min-width: 992px) {
	.card-dashboard-ten .card-body>div {
		flex: none;
	}
}
@media (min-width: 1360px) {
	.card-dashboard-ten .card-body>div {
		&:first-child {
			flex-basis: 60%;
		}
		&:last-child {
			flex-basis: 40%;
		}
	}
}
@media (min-width: 992px) {
	.card-dashboard-ten .card-body>div h6 {
		font-size: 24px;
	}
}
.card-dashboard-eleven {
	border-color: #e8e7ef;
	position: relative;
	overflow: hidden;
	.card-header {
		padding: 20px;
		background-color: transparent;
		position: relative;
		z-index: 200;
	}
	.main-content-label, .card-table-two .card-title {
		display: block;
		font-size: 13px;
		margin-bottom: 5px;
		color: $dark;
		line-height: 1.4;
	}
}
.card-table-two .card-dashboard-eleven .card-title, .card-dashboard-eleven .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-eleven .card-title {
	display: block;
	font-size: 13px;
	margin-bottom: 5px;
	color: $dark;
	line-height: 1.4;
}
.card-dashboard-eleven {
	.card-text {
		color: #8587a7;
	}
	.card-body {
		padding: 0 0 5px;
		margin-left: 20px;
		position: relative;
		z-index: 200;
	}
	.flot-chart {
		width: 100%;
		height: 250px;
	}
	.card-footer {
		z-index: 200;
		background-color: transparent;
		padding: 0;
		border-width: 0;
		>div {
			flex: 1;
			padding: 15px 20px;
			border-top: 1px solid $border;
			position: relative;
		}
		label {
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 10px;
			color: #596882;
			display: block;
		}
		h6 {
			font-size: 28px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			line-height: .7;
			letter-spacing: -1px;
			margin-bottom: 10px;
			display: flex;
			align-items: baseline;
			span {
				margin-left: 5px;
				font-size: 11px;
				font-weight: 500;
				display: block;
				padding: 4px 6px;
				color: $white;
				line-height: .8;
				position: relative;
				bottom: 3px;
				letter-spacing: normal;
				&.up {
					background-color: $success;
				}
				&.down {
					background-color: $danger;
				}
			}
		}
		small {
			display: block;
			font-size: 13px;
			color: #596882;
		}
	}
	.jqvmap {
		position: absolute;
		top: 10%;
		left: 0;
		width: 100%;
		height: 300px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-eleven .card-footer {
		display: flex;
		align-items: stretch;
	}
}
@media (min-width: 768px) {
	.card-dashboard-eleven .card-footer>div+div {
		border-left: 1px solid $border;
	}
}
@media (min-width: 992px) {
	.card-dashboard-eleven .jqvmap {
		height: 80%;
	}
}
/* ###### 8.4 Dashboard Four  ###### */

.main-navbar-dashboard-four {
	box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}
@media (min-width: 992px) {
	.main-navbar-dashboard-four .container-fluid {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.main-content-dashboard-four {
	padding: 20px;
	~.main-footer {
		.container, .container-fluid {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
	>.media-dashboard {
		display: block;
		.media-aside {
			margin-top: 20px;
		}
	}
	.main-content-header {
		height: auto;
		margin-bottom: 20px;
		align-items: flex-start;
		padding: 0;
	}
}
@media (min-width: 992px) {
	.main-content-dashboard-four {
		flex: 1;
		padding: 30px 25px;
	}
}
@media (min-width: 1200px) {
	.main-content-dashboard-four>.media-dashboard {
		display: flex;
		align-items: stretch;
		height: 100%;
	}
}
@media (min-width: 1200px) {
	.main-content-dashboard-four>.media-dashboard .media-aside {
		margin-top: 0;
		display: block;
		width: 320px;
	}
}
@media (min-width: 1200px) {
	.main-content-dashboard-four>.media-dashboard>.media-body {
		padding: 0 20px 0 0;
	}
}
@media (min-width: 768px) {
	.main-content-dashboard-four .main-content-header {
		display: flex;
	}
}
.main-content-header-right {
	display: none;
	.media {
		label {
			margin-bottom: 2px;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: .5px;
			text-transform: uppercase;
			color: #97a3b9;
		}
		h6 {
			color: $default-color;
			margin-bottom: 0;
		}
		+.media {
			margin-left: 20px;
			padding-left: 20px;
			border-left: 1px solid $border;
		}
	}
	.btn:first-of-type, .sp-container button:first-of-type {
		margin-left: 30px;
	}
}
@media (min-width: 992px) {
	.main-content-header-right {
		display: flex;
		align-items: center;
	}
}
.sp-container .main-content-header-right button:first-of-type {
	margin-left: 30px;
}
.main-content-header-right {
	.btn+.btn, .sp-container button+.btn {
		margin-left: 5px;
	}
}
.sp-container .main-content-header-right button+.btn, .main-content-header-right .sp-container .btn+button, .sp-container .main-content-header-right .btn+button, .main-content-header-right .sp-container button+button, .sp-container .main-content-header-right button+button {
	margin-left: 5px;
}
.card-dashboard-calendar {
	border-color: #e8e7ef;
	padding: 20px;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	.card-title {
		color: $default-color;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	.card-body {
		padding: 0;
	}
	.ui-datepicker-inline {
		border-width: 0;
		max-width: none;
		padding: 0;
		margin: 0;
	}
	.ui-datepicker {
		.ui-datepicker-header {
			border-bottom: 1px solid $border;
			padding: 10px;
			margin-bottom: 5px;
			.ui-datepicker-next::before, .ui-datepicker-prev::before {
				top: 8px;
			}
		}
		.ui-datepicker-month {
			color: $primary;
		}
		.ui-datepicker-calendar {
			width: calc(100% - 20px);
			th {
				font-size: 10px;
				text-align: center;
			}
			td {
				text-align: center;
				a {
					display: block;
					width: 34px;
					height: 34px;
					font-size: 12px;
					font-family: 'Archivo', sans-serif;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.ui-datepicker-today a {
				background-color: transparent;
				border: 2px solid $primary;
				color: $primary;
				font-weight: 700;
			}
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
		width: 100%;
	}
}
.card-dashboard-twelve {
	border-color: #e8e7ef;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	.card-header {
		background-color: transparent;
		border-bottom: 1px solid #e8e7ef;
		padding: 20px;
	}
	.card-title {
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 20px;
		span {
			font-weight: 400;
			color: #031b4e;
			text-transform: capitalize;
		}
	}
	.sales-overview {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		.media {
			align-items: flex-end;
			flex-shrink: 0;
			flex-basis: calc(50% - 10px);
			+.media {
				margin-left: 20px;
			}
			&:last-child {
				display: none;
			}
		}
		.media-icon {
			width: 45px;
			height: 45px;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			top: -5px;
			margin-right: 15px;
			border-radius: 100%;
			display: none;
			.typcn {
				line-height: .9;
				font-size: 24px;
			}
		}
		label {
			text-transform: uppercase;
			font-size: 9px;
			font-weight: 700;
			letter-spacing: .5px;
			color: #031b4e;
			margin-bottom: 8px;
		}
		h4 {
			font-size: 20px;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 600;
			color: $default-color;
			line-height: 1;
			letter-spacing: -1px;
			margin-bottom: 3px;
			span {
				color: #031b4e;
				font-weight: 400;
			}
		}
		.media-body>span {
			display: block;
			font-size: 10px;
			color: #97a3b9;
			strong {
				font-weight: 500;
				color: #031b4e;
			}
		}
		p {
			font-size: 11px;
			margin-bottom: 0;
			color: #97a3b9;
		}
	}
	.card-body {
		position: relative;
		padding: 20px 5px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-twelve .sales-overview {
		margin-bottom: 10px;
		flex-wrap: nowrap;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .sales-overview .media {
		flex-basis: auto;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .sales-overview .media+.media {
		margin-left: 40px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-twelve .sales-overview .media+.media {
		margin-left: 50px;
	}
}
@media (max-width: 575.98px) {
	.card-dashboard-twelve .sales-overview .media:nth-child(3) {
		margin-left: 0;
		margin-top: 15px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-twelve .sales-overview .media:last-child {
		flex: 1;
		display: block;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .sales-overview label {
		font-size: 10px;
		margin-bottom: 10px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .sales-overview h4 {
		font-size: 28px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .sales-overview .media-body>span {
		font-size: 11px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twelve .card-body {
		padding: 20px;
	}
}
.chart-legend {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	left: 0;
	right: 0;
	z-index: 10;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	div {
		display: flex;
		align-items: center;
		+div {
			margin-left: 15px;
		}
	}
	span {
		width: 10px;
		height: 4px;
		display: block;
		margin-right: 5px;
	}
}
@media (min-width: 576px) {
	.chart-legend div+div {
		margin-left: 30px;
	}
}
@media (min-width: 576px) {
	.chart-legend span {
		width: 40px;
		margin-right: 10px;
	}
}
.chart-wrapper {
	position: relative;
	margin-left: -13px;
	margin-right: -13px;
}
@media (min-width: 576px) {
	.chart-wrapper {
		margin-left: -10px;
		margin-right: -15px;
	}
}
.card-dashboard-twelve .flot-chart {
	width: 100%;
	height: 200px;
	position: relative;
	.flot-x-axis>div span {
		&:first-child {
			display: block;
			text-transform: uppercase;
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 500;
			font-size: 10px;
			color: #8587a7;
		}
		&:last-child {
			display: block;
			font-size: 16px;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			line-height: 1;
		}
	}
}
@media (min-width: 768px) {
	.card-dashboard-twelve .flot-chart {
		height: 250px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-twelve .flot-chart {
		height: 300px;
	}
}
.main-media-date {
	h1 {
		font-size: 42px;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		line-height: .95;
	}
	.media-body {
		margin-left: 5px;
	}
	p {
		color: $default-color;
		font-weight: 700;
		margin-bottom: 0;
		text-transform: uppercase;
	}
	span {
		display: block;
		line-height: 1.2;
	}
}
.card-dashboard-thirteen {
	border-color: #e8e7ef;
	padding: 20px;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	.main-content-label, .card-table-two .card-title {
		font-size: 13px;
	}
}
.card-table-two .card-dashboard-thirteen .card-title, .card-dashboard-thirteen .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-thirteen .card-title {
	font-size: 13px;
}
.card-dashboard-thirteen {
	.media {
		margin-bottom: 15px;
	}
	.media-icon {
		font-size: 45px;
		line-height: 1;
		margin-right: 10px;
		display: flex;
		i {
			line-height: 0;
		}
	}
	.media-body {
		h6 {
			font-size: 22px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			letter-spacing: -.5px;
			margin-bottom: 2px;
			span {
				font-weight: 400;
				color: #031b4e;
			}
		}
		label {
			font-weight: 700;
			font-size: 12px;
			text-transform: uppercase;
			margin-bottom: 0;
			display: block;
			letter-spacing: .5px;
		}
	}
}
.card-dashboard-events {
	.card-header {
		padding: 20px;
		background-color: transparent;
	}
	.card-subtitle {
		color: $default-color;
		font-weight: 400;
		font-size: 18px;
	}
	.card-body {
		padding: 0;
	}
	.list-group-item {
		padding: 15px 20px 15px 40px;
		border-left-width: 0;
		border-right-width: 0;
		position: relative;
		&:first-child, &:last-child {
			border-radius: 0;
		}
		label {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 10px;
			color: #596882;
			margin-bottom: 5px;
			letter-spacing: .5px;
			span {
				color: #97a3b9;
				font-weight: 500;
			}
		}
		h6 {
			font-size: 14px;
			font-weight: 700;
			color: $default-color;
			margin-bottom: 5px;
			display: flex;
			align-items: center;
		}
		p {
			margin-bottom: 5px;
			strong {
				font-weight: 500;
			}
		}
		small {
			font-size: 11px;
			span {
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}
	.event-indicator {
		width: 10px;
		height: 10px;
		position: absolute;
		top: 21px;
		left: 20px;
		border-radius: 100%;
	}
	.event-user {
		margin-top: 10px;
		display: flex;
		align-items: center;
		.main-img-user {
			width: 28px;
			height: 28px;
			&::after {
				display: none;
			}
			+.main-img-user {
				margin-left: -6px;
			}
		}
		a:last-child {
			margin-left: 10px;
			color: #8587a7;
			font-weight: 500;
			font-size: 11px;
			&:hover, &:focus {
				color: #596882;
			}
		}
	}
}
.card-dashboard-fourteen {
	border-color: #e8e7ef;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	padding: 20px;
	.main-content-label, .card-table-two .card-title {
		font-size: 0.875rem;
		margin-bottom: 20px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-fourteen {
		height: 100%;
	}
}
.card-table-two .card-dashboard-fourteen .card-title, .card-dashboard-fourteen .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-fourteen .card-title {
	font-size: 0.875rem;
	margin-bottom: 20px;
}
.card-dashboard-fourteen {
	.main-content-label span, .card-table-two .card-title span {
		font-weight: 400;
		color: #97a3b9;
		text-transform: none;
	}
}
.card-table-two .card-dashboard-fourteen .card-title span, .card-dashboard-fourteen .card-dashboard-eight .card-title span, .card-dashboard-eight .card-dashboard-fourteen .card-title span {
	font-weight: 400;
	color: #97a3b9;
	text-transform: none;
}
.card-dashboard-fourteen {
	.card-body {
		padding: 0;
		min-height: 100px;
		h1 {
			color: $default-color;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 600;
			letter-spacing: -1px;
			margin-bottom: 0;
			span {
				color: #97a3b9;
				letter-spacing: normal;
				font-weight: 400;
				margin-right: 5px;
			}
		}
		h4 {
			color: $default-color;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			margin-bottom: 0;
			letter-spacing: -.5px;
		}
		.progress {
			margin-bottom: 2px;
		}
		small {
			font-size: 11px;
			color: #97a3b9;
		}
	}
	.sparkline-wrapper canvas {
		width: 100% !important;
	}
	.card-footer {
		background-color: transparent;
		padding: 0;
		margin-top: 15px;
		border-top-width: 0;
		h6 {
			color: $default-color;
			font-size: 20px;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			margin-bottom: 0;
			letter-spacing: -.5px;
		}
		label {
			display: block;
			margin-bottom: 0;
			color: #97a3b9;
			font-size: 11px;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-fourteen .card-footer label {
		font-size: 0.875rem;
	}
}
/* ###### 8.5 Dashboard Five  ###### */

.main-body-sidebar.main-light .main-sidebar {
	background-color: #f4f5f8;
	border-right-color: #e2e8f5;
	box-shadow: 2px 0 3px rgba(28, 39, 60, 0.04);
}
.main-content-dashboard-five {
	background-color: #eeeff4;
	transition: all 0.4s;
	.main-header {
		background-color: #fcfcfc;
		box-shadow: 0 2px 3px rgba(28, 39, 60, 0.05);
	}
	.main-header-menu-icon {
		margin-right: 0;
	}
	.main-header-center .form-control {
		border-color: #e8e7ef;
	}
	.main-content-header {
		padding: 20px 20px 0;
	}
	.main-content-body {
		padding: 20px;
	}
	.card {
		background-color: #fcfcfc;
		border-color: #e8e7ef;
		box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	}
	.card-header {
		padding: 20px 20px 0;
		background-color: transparent;
	}
	.card-text {
		font-size: 12px;
		line-height: 1.4;
		color: #97a3b9;
		margin-bottom: 0;
	}
}
@media (prefers-reduced-motion: reduce) {
	.main-content-dashboard-five {
		transition: none;
	}
}
@media (min-width: 1200px) {
	.main-content-dashboard-five .main-content-header {
		padding: 25px 25px 0;
	}
}
.card-dashboard-fifteen {
	padding: 20px 20px 0;
	overflow: hidden;
	h1 {
		font-size: 40px;
		font-weight: 500;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		margin-bottom: 0;
		line-height: 1;
		span {
			display: inline;
			font-size: 18px;
			color: #8587a7;
		}
	}
	label {
		font-size: 14px;
		font-weight: 500;
		display: block;
	}
	span {
		color: #97a3b9;
		display: block;
		font-size: 12px;
	}
	.chart-wrapper {
		position: relative;
		margin-left: -32px;
		margin-right: -32px;
		margin-bottom: -10px;
	}
	.flot-chart {
		width: 100%;
		height: 127px;
		position: relative;
		margin-top: 20px;
		.flot-x-axis>div:nth-child(4) {
			color: $default-color !important;
		}
	}
}
.table-talk-time {
	margin-bottom: 0;
	thead>tr {
		th, td {
			padding: 7px 10px;
		}
	}
	tbody>tr {
		th, td {
			padding: 7px 10px;
		}
	}
	thead>tr {
		th:first-child, td:first-child {
			padding-left: 20px;
		}
	}
	tbody>tr {
		th:first-child, td:first-child {
			padding-left: 20px;
		}
	}
}
.card-dashboard-sixteen {
	.main-img-user::after {
		display: none;
	}
	.table tbody>tr {
		background-color: transparent;
		th, td {
			padding: 10px 10px 10px 0;
			vertical-align: middle;
			white-space: nowrap;
		}
		th:first-child, td:first-child {
			width: 10%;
		}
		th:nth-child(2), td:nth-child(2) {
			width: 60%;
		}
		th:last-child, td:last-child {
			width: 30%;
		}
	}
}
.dashboard-five-stacked-chart {
	height: 200px;
}
@media (min-width: 768px) {
	.dashboard-five-stacked-chart {
		height: 240px;
	}
}
@media (min-width: 847px) {
	.dashboard-five-stacked-chart {
		height: 260px;
	}
}
@media (min-width: 992px) {
	.dashboard-five-stacked-chart {
		height: 240px;
	}
}
@media (min-width: 1067px) {
	.dashboard-five-stacked-chart {
		height: 260px;
	}
}
/* ###### 8.6 Dashboard Six  ###### */

.main-light {
	.main-iconbar, .main-iconbar-aside {
		background-color: #e8e7ef;
	}
	.main-iconbar-body {
		.nav {
			border-left-color: #e2e8f5;
		}
		.nav-item+.nav-item, .nav-sub, .nav-sub-item+.nav-sub-item {
			border-top-color: #bdc6d6;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.main-light .main-iconbar-aside.show+.main-content {
		box-shadow: none;
	}
}
.main-content-dashboard-six {
	border-left-color: #e2e8f5;
	box-shadow: 0 0 7px rgba(28, 39, 60, 0.08);
	position: relative;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #f4f5f8;
}
@media (min-width: 992px) {
	.main-content-dashboard-six {
		border-left: 1px solid $border;
		margin-left: 64px;
		z-index: 550;
	}
}
.main-header-dashboard-six {
	background-color: $white;
	border-bottom: 1px solid $border;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
	.main-header-menu-icon {
		margin-right: 0;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.main-header-dashboard-six .main-header-center {
		margin-left: 5px;
	}
}
@media (min-width: 1200px) {
	.main-header-dashboard-six .main-header-center {
		margin-left: 0;
	}
}
@media (min-width: 992px) {
	.main-header-dashboard-six .main-header-menu-icon {
		display: none;
	}
}
.main-content-body-dashboard-six {
	padding: 25px;
	.main-content-title {
		letter-spacing: -.8px;
	}
	.card {
		border-color: #e8e7ef;
		box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
	}
	.card-header {
		background-color: transparent;
		padding: 20px 20px 0;
	}
	.card-title {
		text-transform: uppercase;
		font-weight: 700;
	}
}
.card-dashboard-donut {
	.card-body {
		padding: 25px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-donut-chart {
		&.chart1 {
			width: 140px;
			height: 140px;
			background: #e2e8f5;
			.slice {
				&.one {
					clip: rect(0 140px 70px 0);
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
					background: $purple;
				}
				&.two {
					clip: rect(0 70px 140px 0);
					-webkit-transform: rotate(270deg);
					transform: rotate(270deg);
					background: $purple;
				}
			}
			.chart-center {
				top: 10px;
				left: 10px;
				width: 120px;
				height: 120px;
				background: $white;
				span {
					font-size: 40px;
					line-height: 120px;
					color: $purple;
					&:after {
						content: "75%";
					}
				}
			}
		}
		&.chart2 {
			width: 140px;
			height: 140px;
			background: #e2e8f5;
			.slice {
				&.one {
					clip: rect(0 140px 70px 0);
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
					background: $primary;
				}
				&.two {
					clip: rect(0 70px 140px 0);
					-webkit-transform: rotate(244.8deg);
					transform: rotate(244.8deg);
					background: $primary;
				}
			}
			.chart-center {
				top: 10px;
				left: 10px;
				width: 120px;
				height: 120px;
				background: $white;
				span {
					font-size: 40px;
					line-height: 120px;
					color: $primary;
					&:after {
						content: "68%";
					}
				}
			}
		}
		&.chart1 .chart-center span, &.chart2 .chart-center span {
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-size: 32px;
		}
	}
}
.card-dashboard-balance {
	position: relative;
	overflow: hidden;
	.card-body {
		padding: 20px;
		position: relative;
		z-index: 5;
	}
	.fab {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 48px;
		color: $primary;
		z-index: 5;
		background-color: $white;
	}
	.main-content-label, .card-table-two .card-title {
		color: #8587a7;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 1px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance {
		min-height: 260px;
		height: 100%;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance .card-body {
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
.card-table-two .card-dashboard-balance .card-title, .card-dashboard-balance .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-balance .card-title {
	color: #8587a7;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
}
@media (min-width: 576px) {
	.card-dashboard-balance {
		.main-content-label, .card-table-two .card-title {
			font-size: 11px;
		}
	}
	.card-table-two .card-dashboard-balance .card-title, .card-dashboard-balance .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-balance .card-title {
		font-size: 11px;
	}
}
.card-dashboard-balance {
	.balance-amount {
		color: $default-color;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-weight: 600;
		font-size: 28px;
		letter-spacing: -1px;
		margin-bottom: 20px;
		span {
			color: #97a3b9;
			font-weight: 400;
			letter-spacing: normal;
		}
	}
	.account-number {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: 700;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		letter-spacing: -1px;
		margin-bottom: 20px;
		span {
			display: flex;
			align-items: center;
			line-height: 1;
			+span {
				margin-left: 20px;
			}
			i {
				width: 5px;
				height: 5px;
				background-color: $default-color;
				border-radius: 100%;
				+i {
					margin-left: 5px;
				}
			}
		}
	}
	.account-name {
		color: $default-color;
		font-weight: 400;
		margin-bottom: 0;
	}
	.chart-wrapper {
		position: absolute;
		top: 20px;
		bottom: 10px;
		left: -10px;
		right: -10px;
		opacity: .3;
		z-index: 4;
	}
	.flot-chart {
		width: 100%;
		height: 100%;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance .balance-amount {
		font-size: 36px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance .account-number {
		font-size: 28px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance .account-number span+span {
		margin-left: 30px;
	}
}
@media (min-width: 1200px) {
	.card-dashboard-balance .account-number span+span {
		margin-left: 50px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-balance .account-number span i {
		width: 10px;
		height: 10px;
	}
}
@media (min-width: 1200px) {
	.card-dashboard-ratio {
		height: 350px;
	}
}
.card-dashboard-ratio {
	.card-body {
		display: flex;
		align-items: center;
		+.card-body {
			padding-top: 0px;
		}
		>div {
			&:first-child {
				width: 140px;
				display: none;
				align-items: center;
				justify-content: center;
			}
			&:last-child {
				flex: 1;
			}
		}
		h5 {
			font-size: 28px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			letter-spacing: -1px;
			margin-bottom: 2px;
		}
	}
	.card-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #e2e8f5;
		font-size: 140px;
		.typcn {
			line-height: 1;
			&::before {
				width: auto;
			}
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-ratio .card-body>div:first-child {
		display: flex;
	}
}
@media (min-width: 576px) {
	.card-dashboard-ratio .card-body>div:last-child {
		margin-left: 30px;
	}
}
.card-dashboard-finance {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	.card-title {
		margin-bottom: 20px;
	}
	h2 {
		line-height: 1;
		color: $default-color;
		font-weight: 600;
		font-size: 30px;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		letter-spacing: -1px;
		margin-top: 10px;
		margin-bottom: 2px;
		span {
			letter-spacing: normal;
			color: #97a3b9;
			font-weight: 400;
			margin-right: 5px;
		}
	}
}
@media (min-width: 992px) {
	.card-dashboard-finance {
		min-height: 172px;
	}
}
@media (min-width: 1200px) {
	.card-dashboard-finance {
		min-height: 165px;
	}
}
@media (min-width: 992px) {
	.card-dashboard-finance .card-title {
		margin-bottom: auto;
	}
}
.card-dashboard-table-six {
	.card-title {
		padding: 15px 20px;
		margin-bottom: 0;
		border-bottom: 1px solid $border;
	}
	.table-responsive {
		width: calc(100vw - 42px);
	}
	.table {
		margin-bottom: 0;
		thead tr {
			th {
				white-space: nowrap;
			}
			&:first-child {
				background-color: #e8e7ef;
				th {
					padding: 10px 12px;
					line-height: 1;
					&:last-child {
						border-left: 1px solid $border;
					}
				}
			}
			&:last-child th {
				width: 13%;
				padding: 10px 12px;
				line-height: 1;
				text-align: right;
				&:first-child {
					width: 22%;
				}
				&:nth-child(2), &:nth-child(6) {
					border-left: 1px solid $border;
				}
			}
		}
		tbody tr {
			td {
				text-align: right;
				white-space: nowrap;
				&:first-child {
					text-align: left;
				}
				&:nth-child(2), &:nth-child(6) {
					border-left: 1px solid $border;
				}
			}
			&:last-child td {
				font-weight: 500;
				color: $default-color;
			}
		}
	}
}
@media (min-width: 992px) {
	.card-dashboard-table-six .table-responsive {
		width: 100%;
	}
}
/* ###### 8.7 Dashboard Seven  ###### */

.main-minimal {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: $white;
	.main-navbar-three {
		.nav-item.active {
			&::before {
				bottom: -3px;
			}
			.nav-link {
				font-weight: 700;
			}
		}
		.nav-link {
			font-size: 13px;
		}
	}
	&.main-navbar-show .main-header {
		z-index: auto;
	}
}
@media (min-width: 1200px) {
	.main-minimal {
		padding: 10px 0 0;
	}
}
.main-content-title-minimal {
	color: $default-color;
	font-weight: 500;
	font-size: 22px;
	letter-spacing: -1px;
	line-height: 1;
	margin-bottom: 8px;
	span {
		font-weight: 700;
	}
}
.main-content-text-minimal {
	font-weight: 400;
	color: #596882;
	margin-bottom: 20px;
}
.media-dashboard-one {
	display: block;
	.media-right {
		width: 100%;
		flex-shrink: 0;
	}
	.media-body {
		margin-bottom: 20px;
	}
}
@media (min-width: 992px) {
	.media-dashboard-one {
		display: flex;
		align-items: stretch;
	}
}
@media (min-width: 992px) {
	.media-dashboard-one .media-right {
		width: 280px;
	}
}
@media (min-width: 992px) {
	.media-dashboard-one .media-body {
		margin-right: 20px;
		margin-bottom: 0;
	}
}
.card-minimal-one {
	border-color: #e8e7ef;
	min-height: 320px;
	height: 100%;
	overflow: hidden;
	position: relative;
	.card-body {
		position: relative;
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		z-index: 5;
		> {
			label {
				font-weight: 700;
				font-size: 14px;
				color: $primary;
				text-transform: uppercase;
				margin-bottom: 5px;
				display: block;
				line-height: 1;
				span {
					font-weight: 500;
					color: #596882;
				}
			}
			small {
				font-size: 12px;
				display: block;
				color: #97a3b9;
			}
			h6 {
				color: $default-color;
				font-size: 36px;
				font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				font-weight: 600;
				letter-spacing: -2px;
				display: flex;
				line-height: 1;
				align-items: flex-end;
				margin: 15px 0;
				span {
					font-weight: 400;
					margin-right: 3px;
					color: #031b4e;
				}
			}
		}
	}
	.value-info {
		display: flex;
		align-items: center;
		+.value-info {
			margin-top: 5px;
		}
		p {
			margin-bottom: 0;
			color: $default-color;
			font-weight: 700;
			font-family: 'Archivo', sans-serif;
			font-size: 14px;
			letter-spacing: -.35px;
			span {
				letter-spacing: normal;
				margin-right: 2px;
				color: #031b4e;
			}
		}
		label {
			display: block;
			margin-bottom: 0;
			font-weight: 700;
			width: 40px;
			position: relative;
			top: -2px;
		}
	}
	.open-info {
		display: flex;
		align-items: flex-start;
		margin-top: auto;
		margin-bottom: 25px;
		>div+div {
			margin-left: 25px;
		}
		label {
			font-size: 12px;
			margin-bottom: 2px;
			color: #7f8da9;
		}
		h6 {
			margin-bottom: 0;
			color: $default-color;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 700;
			font-size: 13px;
			span {
				font-weight: 400;
			}
		}
	}
	.chart-wrapper {
		position: absolute;
		left: -9px;
		right: -8px;
		bottom: -13px;
		.flot-x-axis>div {
			transform: translate(20px, -23px);
		}
	}
	.flot-chart {
		width: 100%;
		height: 250px;
		position: relative;
	}
}
.card-minimal-two {
	height: 72px;
	border-color: #e8e7ef;
	background-color: transparent;
	overflow: hidden;
	.card-header {
		background-color: transparent;
		padding: 15px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $border;
		>.nav .nav-link {
			padding: 8px 18px;
			font-weight: 500;
			background-color: #e8e7ef;
			+.nav-link {
				margin-left: 2px;
			}
			&:hover, &:focus {
				background-color: #e2e8f5;
			}
			&.active {
				background-color: $primary;
				color: $white;
			}
		}
	}
	.card-header-right {
		display: flex;
		align-items: center;
		margin-top: 20px;
		.nav {
			justify-content: flex-end;
			width: 100%;
		}
		.nav-link {
			padding: 5px 10px;
			text-transform: capitalize;
			+.nav-link {
				margin-left: 2px;
			}
			span {
				&:first-child {
					display: none;
				}
				&:last-child {
					text-transform: uppercase;
					font-size: 11px;
					font-weight: 500;
				}
			}
			&:hover, &:focus {
				background-color: #e8e7ef;
			}
			&.active {
				background-color: #97a3b9;
				color: $white;
			}
		}
	}
	.card-body {
		position: relative;
		overflow: hidden;
		padding: 0;
	}
	.flot-wrapper {
		position: relative;
		bottom: -14px;
		margin-right: -9px;
		margin-left: -31px;
		margin-top: -25px;
		.flot-x-axis>div {
			transform: translate(22px, -23px);
		}
		.flot-y-axis>div {
			transform: translateX(-10px);
		}
	}
	.flot-chart {
		width: 100%;
		height: 250px;
		position: relative;
	}
	.more-info {
		position: absolute;
		top: 10px;
		right: 10px;
		div {
			display: flex;
			align-items: flex-start;
			font-size: 13px;
		}
		span {
			&:first-child {
				width: 80px;
				text-align: right;
				color: #8587a7;
				display: block;
				line-height: 1.35;
			}
			&:last-child {
				margin-left: 15px;
				color: $default-color;
				font-weight: 700;
				font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				font-size: 14px;
			}
		}
	}
	.card-footer {
		padding: 20px;
		position: relative;
		z-index: 2;
		overflow-y: hidden;
		width: calc(100vw - 40px);
		margin-bottom: -8px;
	}
}
@media (min-width: 576px) {
	.card-minimal-two .card-header {
		display: flex;
		padding: 15px 20px;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .card-header>.nav .nav-link {
		padding: 8px 20px;
	}
}
@media (min-width: 1200px) {
	.card-minimal-two .card-header>.nav .nav-link {
		padding: 8px 30px;
	}
}
@media (min-width: 576px) {
	.card-minimal-two .card-header-right {
		margin-top: 0;
	}
}
@media (min-width: 576px) {
	.card-minimal-two .card-header-right .nav-link {
		padding: 8px 10px;
	}
}
@media (min-width: 768px) {
	.card-minimal-two .card-header-right .nav-link span:first-child {
		display: inline;
	}
}
@media (min-width: 768px) {
	.card-minimal-two .card-header-right .nav-link span:last-child {
		display: none;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .card-body {
		padding: 0 20px;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .flot-wrapper {
		margin-right: -9px;
		margin-left: 10px;
		margin-top: 0;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .flot-chart {
		height: 300px;
	}
}
@media (min-width: 576px) {
	.card-minimal-two .more-info {
		right: 20px;
		top: 30px;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .more-info {
		right: 30px;
		top: 50px;
	}
}
@media (min-width: 992px) {
	.card-minimal-two .card-footer {
		width: auto;
	}
}
.card-bitcoin {
	.media-icon {
		color: #f7931a;
		font-size: 42px;
		line-height: 0;
	}
	.media-body {
		margin-left: 20px;
		margin-bottom: 10px;
	}
	.row {
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow-x: auto;
		>div {
			flex-shrink: 0;
		}
	}
	label {
		margin-bottom: 2px;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 11px;
		letter-spacing: .5px;
		color: #8587a7;
	}
	p {
		margin-bottom: 0;
		color: $default-color;
		font-weight: 700;
		font-size: 16px;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	}
}
.card-minimal-three {
	border-color: #e8e7ef;
	width: calc(100vw - 38px);
	.card-header {
		border-bottom: 1px solid $border;
		background-color: transparent;
		padding: 20px 20px 0;
		.main-content-text {
			margin-bottom: 20px;
		}
	}
	.main-nav-tabs {
		padding: 0;
		background-color: transparent;
		position: relative;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 3px;
		.lSAction>a {
			background-color: #e2e8f5;
			&::before {
				top: -4px;
			}
			&.lSPrev {
				left: -35px;
			}
		}
		.tab-item {
			min-width: 203px;
		}
		.tab-link {
			background-color: #e8e7ef;
			font-weight: 500;
			align-items: flex-start;
			padding-top: 8px;
			&:hover {
				background-color: #e2e8f5;
			}
			&.active {
				background-color: #596882;
				color: $white;
				&:hover, &:focus {
					background-color: #596882;
					color: $white;
				}
			}
		}
	}
	.card-body {
		padding: 0;
	}
	.tab-pane-header {
		padding: 20px;
		background-color: $white;
		border-bottom: 1px solid $border;
		.main-content-label, .card-table-two .card-title {
			margin-bottom: 3px;
		}
	}
}
@media (min-width: 992px) {
	.card-minimal-three {
		width: 634px;
	}
}
@media (min-width: 1200px) {
	.card-minimal-three {
		width: 753px;
	}
}
@media (min-width: 576px) {
	.card-minimal-three .main-nav-tabs .tab-item {
		min-width: 150px;
	}
}
@media (min-width: 576px) {
	.card-minimal-three .tab-pane-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.card-table-two .card-minimal-three .tab-pane-header .card-title, .card-minimal-three .tab-pane-header .card-dashboard-eight .card-title, .card-dashboard-eight .card-minimal-three .tab-pane-header .card-title {
	margin-bottom: 3px;
}
.card-minimal-three {
	.tab-pane-header>div:last-child {
		display: flex;
		margin-top: 15px;
		a {
			flex: 1;
			+a {
				margin-left: 10px;
			}
		}
	}
	.tab-pane-body {
		padding: 20px;
		.table {
			thead tr {
				th, td {
					white-space: nowrap;
				}
			}
			tbody tr {
				th, td {
					white-space: nowrap;
				}
			}
		}
	}
	.no-transactions-panel {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.icon-wrapper {
			width: 80px;
			height: 80px;
			background-color: #e8e7ef;
			color: #97a3b9;
			font-size: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			border-radius: 100%;
			i {
				line-height: 0;
			}
		}
		h6 {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 15px;
			color: $default-color;
			letter-spacing: 1px;
			margin-bottom: 2px;
		}
	}
	.table tbody tr td {
		vertical-align: middle;
		span {
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 100%;
		}
		&:last-child {
			font-weight: 500;
			color: $default-color;
		}
	}
}
.card-crypto-portfolio {
	width: 100%;
	border-color: #e8e7ef;
	margin-top: 20px;
}
.crypt-primary.crypto .card-footer .nav-link {
	background-color: $primary-006;
}
.crypt-danger.crypto .card-footer .nav-link {
	background-color: rgb(255, 248, 248);
}
.crypt-success.crypto .card-footer .nav-link {
	background-color: rgb(244, 253, 250);
}
.crypt-primary.crypto .card-footer .nav-link {
	&:hover, &:focus {
		background-color: $primary-015 !important;
	}
}
.crypt-danger.crypto .card-footer .nav-link {
	&:hover, &:focus {
		background-color: #ffefef !important;
	}
}
.crypt-success.crypto .card-footer .nav-link {
	&:hover, &:focus {
		background-color: #e3f9f3 !important;
	}
}
@media (min-width: 992px) {
	.card-crypto-portfolio {
		width: calc(33.33333% - 13px);
		margin-top: 0;
	}
}
.card-crypto-portfolio {
	.card-title {
		text-transform: uppercase;
	}
	.table {
		margin-bottom: 0;
		thead tr {
			th, td {
				padding: 10px 0;
				text-align: right;
				white-space: nowrap;
			}
		}
		tbody tr {
			th, td {
				padding: 10px 0;
				text-align: right;
				white-space: nowrap;
			}
		}
		thead tr {
			th:first-child, td:first-child {
				text-align: left;
			}
		}
		tbody tr {
			th:first-child, td:first-child {
				text-align: left;
			}
		}
		thead tr th {
			font-size: 10px;
		}
		tbody tr td:first-child {
			font-weight: 500;
			color: $default-color;
		}
	}
}
.card-minimal-four {
	border-color: #e8e7ef;
	.card-header {
		background-color: transparent;
		border-bottom: 1px solid $border;
		h6 {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 2px;
			color: $default-color;
		}
		p {
			margin-bottom: 0;
			white-space: nowrap;
			span {
				color: $danger;
			}
		}
		.media {
			align-items: center;
		}
		.media-body {
			margin-left: 12px;
		}
	}
	.coin-logo {
		width: 42px;
		height: 42px;
		color: $white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		i {
			line-height: 0;
		}
	}
	.card-body {
		padding: 0;
		overflow: hidden;
		position: relative;
		background-color: #fcfcfc;
	}
	.card-body-top {
		top: 15px;
		left: 10px;
		position: absolute;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
		z-index: 8;
		a {
			color: $default-color;
			font-weight: 700;
			width: 20px;
			text-align: right;
			display: inline-block;
			&:hover, &:focus {
				color: $primary;
			}
		}
		div+div {
			margin-top: 5px;
		}
	}
	.chart-wrapper {
		position: relative;
		margin-left: -20px;
		margin-right: -9px;
		margin-bottom: -17px;
	}
	.flot-chart {
		width: 100%;
		height: 150px;
	}
	.card-footer {
		background-color: transparent;
		border-top-color: #e2e8f5;
		padding: 0;
		.nav-link {
			flex: 1;
			display: block;
			text-align: center;
			background-color: #e8e7ef;
			position: relative;
			padding-left: 10px;
			padding-right: 10px;
			&:hover, &:focus {
				background-color: #eeeff4;
			}
			span {
				display: block;
				&:first-child {
					color: #97a3b9;
					font-weight: 700;
					font-size: 11px;
				}
				&:last-child {
					color: #031b4e;
					font-weight: 500;
					font-size: 11px;
					font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				}
			}
			+.nav-link {
				border-left: 1px solid $border;
			}
			&.active {
				background-color: #fcfcfc;
				&::before {
					content: '';
					position: absolute;
					top: -1px;
					left: 0;
					right: 0;
					border-top: 1px solid #f7f7f7;
					z-index: 5;
				}
				span:first-child {
					color: $default-color;
				}
			}
		}
	}
}
/* ###### 8.8 Dashboard Eight  ###### */

.main-dashboard-eight {
	background-color: #dde2e9;
}
.main-header-primary {
	background-color: #0040ff;
	.main-header-center {
		.form-control {
			background-color: #0033cc;
			border-width: 0;
			height: 38px;
			border-radius: 2px;
			color: $white;
			&::placeholder {
				color: $white-4;
			}
			&:focus+.btn {
				color: $white;
			}
		}
		.sp-container .form-control:focus+button {
			color: $white;
		}
	}
}
.sp-container .main-header-primary .main-header-center .form-control:focus+button {
	color: $white;
}
.main-header-primary .main-header-center {
	.btn, .sp-container button {
		font-size: 14px;
	}
}
.sp-container .main-header-primary .main-header-center button {
	font-size: 14px;
}
.main-header-primary .main-header-center {
	.btn:hover, .sp-container button:hover {
		color: $white;
	}
}
.sp-container .main-header-primary .main-header-center button:hover {
	color: $white;
}
.main-header-primary .main-header-center {
	.btn:focus, .sp-container button:focus {
		color: $white;
	}
}
.sp-container .main-header-primary .main-header-center button:focus {
	color: $white;
}
.main-header-primary {
	.main-logo {
		color: $white;
		font-weight: 600;
		letter-spacing: -1.2px;
		span {
			color: rgba(255, 255, 255, 0.65);
		}
	}
	.main-header-menu-icon span {
		background-color: rgba(255, 255, 255, 0.65);
		&::before, &::after {
			background-color: rgba(255, 255, 255, 0.65);
		}
	}
	.main-header-message>a {
		color: rgba(255, 255, 255, 0.75);
	}
	.main-header-notification {
		>a {
			color: rgba(255, 255, 255, 0.75);
			&::after {
				display: none;
			}
		}
		.dropdown-menu {
			border-width: 0;
			box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
		}
	}
	.main-profile-menu {
		.dropdown-menu {
			border-width: 0;
			box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
		}
		&.show .main-img-user::before {
			display: none;
		}
	}
}
@media (min-width: 576px) {
	.main-header-primary .main-header-notification .dropdown-menu {
		top: 43px;
	}
}
@media (min-width: 992px) {
	.main-header-primary .main-header-notification .dropdown-menu {
		top: 45px;
	}
}
@media (min-width: 576px) {
	.main-header-primary .main-profile-menu .dropdown-menu {
		top: 46px;
	}
}
@media (min-width: 992px) {
	.main-header-primary .main-profile-menu .dropdown-menu {
		top: 48px;
	}
}
.main-navbar-dashboard-eight {
	background-color: #f9f9f9;
	border-bottom-width: 0;
	.main-navbar-header {
		background-color: #0040ff;
		border-bottom-width: 0;
		color: $white;
		.main-logo {
			color: $white;
		}
	}
	.nav-item.active {
		&::before {
			border-bottom-color: #1a53ff;
		}
		.nav-link {
			color: #1a53ff;
		}
	}
	.nav-link {
		text-transform: capitalize;
		font-weight: 500;
		font-size: 14px;
	}
	.nav-sub:not(.nav-sub-mega) {
		background-color: #f9f9f9;
		border-width: 0;
		&::before {
			content: '';
			position: absolute;
			top: -5px;
			left: -5px;
			right: -5px;
			height: 5px;
			background-color: #f9f9f9;
			z-index: 4;
		}
	}
	.nav-sub-item {
		>.nav-sub {
			left: 180px;
			&::before {
				left: -5px;
				top: -5px;
				bottom: -5px;
				right: auto;
				width: 5px;
				height: auto;
			}
		}
		&.active .nav-sub-link {
			color: $primary;
		}
	}
	.nav-sub-link:hover {
		color: $primary;
	}
	.nav-sub-mega {
		.container, .container-fluid {
			background-color: #f9f9f9;
			border-width: 0;
		}
	}
}
@media (min-width: 992px) {
	.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
		box-shadow: 0 0 5px rgba(28, 39, 60, 0.12);
	}
}
@media (min-width: 992px) {
	.main-navbar-dashboard-eight .nav-sub-mega {
		.container, .container-fluid {
			box-shadow: 0 5px 3px -3px rgba(28, 39, 60, 0.12);
		}
	}
}
.main-content-dashboard-eight {
	padding-top: 25px;
	.main-content-title {
		letter-spacing: -.8px;
	}
	.card {
		border-width: 0;
		border-radius: 2px;
		box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
	}
	.card-title {
		font-weight: 500;
	}
}
@media (max-width: 575px) {
	.main-content-dashboard-eight .container {
		padding-left: 20px;
		padding-right: 20px;
	}
}
.card-dashboard-seventeen {
	position: relative;
	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 180px;
		position: relative;
		z-index: 8;
		h4 {
			margin-bottom: 0;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
		}
		span {
			font-size: 11px;
		}
	}
	.card-title {
		line-height: 1.3;
	}
	&.bg-primary-dark {
		background-color: #0033cc;
	}
	.chart-wrapper {
		position: absolute;
		right: -8px;
		bottom: -8px;
		left: -8px;
	}
	.flot-chart {
		width: 100%;
		height: 180px;
	}
}
.card-dashboard-eighteen {
	display: block;
	padding: 20px;
	.card-title {
		font-weight: 700;
		text-transform: uppercase;
	}
	.card-body {
		padding: 20px 0 34px;
		align-items: flex-start;
		h6 {
			color: $default-color;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 600;
			font-size: 21px;
			letter-spacing: -.8px;
			margin-bottom: 2px;
			position: relative;
			padding-left: 23px;
			span {
				font-weight: 400;
				color: #97a3b9;
				letter-spacing: normal;
			}
			&::before {
				content: '';
				position: absolute;
				display: block;
				top: calc(50% - 8px);
				left: 0;
				width: 16px;
				height: 16px;
				border-radius: 100%;
				border: 4px solid transparent;
			}
			&.dot-primary::before {
				border-color: $primary;
			}
			&.dot-purple::before {
				border-color: $purple;
			}
			&.dot-teal::before {
				border-color: $teal;
			}
			&.dot-dark-blue::before {
				border-color: #0033cc;
			}
		}
		label {
			color: #8587a7;
			margin-bottom: 0;
			display: block;
		}
	}
	.chartjs-wrapper {
		height: 309px;
		position: relative;
		margin-left: -10px;
		margin-right: -5px;
	}
}
.card-dashboard-nineteen {
	overflow: hidden;
	position: relative;
	background-color: #f9f9f9;
	.card-header {
		padding: 20px 20px 0;
		background-color: transparent;
		.row {
			display: block;
			>div {
				max-width: none;
				+div {
					margin-top: 20px;
				}
			}
		}
		h4 {
			font-size: 24px;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			font-weight: 600;
			letter-spacing: -1px;
			color: $default-color;
			margin-bottom: 10px;
			span {
				font-weight: 400;
				letter-spacing: normal;
				color: #97a3b9;
			}
		}
		.main-content-label, .card-table-two .card-title {
			text-transform: none;
			line-height: 1.3;
			margin-bottom: 5px;
			font-size: 0.875rem;
		}
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .card-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 8;
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .card-header .row {
		display: flex;
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .card-header .row>div+div {
		margin-top: 0;
	}
}
@media (min-width: 576px) {
	.card-dashboard-nineteen .card-header h4 {
		font-size: 30px;
	}
}
.card-table-two .card-dashboard-nineteen .card-header .card-title, .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
	text-transform: none;
	line-height: 1.3;
	margin-bottom: 5px;
	font-size: 0.875rem;
}
@media (min-width: 992px) {
	.card-dashboard-nineteen .card-header {
		.main-content-label, .card-table-two .card-title {
			font-size: 14px;
		}
	}
	.card-table-two .card-dashboard-nineteen .card-header .card-title, .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
		font-size: 14px;
	}
}
.card-dashboard-nineteen .card-header {
	p {
		font-size: 11px;
		color: #8587a7;
		margin-bottom: 0;
	}
	.btn, .sp-container button {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 11px;
		letter-spacing: .5px;
		padding: 5px 20px;
		min-height: inherit;
		border-width: 2px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-nineteen .card-header p {
		font-size: 12px;
	}
}
.sp-container .card-dashboard-nineteen .card-header button {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: .5px;
	padding: 5px 20px;
	min-height: inherit;
	border-width: 2px;
}
.card-dashboard-nineteen {
	.card-title {
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 15px;
		color: #0040ff;
	}
	.chart-legend {
		margin-top: 22px;
		display: flex;
		align-items: center;
		>div {
			position: relative;
			text-transform: uppercase;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: .5px;
			&::before {
				content: '';
				position: relative;
				top: 1.5px;
				display: inline-block;
				width: 10px;
				height: 10px;
				margin-right: 5px;
			}
			&:first-child::before {
				background-color: #05478f;
			}
			&:nth-child(2)::before {
				background-color: #2f93fe;
			}
			&:last-child::before {
				background-color: #bbdafc;
			}
			+div {
				margin-left: 10px;
			}
		}
	}
	.card-body {
		padding: 0;
		position: relative;
	}
	.flot-chart-wrapper {
		position: relative;
		margin: -30px -8px -16px -47px;
	}
	.flot-chart {
		width: 100%;
		height: 250px;
		.flot-x-axis {
			transform: translate(18px, -25px);
			color: $white;
			font-size: 10px;
			text-transform: uppercase;
			letter-spacing: 1px;
			opacity: .5;
		}
		.flot-y-axis {
			transform: translate(66px, -13px);
			color: rgba(0, 0, 0, 0.65);
			font-weight: 400;
			font-size: 10px;
			letter-spacing: .5px;
		}
	}
}
@media (min-width: 768px) {
	.card-dashboard-nineteen .chart-legend {
		margin-left: 70px;
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .chart-legend>div {
		letter-spacing: 1px;
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .chart-legend>div+div {
		margin-left: 30px;
	}
}
@media (min-width: 375px) and (max-width: 575px) {
	.card-dashboard-nineteen .card-body {
		padding-top: 170px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-nineteen .card-body {
		padding-top: 130px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-nineteen .card-body {
		padding-top: 95px;
	}
}
@media (min-width: 375px) {
	.card-dashboard-nineteen .flot-chart-wrapper {
		margin-top: 0;
	}
}
@media (min-width: 576px) {
	.card-dashboard-nineteen .flot-chart {
		height: 314px;
	}
}
.card-dashboard-twenty {
	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.chartjs-wrapper {
		width: 100%;
		height: 230px;
	}
	.expansion-value {
		display: flex;
		justify-content: space-between;
		font-size: 17px;
		font-weight: 700;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		letter-spacing: -.5px;
		margin-bottom: 5px;
		margin-top: auto;
		strong {
			&:first-child {
				color: $default-color;
			}
			&:last-child {
				color: #8587a7;
			}
		}
	}
	.progress {
		margin-bottom: 3px;
		height: 4px;
	}
	.expansion-label {
		display: flex;
		justify-content: space-between;
		span {
			font-weight: 400;
			font-size: 11px;
			color: #97a3b9;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.card-dashboard-twenty .chartjs-wrapper {
		margin-top: auto;
	}
}
.card-dashboard-progress {
	.progress-legend {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;
		li {
			position: relative;
			text-transform: uppercase;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: 1px;
			padding-left: 15px;
			line-height: .95;
			&::before {
				content: '';
				position: absolute;
				top: 1px;
				left: 0;
				width: 8px;
				height: 8px;
			}
			&:first-child::before {
				background-color: $primary;
			}
			&:last-child::before {
				background-color: $teal;
			}
			+li {
				margin-left: 20px;
			}
		}
	}
	.media {
		display: block;
		>label {
			width: 50px;
			display: block;
			margin-bottom: 0;
		}
		+.media {
			margin-top: 15px;
		}
	}
	.media-body {
		margin-top: 5px;
	}
	.progress {
		background-color: #e8e7ef;
	}
	.progress-bar {
		height: 15px;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-weight: 600;
		+.progress-bar {
			margin-left: 1px;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-progress .media {
		display: flex;
		align-items: center;
	}
}
@media (min-width: 576px) {
	.card-dashboard-progress .media-body {
		margin-top: 0;
		margin-left: 15px;
	}
}
/* ###### 8.9 Dashboard Nine  ###### */

.main-body-dashboard-nine {
	background-color: #e2e8f5;
}
@media (min-width: 992px) {
	.main-body-dashboard-nine .main-header .container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media (min-width: 992px) {
	.main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
		left: 0;
	}
}
.main-sidebar-indigo-dark {
	background-color: #4130c5;
	border-right-width: 0;
	.main-logo {
		color: $white;
		letter-spacing: -1.4px;
	}
	.main-sidebar-loggedin .media-body {
		h6 {
			color: $white;
			font-weight: 500;
		}
		span {
			color: $white-5;
		}
	}
	.main-img-user::after {
		box-shadow: none;
	}
	.main-sidebar-body {
		.nav-label {
			border-bottom-color: $white-2;
			color: rgba(255, 255, 255, 0.35);
			font-weight: 400;
		}
		.nav-item {
			+.nav-item {
				margin-top: -1px;
				&::before {
					border-top-color: rgba(255, 255, 255, 0.12);
				}
			}
			&.active {
				position: relative;
				z-index: 5;
				&::before {
					content: '';
					position: absolute;
					top: -1px;
					left: -20px;
					right: -20px;
					background-color: #33269c;
					height: 41px;
				}
				.nav-link {
					font-weight: 500;
					color: $white;
					&:hover, &:focus {
						font-weight: 500;
						color: $white;
					}
					i, &:hover i, &:focus i {
						color: $white;
					}
				}
			}
		}
		.nav-link {
			color: rgba(255, 255, 255, 0.45);
			font-weight: 400;
			i {
				color: rgba(255, 255, 255, 0.75);
			}
			&.with-sub::after {
				opacity: .3;
			}
			&:hover, &:focus {
				color: rgba(255, 255, 255, 0.75);
			}
		}
		.nav-sub {
			border-left-color: $white-1;
			padding-left: 0;
			padding-bottom: 0;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: -32px;
				right: -20px;
				bottom: 0;
				background-color: $black-1;
				z-index: 4;
			}
		}
		.nav-sub-item.active .nav-sub-link {
			color: $white;
		}
		.nav-sub-link {
			padding-left: 21px;
			color: $white-4;
			border-top-color: $white-1;
			position: relative;
			z-index: 5;
			&:hover, &:focus {
				color: $white;
			}
		}
	}
}
.main-header-dashboard-nine {
	background-color: $white;
	.main-header-menu-icon {
		margin-right: 0;
	}
}
@media (min-width: 992px) {
	.main-header-dashboard-nine {
		position: fixed;
		top: 0;
		left: 220px;
		right: 0;
		box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
	}
}
@media (min-width: 1200px) {
	.main-header-dashboard-nine {
		left: 240px;
	}
}
.main-content-1 {
	position: relative;
	.main-content-header {
		display: block;
		padding: 20px;
		background-color: $white;
		border: 0;
		border-bottom: 1px solid #e3e8f1;
		.nav {
			justify-content: flex-start;
		}
		.nav-link {
			color: #596882;
			font-weight: 500;
			&:hover, &:focus {
				color: $default-color;
			}
			&.active {
				color: $primary;
				&::before {
					top: 34px;
					bottom: auto;
					background-color: $primary;
				}
			}
			+.nav-link {
				margin-top: 0;
				margin-left: 25px;
			}
			&:last-child {
				position: relative;
				&::after {
					content: '';
					position: relative;
					display: inline-block;
					width: 25px;
				}
			}
		}
	}
	.main-content-header-top {
		margin-bottom: 20px;
	}
	.main-content-title {
		letter-spacing: -.7px;
		font-size: 24px;
	}
	.main-content-body {
		padding: 20px;
	}
	.card {
		border-width: 0;
		box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	}
}
@media (min-width: 992px) {
	.main-content-1 {
		margin-top: 64px;
	}
}
@media (min-width: 992px) {
	.main-content-1 .main-content-header {
		padding: 15.7px 21px;
		margin: 0 0 1.3rem 0;
	}
}
@media (max-width: 991.98px) {
	.main-content-1 .main-content-header .nav-wrapper {
		overflow: hidden;
		width: 100%;
		height: 20px;
	}
}
@media (max-width: 991.98px) {
	.main-content-1 .main-content-header .nav {
		flex-direction: row;
		flex-wrap: nowrap;
		overflow-x: auto;
		width: calc(100vw - 20px);
	}
}
@media (max-width: 991.98px) {
	.main-content-1 .main-content-header .nav-link {
		white-space: nowrap;
		padding-bottom: 10px;
	}
}
@media (min-width: 992px) {
	.main-content-1 .main-content-header .nav-link {
		&:nth-child(5), &:last-child {
			font-weight: 400;
		}
	}
}
@media (min-width: 992px) {
	.main-content-1 .main-content-header .nav-link:nth-child(5) {
		margin-left: auto;
	}
}
@media (min-width: 768px) {
	.main-content-1 .main-content-header .nav-link:last-child::after {
		display: none;
	}
}
@media (min-width: 576px) {
	.main-content-1 .main-content-header-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
}
.card-dashboard-twentyone {
	position: relative;
	.card-body {
		background-color: #f4f5f8;
		padding: 20px;
		height: 100%;
		.main-content-label, .card-table-two .card-title {
			margin-bottom: 8px;
			text-transform: capitalize;
			font-size: 14px;
		}
	}
}
.card-table-two .card-dashboard-twentyone .card-body .card-title, .card-dashboard-twentyone .card-body .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-twentyone .card-body .card-title {
	margin-bottom: 8px;
	text-transform: capitalize;
	font-size: 14px;
}
@media (min-width: 768px) {
	.card-dashboard-twentyone .list-group {
		margin-bottom: 10px;
	}
}
.card-dashboard-twentyone {
	.list-group-item {
		padding: 8px 0;
		border-width: 0;
		border-right-width: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
		background-color: transparent;
		span {
			flex-basis: 50%;
			max-width: 50%;
			&:last-of-type {
				text-align: right;
				color: $default-color;
				font-size: 12px;
				font-weight: 700;
				font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			}
		}
		.progress {
			margin-top: 5px;
			margin-bottom: 0;
			flex-basis: 100%;
			max-width: 100%;
			height: 5px;
			background-color: #b4bdce;
		}
		&:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.vmap-wrapper {
		width: 100%;
		height: 180px;
		.jqvmap-zoomin, .jqvmap-zoomout {
			width: 24px;
			height: 24px;
			left: 15px;
			border-radius: 100%;
			background-color: $default-color;
			color: $white;
			font-size: 20px;
			font-weight: 500;
			opacity: .2;
		}
		.jqvmap-zoomin {
			&:hover, &:focus {
				opacity: .8;
			}
		}
		.jqvmap-zoomout {
			&:hover, &:focus {
				opacity: .8;
			}
		}
		.jqvmap-zoomin {
			top: 15px;
		}
		.jqvmap-zoomout {
			top: 45px;
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-twentyone .vmap-wrapper {
		height: 250px;
	}
}
@media (min-width: 768px) {
	.card-dashboard-twentyone .vmap-wrapper {
		height: 100%;
	}
}
.card-dashboard-twentytwo {
	background-color: $white;
	position: relative;
	height: 120px;
	.media {
		padding: 20px;
		position: relative;
		z-index: 5;
	}
	.media-icon {
		width: 45px;
		height: 45px;
		color: $white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			font-size: 24px;
			line-height: 0;
			&.typcn {
				line-height: .9;
			}
		}
	}
	.media-body {
		margin-left: 15px;
		padding-top: 5px;
		h6 {
			margin-bottom: 5px;
			line-height: .7;
			color: $default-color;
			font-size: 26px;
			font-weight: 700;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			letter-spacing: -.5px;
			display: flex;
			align-items: flex-end;
			>small {
				color: $white;
				padding: 2px 3px;
				font-size: 9px;
				font-weight: 500;
				line-height: 1.1;
				margin-left: 5px;
				letter-spacing: normal;
				&.up {
					background-color: $success;
				}
				&.down {
					background-color: $danger;
				}
			}
		}
		> {
			span {
				display: block;
			}
			small {
				display: block;
				font-size: 11px;
				color: #97a3b9;
				strong {
					font-weight: 500;
				}
			}
		}
	}
	.chart-wrapper {
		position: absolute;
		right: -9px;
		left: -9px;
		opacity: .3;
	}
	.flot-chart {
		width: 100%;
		height: 120px;
	}
}
.card-dashboard-twentythree {
	background-color: #f4f5f8;
	.main-donut-chart.chart1 {
		width: 130px;
		height: 130px;
		background: #b4bdce;
		.slice {
			&.one {
				clip: rect(0 130px 65px 0);
				-webkit-transform: rotate(90deg);
				transform: rotate(90deg);
				background: $primary;
			}
			&.two {
				clip: rect(0 65px 130px 0);
				-webkit-transform: rotate(234deg);
				transform: rotate(234deg);
				background: $primary;
			}
		}
		.chart-center {
			top: 15px;
			left: 15px;
			width: 100px;
			height: 100px;
			background: #f4f5f8;
			span {
				font-size: 40px;
				line-height: 100px;
				color: $primary;
				&:after {
					content: "65%";
				}
				font-weight: 700;
				font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				font-size: 32px;
			}
		}
	}
	label {
		display: flex;
		align-items: center;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 10px;
		line-height: 1;
		letter-spacing: .5px;
		span {
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			margin-right: 5px;
		}
	}
	h5 {
		font-size: 24px;
		font-weight: 700;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		letter-spacing: -1px;
	}
}
.card-dashboard-audience-metrics {
	background-color: #f4f5f8;
	position: relative;
	overflow: hidden;
	height: 250px;
	.card-header {
		padding: 20px 20px 0;
		background-color: $white;
		position: relative;
		z-index: 3;
	}
	.card-title {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.chart-wrapper {
		position: absolute;
		right: 0;
		bottom: -20px;
		left: 0;
		padding: 5px 5px 0;
	}
	.flot-chart {
		width: 100%;
		height: 180px;
		.flot-x-axis>div {
			text-transform: uppercase;
			font-size: 10px;
			font-weight: 700;
			color: #8587a7;
			display: none;
			span:last-child {
				padding-left: 2px;
				font-weight: 700;
				color: $default-color;
			}
		}
	}
	.card-body {
		background-color: $white;
		display: flex;
		padding: 0 20px 20px;
		flex: none;
		position: relative;
		z-index: 3;
		>div+div {
			margin-left: 10px;
			padding-left: 10px;
		}
		h4 {
			font-weight: 700;
			font-size: 17px;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			color: $default-color;
			letter-spacing: -.5px;
			margin-bottom: 3px;
		}
		label {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			font-size: 11px;
			span {
				display: inline-block;
				width: 9px;
				height: 9px;
				margin-right: 5px;
				border-radius: 100%;
			}
		}
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics {
		height: 270px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics .chart-wrapper {
		padding-bottom: 10px;
		bottom: 10px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
		display: block;
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics .card-body>div+div {
		margin-left: 20px;
		padding-left: 20px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics .card-body h4 {
		font-size: 21px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-audience-metrics .card-body label {
		font-size: 0.875rem;
	}
}
/* ###### 8.10 Dashboard Ten  ###### */

.card-dashboard-twentyfour {
	.card-header {
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 20px 0;
	}
	.card-title {
		font-size: 14px;
		margin-bottom: 0;
	}
	.card-body {
		padding: 15px 20px 20px;
	}
	.card-body-top {
		display: flex;
		margin-bottom: 20px;
		h6 {
			color: $default-color;
			font-size: 18px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			margin-bottom: 0;
			letter-spacing: -.75px;
			small {
				font-weight: 600;
			}
			span {
				color: #97a3b9;
				font-weight: 400;
				letter-spacing: normal;
			}
		}
		label {
			display: block;
			margin-bottom: 0;
			color: #8587a7;
			font-size: 11px;
		}
		>div+div {
			margin-left: 30px;
		}
	}
	.main-content-label, .card-table-two .card-title {
		font-size: 10px;
		color: #031b4e;
		letter-spacing: .5px;
		margin-bottom: 0;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twentyfour .card-body-top h6 {
		font-size: 22px;
	}
}
@media (min-width: 576px) {
	.card-dashboard-twentyfour .card-body-top label {
		font-size: 0.875rem;
	}
	.card-dashboard-audience-metrics .flot-chart .flot-x-axis .flot-tick-label {
		display: block;
	}
}
.card-table-two .card-dashboard-twentyfour .card-title, .card-dashboard-twentyfour .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-twentyfour .card-title {
	font-size: 10px;
	color: #031b4e;
	letter-spacing: .5px;
	margin-bottom: 0;
}
.card-dashboard-twentyfour {
	.chart-legend {
		display: flex;
		align-items: center;
		font-size: 10px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: .5px;
		line-height: .6;
		span {
			display: inline-block;
			width: 7px;
			height: 7px;
			margin-right: 5px;
		}
		>div {
			display: flex;
			align-items: center;
			+div {
				margin-left: 20px;
			}
		}
	}
	.chart-wrapper {
		position: relative;
		margin-right: -10px;
	}
	.flot-chart {
		width: 100%;
		height: 130px;
	}
}
.card-dashboard-twentyfive {
	.card-title {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.col+.col {
		border-left: 1px solid $border;
	}
	.card-label {
		display: block;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: .5px;
		margin-bottom: 2px;
		color: #97a3b9;
		white-space: nowrap;
	}
	.card-value {
		font-size: 22px;
		font-weight: 600;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		margin-bottom: 0;
		letter-spacing: -1px;
	}
	.chart-wrapper {
		position: relative;
		margin: 0 -8px -5px;
	}
	.flot-chart {
		width: 100%;
		height: 35px;
	}
}
#flotChart2 {
	width: 100%;
	height: 35px;
}
.card-dashboard-twentysix {
	.card-header {
		padding: 15px 15px 10px;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.card-title {
		margin-bottom: 0;
		text-transform: uppercase;
	}
	.chart-legend {
		display: flex;
		align-items: center;
		>div {
			font-size: 10px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: .5px;
			+div {
				margin-left: 15px;
			}
		}
		span {
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-right: 2px;
		}
	}
	.card-body {
		padding: 0;
		position: relative;
		overflow: hidden;
		h6 {
			margin-bottom: 0;
			color: $default-color;
			font-size: 22px;
			font-weight: 600;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			letter-spacing: -.5px;
			line-height: 1;
			span {
				font-weight: 400;
				font-size: 18px;
				letter-spacing: -1px;
			}
		}
		label {
			font-size: 12px;
			margin-bottom: 0;
			color: #97a3b9;
		}
	}
	.chart-wrapper {
		position: relative;
		margin: -30px -18px -15px -18px;
	}
	.flot-chart {
		width: 100%;
		height: 120px;
		.flot-x-axis>div {
			font-size: 10px;
			letter-spacing: .5px;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.75);
			transform: translateY(-20px);
		}
		.flot-y-axis>div {
			font-size: 10px;
			transform: translateX(33px);
			color: rgba(28, 39, 60, 0.5);
		}
	}
	&.card-dark-one .card-title, &.card-dark-two .card-title, &.card-dark-one .card-body h6, &.card-dark-two .card-body h6 {
		color: $white;
	}
	&.card-dark-one .card-body h6 span, &.card-dark-two .card-body h6 span {
		color: $white-5;
	}
	&.card-dark-one .card-body label, &.card-dark-two .card-body label {
		color: $white-7;
	}
	&.card-dark-one .flot-chart .flot-y-axis>div, &.card-dark-two .flot-chart .flot-y-axis>div {
		color: $white-2;
	}
	&.card-dark-one {
		background-color: #0039e6;
		background-image: linear-gradient(to bottom, #1f05f0 0%, #0039e6 100%);
		background-repeat: repeat-x;
	}
	&.card-dark-two {
		background-color: #0040ff;
		background-image: linear-gradient(to bottom, #0a47ff 0%, $primary 100%);
		background-repeat: repeat-x;
	}
}
.main-rating-value {
	font-size: 40px;
	font-weight: 400;
	font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	color: $default-color;
	margin-bottom: 0;
	letter-spacing: -.5px;
	line-height: .7;
}
.main-rating-label {
	margin-bottom: 10px;
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .5px;
}
.main-media-list-reviews {
	.media+.media {
		margin-top: 25px;
	}
	.main-img-user {
		width: 32px;
		height: 32px;
		&::after {
			display: none;
		}
	}
	.media-body {
		margin-left: 15px;
		h6 {
			margin-bottom: 2px;
			line-height: 1;
		}
		small {
			display: inline-block;
			font-size: 12px;
			color: #97a3b9;
			line-height: 1;
		}
	}
}
.main-star-group {
	display: flex;
	align-items: center;
	span:last-child {
		display: block;
		font-weight: 500;
		font-size: 11px;
		margin-left: 5px;
		color: #8587a7;
	}
}
.main-star-item {
	color: $primary;
	font-size: 14px;
	position: relative;
	+.main-star-item {
		margin-left: 3px;
	}
}
.main-media-list-activity {
	.media+.media {
		margin-top: 23px;
	}
	.media-icon {
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		border-radius: 100%;
		i {
			font-size: 21px;
			line-height: 0;
			&.typcn {
				line-height: .9;
			}
		}
	}
	.media-body {
		margin-left: 15px;
		h6 {
			margin-bottom: 2px;
			color: $default-color;
		}
		span {
			display: block;
			font-size: 11px;
			color: #97a3b9;
		}
	}
	.media-right {
		font-size: 11px;
		color: #97a3b9;
	}
}
/* ############### UTILITIES/HELPER CLASSES ############### */

/*--Search--*/

.main-header {
	.input-group-btn.btn {
		border: 0px solid #ebeaf1 !important;
		background: transparent;
		border-radius: 3px;
		color: #3c4858;
		height: 38px;
		width: 38px;
		line-height: 24px;
		border-radius: 50px;
		font-size: 22px;
		&:hover {
			border-radius: 50%;
			color: $primary;
			i {
				color: $primary;
			}
		}
		i {
			font-size: 18px;
			color: $black;
		}
	}
	.navbar-form.active .input-group-btn .btn i {
		font-size: 14px;
		line-height: 27px;
	}
}
.navbar-form.active .input-group-btn {
	position: absolute;
	right: 12px;
	z-index: 9999;
	top: 13px;
}
.main-header form[role="search"] {
	top: 0px;
	right: 0;
	width: 100%;
	padding: 0px;
	margin: 0px;
	z-index: 0;
	button {
		padding: 5px 12px;
		border-radius: 0px;
		border-width: 0px;
		color: #6f7282;
		background-color: rgb(248, 248, 248);
		border-color: rgb(231, 231, 231);
		box-shadow: none;
		outline: none;
	}
	input {
		padding: 5px 12px;
		border-radius: 0px;
		border-width: 0px;
		color: #6f7282;
		background-color: rgb(248, 248, 248);
		border-color: rgb(231, 231, 231);
		box-shadow: none;
		outline: none;
		padding: 16px 12px;
		font-size: 14px;
		color: #807b90;
		box-shadow: none;
	}
	button[type="reset"] {
		display: none;
		background: $white;
		border: 0 !important;
	}
}
@media (min-width: 768px) {
	.dropdown {
		&.d-cart .dropdown-menu, &.message .dropdown-menu {
			width: 20.5rem !important;
		}
	}
}
.nav .nav-item .dropdown-menu {
	.dropdown-item.dropdown-header {
		background: #1b2d8f;
		height: 85px;
		color: $white;
	}
	top: 52px;
	border-radius: 6px;
	min-width: 245px;
	-webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
	box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}
.img-xs {
	width: 32px;
	min-width: 32px;
	height: 32px;
}
.horizontal-nav .mega-dropdown-menu {
	box-shadow: 0 25px 100px rgba(115, 77, 191, 0.2);
	border: 0;
	margin: -2px;
	width: 100%;
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent $white transparent;
		right: auto;
		left: 16px;
	}
}
.mega-dropdown-menu:after {
	right: auto;
	left: 16px;
}
.horizontal-nav .dropdown-menu {
	min-width: 12.5rem;
}
.drop-icon-wrap .drop-icon-item {
	display: inline-block;
	padding-bottom: 10px;
	padding-top: 10px;
	text-align: center;
	color: #020202;
	text-transform: capitalize;
	width: 33.3%;
	float: left;
}
.h-6 {
	height: 2.5rem !important;
}
.w-6 {
	width: 2.5rem !important;
}
.dropgroupicons .dropdown-menu {
	width: 260px ! important;
	padding: 5px ! important;
	top: 52px !important;
	border-radius: 6px;
	-webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
	box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}
.flag {
	.dropdown-menu {
		top: 52px !important;
		border-radius: 6px;
		-webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
		box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
	}
	>a::before {
		content: '';
		position: absolute;
		top: 47px;
		left: 50%;
		margin-left: -7px;
		width: 14px;
		height: 14px;
		border: 2px solid transparent;
		border-top-color: #e2e8f5;
		border-left-color: #e2e8f5;
		transform: rotate(45deg);
		background-color: $white;
		z-index: 901;
		display: none;
	}
}
.dropdown-toggle-1::after {
	top: 10px;
	right: 9px;
	border-right: 0;
	border-bottom: 0;
	border-left: 0;
	content: '\f3d0';
	font-family: 'Ionicons';
	position: absolute;
	display: inline-block;
	color: #7a8eb1;
}
.dropdown-toggle-2::after {
	top: 13px;
	right: 0px;
	border-right: 0;
	border-bottom: 0;
	border-left: 0;
	content: '\f3d0';
	font-family: 'Ionicons';
	position: absolute;
	display: inline-block;
	color: #7a8eb1;
}
.dropgroupicons .nav-link.icon i {
	font-size: 21px;
}
.drop-icon-wrap {
	a:hover {
		background: $gray-100;
	}
	.drop-icon-item .drop-font {
		font-size: 12px;
	}
}
.country-Flag img {
	width: 1.5rem;
	height: 1rem;
	line-height: 0.1rem;
	font-size: .75rem;
}
.dropdown.flag .dropdown-item span {
	font-size: 14px;
}

.nav-item.theme-layout i {
font-size: 22px !important;
}

.nav-item.theme-layout .light-layout {
display: none;
}

.main-header-message i, .main-header-notification i, .nav-item.full-screen i, .nav-item.theme-layout i, .main-header-search i, .demo-icon i {
	height: 40px;
	width: 40px;
	text-align: center;
	border-radius: 50%;
	line-height: 40px;
	font-size: 18px;
	color: #404259;
}

.btn.btn-default.nav-link {
	height: 40px;
	width: 40px;
	text-align: center;
	border-radius: 50%;
	line-height: 1;
	font-size: 17px;
}
.d-md-block.nav.nav-item.nav-link {
	padding: 0.5rem 0.7rem;
}
.mega-dropdown-menu {
	width: 65rem;
	padding: 0.70rem 0.80rem 0.50rem 0.80rem;
	.dropdown-item {
		padding: 0.5rem 1rem;
		&:hover {
			color: $primary;
			background: transparent;
		}
	}
}
.btn-absolute {
	position: absolute;
	display: block;
	left: 0;
	right: 0;
	bottom: 12px;
	padding: 20px;
}
.bg-transparent {
	background: transparent;
}
.arrow-ribbon {
	padding: 6px 8px;
	position: absolute;
	top: 10px;
	left: 0px;
	z-index: 999;
	font-size: 14px;
	line-height: 17px;
	background: $black;
	color: $white;
	&:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 14px solid transparent;
		border-left: 15px solid $black;
		border-bottom: 15px solid transparent;
		width: 0;
	}
}
.header-megamenu-dropdown .nav-item .btn-link {
	color: #031b4e;
}
.wordpress-project {
	border-radius: 5px;
	width: 55px;
	height: 55px;
}
.mega-dropdown-menu .media-body .progress-bar {
	height: 4px;
}
.header-megamenu-dropdown .dropdown-menu {
	top: 8px !important;
}
.mega-menu {
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 14px;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent $white transparent;
	}
	&:after {
		content: '';
		position: absolute;
		top: -10px;
		left: 14px;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent #c9cddb transparent;
		border-color: transparent transparent rgba(64, 82, 128, 0.9) transparent;
		z-index: -99;
	}
}
.drop-menu-header {
	padding: 1.5rem;
	color: $white;
}
.dropdown-menu .dropdown-header {
	text-transform: uppercase;
	font-size: 0.73333333rem;
	color: #3f6ad8;
	font-weight: bold;
}
.flag-text {
	color: #031b4e;
	font-weight: 400;
	&:before {
		content: '\f3d0';
		font-family: 'Ionicons';
		font-weight: 400;
		font-size: 12px;
		position: absolute;
		top: 10px;
		right: 0;
		color: #031b4e;
	}
}
.drop-flag {
	margin-right: 2.3rem !important;
}
.flag-notification {
	a {
		display: block;
		font-size: 22px;
		color: $default-color;
		position: relative;
		line-height: 1.5;
		outline: none;
		padding: 0.5rem 0.5rem;
	}
	.dropdown-menu {}
}
.flag-img {
	width: 2rem;
	height: 1.3rem;
}
.drop-flag .dropdown-menu {
	width: 150px;
	position: absolute;
	top: 52px;
	left: auto;
	right: -10px;
	bottom: auto;
	padding: 10px 10px 10px;
	border-color: #e8e7ef;
	border-width: 2px;
	.dropdown-item {
		font-size: 14px;
	}
}
.setting-menu-footer li {
	list-style-type: none;
}
.setting-menu-btn {
	padding: 0.5rem 1rem;
}
.setting-menu-footer .divider {
	margin: 0.5rem 0;
	height: 1px;
	overflow: hidden;
	background: #dee2e6;
}
.setting-menu .dropdown-item {
	padding: 0.4rem 1.5rem;
}
.setting-scroll {
	max-height: 162px;
	position: relative;
}
.header-img {
	background: $bg-primary-gradient;
	background-size: cover;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding: 20px 0 20px 0;
}
#dropdownMenuButton2 .rounded-pill {
	padding: 5px 7px;
	font-size: 10.5px;
}
.bx-flip-horizontal {
	transform: scaleX(-1);
}
.bx-tada {
	-webkit-animation: tada 1.5s ease infinite;
	animation: tada 1.5s ease infinite;
}
.drop-img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: $white;
	position: relative;
}
.dropdown-item .content {
	margin-left: 15px;
	width: 200px;
	white-space: normal;
}
.avatar-status {
	content: '';
	position: absolute;
	bottom: 0;
	right: 5px;
	width: 6px;
	height: 6px;
	background-color: #97a3b9;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
	border-radius: 100%;
	bottom: 4px;
}
.pulse {
	display: block;
	position: absolute;
	top: 0.9rem;
	right: 0.9rem;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: $success;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
	animation: pulse 2s infinite;
	animation-duration: .9s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
	&:hover {
		animation: none;
	}
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.9);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.9);
		box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.7);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
		box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
		box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
	}
}
.pulse-danger {
	display: block;
	position: absolute;
	top: 0.9rem;
	right: 0.9rem;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: $danger;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
	animation: pulse-danger 2s infinite;
	animation-duration: .9s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
}
@-webkit-keyframes pulse-danger {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.9);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
	}
}
@keyframes pulse-primary {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0.9);
		box-shadow: 0 0 0 0 rgba(40, 92, 247, 0.7);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(40, 92, 247, 0);
		box-shadow: 0 0 0 10px rgba(40, 92, 247, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0);
		box-shadow: 0 0 0 0 rgba(40, 92, 247, 0);
	}
}
@-webkit-keyframes pulse-primary {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0.9);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(40, 92, 247, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0);
	}
}
@keyframes pulse-primary {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0.9);
		box-shadow: 0 0 0 0 rgba(40, 92, 247, 0.7);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(40, 92, 247, 0);
		box-shadow: 0 0 0 10px rgba(40, 92, 247, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(40, 92, 247, 0);
		box-shadow: 0 0 0 0 rgba(40, 92, 247, 0);
	}
}
.main-message-list a {
	.desc {
		font-size: 12px;
		color: #596882;
		margin-left: 10px;
	}
	.name {
		font-size: .9rem;
		transition: all .2s ease-in-out;
		border-radius: 2px;
		color: #031b4e;
		margin-left: 10px;
	}
	.time {
		font-size: 11px;
		color: #031b4e;
	}
}
.menu-header-content {
	padding: 1.2rem;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.chat-scroll, .Notification-scroll, .text-scroll, .notify-scroll {
	max-height: 320px;
	position: relative;
}
.main-header-message .dropdown-footer, .main-header-notification .dropdown-footer {
	border-top: 1px solid #dce1ef;
	padding: 10px;
	background: $primary-01;
	box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.1);
	border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.main-header-message .dropdown-footer a {
	font-size: 13px;
}
.cover-image {
	background-size: cover !important;
}
.notifyimg {
	i {
		color: $black;
		float: left;
		height: 40px;
		line-height: 40px;
		text-align: center;
		vertical-align: middle;
		width: 40px;
		font-size: 21px;
	}
	border-radius: 50%;
}
.notification-label {
	font-size: 13.5px;
	transition: all .2s ease-in-out;
	border-radius: 2px;
	color: #031b4e;
	&:hover {
		color: $primary;
	}
}
.main-message-list .name:hover {
	color: $primary;
}
.notification-subtext {
	font-size: 12px;
	color: #596882;
}
@media (max-width: 576px) {
	.card-minimal-two {
		overflow: visible;
	}
	.card-minimal-two .card-header {
		border-bottom: 0;
	}
	.card-minimal-two .nav.nav-pills {
		margin-top: 10px;
	}
	.card-minimal-two .nav-link {
		padding: 8px 16px !important;
	}
}
.dropdown-menu .menu-header-content {
	background: $bg-primary-gradient;
}
@media (max-width: 992px) {
	.header-brand2 {
		display: none;
	}
}
@media (min-width: 991px) {
	.hor-header.top-header .header-brand2 .top-header-logo1 {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 20px;
		height: 2rem;
	}
	.hor-header.top-header .header-brand {
		display: none;
	}
	.hor-header.top-header .header-brand2 .top-header-logo2 {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 20px;
		height: 2rem;
	}
}
@media (min-width: 1159px) {
	.hor-header.top-header .main-header-center .form-control {
		width: 300px !important;
	}
}
.page {
	position: relative;
	display: flex;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	min-height: 100vh;
}
@media (max-width: 767px) {
	.card-crypto-scroll:before {
		display: none !important;
	}
	.card-crypto-scroll:after {
		display: none !important;
	}
	.main-header > .container, .main-header > .container-fluid {
		padding-right: 6px !important;
	}
}
IE *::-ms-backdrop, .main-header-center .btn {
	left: 370px;
	padding-left: 100px;
}
*::-ms-backdrop, .list-unstyled li {
	display: block !important;
	margin-bottom: -30px !important;
}
*::-ms-backdrop, .media-body {
	margin-top: -40px;
	margin-left: 55px !important;
}
*::-ms-backdrop, .crypto .media {
	display: block !important;
}
*::-ms-backdrop, .media {
	display: block !important;
}
*::-ms-backdrop, [type="search"] {
	-webkit-appearance: none;
	&::-webkit-search-cancel-button, &::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
/* ###### File Manager ###### */
.list-group-transparent .list-group-item {
    background: none;
    border: 0;
    padding: 0.5rem 1rem;
}
.text-success {
    color: #09ad95 !important;
}
.fs-18 {
    font-size: 18px !important;
}
.text-success {
    --bs-text-opacity: 1;
}
.fs-11 {
    font-size: 11px !important;
}
.file-manager-icon {
    width: 28px;
    height: 28px;
}
.file-dropdown.border{
	height: 2.5rem;
	width: 2.5rem;
}
// .border .fe-more-vertical{
//     position: absolute;
//     top: 18px;
// }
// .progress-xs .progress-bar {
//     height: 0.25rem;
// }
.file-manager-image {
    background: #e5bc4a40;
    border-radius: 50px;
    margin-bottom: 23px;
    padding: 6px;
    width: 42px;
}
.file-dropdown {
    z-index: 2;
}
.card-footer:last-child {
    border-radius: 0 0 6px 6px;
}

/* ###### File Manager-Details ###### */
.owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -25px;
    right: -1.5em;
    margin-top: -1.65em;
}
.owl-nav button {
    display: block;
    font-size: 1.3rem!important;
    line-height: 2em;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: rgba(255, 255, 255, .5)!important;
    border: 1px solid #e8ebf3!important;
    z-index: 99;
    box-shadow: 0 4px 15px rgb(67 67 67 / 15%);
}
.owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -1.65em;
}
.owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -20px;
    right: -1.5em;
    margin-top: -1.65em;
}
.owl-carousel-icons2 .files-card{
	background-color: #f2f1fb !important;
}
.progress .progress-bar{
    height: 100%;
}
/* ###### File Manager-Attachment ###### */
.tag-outline {
    border: 1px solid #e8e8f7;
    color: #1d212f;
    background-color: transparent;
}
.file-radius-attachments {
    padding: 4px 12px;
    border-radius: 100px !important;
    font-size: 15px;
}
.file-square-attachments {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
}
.file-transparent-rounded {
    border-radius: 50px !important;
}
.file-lg {
    font-size: 17px;
    padding: 6px 15px;
}
@media (max-width: 350px){
	.file-lg {
		font-size: 17px;
		padding: 6px 3px;
	}
}
.file-md {
    font-size: 15px;
    padding: 4px 13px;
}
.file-sm {
    font-size: 12px;
    padding: 4px 12px;
}
.text-wrap {
    font-size: 14px;
    line-height: 1.66;
}
.file-image img {
    border-radius: 7px;
}
.file-image .icons li {
    display: block;
    margin: 5px;
}
.file-image .icons li .btn {
    font-size: 15px;
    line-height: 36px;
    text-align: center;
    height: 35px;
    width: 35px;
    margin: 2px 7px;
    padding: 0;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.file-image .icons li .btn {
    color: $white;
}
.file-image .icons li .btn:after {
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(
45deg
);
    top: -20px;
    z-index: -1;
}
.file-image .icons li .btn:after, .file-image .icons li .btn:before {
    content: attr(data-tip);
    color: $white;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px;
}
.file-image-1 {
    width: 100px;
    height: 100px;
    display: inline-table;
    margin: 4px;
    position: relative;
    border: 1px solid #e9edf4;
    border-radius: 5px;
    margin-bottom: 20px;
}
.file-image-1 .icons li .btn {
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    height: 25px;
    width: 25px;
    margin: 4px 1px;
    padding: 0;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    color: $white;
}

.file-image-1 .product-image img {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.br-5 {
    border-radius: 5px;
}
.br-6 {
    border-radius: 6px;
}
img {
    max-width: 100%;
}
.file-image-1 .file-name-1 {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -21px;
    color: #524d4d;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
}
.file-image-md {
    width: 150px;
    height: 150px;
}
.file-image-md .icons li .btn {
    font-size: 13px;
    line-height: 30px;
    height: 28px;
    width: 28px;
}
.file-image-lg {
    width: 200px;
    height: 200px;
}
.file-image-lg .icons li .btn {
    font-size: 15px;
    line-height: 35px;
    height: 35px;
    width: 35px;
}
a.bg-danger:hover, a.bg-danger:focus {
    background-color: #de223d !important;
}
.file-image .icons li a:hover {
    color: $white;
}
a.bg-secondary:hover, a.bg-secondary:focus {
    background-color: $secondary !important;
}
a.bg-primary:hover, a.bg-primary:focus {
    background-color: #5f51f7 !important;
}

.file-image .icons {
    padding: 1px;
    margin: 0;
    list-style: none;
    opacity: 0;
    top: 14px;
    text-align: center;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
}
.file-image-1 .icons {
    padding: 1px;
    margin: 0;
    list-style: none;
    opacity: 0;
    top: 6px;
    text-align: center;
    position: absolute;
    bottom: 4px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
}
.file-name {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    color: $white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}
.file-image:hover .icons {
    opacity: 1;
    bottom: 33px;
}
.file-image-1:hover .icons {
    opacity: 1;
    bottom: 33px;
}

/* ###### BLOG ###### */
.card-img-top {
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.card-header:first-child {
    border-radius: 8px 8px 0 0;
}
.card .blog-link{
	position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
	// z-index: 86;
}

/* ###### BLOG-DETAILS###### */
.product-label {
    margin-bottom: 0.5rem;
    padding: 0.4em 0.6em;
    font-size: 75%;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 7px;
    background: rgba(178, 177, 183, 0.1);
    float: right;
}
.bradius {
    border-radius: 25%;
}
a.text-dark:hover, a.text-dark:focus {
    color: $primary !important;
}
.image-layer .blog-layer{
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0;
	// visibility: hidden;
	transition: all ease 0.2s;
	bottom: 0px;
}
.blog-card:hover .blog-layer{
    // visibility: visible;
	opacity: 0.25;
}
.flex-2{
	flex:2;
}

/* ###### BLOG-DETAILS###### */
.ff_fileupload_wrap .ff_fileupload_dropzone {
	display: block;
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	border: 2px dashed #e9edf4;
	border-radius: 5px;
	padding: 0;
	background-color: #f6f6fb;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: 0.85;
	cursor: pointer;
	outline: none;
}
.richText {
    position: relative;
    width: 100%;
    border-radius: 3px;
}

.richText {
    border: #e9edf4 solid 1px;
    background-color: #f6f6fb !important;
}

/* ###### Settings ###### */
.settings-icon {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 11px 15px;
    font-size: 20px;
}
h4, .h4 {
    // font-size: 1.3125rem !important;
}
a {
    /* color: #000; */
    text-decoration: none;
    background-color: transparent;
}
.list-group-transparent.file-manager.file-manager-border .list-group-item {
    border: 1px solid #e9edf4;
    border-top: 0;
}

/* ###### Settings ###### */
ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
    // list-style-type: none;
}

/* ###### Services ###### */
.service .item-box i {
    font-size: 35px;
}
.fs{
	font-size: 35px;
}
.fs-25{
	font-size: 25px;
}

.user-social-detail .social-profile {
    height: 35px;
    width: 35px;
    background: #f6f6fb;
    font-size: 16px;
    line-height: 2.3;
}
.bg-danger-gradient {
    background-image: linear-gradient(to bottom right, #b51b35 0%, #fd4a68 100%) !important;
}
.bg-success-gradient {
    background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%) !important;
}
.bg-secondary-gradient {
    background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%) !important;
}
.bg-primary-gradient {
    background: linear-gradient(to bottom right, $primary 0%, #8e77fa 100%) !important;
}
.services-statistics .counter-icon {
    margin-bottom: 1.5rem;
    margin-top: 14px;
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    padding: 1.3rem 1.4rem;
    border-radius: 7px;
    text-align: center;
}
.counter-icon {
    margin-bottom: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4rem;
    text-align: center;
    font-size: 24px;
}
.counter-icon.num-counter {
    border-radius: 0 40% 0 40%;
}
.fs-30{
    font-size: 30px;
}

.counter-style{
	border-radius: 0 20px 0 20px;
	padding: 1rem !important;
}

.dark-layout .fe-moon{
	color: #000000a3 !important;
}

/* ###### Profile ###### */
.custom-switch-input:checked~.custom-switch-indicator {
    background: $primary;
}
.custom-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    background: #f0f0f2;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator:before {
    content: "";
    position: absolute;
    height: calc(1.45rem - 6px);
    width: calc(1.25rem - 4px);
    top: 1.10px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: 0.3s left;
}
.custom-switch-input:checked~.custom-switch-indicator.custom-switch-indicator-lg:before {
    left: calc(2rem + 1px);
}
.custom-switch-input:checked~.custom-switch-indicator.custom-switch-indicator-md:before {
    left: calc(1.76rem + 1px) !important;
}
.custom-switch-input:checked~.custom-switch-indicator:before {
    left: calc(1rem + 1px);
}
.custom-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.profile-icon{
	position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    right: 0px;
    background: #d5d4e0;
    margin: 0 auto;
    text-align: center;
}
.toast{
	button.btn-close{
		top: 0;
	}
}

.nav-link-icon{
	i{
		display: inline-block;
	}
}

.login-page .demo-icon {
    position: absolute;
    left: auto;
    right: 60px;
    top: 60px;
    z-index: 1;
	a{
		color: #fff !important;
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 50px;
		padding: 0px;
	}
}

@media (min-width: 768px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        border: 1px solid #eae8f1;
        font-size: 17px;
        font-weight: 400;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        font-size: 15px;
        font-weight: 600;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.steps a {
        font-size: 12px !important;
    }
}

@media (max-width: 767px) {
    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 20px;
    }
}

@media (min-width: 768px) {
    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 28px;
    }
}

#checkoutsteps ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

#checkoutsteps ul[role=tablist] li {
    display: flex;
    z-index: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    font-weight: 500;
    font-size: 1.1rem;
}

#checkoutsteps>.steps a {
    display: block;
    width: auto;
    background-color: transparent;
    color: #2d2c40;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 0;
    text-decoration: none;
    border-radius: 30px;
    cursor: default;
    text-align: center;
    outline: none;
}

.wizard>.steps>ul li .current-info {
    display: none;
}

#checkoutsteps>.steps .current a .number {
    background: var(--primary-bg-color);
    color: #fff;
}

#checkoutsteps .item .thumb {
    display: inline-flex;
    width: 100px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
}

#checkoutsteps .item .left {
    display: flex;
    align-items: center;
}

#checkoutsteps .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #eae8f1;
    margin-bottom: 30px;
}

#checkoutsteps .item .purchase {
    text-decoration: none;
}

#checkoutsteps .item .purchase {
    display: inline-block;
    margin-left: 21px;
}

#checkoutsteps .total {
    float: right;
    color: var(--primary-bg-color);
}

#checkoutsteps>.steps {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 3px;
    padding: 0;
}

#checkoutsteps.wizard {
    border-radius: 10px;
}

#checkoutsteps {
	.card-pay .tabs-menu li a{
		border-radius: 0;
	}
}

#checkoutsteps.wizard>.content>.title.current{
	display: none;
}

.handle-counter input {
    float: left;
    text-align: center;
    height: 41px;
    border-radius: 0;
    width: 70px;
    border: 1px solid #ededf5;
    outline: none;
    border-left: 0;
    border-right: 0;
}
.handle-counter {
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.handle-counter .counter-minus {
    border-radius: 3px 0px 0px 3px !important;
}
.handle-counter .counter-minus, .handle-counter .handle-counter .counter-plus {
    float: left;
    text-align: center;
    border-radius: 1px;
}
.handle-counter .btn {
    padding: 8px 10px !important;
}
.handle-counter{
	.counter-minus:hover, .counter-plus:hover{
		background-color: #e4e3ef;
		color: $default-color;
	}
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus{
	box-shadow: none;
}
@media (max-width: 400px){
	#checkoutsteps .item .left {
		display: block !important;
	}
	.wizard>.actions>ul {
		display: block !important;
		text-align: center;
	}
	.actions.clearfix ul li {
		margin: 2px;
	}
	.card-pay .tabs-menu li {
		width: 100% !important;
	}
}
.flag {
    width: 1.6rem;
    height: 1.2rem;
    display: block;
    font-style: normal;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    border-radius: 2px;
}
.payment {
    width: 1.8rem;
    height: 1.1rem;
    display: inline-block;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    border-radius: 2px;
}