/* ###### 5.7 Nav   ###### */

@media (min-width: 768px) {
	.main-nav {
	  align-items: center;
	}
  }
  
  .main-nav .nav-link {
	display: block;
	color: #596882;
	padding: 0;
	position: relative;
	line-height: normal;
  
	&:hover, &:focus {
	  color: $primary;
	}
  
	+ .nav-link {
	  padding-top: 12px;
	  margin-top: 12px;
	  border-top: 1px dotted #97a3b9;
	}
  
	&.active {
	  color: $primary;
	}
  }
  
  @media (min-width: 768px) {
	.main-nav .nav-link + .nav-link {
	  padding-top: 0;
	  margin-top: 0;
	  border-top: 0;
	  padding-left: 15px;
	  margin-left: 15px;
	}
  }
  
  .main-nav-column {
	flex-direction: column;
  
	.nav-link {
	  padding: 0;
	  height: 38px;
	  color: $default-color;
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
  
	  i {
		font-size: 24px;
		line-height: 0;
		width: 24px;
		margin-right: 12px;
		text-align: center;
		transition: all 0.2s ease-in-out;
  
		&:not([class*=' tx-']) {
		  color: #8587a7;
		}
  
		&.typcn {
		  line-height: .9;
		}
	  }
  
	  span {
		font-weight: 400;
		font-size: 11px;
		color: #97a3b9;
		margin-left: auto;
	  }
  
	  &:hover, &:focus, &:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
		color: $primary;
	  }
  
	  &.active {
		position: relative;
  
		&::before {
		  content: '';
		  position: absolute;
		  top: 6px;
		  bottom: 6px;
		  left: -28px;
		  width: 3px;
		  background-color: $primary;
		  border-radius: 3px;
		  display: none;
		}
  
		color: $primary;
  
		&:hover, &:focus, i, &:hover i, &:focus i {
		  color: $primary;
		}
	  }
  
	  + .nav-link {
		// border-top: 1px dotted #b4bdce;
	  }
	}
  
	&.sm .nav-link {
	  font-size: 0.875rem;
	  font-weight: 400;
	  padding: 10px 0;
  
	  i {
		font-size: 21px;
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-nav-column .nav-link i {
	  transition: none;
	}
  }
  
  .main-nav-dark .nav-link {
	color: $white-7;
  
	&:hover, &:focus {
	  color: $white;
	}
  
	+ .nav-link {
	  border-color: #596882;
	}
  
	&.active {
	  color: $primary;
	}
  }
  
  .main-nav-colored-bg .nav-link {
	+ .nav-link {
	  border-color: $white-4;
	}
  
	&.active {
	  color: $white;
	}
  }
  
  .main-nav-line {
	position: relative;
  
	.nav-link {
	  padding: 0;
	  color: #596882;
	  position: relative;
  
	  &:hover, &:focus {
		color: $default-color;
	  }
  
	  padding: 7px 13px 5px 13px;
	  color: #37326d;
	  position: relative;
  
	  &.active {
		color: $default-color;
	  }
	}
  
	&.main-nav-dark .nav-link {
	  color: $white-7;
  
	  &:hover, &:focus {
		color: $white;
	  }
  
	  &.active {
		color: $white;
  
		&::before {
		  background-color: $white;
		}
	  }
	}
  }
  
  .main-nav-tabs {
	padding: 15px 15px 0;
	background-color: #e2e8f5;
  
	.lSSlideOuter {
	  position: relative;
	  padding-left: 32px;
	  padding-right: 35px;
	}
  
	.lSSlideWrapper {
	  overflow: visible;
	}
  
	.lSAction > a {
	  display: block;
	  height: 40px;
	  top: 16px;
	  opacity: 1;
	  background-color: #b4bdce;
	  background-image: none;
  
	  &:hover, &:focus {
		background-color: #a5afc4;
	  }
  
	  &::before {
		font-family: 'Ionicons';
		font-size: 18px;
		position: absolute;
		top: -4px;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	  }
  
	  &.lSPrev {
		left: -32px;
  
		&::before {
		  content: '\f3cf';
		}
	  }
  
	  &.lSNext {
		right: -35px;
  
		&::before {
		  content: '\f3d1';
		}
	  }
  
	  &.disabled {
		background-color: #e8e7ef;
		color: $white;
	  }
	}
  
	.lightSlider {
	  display: flex;
	}
  
	.tab-item {
	  flex-shrink: 0;
	  display: block;
	  float: none;
	  min-width: 150px;
	}
  
	.tab-link {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  padding: 10px 20px;
	  line-height: 1.428;
	  color: #596882;
	  white-space: nowrap;
	  background-color: #e8e7ef;
  
	  &:hover, &:focus {
		background-color: #f4f5f8;
	  }
  
	  &.active {
		background-color: $white;
		color: $default-color;
		font-weight: 500;
	  }
	}
  }
  
  .main-tab-pane {
	display: none;
  
	&.active {
	  display: block;
	}
  }