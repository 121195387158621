/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: #14112d;
	background-color: #efeff5;
	border-radius: 5px !important;
	padding: 0 0.5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: pointer;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: 0.3s color, 0.3s background;

	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}

.tag-addon {
	display: inline-block;
	padding: 0 0.5rem;
	color: inherit;
	text-decoration: none;
	// background: rgba(0, 0, 0, 0.06);
	margin: 0 -0.5rem 0 0.5rem;
	text-align: center;
	min-width: 1.5rem;
	border-left: 1px solid rgba(225, 225, 225, 0.2);

	&:last-child {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	i {
		vertical-align: middle;
		margin: 0 -0.25rem;
	}
}

a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: 0.3s color, 0.3s background;

	&:hover {
		//   background: rgba(0, 0, 0, 0.16);
		color: inherit;
	}
}

.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 3px 0 0 3px;
	margin: 0 0.5rem 0 -0.5rem;
}

.tag-blue {
	background-color: #467fcf;
	color: $white;
}

.tag-indigo {
	background-color: $primary;
	color: $white;
}

.tag-purple {
	background-color: #8500ff;
	color: $white;
}

.tag-pink {
	background-color: #ec82ef;
	color: $white;
	align-items: center;
}

.tag-red {
	background-color: #ec2d38;
	color: $white;
}

.tag-orange {
	background-color: $orange;
	color: $white;
}

.tag-yellow {
	background-color: #fdb901;
	color: $white;
}

.tag-green {
	background-color: #0fa751;
	color: $white;
}

.tag-teal {
	background-color: $teal;
	color: $white;
}

.tag-cyan {
	background-color: $info;
	color: $white;
}

.tag-white {
	background-color: $white;
	color: $white;
}

.tag-gray {
	background-color: #868e96;
	color: $white;
}

.tag-gray-dark {
	background-color: #343a40;
	color: $white;
}

.tag-azure {
	background-color: #17c1f4;
	color: $white;
}

.tag-lime {
	background-color: #7bd235;
	color: $white;
}

.tag-primary {
	background-color: #467fcf;
	color: $white;
}

.tag-secondary {
	background-color: #868e96;
	color: $white;
}

.tag-success {
	background-color: #0fa751;
	color: $white;
}

.tag-info {
	background-color: #17c1f4;
	color: $white;
}

.tag-warning {
	background-color: #fdb901;
	color: $white;
}

.tag-danger {
	background-color: #ec2d38;
	color: $white;
}

.tag-light {
	background-color: #f8f9fa;
	color: $white;
}

.tag-dark {
	background-color: #343a40;
	color: $white;
}

.tag-rounded {
	border-radius: 50px;

	.tag-avatar {
		border-radius: 50px;
	}
}

.tags {
	font-size: 0;
	margin-bottom: -0.5rem;

	> .tag {
		margin-bottom: 0.5rem;

		&:not(:last-child) {
			margin-right: 0.5rem;
			align-items: center;
		}
	}
}

.table-invoice.table thead {
	th,
	td {
		padding: 9px 15px;
	}
}
.tag-radius {
	border-radius: 50px 0 0 50px !important;
}
.tag-outline-secondary {
	background-color: #007bff3d !important;
	color: $secondary;
	border: 1px solid $secondary;
}
.tag-outline-primary {
	background-color: rgba(108, 95, 252, 0.1);
	color: $primary;
	border: 1px solid $primary;
}
.tag-outline-danger {
	background-color: rgba(255, 56, 43, 0.1) !important;
	color: #e82646;
	border: 1px solid #e82646;
}
.tag-outline-warning {
	background-color: rgba(255, 162, 43, 0.1) !important;
	color: #f7b731;
	border: 1px solid #f7b731;
}

.tag-outline-success {
	background-color: rgba(0, 230, 130, 0.1) !important;
	color: #09ad95;
	border: 1px solid #09ad95;
}
.tag-outline-info {
	background-color: #c7e0fd;
	color: #1170e4;
	border: 1px solid #1170e4;
	align-items: center;
}
.tag-round::before {
	content: "";
	width: 8px;
	height: 8px;
	background-color: $white;
	border-radius: 50%;
	border: 1px solid;
	margin-right: 5px;
}
.tag .fe {
	vertical-align: middle;
}
.tag-rounded.tag-icon i {
	margin: 6px;
}
