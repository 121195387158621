/* ###### 5.4 Image   ###### */

.main-img-user {
	display: inline-block;
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	text-align: center;
  
	img {
	  width: 100% !important;
	  height: 100% !important;
	  object-fit: cover;
	  border-radius: 100%;
	}
  }
  
  .main-profile-menu .profile-user img {
	width: 37px;
	height: 37px;
	border-radius: 50% !important;
  }
  
  .main-img-user {
	&::after {
	  content: '';
	  position: absolute;
	  bottom: 0;
	  right: 5px;
	  width: 6px;
	  height: 6px;
	  background-color: #97a3b9;
	  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
	  border-radius: 100%;
	  bottom: 2px;
	  display: block;
	}
  
	&.online::after {
	  background-color: $success;
	}
  }
  
  .main-avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-weight: 600;
	font-size: 16px;
	background-color: #596882;
  
	&::after {
	  content: '';
	  position: absolute;
	  bottom: 0;
	  right: 3px;
	  width: 6px;
	  height: 6px;
	  background-color: #97a3b9;
	  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
	  border-radius: 100%;
	}
  
	&.online::after {
	  background-color: $success;
	}
  }
  
  .avatar-xs {
	width: 24px !important;
	height: 24px !important;
	font-size: 11px !important;
  
	&::after {
	  width: 5px !important;
	  height: 5px !important;
	}
  }
  
  .avatar-sm {
	width: 32px !important;
	height: 32px !important;
	font-size: 14px !important;
  
	&::after {
	  width: 7px !important;
	  height: 7px !important;
	}
  }
  
  .avatar-md {
	width: 48px !important;
	height: 48px !important;
	font-size: 24px !important;
  
	&::after {
	  width: 9px !important;
	  height: 9px !important;
	  right: 2px !important;
	  bottom: 2px !important;
	}
  }
  
  .avatar-lg {
	width: 64px !important;
	height: 64px !important;
	font-size: 28px !important;
  
	&::after {
	  width: 10px !important;
	  height: 10px !important;
	  bottom: 3px !important;
	  right: 4px !important;
	}
  }
  
  .avatar-xl {
	width: 72px !important;
	height: 72px !important;
	font-size: 36px !important;
  
	&::after {
	  width: 11px !important;
	  height: 11px !important;
	  bottom: 4px !important;
	  right: 5px !important;
	  box-shadow: 0 0 0 2.5px $white;
	}
  }
  
  .avatar-xxl {
	width: 100px !important;
	height: 100px !important;
	font-size: 56px !important;
  
	&::after {
	  width: 13px !important;
	  height: 13px !important;
	  bottom: 6px !important;
	  right: 7px !important;
	  box-shadow: 0 0 0 3px $white;
	}
  }
 .avatar .bradius{
	border-radius: 25%;
	}

.main-img-user1.avatar-sm .number-badge {
  position: absolute;
  top: 105px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  border: 1px solid #ededf5;
  left: 58px;
}

.main-img-user1.avatar-md .number-badge {
  position: absolute;
  top: 99px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  left: 128px;
  border: 1px solid #ededf5;
}

.main-img-user1.avatar-lg .number-badge {
  position: absolute;
  top: 85px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  left: 207px;
  border: 1px solid #ededf5;
}
.main-img-user3.avatar-sm .icon-badgeavatar {
  position: absolute;
  top: 130px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  left: 52px;
  border: 1px solid #ededf5;

}

.main-img-user3.avatar-md .icon-badgeavatar {
  position: absolute;
  top: 139px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  left: 123px;
  border: 1px solid #ededf5;
}
.main-img-user3.avatar-lg .icon-badgeavatar {
  position: absolute;
  top: 139px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  left: 202px;
  border: 1px solid #ededf5;
}
.main-img-user .square {
	border-radius: 6px;
}

.main-img-user .radius {
	border-radius: 10px;
}

.demo-avatar-group .main-img-user::after {
  content: '';
  position: absolute;
  bottom: 3px;
  right: 6px;
  width: 6px;
  height: 6px;
  background-color: #17b86a;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 95%);
  border-radius: 100%;
  display: block !important;
}
.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;
}
.avatar-list-stacked .avatar1 {
  box-shadow: 0 0 0 2px $white;
  margin-right: -0.8em;
}
.main-avatar-list-stacked .main-img-user {
  box-shadow: 0 0 0 2px $white;
  margin-right: -0.8em;
}
.avatar1.btn{
    background: #4fb7e3 no-repeat center/cover;
	padding: 14px;
}

.avatar1 {
  width: 3rem;
  height: 3rem;
  line-height: 2.85rem;
  position: relative;
  text-align: center;
  display: inline-block;
  color: $white;
  font-weight: 600;
  vertical-align: bottom;
  font-size: .875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar-list .avatar {
    margin-bottom: 0.5rem;
}
.fe {
  font-family: feather!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.fs-12 {
    font-size: 12px !important;
}
.avatar {
  display: inline-block;
  position: relative;
  text-align: center;
  color: $white;
  font-weight: 500;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar-xl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.75rem;
}
.badge {
    display: inline-block;
    padding: 3px 5px 4px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    font-size: 0.75em;
    font-weight: 500;
}
.avatar-icons {
    content: "";
    top: 73%;
    right: -6%;
    width: 23px;
    height: 23px;
    position: absolute;
    line-height: initial;
    cursor: pointer;
}
.rounded-pill {
    border-radius: 50rem!important;
}
.avatar .avatar-icons i {
    font-size: 0.74rem;
    padding-top: 2px;
    display: block;
}
.avatar i {
    font-size: 125%;
    vertical-align: sub;
}

.fe {
    font-family: feather!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-weight: 600;
	font-size: 16px;
	// background-color: $primary;
  }
  
  .avatar-list .avatar:not(:last-child) {
	margin-right: .5rem;
  }