/* ###### 9.6 Padding   ###### */

.pd-0 {
	padding: 0px;
}

.pd-0-f {
	padding: 0px !important;
}

.pd-y-0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.pd-y-0-f {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.pd-x-0 {
	padding-left: 0px;
	padding-right: 0px;
}

.pd-x-0-f {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.pd-t-0 {
	padding-top: 0px;
}

.pd-r-0 {
	padding-right: 0px;
}

.pd-b-0 {
	padding-bottom: 0px;
}

.pd-l-0 {
	padding-left: 0px;
}

.pd-t-0-f {
	padding-top: 0px !important;
}

.pd-r-0-f {
	padding-right: 0px !important;
}

.pd-b-0-f {
	padding-bottom: 0px !important;
}

.pd-l-0-f {
	padding-left: 0px !important;
}

.pd-1 {
	padding: 1px;
}

.pd-1-f {
	padding: 1px !important;
}

.pd-y-1 {
	padding-top: 1px;
	padding-bottom: 1px;
}

.pd-y-1-f {
	padding-top: 1px !important;
	padding-bottom: 1px !important;
}

.pd-x-1 {
	padding-left: 1px;
	padding-right: 1px;
}

.pd-x-1-f {
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.pd-t-1 {
	padding-top: 1px;
}

.pd-r-1 {
	padding-right: 1px;
}

.pd-b-1 {
	padding-bottom: 1px;
}

.pd-l-1 {
	padding-left: 1px;
}

.pd-t-1-f {
	padding-top: 1px !important;
}

.pd-r-1-f {
	padding-right: 1px !important;
}

.pd-b-1-f {
	padding-bottom: 1px !important;
}

.pd-l-1-f {
	padding-left: 1px !important;
}

.pd-2 {
	padding: 2px;
}

.pd-2-f {
	padding: 2px !important;
}

.pd-y-2 {
	padding-top: 2px;
	padding-bottom: 2px;
}

.pd-y-2-f {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.pd-x-2 {
	padding-left: 2px;
	padding-right: 2px;
}

.pd-x-2-f {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.pd-t-2 {
	padding-top: 2px;
}

.pd-r-2 {
	padding-right: 2px;
}

.pd-b-2 {
	padding-bottom: 2px;
}

.pd-l-2 {
	padding-left: 2px;
}

.pd-t-2-f {
	padding-top: 2px !important;
}

.pd-r-2-f {
	padding-right: 2px !important;
}

.pd-b-2-f {
	padding-bottom: 2px !important;
}

.pd-l-2-f {
	padding-left: 2px !important;
}

.pd-3 {
	padding: 3px;
}

.pd-3-f {
	padding: 3px !important;
}

.pd-y-3 {
	padding-top: 3px;
	padding-bottom: 3px;
}

.pd-y-3-f {
	padding-top: 3px !important;
	padding-bottom: 3px !important;
}

.pd-x-3 {
	padding-left: 3px;
	padding-right: 3px;
}

.pd-x-3-f {
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.pd-t-3 {
	padding-top: 3px;
}

.pd-r-3 {
	padding-right: 3px;
}

.pd-b-3 {
	padding-bottom: 3px;
}

.pd-l-3 {
	padding-left: 3px;
}

.pd-t-3-f {
	padding-top: 3px !important;
}

.pd-r-3-f {
	padding-right: 3px !important;
}

.pd-b-3-f {
	padding-bottom: 3px !important;
}

.pd-l-3-f {
	padding-left: 3px !important;
}

.pd-4 {
	padding: 4px;
}

.pd-4-f {
	padding: 4px !important;
}

.pd-y-4 {
	padding-top: 4px;
	padding-bottom: 4px;
}

.pd-y-4-f {
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}

.pd-x-4 {
	padding-left: 4px;
	padding-right: 4px;
}

.pd-x-4-f {
	padding-left: 4px !important;
	padding-right: 4px !important;
}

.pd-t-4 {
	padding-top: 4px;
}

.pd-r-4 {
	padding-right: 4px;
}

.pd-b-4 {
	padding-bottom: 4px;
}

.pd-l-4 {
	padding-left: 4px;
}

.pd-t-4-f {
	padding-top: 4px !important;
}

.pd-r-4-f {
	padding-right: 4px !important;
}

.pd-b-4-f {
	padding-bottom: 4px !important;
}

.pd-l-4-f {
	padding-left: 4px !important;
}

.pd-5 {
	padding: 5px;
}

.pd-5-f {
	padding: 5px !important;
}

.pd-y-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.pd-y-5-f {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

.pd-x-5 {
	padding-left: 5px;
	padding-right: 5px;
}

.pd-x-5-f {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.pd-t-5 {
	padding-top: 5px;
}

.pd-r-5 {
	padding-right: 5px;
}

.pd-b-5 {
	padding-bottom: 5px;
}

.pd-l-5 {
	padding-left: 5px;
}

.pd-t-5-f {
	padding-top: 5px !important;
}

.pd-r-5-f {
	padding-right: 5px !important;
}

.pd-b-5-f {
	padding-bottom: 5px !important;
}

.pd-l-5-f {
	padding-left: 5px !important;
}

.pd-6 {
	padding: 6px;
}

.pd-6-f {
	padding: 6px !important;
}

.pd-y-6 {
	padding-top: 6px;
	padding-bottom: 6px;
}

.pd-y-6-f {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}

.pd-x-6 {
	padding-left: 6px;
	padding-right: 6px;
}

.pd-x-6-f {
	padding-left: 6px !important;
	padding-right: 6px !important;
}

.pd-t-6 {
	padding-top: 6px !important;
}

.pd-r-6 {
	padding-right: 6px;
}

.pd-b-6 {
	padding-bottom: 6px;
}

.pd-l-6 {
	padding-left: 6px;
}

.pd-t-6-f {
	padding-top: 6px !important;
}

.pd-r-6-f {
	padding-right: 6px !important;
}

.pd-b-6-f {
	padding-bottom: 6px !important;
}

.pd-l-6-f {
	padding-left: 6px !important;
}

.pd-7 {
	padding: 7px;
}

.pd-7-f {
	padding: 7px !important;
}

.pd-y-7 {
	padding-top: 7px;
	padding-bottom: 7px;
}

.pd-y-7-f {
	padding-top: 7px !important;
	padding-bottom: 7px !important;
}

.pd-x-7 {
	padding-left: 7px;
	padding-right: 7px;
}

.pd-x-7-f {
	padding-left: 7px !important;
	padding-right: 7px !important;
}

.pd-t-7 {
	padding-top: 7px;
}

.pd-r-7 {
	padding-right: 7px;
}

.pd-b-7 {
	padding-bottom: 7px;
}

.pd-l-7 {
	padding-left: 7px;
}

.pd-t-7-f {
	padding-top: 7px !important;
}

.pd-r-7-f {
	padding-right: 7px !important;
}

.pd-b-7-f {
	padding-bottom: 7px !important;
}

.pd-l-7-f {
	padding-left: 7px !important;
}

.pd-8 {
	padding: 8px;
}

.pd-8-f {
	padding: 8px !important;
}

.pd-y-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.pd-y-8-f {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.pd-x-8 {
	padding-left: 8px;
	padding-right: 8px;
}

.pd-x-8-f {
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.pd-t-8 {
	padding-top: 8px;
}

.pd-r-8 {
	padding-right: 8px;
}

.pd-b-8 {
	padding-bottom: 8px;
}

.pd-l-8 {
	padding-left: 8px;
}

.pd-t-8-f {
	padding-top: 8px !important;
}

.pd-r-8-f {
	padding-right: 8px !important;
}

.pd-b-8-f {
	padding-bottom: 8px !important;
}

.pd-l-8-f {
	padding-left: 8px !important;
}

.pd-9 {
	padding: 9px;
}

.pd-9-f {
	padding: 9px !important;
}

.pd-y-9 {
	padding-top: 9px;
	padding-bottom: 9px;
}

.pd-y-9-f {
	padding-top: 9px !important;
	padding-bottom: 9px !important;
}

.pd-x-9 {
	padding-left: 9px;
	padding-right: 9px;
}

.pd-x-9-f {
	padding-left: 9px !important;
	padding-right: 9px !important;
}

.pd-t-9 {
	padding-top: 9px;
}

.pd-r-9 {
	padding-right: 9px;
}

.pd-b-9 {
	padding-bottom: 9px;
}

.pd-l-9 {
	padding-left: 9px;
}

.pd-t-9-f {
	padding-top: 9px !important;
}

.pd-r-9-f {
	padding-right: 9px !important;
}

.pd-b-9-f {
	padding-bottom: 9px !important;
}

.pd-l-9-f {
	padding-left: 9px !important;
}

.pd-10 {
	padding: 10px;
}

.pd-10-f {
	padding: 10px !important;
}

.pd-y-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.pd-y-10-f {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.pd-x-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.pd-x-10-f {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.pd-t-10 {
	padding-top: 10px;
}

.pd-r-10 {
	padding-right: 10px;
}

.pd-b-10 {
	padding-bottom: 10px;
}

.pd-l-10 {
	padding-left: 10px;
}

.pd-t-10-f {
	padding-top: 10px !important;
}

.pd-r-10-f {
	padding-right: 10px !important;
}

.pd-b-10-f {
	padding-bottom: 10px !important;
}

.pd-l-10-f {
	padding-left: 10px !important;
}

.pd-15 {
	padding: 15px;
}

.pd-15-f {
	padding: 15px !important;
}

.pd-y-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.pd-y-15-f {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.pd-x-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.pd-x-15-f {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.pd-t-15 {
	padding-top: 15px;
}

.pd-r-15 {
	padding-right: 15px;
}

.pd-b-15 {
	padding-bottom: 15px;
}

.pd-l-15 {
	padding-left: 15px;
}

.pd-t-15-f {
	padding-top: 15px !important;
}

.pd-r-15-f {
	padding-right: 15px !important;
}

.pd-b-15-f {
	padding-bottom: 15px !important;
}

.pd-l-15-f {
	padding-left: 15px !important;
}

.pd-20 {
	padding: 20px;
}

.pd-20-f {
	padding: 20px !important;
}

.pd-y-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.pd-y-20-f {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.pd-x-20 {
	padding-left: 20px;
	padding-right: 20px;
}

.pd-x-20-f {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.pd-t-20 {
	padding-top: 20px;
}

.pd-r-20 {
	padding-right: 20px;
}

.pd-b-20 {
	padding-bottom: 20px;
}

.pd-l-20 {
	padding-left: 20px;
}

.pd-t-20-f {
	padding-top: 20px !important;
}

.pd-r-20-f {
	padding-right: 20px !important;
}

.pd-b-20-f {
	padding-bottom: 20px !important;
}

.pd-l-20-f {
	padding-left: 20px !important;
}

.pd-25 {
	padding: 25px;
}

.pd-25-f {
	padding: 25px !important;
}

.pd-y-25 {
	padding-top: 25px;
	padding-bottom: 25px;
}

.pd-y-25-f {
	padding-top: 25px !important;
	padding-bottom: 25px !important;
}

.pd-x-25 {
	padding-left: 25px;
	padding-right: 25px;
}

.pd-x-25-f {
	padding-left: 25px !important;
	padding-right: 25px !important;
}

.pd-t-25 {
	padding-top: 25px;
}

.pd-r-25 {
	padding-right: 25px;
}

.pd-b-25 {
	padding-bottom: 25px;
}

.pd-l-25 {
	padding-left: 25px;
}

.pd-t-25-f {
	padding-top: 25px !important;
}

.pd-r-25-f {
	padding-right: 25px !important;
}

.pd-b-25-f {
	padding-bottom: 25px !important;
}

.pd-l-25-f {
	padding-left: 25px !important;
}

.pd-30 {
	padding: 30px;
}

.pd-30-f {
	padding: 30px !important;
}

.pd-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.pd-y-30-f {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.pd-x-30 {
	padding-left: 30px;
	padding-right: 30px;
}

.pd-x-30-f {
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.pd-t-30 {
	padding-top: 30px;
}

.pd-r-30 {
	padding-right: 30px;
}

.pd-b-30 {
	padding-bottom: 30px;
}

.pd-l-30 {
	padding-left: 30px;
}

.pd-t-30-f {
	padding-top: 30px !important;
}

.pd-r-30-f {
	padding-right: 30px !important;
}

.pd-b-30-f {
	padding-bottom: 30px !important;
}

.pd-l-30-f {
	padding-left: 30px !important;
}

.pd-35 {
	padding: 35px;
}

.pd-35-f {
	padding: 35px !important;
}

.pd-y-35 {
	padding-top: 35px;
	padding-bottom: 35px;
}

.pd-y-35-f {
	padding-top: 35px !important;
	padding-bottom: 35px !important;
}

.pd-x-35 {
	padding-left: 35px;
	padding-right: 35px;
}

.pd-x-35-f {
	padding-left: 35px !important;
	padding-right: 35px !important;
}

.pd-t-35 {
	padding-top: 35px;
}

.pd-r-35 {
	padding-right: 35px;
}

.pd-b-35 {
	padding-bottom: 35px;
}

.pd-l-35 {
	padding-left: 35px;
}

.pd-t-35-f {
	padding-top: 35px !important;
}

.pd-r-35-f {
	padding-right: 35px !important;
}

.pd-b-35-f {
	padding-bottom: 35px !important;
}

.pd-l-35-f {
	padding-left: 35px !important;
}

.pd-40 {
	padding: 40px;
}

.pd-40-f {
	padding: 40px !important;
}

.pd-y-40 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.pd-y-40-f {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.pd-x-40 {
	padding-left: 40px;
	padding-right: 40px;
}

.pd-x-40-f {
	padding-left: 40px !important;
	padding-right: 40px !important;
}

.pd-t-40 {
	padding-top: 40px;
}

.pd-r-40 {
	padding-right: 40px;
}

.pd-b-40 {
	padding-bottom: 40px;
}

.pd-l-40 {
	padding-left: 40px;
}

.pd-t-40-f {
	padding-top: 40px !important;
}

.pd-r-40-f {
	padding-right: 40px !important;
}

.pd-b-40-f {
	padding-bottom: 40px !important;
}

.pd-l-40-f {
	padding-left: 40px !important;
}

.pd-45 {
	padding: 45px;
}

.pd-45-f {
	padding: 45px !important;
}

.pd-y-45 {
	padding-top: 45px;
	padding-bottom: 45px;
}

.pd-y-45-f {
	padding-top: 45px !important;
	padding-bottom: 45px !important;
}

.pd-x-45 {
	padding-left: 45px;
	padding-right: 45px;
}

.pd-x-45-f {
	padding-left: 45px !important;
	padding-right: 45px !important;
}

.pd-t-45 {
	padding-top: 45px;
}

.pd-r-45 {
	padding-right: 45px;
}

.pd-b-45 {
	padding-bottom: 45px;
}

.pd-l-45 {
	padding-left: 45px;
}

.pd-t-45-f {
	padding-top: 45px !important;
}

.pd-r-45-f {
	padding-right: 45px !important;
}

.pd-b-45-f {
	padding-bottom: 45px !important;
}

.pd-l-45-f {
	padding-left: 45px !important;
}

.pd-50 {
	padding: 50px;
}

.pd-50-f {
	padding: 50px !important;
}

.pd-y-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.pd-y-50-f {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
}

.pd-x-50 {
	padding-left: 50px;
	padding-right: 50px;
}

.pd-x-50-f {
	padding-left: 50px !important;
	padding-right: 50px !important;
}

.pd-t-50 {
	padding-top: 50px;
}

.pd-r-50 {
	padding-right: 50px;
}

.pd-b-50 {
	padding-bottom: 50px;
}

.pd-l-50 {
	padding-left: 50px;
}

.pd-t-50-f {
	padding-top: 50px !important;
}

.pd-r-50-f {
	padding-right: 50px !important;
}

.pd-b-50-f {
	padding-bottom: 50px !important;
}

.pd-l-50-f {
	padding-left: 50px !important;
}

.pd-55 {
	padding: 55px;
}

.pd-55-f {
	padding: 55px !important;
}

.pd-y-55 {
	padding-top: 55px;
	padding-bottom: 55px;
}

.pd-y-55-f {
	padding-top: 55px !important;
	padding-bottom: 55px !important;
}

.pd-x-55 {
	padding-left: 55px;
	padding-right: 55px;
}

.pd-x-55-f {
	padding-left: 55px !important;
	padding-right: 55px !important;
}

.pd-t-55 {
	padding-top: 55px;
}

.pd-r-55 {
	padding-right: 55px;
}

.pd-b-55 {
	padding-bottom: 55px;
}

.pd-l-55 {
	padding-left: 55px;
}

.pd-t-55-f {
	padding-top: 55px !important;
}

.pd-r-55-f {
	padding-right: 55px !important;
}

.pd-b-55-f {
	padding-bottom: 55px !important;
}

.pd-l-55-f {
	padding-left: 55px !important;
}

.pd-60 {
	padding: 60px;
}

.pd-60-f {
	padding: 60px !important;
}

.pd-y-60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.pd-y-60-f {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.pd-x-60 {
	padding-left: 60px;
	padding-right: 60px;
}

.pd-x-60-f {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.pd-t-60 {
	padding-top: 60px;
}

.pd-r-60 {
	padding-right: 60px;
}

.pd-b-60 {
	padding-bottom: 60px;
}

.pd-l-60 {
	padding-left: 60px;
}

.pd-t-60-f {
	padding-top: 60px !important;
}

.pd-r-60-f {
	padding-right: 60px !important;
}

.pd-b-60-f {
	padding-bottom: 60px !important;
}

.pd-l-60-f {
	padding-left: 60px !important;
}

.pd-65 {
	padding: 65px;
}

.pd-65-f {
	padding: 65px !important;
}

.pd-y-65 {
	padding-top: 65px;
	padding-bottom: 65px;
}

.pd-y-65-f {
	padding-top: 65px !important;
	padding-bottom: 65px !important;
}

.pd-x-65 {
	padding-left: 65px;
	padding-right: 65px;
}

.pd-x-65-f {
	padding-left: 65px !important;
	padding-right: 65px !important;
}

.pd-t-65 {
	padding-top: 65px;
}

.pd-r-65 {
	padding-right: 65px;
}

.pd-b-65 {
	padding-bottom: 65px;
}

.pd-l-65 {
	padding-left: 65px;
}

.pd-t-65-f {
	padding-top: 65px !important;
}

.pd-r-65-f {
	padding-right: 65px !important;
}

.pd-b-65-f {
	padding-bottom: 65px !important;
}

.pd-l-65-f {
	padding-left: 65px !important;
}

.pd-70 {
	padding: 70px;
}

.pd-70-f {
	padding: 70px !important;
}

.pd-y-70 {
	padding-top: 70px;
	padding-bottom: 70px;
}

.pd-y-70-f {
	padding-top: 70px !important;
	padding-bottom: 70px !important;
}

.pd-x-70 {
	padding-left: 70px;
	padding-right: 70px;
}

.pd-x-70-f {
	padding-left: 70px !important;
	padding-right: 70px !important;
}

.pd-t-70 {
	padding-top: 70px;
}

.pd-r-70 {
	padding-right: 70px;
}

.pd-b-70 {
	padding-bottom: 70px;
}

.pd-l-70 {
	padding-left: 70px;
}

.pd-t-70-f {
	padding-top: 70px !important;
}

.pd-r-70-f {
	padding-right: 70px !important;
}

.pd-b-70-f {
	padding-bottom: 70px !important;
}

.pd-l-70-f {
	padding-left: 70px !important;
}

.pd-75 {
	padding: 75px;
}

.pd-75-f {
	padding: 75px !important;
}

.pd-y-75 {
	padding-top: 75px;
	padding-bottom: 75px;
}

.pd-y-75-f {
	padding-top: 75px !important;
	padding-bottom: 75px !important;
}

.pd-x-75 {
	padding-left: 75px;
	padding-right: 75px;
}

.pd-x-75-f {
	padding-left: 75px !important;
	padding-right: 75px !important;
}

.pd-t-75 {
	padding-top: 75px;
}

.pd-r-75 {
	padding-right: 75px;
}

.pd-b-75 {
	padding-bottom: 75px;
}

.pd-l-75 {
	padding-left: 75px;
}

.pd-t-75-f {
	padding-top: 75px !important;
}

.pd-r-75-f {
	padding-right: 75px !important;
}

.pd-b-75-f {
	padding-bottom: 75px !important;
}

.pd-l-75-f {
	padding-left: 75px !important;
}

.pd-80 {
	padding: 80px;
}

.pd-80-f {
	padding: 80px !important;
}

.pd-y-80 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.pd-y-80-f {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
}

.pd-x-80 {
	padding-left: 80px;
	padding-right: 80px;
}

.pd-x-80-f {
	padding-left: 80px !important;
	padding-right: 80px !important;
}

.pd-t-80 {
	padding-top: 80px;
}

.pd-r-80 {
	padding-right: 80px;
}

.pd-b-80 {
	padding-bottom: 80px;
}

.pd-l-80 {
	padding-left: 80px;
}

.pd-t-80-f {
	padding-top: 80px !important;
}

.pd-r-80-f {
	padding-right: 80px !important;
}

.pd-b-80-f {
	padding-bottom: 80px !important;
}

.pd-l-80-f {
	padding-left: 80px !important;
}

.pd-85 {
	padding: 85px;
}

.pd-85-f {
	padding: 85px !important;
}

.pd-y-85 {
	padding-top: 85px;
	padding-bottom: 85px;
}

.pd-y-85-f {
	padding-top: 85px !important;
	padding-bottom: 85px !important;
}

.pd-x-85 {
	padding-left: 85px;
	padding-right: 85px;
}

.pd-x-85-f {
	padding-left: 85px !important;
	padding-right: 85px !important;
}

.pd-t-85 {
	padding-top: 85px;
}

.pd-r-85 {
	padding-right: 85px;
}

.pd-b-85 {
	padding-bottom: 85px;
}

.pd-l-85 {
	padding-left: 85px;
}

.pd-t-85-f {
	padding-top: 85px !important;
}

.pd-r-85-f {
	padding-right: 85px !important;
}

.pd-b-85-f {
	padding-bottom: 85px !important;
}

.pd-l-85-f {
	padding-left: 85px !important;
}

.pd-90 {
	padding: 90px;
}

.pd-90-f {
	padding: 90px !important;
}

.pd-y-90 {
	padding-top: 90px;
	padding-bottom: 90px;
}

.pd-y-90-f {
	padding-top: 90px !important;
	padding-bottom: 90px !important;
}

.pd-x-90 {
	padding-left: 90px;
	padding-right: 90px;
}

.pd-x-90-f {
	padding-left: 90px !important;
	padding-right: 90px !important;
}

.pd-t-90 {
	padding-top: 90px;
}

.pd-r-90 {
	padding-right: 90px;
}

.pd-b-90 {
	padding-bottom: 90px;
}

.pd-l-90 {
	padding-left: 90px;
}

.pd-t-90-f {
	padding-top: 90px !important;
}

.pd-r-90-f {
	padding-right: 90px !important;
}

.pd-b-90-f {
	padding-bottom: 90px !important;
}

.pd-l-90-f {
	padding-left: 90px !important;
}

.pd-95 {
	padding: 95px;
}

.pd-95-f {
	padding: 95px !important;
}

.pd-y-95 {
	padding-top: 95px;
	padding-bottom: 95px;
}

.pd-y-95-f {
	padding-top: 95px !important;
	padding-bottom: 95px !important;
}

.pd-x-95 {
	padding-left: 95px;
	padding-right: 95px;
}

.pd-x-95-f {
	padding-left: 95px !important;
	padding-right: 95px !important;
}

.pd-t-95 {
	padding-top: 95px;
}

.pd-r-95 {
	padding-right: 95px;
}

.pd-b-95 {
	padding-bottom: 95px;
}

.pd-l-95 {
	padding-left: 95px;
}

.pd-t-95-f {
	padding-top: 95px !important;
}

.pd-r-95-f {
	padding-right: 95px !important;
}

.pd-b-95-f {
	padding-bottom: 95px !important;
}

.pd-l-95-f {
	padding-left: 95px !important;
}

.pd-100 {
	padding: 100px;
}

.pd-100-f {
	padding: 100px !important;
}

.pd-y-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.pd-y-100-f {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}

.pd-x-100 {
	padding-left: 100px;
	padding-right: 100px;
}

.pd-x-100-f {
	padding-left: 100px !important;
	padding-right: 100px !important;
}

.pd-t-100 {
	padding-top: 100px;
}

.pd-r-100 {
	padding-right: 100px;
}

.pd-b-100 {
	padding-bottom: 100px;
}

.pd-l-100 {
	padding-left: 100px;
}

.pd-t-100-f {
	padding-top: 100px !important;
}

.pd-r-100-f {
	padding-right: 100px !important;
}

.pd-b-100-f {
	padding-bottom: 100px !important;
}

.pd-l-100-f {
	padding-left: 100px !important;
}

.pd-110 {
	padding: 110px;
}

.pd-110-f {
	padding: 110px !important;
}

.pd-y-110 {
	padding-top: 110px;
	padding-bottom: 110px;
}

.pd-y-110-f {
	padding-top: 110px !important;
	padding-bottom: 110px !important;
}

.pd-x-110 {
	padding-left: 110px;
	padding-right: 110px;
}

.pd-x-110-f {
	padding-left: 110px !important;
	padding-right: 110px !important;
}

.pd-t-110 {
	padding-top: 110px;
}

.pd-r-110 {
	padding-right: 110px;
}

.pd-b-110 {
	padding-bottom: 110px;
}

.pd-l-110 {
	padding-left: 110px;
}

.pd-t-110-f {
	padding-top: 110px !important;
}

.pd-r-110-f {
	padding-right: 110px !important;
}

.pd-b-110-f {
	padding-bottom: 110px !important;
}

.pd-l-110-f {
	padding-left: 110px !important;
}

.pd-120 {
	padding: 120px;
}

.pd-120-f {
	padding: 120px !important;
}

.pd-y-120 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.pd-y-120-f {
	padding-top: 120px !important;
	padding-bottom: 120px !important;
}

.pd-x-120 {
	padding-left: 120px;
	padding-right: 120px;
}

.pd-x-120-f {
	padding-left: 120px !important;
	padding-right: 120px !important;
}

.pd-t-120 {
	padding-top: 120px;
}

.pd-r-120 {
	padding-right: 120px;
}

.pd-b-120 {
	padding-bottom: 120px;
}

.pd-l-120 {
	padding-left: 120px;
}

.pd-t-120-f {
	padding-top: 120px !important;
}

.pd-r-120-f {
	padding-right: 120px !important;
}

.pd-b-120-f {
	padding-bottom: 120px !important;
}

.pd-l-120-f {
	padding-left: 120px !important;
}

.pd-130 {
	padding: 130px;
}

.pd-130-f {
	padding: 130px !important;
}

.pd-y-130 {
	padding-top: 130px;
	padding-bottom: 130px;
}

.pd-y-130-f {
	padding-top: 130px !important;
	padding-bottom: 130px !important;
}

.pd-x-130 {
	padding-left: 130px;
	padding-right: 130px;
}

.pd-x-130-f {
	padding-left: 130px !important;
	padding-right: 130px !important;
}

.pd-t-130 {
	padding-top: 130px;
}

.pd-r-130 {
	padding-right: 130px;
}

.pd-b-130 {
	padding-bottom: 130px;
}

.pd-l-130 {
	padding-left: 130px;
}

.pd-t-130-f {
	padding-top: 130px !important;
}

.pd-r-130-f {
	padding-right: 130px !important;
}

.pd-b-130-f {
	padding-bottom: 130px !important;
}

.pd-l-130-f {
	padding-left: 130px !important;
}

.pd-140 {
	padding: 140px;
}

.pd-140-f {
	padding: 140px !important;
}

.pd-y-140 {
	padding-top: 140px;
	padding-bottom: 140px;
}

.pd-y-140-f {
	padding-top: 140px !important;
	padding-bottom: 140px !important;
}

.pd-x-140 {
	padding-left: 140px;
	padding-right: 140px;
}

.pd-x-140-f {
	padding-left: 140px !important;
	padding-right: 140px !important;
}

.pd-t-140 {
	padding-top: 140px;
}

.pd-r-140 {
	padding-right: 140px;
}

.pd-b-140 {
	padding-bottom: 140px;
}

.pd-l-140 {
	padding-left: 140px;
}

.pd-t-140-f {
	padding-top: 140px !important;
}

.pd-r-140-f {
	padding-right: 140px !important;
}

.pd-b-140-f {
	padding-bottom: 140px !important;
}

.pd-l-140-f {
	padding-left: 140px !important;
}

.pd-150 {
	padding: 150px;
}

.pd-150-f {
	padding: 150px !important;
}

.pd-y-150 {
	padding-top: 150px;
	padding-bottom: 150px;
}

.pd-y-150-f {
	padding-top: 150px !important;
	padding-bottom: 150px !important;
}

.pd-x-150 {
	padding-left: 150px;
	padding-right: 150px;
}

.pd-x-150-f {
	padding-left: 150px !important;
	padding-right: 150px !important;
}

.pd-t-150 {
	padding-top: 150px;
}

.pd-r-150 {
	padding-right: 150px;
}

.pd-b-150 {
	padding-bottom: 150px;
}

.pd-l-150 {
	padding-left: 150px;
}

.pd-t-150-f {
	padding-top: 150px !important;
}

.pd-r-150-f {
	padding-right: 150px !important;
}

.pd-b-150-f {
	padding-bottom: 150px !important;
}

.pd-l-150-f {
	padding-left: 150px !important;
}

.pd-160 {
	padding: 160px;
}

.pd-160-f {
	padding: 160px !important;
}

.pd-y-160 {
	padding-top: 160px;
	padding-bottom: 160px;
}

.pd-y-160-f {
	padding-top: 160px !important;
	padding-bottom: 160px !important;
}

.pd-x-160 {
	padding-left: 160px;
	padding-right: 160px;
}

.pd-x-160-f {
	padding-left: 160px !important;
	padding-right: 160px !important;
}

.pd-t-160 {
	padding-top: 160px;
}

.pd-r-160 {
	padding-right: 160px;
}

.pd-b-160 {
	padding-bottom: 160px;
}

.pd-l-160 {
	padding-left: 160px;
}

.pd-t-160-f {
	padding-top: 160px !important;
}

.pd-r-160-f {
	padding-right: 160px !important;
}

.pd-b-160-f {
	padding-bottom: 160px !important;
}

.pd-l-160-f {
	padding-left: 160px !important;
}

.pd-170 {
	padding: 170px;
}

.pd-170-f {
	padding: 170px !important;
}

.pd-y-170 {
	padding-top: 170px;
	padding-bottom: 170px;
}

.pd-y-170-f {
	padding-top: 170px !important;
	padding-bottom: 170px !important;
}

.pd-x-170 {
	padding-left: 170px;
	padding-right: 170px;
}

.pd-x-170-f {
	padding-left: 170px !important;
	padding-right: 170px !important;
}

.pd-t-170 {
	padding-top: 170px;
}

.pd-r-170 {
	padding-right: 170px;
}

.pd-b-170 {
	padding-bottom: 170px;
}

.pd-l-170 {
	padding-left: 170px;
}

.pd-t-170-f {
	padding-top: 170px !important;
}

.pd-r-170-f {
	padding-right: 170px !important;
}

.pd-b-170-f {
	padding-bottom: 170px !important;
}

.pd-l-170-f {
	padding-left: 170px !important;
}

.pd-180 {
	padding: 180px;
}

.pd-180-f {
	padding: 180px !important;
}

.pd-y-180 {
	padding-top: 180px;
	padding-bottom: 180px;
}

.pd-y-180-f {
	padding-top: 180px !important;
	padding-bottom: 180px !important;
}

.pd-x-180 {
	padding-left: 180px;
	padding-right: 180px;
}

.pd-x-180-f {
	padding-left: 180px !important;
	padding-right: 180px !important;
}

.pd-t-180 {
	padding-top: 180px;
}

.pd-r-180 {
	padding-right: 180px;
}

.pd-b-180 {
	padding-bottom: 180px;
}

.pd-l-180 {
	padding-left: 180px;
}

.pd-t-180-f {
	padding-top: 180px !important;
}

.pd-r-180-f {
	padding-right: 180px !important;
}

.pd-b-180-f {
	padding-bottom: 180px !important;
}

.pd-l-180-f {
	padding-left: 180px !important;
}

.pd-190 {
	padding: 190px;
}

.pd-190-f {
	padding: 190px !important;
}

.pd-y-190 {
	padding-top: 190px;
	padding-bottom: 190px;
}

.pd-y-190-f {
	padding-top: 190px !important;
	padding-bottom: 190px !important;
}

.pd-x-190 {
	padding-left: 190px;
	padding-right: 190px;
}

.pd-x-190-f {
	padding-left: 190px !important;
	padding-right: 190px !important;
}

.pd-t-190 {
	padding-top: 190px;
}

.pd-r-190 {
	padding-right: 190px;
}

.pd-b-190 {
	padding-bottom: 190px;
}

.pd-l-190 {
	padding-left: 190px;
}

.pd-t-190-f {
	padding-top: 190px !important;
}

.pd-r-190-f {
	padding-right: 190px !important;
}

.pd-b-190-f {
	padding-bottom: 190px !important;
}

.pd-l-190-f {
	padding-left: 190px !important;
}

.pd-200 {
	padding: 200px;
}

.pd-200-f {
	padding: 200px !important;
}

.pd-y-200 {
	padding-top: 200px;
	padding-bottom: 200px;
}

.pd-y-200-f {
	padding-top: 200px !important;
	padding-bottom: 200px !important;
}

.pd-x-200 {
	padding-left: 200px;
	padding-right: 200px;
}

.pd-x-200-f {
	padding-left: 200px !important;
	padding-right: 200px !important;
}

.pd-t-200 {
	padding-top: 200px;
}

.pd-r-200 {
	padding-right: 200px;
}

.pd-b-200 {
	padding-bottom: 200px;
}

.pd-l-200 {
	padding-left: 200px;
}

.pd-t-200-f {
	padding-top: 200px !important;
}

.pd-r-200-f {
	padding-right: 200px !important;
}

.pd-b-200-f {
	padding-bottom: 200px !important;
}

.pd-l-200-f {
	padding-left: 200px !important;
}

.pd-12 {
	padding: 12px;
}

.pd-12-f {
	padding: 12px !important;
}

.pd-y-12 {
	padding-top: 12px;
	padding-bottom: 12px;
}

.pd-y-12-f {
	padding-top: 12px !important;
	padding-bottom: 12px !important;
}

.pd-x-12 {
	padding-left: 12px;
	padding-right: 12px;
}

.pd-x-12-f {
	padding-left: 12px !important;
	padding-right: 12px !important;
}

.pd-t-12 {
	padding-top: 12px;
}

.pd-r-12 {
	padding-right: 12px;
}

.pd-b-12 {
	padding-bottom: 12px;
}

.pd-l-12 {
	padding-left: 12px;
}

.pd-t-12-f {
	padding-top: 12px !important;
}

.pd-r-12-f {
	padding-right: 12px !important;
}

.pd-b-12-f {
	padding-bottom: 12px !important;
}

.pd-l-12-f {
	padding-left: 12px !important;
}

.pd-13 {
	padding: 13px;
}

.pd-13-f {
	padding: 13px !important;
}

.pd-y-13 {
	padding-top: 13px;
	padding-bottom: 13px;
}

.pd-y-13-f {
	padding-top: 13px !important;
	padding-bottom: 13px !important;
}

.pd-x-13 {
	padding-left: 13px;
	padding-right: 13px;
}

.pd-x-13-f {
	padding-left: 13px !important;
	padding-right: 13px !important;
}

.pd-t-13 {
	padding-top: 13px;
}

.pd-r-13 {
	padding-right: 13px;
}

.pd-b-13 {
	padding-bottom: 13px;
}

.pd-l-13 {
	padding-left: 13px;
}

.pd-t-13-f {
	padding-top: 13px !important;
}

.pd-r-13-f {
	padding-right: 13px !important;
}

.pd-b-13-f {
	padding-bottom: 13px !important;
}

.pd-l-13-f {
	padding-left: 13px !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pe-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.ps-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pe-1,
.px-1 {
	padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.ps-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pe-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.ps-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pe-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.ps-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}
.p-1-25 {
	padding: 1.25rem !important;
}
.p-x-25 {
	padding-left: 1.25rem !important;
	padding-right: 1.25rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pe-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.ps-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pe-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.ps-5,
.px-5 {
	padding-left: 3rem !important;
}

@media (min-width: 576px) {
	.p-sm-0 {
		padding: 0 !important;
	}

	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0 !important;
	}

	.pe-sm-0,
	.px-sm-0 {
		padding-right: 0 !important;
	}

	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0 !important;
	}

	.ps-sm-0,
	.px-sm-0 {
		padding-left: 0 !important;
	}

	.p-sm-1 {
		padding: 0.25rem !important;
	}

	.pt-sm-1,
	.py-sm-1 {
		padding-top: 0.25rem !important;
	}

	.pe-sm-1,
	.px-sm-1 {
		padding-right: 0.25rem !important;
	}

	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: 0.25rem !important;
	}

	.ps-sm-1,
	.px-sm-1 {
		padding-left: 0.25rem !important;
	}

	.p-sm-2 {
		padding: 0.5rem !important;
	}

	.pt-sm-2,
	.py-sm-2 {
		padding-top: 0.5rem !important;
	}

	.pe-sm-2,
	.px-sm-2 {
		padding-right: 0.5rem !important;
	}

	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: 0.5rem !important;
	}

	.ps-sm-2,
	.px-sm-2 {
		padding-left: 0.5rem !important;
	}

	.p-sm-3 {
		padding: 1rem !important;
	}

	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem !important;
	}

	.pe-sm-3,
	.px-sm-3 {
		padding-right: 1rem !important;
	}

	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem !important;
	}

	.ps-sm-3,
	.px-sm-3 {
		padding-left: 1rem !important;
	}

	.p-sm-4 {
		padding: 1.5rem !important;
	}

	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem !important;
	}

	.pe-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem !important;
	}

	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem !important;
	}

	.ps-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem !important;
	}

	.p-sm-5 {
		padding: 3rem !important;
	}

	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem !important;
	}

	.pe-sm-5,
	.px-sm-5 {
		padding-right: 3rem !important;
	}

	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem !important;
	}

	.ps-sm-5,
	.px-sm-5 {
		padding-left: 3rem !important;
	}
}

@media (min-width: 768px) {
	.p-md-0 {
		padding: 0 !important;
	}

	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important;
	}

	.pe-md-0,
	.px-md-0 {
		padding-right: 0 !important;
	}

	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0 !important;
	}

	.ps-md-0,
	.px-md-0 {
		padding-left: 0 !important;
	}

	.p-md-1 {
		padding: 0.25rem !important;
	}

	.pt-md-1,
	.py-md-1 {
		padding-top: 0.25rem !important;
	}

	.pe-md-1,
	.px-md-1 {
		padding-right: 0.25rem !important;
	}

	.pb-md-1,
	.py-md-1 {
		padding-bottom: 0.25rem !important;
	}

	.ps-md-1,
	.px-md-1 {
		padding-left: 0.25rem !important;
	}

	.p-md-2 {
		padding: 0.5rem !important;
	}

	.pt-md-2,
	.py-md-2 {
		padding-top: 0.5rem !important;
	}

	.pe-md-2,
	.px-md-2 {
		padding-right: 0.5rem !important;
	}

	.pb-md-2,
	.py-md-2 {
		padding-bottom: 0.5rem !important;
	}

	.ps-md-2,
	.px-md-2 {
		padding-left: 0.5rem !important;
	}

	.p-md-3 {
		padding: 1rem !important;
	}

	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem !important;
	}

	.pe-md-3,
	.px-md-3 {
		padding-right: 1rem !important;
	}

	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem !important;
	}

	.ps-md-3,
	.px-md-3 {
		padding-left: 1rem !important;
	}

	.p-md-4 {
		padding: 1.5rem !important;
	}

	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem !important;
	}

	.pe-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important;
	}

	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem !important;
	}

	.ps-md-4,
	.px-md-4 {
		padding-left: 1.5rem !important;
	}

	.p-md-5 {
		padding: 3rem !important;
	}

	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem !important;
	}

	.pe-md-5,
	.px-md-5 {
		padding-right: 3rem !important;
	}

	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem !important;
	}

	.ps-md-5,
	.px-md-5 {
		padding-left: 3rem !important;
	}
}

@media (min-width: 992px) {
	.p-lg-0 {
		padding: 0 !important;
	}

	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0 !important;
	}

	.pe-lg-0,
	.px-lg-0 {
		padding-right: 0 !important;
	}

	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0 !important;
	}

	.ps-lg-0,
	.px-lg-0 {
		padding-left: 0 !important;
	}

	.p-lg-1 {
		padding: 0.25rem !important;
	}

	.pt-lg-1,
	.py-lg-1 {
		padding-top: 0.25rem !important;
	}

	.pe-lg-1,
	.px-lg-1 {
		padding-right: 0.25rem !important;
	}

	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: 0.25rem !important;
	}

	.ps-lg-1,
	.px-lg-1 {
		padding-left: 0.25rem !important;
	}

	.p-lg-2 {
		padding: 0.5rem !important;
	}

	.pt-lg-2,
	.py-lg-2 {
		padding-top: 0.5rem !important;
	}

	.pe-lg-2,
	.px-lg-2 {
		padding-right: 0.5rem !important;
	}

	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: 0.5rem !important;
	}

	.ps-lg-2,
	.px-lg-2 {
		padding-left: 0.5rem !important;
	}

	.p-lg-3 {
		padding: 1rem !important;
	}

	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem !important;
	}

	.pe-lg-3,
	.px-lg-3 {
		padding-right: 1rem !important;
	}

	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem !important;
	}

	.ps-lg-3,
	.px-lg-3 {
		padding-left: 1rem !important;
	}

	.p-lg-4 {
		padding: 1.5rem !important;
	}

	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem !important;
	}

	.pe-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem !important;
	}

	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem !important;
	}

	.ps-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem !important;
	}

	.p-lg-5 {
		padding: 3rem !important;
	}

	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem !important;
	}

	.pe-lg-5,
	.px-lg-5 {
		padding-right: 3rem !important;
	}

	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem !important;
	}

	.ps-lg-5,
	.px-lg-5 {
		padding-left: 3rem !important;
	}
}

@media (min-width: 1200px) {
	.p-xl-0 {
		padding: 0 !important;
	}

	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important;
	}

	.pe-xl-0,
	.px-xl-0 {
		padding-right: 0 !important;
	}

	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important;
	}

	.ps-xl-0,
	.px-xl-0 {
		padding-left: 0 !important;
	}

	.p-xl-1 {
		padding: 0.25rem !important;
	}

	.pt-xl-1,
	.py-xl-1 {
		padding-top: 0.25rem !important;
	}

	.pe-xl-1,
	.px-xl-1 {
		padding-right: 0.25rem !important;
	}

	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: 0.25rem !important;
	}

	.ps-xl-1,
	.px-xl-1 {
		padding-left: 0.25rem !important;
	}

	.p-xl-2 {
		padding: 0.5rem !important;
	}

	.pt-xl-2,
	.py-xl-2 {
		padding-top: 0.5rem !important;
	}

	.pe-xl-2,
	.px-xl-2 {
		padding-right: 0.5rem !important;
	}

	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: 0.5rem !important;
	}

	.ps-xl-2,
	.px-xl-2 {
		padding-left: 0.5rem !important;
	}

	.p-xl-3 {
		padding: 1rem !important;
	}

	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem !important;
	}

	.pe-xl-3,
	.px-xl-3 {
		padding-right: 1rem !important;
	}

	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem !important;
	}

	.ps-xl-3,
	.px-xl-3 {
		padding-left: 1rem !important;
	}

	.p-xl-4 {
		padding: 1.5rem !important;
	}

	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem !important;
	}

	.pe-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem !important;
	}

	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem !important;
	}

	.ps-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem !important;
	}

	.p-xl-5 {
		padding: 3rem !important;
	}

	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem !important;
	}

	.pe-xl-5,
	.px-xl-5 {
		padding-right: 3rem !important;
	}

	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem !important;
	}

	.ps-xl-5,
	.px-xl-5 {
		padding-left: 3rem !important;
	}
}

@media (min-width: 576px) {
	.pd-sm-40 {
		padding: 40px;
	}
}

@media (min-width: 768px) {
	.pd-md-20 {
		padding: 20px;
	}
}
