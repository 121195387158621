.btn-group, .btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
  }
  
  .btn-group > .btn, .sp-container .btn-group > button, .btn-group-vertical > .btn, .sp-container .btn-group-vertical > button {
	position: relative;
	flex: 1 1 auto;
  }
  
  .btn-group > .btn:hover, .sp-container .btn-group > button:hover, .btn-group-vertical > .btn:hover, .sp-container .btn-group-vertical > button:hover, .btn-group > .btn:focus, .sp-container .btn-group > button:focus, .btn-group > .btn:active, .sp-container .btn-group > button:active, .btn-group > .btn.active, .sp-container .btn-group > button.active, .btn-group-vertical > .btn:focus, .sp-container .btn-group-vertical > button:focus, .btn-group-vertical > .btn:active, .sp-container .btn-group-vertical > button:active, .btn-group-vertical > .btn.active, .sp-container .btn-group-vertical > button.active {
	z-index: 1;
  }
  
  .btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
  
	.input-group {
	  width: auto;
	}
  }
  
  .btn-group > .btn:not(:first-child), .sp-container .btn-group > button:not(:first-child) {
	margin-left: -1px;
  }
  
  .btn-group > {
	.btn-group:not(:first-child) {
	  margin-left: -1px;
	}
  
	.btn:not(:last-child):not(.dropdown-toggle) {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  }
  
  .sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn, .sp-container .btn-group > .btn-group:not(:last-child) > button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  
  .btn-group > .btn:not(:first-child), .sp-container .btn-group > button:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn, .sp-container .btn-group > .btn-group:not(:first-child) > button {
	// border-top-left-radius: 0;
	// border-bottom-left-radius: 0;
  }
  
  .dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
  
	&::after {
	  margin-left: 0;
	}
  }
  
  .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
	margin-left: 0;
  }
  
  .dropleft .dropdown-toggle-split::before {
	margin-right: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .sp-container .btn-group-sm > button + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .sp-container .btn-group-lg > button + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
  }
  
  .btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
  
	> .btn {
	  width: 100%;
	}
  }
  
  .sp-container .btn-group-vertical > button {
	width: 100%;
  }
  
  .btn-group-vertical > {
	.btn-group {
	  width: 100%;
	}
  
	.btn:not(:first-child) {
	  margin-top: -1px;
	}
  }
  
  .sp-container .btn-group-vertical > button:not(:first-child) {
	margin-top: -1px;
  }
  
  .btn-group-vertical > {
	.btn-group:not(:first-child) {
	  margin-top: -1px;
	}
  
	.btn:not(:last-child):not(.dropdown-toggle) {
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}
  }
  
  .sp-container .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:last-child) > button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  .btn-group-vertical > .btn:not(:first-child), .sp-container .btn-group-vertical > button:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:first-child) > button {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  }
  
  .btn-group-toggle > .btn, .sp-container .btn-group-toggle > button, .btn-group-toggle > .btn-group > .btn, .sp-container .btn-group-toggle > .btn-group > button {
	margin-bottom: 0;
  }
  
  .btn-group-toggle > .btn input[type="radio"], .sp-container .btn-group-toggle > button input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .sp-container .btn-group-toggle > button input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .sp-container .btn-group-toggle > .btn-group > button input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .sp-container .btn-group-toggle > .btn-group > button input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
  }
  
  .input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
  
	> {
	  .form-control, .form-control-plaintext, .custom-select, .custom-file {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		margin-bottom: 0;
	  }
  
	  .form-control + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .form-control-plaintext + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .custom-select + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .custom-file + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .form-control:focus, .custom-select:focus {
		z-index: 3;
	  }
  
	  .custom-file .custom-file-input:focus {
		~ .custom-file-label {
		  z-index: 3;
		}
  
		z-index: 4;
	  }
  
	  .form-control:not(:last-child), .custom-select:not(:last-child) {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	  }
  
	  .form-control:not(:first-child), .custom-select:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  .custom-file {
		display: flex;
		align-items: center;
  
		&:not(:last-child) .custom-file-label {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
  
		  &::after {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		  }
		}
  
		&:not(:first-child) .custom-file-label {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
		}
	  }
	}
  }
  
  .input-group-text, .input-group-text {
	display: flex;
  }
  
  .input-group-text {
	.btn, .sp-container button {
	  position: relative;
	  z-index: 2;
	}
  }
  
  .sp-container .input-group-text button {
	position: relative;
	z-index: 2;
  }
  
  .input-group-text {
	.btn, .sp-container button {
	  position: relative;
	  z-index: 2;
	}
  }
  
  .sp-container .input-group-text button {
	position: relative;
	z-index: 2;
  }
  
  .input-group-text {
	.btn:focus, .sp-container button:focus {
	  z-index: 3;
	}
  }
  
  .sp-container .input-group-text button:focus {
	z-index: 3;
  }
  
  .input-group-text {
	.btn:focus, .sp-container button:focus {
	  z-index: 3;
	}
  }
  
  .sp-container .input-group-text button:focus {
	z-index: 3;
  }
  
  .input-group-text {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text button + .btn, .input-group-text .sp-container .btn + button, .sp-container .input-group-text .btn + button, .input-group-text .sp-container button + button, .sp-container .input-group-text button + button {
	margin-left: -1px;
  }
  
  .input-group-text {
	.btn + .input-group-text, .sp-container button + .input-group-text {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text button + .input-group-text {
	margin-left: -1px;
  }
  
  .input-group-text {
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  
	.sp-container .input-group-text + button {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text .input-group-text + button {
	margin-left: -1px;
  }
  
  .input-group-text {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text button + .btn, .input-group-text .sp-container .btn + button, .sp-container .input-group-text .btn + button, .input-group-text .sp-container button + button, .sp-container .input-group-text button + button {
	margin-left: -1px;
  }
  
  .input-group-text {
	.btn + .input-group-text, .sp-container button + .input-group-text {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text button + .input-group-text {
	margin-left: -1px;
  }
  
  .input-group-text {
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  
	.sp-container .input-group-text + button {
	  margin-left: -1px;
	}
  }
  
  .sp-container .input-group-text .input-group-text + button {
	margin-left: -1px;
  }
  
  .input-group-text {
	margin-right: -1px;
  }
  
  .input-group-text {
	margin-left: -1px;
  }
  
  .input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #596882;
	text-align: center;
	white-space: nowrap;
	background-color: #e8e7ef;
	border: 1px solid $border;
	border-radius: 3px;
  
	input {
	  &[type="radio"], &[type="checkbox"] {
		margin-top: 0;
	  }
	}
  }
  
  .input-group-lg > {
	.form-control:not(textarea), .custom-select {
	  height: calc(1.5em + 1rem + 2px);
	}
  
	.form-control, .custom-select, .input-group-text > .input-group-text, .input-group-text > .input-group-text, .input-group-text > .btn {
	  padding: 0.5rem 1rem;
	  font-size: 1.09375rem;
	  line-height: 1.5;
	  border-radius: 0.3rem;
	}
  }
  
  .sp-container .input-group-lg > .input-group-text > button, .input-group-lg > .input-group-text > .btn, .sp-container .input-group-lg > .input-group-text > button {
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
  }
  
  .input-group-sm > {
	.form-control:not(textarea), .custom-select {
	  height: calc(1.5em + 0.5rem + 2px);
	}
  
	.form-control, .custom-select, .input-group-text > .input-group-text, .input-group-text > .input-group-text, .input-group-text > .btn {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.76562rem;
	  line-height: 1.5;
	  border-radius: 0.2rem;
	}
  }
  
  .sp-container .input-group-sm > .input-group-text > button, .input-group-sm > .input-group-text > .btn, .sp-container .input-group-sm > .input-group-text > button {
	padding: 0.25rem 0.5rem;
	font-size: 0.76562rem;
	line-height: 1.5;
	border-radius: 0.2rem;
  }
  
  .input-group-lg > .custom-select, .input-group-sm > .custom-select {
	padding-right: 1.75rem;
  }
  
  .input-group > .input-group-text > .btn, .sp-container .input-group > .input-group-text > button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  
  .input-group > {
	.input-group-text > .input-group-text, .input-group-text:not(:last-child) > .btn {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  }
  
  .sp-container .input-group > .input-group-text:not(:last-child) > button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-text {
	&:not(:last-child) > .input-group-text, &:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  }
  
  .sp-container .input-group > .input-group-text:last-child > button:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-text {
	&:last-child > .input-group-text:not(:last-child) {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  
	> .btn {
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  }
  
  .sp-container .input-group > .input-group-text > button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  .input-group > {
	.input-group-text > .input-group-text, .input-group-text:not(:first-child) > .btn {
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  }
  
  .sp-container .input-group > .input-group-text:not(:first-child) > button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  .input-group > .input-group-text {
	&:not(:first-child) > .input-group-text, &:first-child > .btn:not(:first-child) {
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  }
  
  .sp-container .input-group > .input-group-text:first-child > button:not(:first-child), .input-group > .input-group-text:first-child > .input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  /* ###### 3.8 Input Group ###### */
  
  .input-group-text, .input-group-text {
	border-radius: 0;
  }
  
  .input-group-text {
	padding-top: 0;
	padding-bottom: 0;
	align-items: center;
	border-radius: 3px;
  }
  .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
 