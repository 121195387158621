.app-sidebar .mCSB_draggerContainer {
  right: -11px !important;
}

@media (min-width: 992px) {
  .app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }

    .app-sidebar {
      left: 230px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }
  }
}

@media (max-width: 991px) {
  .app {
    overflow-x: hidden;

    .app-sidebar {
      left: -250px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }

    &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
      left: 0;
    }
  }
  .side-menu {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
}

.main-sidebar-body {
  padding: 0;
  margin-top: 80px;
}

@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled .main-sidebar-header {
    .main-logo {
      display: none;
    }

    .logo-icon {
      // display: block;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 0;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;

  .side-app {
    padding: 0px 30px 0 30px;
  }
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .app-content {
    margin-left: 250px;
  }
}

@media (max-width: 991px) {
  .app-content {
    min-width: 100%;
  }
}

@media (max-width: 480px) {
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding-right: 15px;
  padding: 5px 0;
  box-shadow: 0 2px 17px 2px $black-2;
  border-bottom: 1px solid $white-1;
  background: #5e2dd8;
}

@media (min-width: 992px) {
  .app-header {
    padding-right: 30px;
  }
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 992px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}

.app-sidebar__toggle {
  color: $white;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: 1;
  margin-right: 10px;

  &:hover {
    color: $white;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/
@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}

.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background: $black-1;
    color: #f6f6f6;
  }
}

.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}

.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: $white-8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $black-4;
  }
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: $black-8;
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e0e0e0;
  }
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}
.sidebar-mini .app-sidebar {
  width: 240px;
}
.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #8a98ac;
  // height: 100vh;
  z-index: 1000;
  background: linear-gradient(to bottom, $primary 0%, #764ba2 100%);
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 991px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }

  img {
    box-shadow: 0 0 25px $white-1;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #a8a8a8;
  width: 100%;
  display: inline-block;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }

  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  .slide {
    .side-menu__item {
      padding: 10px 14px;
      margin: 2px 0;
    }

    &.active {
      .side-menu__item {
        background-color: transparent;
      }

      .side-menu__label,
      .side-menu__icon {
        color: $white;
      }
    }
  }
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;

  /* color:$white; */
  white-space: normal;

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 3px;
  }
}

.app-sidebar .side-menu__item:hover .side-menu__label {
  color: rgb(255, 255, 255);
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__label {
    display: none;
    position: relative;
    padding: 0;
    min-width: 100%;
    margin: 0;
    left: 0;
    color: $white-8;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
  .sidenav-toggled .side-menu h3 {
    display: none;
  }
  .responsive-logo {
    display: none;
  }
}

.side-menu__item {
  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: $white;
  }
}

.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $white !important;
  }

  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary;
  }
}

.slide-menu .sub-slide-menu {
  padding: 0 23px;
}

.sub-slide .sub-angle {
  margin-left: auto;
  float: right;
  font-size: 12px;
  margin-top: 3px;
}

.slide-menu li {
  position: relative;
}

.slide.is-expanded {
  a {
    color: $white-6;
    text-decoration: none;
    font-size: 0.83rem !important;
  }

  .side-menu__item {
    background: rgba(255, 255, 255, 0.09);
  }

  .sub-side-menu__item:before {
    content: "\e92f";
    font-family: "feather" !important;
    position: absolute;
    top: 11px;
    left: 16px;
    font-size: 11px;
  }
}

.side-menu .side-menu__icon {
  font-size: 18px;
  line-height: 0;
  margin-right: 14px;
  border-radius: 3px;
  text-align: center;
  color: rgb(214, 222, 234);
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: rgb(214, 222, 234);
  transition-property: color;
  -webkit-transition-property: color;
  position: relative;
  font-size: 0.875rem;
  // line-height: 1;
  vertical-align: middle;
  font-weight: 400;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.slide-menu {
  display: none;
  padding: 0;
  padding-left: 29px;
  list-style: none;
  padding: 0;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  /* padding: 6px 7px; */
  font-size: 0.83rem;
  // height: 32px;
  padding: 0 0 0 51px;
  font-weight: 200;

  .icon {
    margin-right: 5px;
  }
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (max-width: 848px) {
  .profile-user .p-text {
    display: none;
  }
}

.app {
  &.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 14px;
    margin-left: 0;
  }

  .side-menu_label {
    display: none;
  }
}

.dropdown-menu {
  border-radius: 0;

  &.dropdown-menu-right {
    left: auto;
  }
}

.dropdown-item {
  .fa,
  .icon {
    vertical-align: middle;
  }
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: $white;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px $black-1;
  box-shadow: 0 1px 2px $black-1;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
    font-style: italic;
  }
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

.tile {
  position: relative;
  background: $white;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: $black-05;
  }

  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_draggerRail {
      background: $white-2;
    }
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.user-info {
  .text-dark {
    color: $white !important;
    font-weight: 400;
    font-size: 16px;
  }

  .text-muted {
    color: $white-4 !important;
  }
}

.side-header {
  .header-brand1 {
    text-align: center;
    display: table;
  }

  .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: block;
    margin-right: 0;
  }
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.side-header .header-brand-img.toggle-logo {
  display: none;
}

.sidenav-toggled {
  .app-sidebar .side-header {
    .header-brand-img.toggle-logo {
      display: none;
      margin-right: 0;
    }

    .header-brand1 {
    }
  }

  .user-pic {
    margin-bottom: 0;
  }

  .sidebar-navs {
    display: none;
  }
}

.side-header {
  display: flex;
  border-bottom: 1px solid $white-1;
  padding: 21px 10px;
  -webkit-transition: left 0.1s ease, width 0.1s ease;
  -o-transition: left 0.1s ease, width 0.1s ease;
  transition: left 0.1s ease, width 0.1s ease;
}

.sidenav-toggled .app-sidebar__toggle {
  display: block;
}

.user-pic {
  margin-bottom: 0.8rem;
}

.sidebar-navs a {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.07) !important;
  color: $white !important;
  border-radius: 5px;
  padding: 0.8rem !important;
}

.app-sidebar .sidebar-navs {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid $white-1;
}

.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.side-menu h3 {
  color: $white-3;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 7px 38px;
  border-radius: 1px;
  margin-top: 1.1rem;
  position: relative;
  margin-bottom: 0.6rem;

  &:after {
    content: "--";
    position: absolute;
    left: 23px;
    top: 7px;
  }
}

.slide.is-expanded .slide-menu {
  position: relative;
}

/*-- Sub-slide--**/

.sub-slide {
  position: relative;
}

.sub-side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 0;
  font-size: 14px;
}

.sub-side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide {
  .sub-angle {
    transform-origin: center;
    opacity: 0.5;
  }

  &.is-expanded .sub-angle {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.sub-slide-menu {
  list-style: none;
  padding: 0;
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  height: 30px;
  padding: 0 0 0 25px !important;
}

.sub-slide-menu {
  display: none;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-slide.is-expanded {
  .sub-slide-menu {
    display: block;
  }

  .sub-side-menu__item {
    color: #6d6ef3;
  }
}

// .sub-side-menu__item {
//   padding-left: 45px !important;
//   padding-right: 20px !important;
//   height: 30px !important;
// }

.sub-slide-item {
  padding-left: 42px !important;
  height: 28px !important;
}

.app-sidebar {
  .side-menu__item.active:hover {
    color: #474579 !important;
  }

  .slide {
    &.active .side-menu__icon,
    &.is-expanded .side-menu__icon {
      fill: #474579 !important;
    }

    &.active {
      .side-menu__icon {
        fill: #474579 !important;
      }

      .side-menu__item {
        color: #474579 !important;
      }
    }
  }
}

.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
  max-height: 0;
}

.app-sidebar__toggle .close-toggle,
.sidenav-toggled .open-toggle {
  display: none;
}

.header-icon {
  text-align: center;
  line-height: 40px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  text-align: center;
  line-height: 2.2;
  font-size: 1.2rem;
  color: $black;
}

.sidenav-toggled .close-toggle {
  display: block;
}

.sidenav-toggled .app-content {
  transition: margin-left 0.1s ease;
}

.sub-slide-menu .active .sub-slide-item.active {
  color: $white;
}

@media (min-width: 992px) {
  .sidebar-mini {
    &.sidenav-toggled {
      .angle {
        display: none;
      }

      .app-content {
        margin-left: 70px;
      }

      .app-sidebar {
        .side-menu__item.active {
          color: $primary;
        }

        left: 0;
        width: 80px;
        overflow: hidden;
      }
    }

    .main-sidebar-header {
      width: 240px;
    }

    &.sidenav-toggled {
      .main-sidebar-header {
        width: 80px;

        .main-logo {
          display: none;
        }

        .logo-icon {
          // display: block;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .side-badge {
        right: 8px;
        position: absolute;
        top: -2px;
      }

      .side-menu {
        margin-top: 16px;
      }

      .side-menu__item {
        margin: 8px 0;
      }

      .nav-search {
        display: none;
      }

      .main-content {
        margin-left: 80px;
      }

      .sideuser-img img {
        width: 35px;
        height: 35px;
      }

      .app-sidebar:hover {
        overflow: visible;
      }

      .side-menu__item:hover {
        overflow: visible;

        .side-menu__label {
          opacity: 0;
        }
      }

      .side-menu__label {
        position: absolute;
        top: 0;
        left: 50px;
        margin-left: -3px;
      }

      .slide {
        &:hover .side-menu__label {
          opacity: 1;
        }

        .side-menu__label {
          border-bottom-right-radius: 0;
        }
      }

      .slide-menu {
        position: absolute;
        left: 50px;
        min-width: 180px;
        padding: 12px 0;
        opacity: 1;
        // border-bottom-right-radius: 43px;
        z-index: 9;
        visibility: hidden;
        display: none;
      }
    }
  }

  .app-content {
    margin-left: 240px;

    &.toggle-content {
      margin-left: 240px;
    }
  }

  .sidenav-toggled {
    .app-sidebar {
      padding-top: 0px;
    }

    .side-menu__icon {
      margin-right: 0;
      margin: 0 auto;
    }

    .side-menu__item {
      margin-left: 0;
    }

    .wideget,
    .side-btn,
    .desktop-logo {
      display: none;
    }

    .side-header {
      width: 70px;
    }
  }

  .app.sidenav-toggled .main-content.app-content .main-header-center {
    margin-left: 70px;
  }
}

/*-- Sub-slide--**/

.sub-slide {
  position: relative;
}

.sub-side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 0;
  font-size: 0.83rem !important;
  color: #aeafc0;
}

.sub-side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide {
  .sub-angle {
    transform-origin: center;
    opacity: 0.5;
  }

  &.is-expanded .sub-angle {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.sub-slide-menu {
  list-style: none;
  padding: 0;

  .active .sub-slide-item.active {
    border-color: $primary;

    &:before {
      content: "\e92f";
      margin-right: 8px;
      position: absolute;
      font-family: "feather" !important;
      opacity: 0.6;
      left: 21px;
    }
  }
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  height: 30px;
  padding: 0 0 0 25px !important;
}

.sub-slide-menu {
  display: none;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-slide.is-expanded {
  .sub-slide-menu {
    display: block;
  }

  .sub-side-menu__item {
    color: $white-6;
  }
}

.slide-menu .sub-slide.active.is-expanded {
  background: rgba(255, 255, 255, 0.09);
}

.sub-slide.is-expanded .sub-slide-menu li:last-child {
  padding-bottom: 10px;
}

.slide-menu .sub-slide.is-expanded {
  display: block;
}

// .sub-side-menu__item {
//   padding-left: 52px !important;
//   padding-right: 20px !important;
//   height: 30px !important;
// }

.sub-slide-item {
  padding-left: 60px !important;
  height: 28px !important;
  padding: 19px 0 19px 47px !important;
}

.app-sidebar {
  .side-menu__item.active:hover {
    color: $primary !important;
  }

  .slide.active .side-menu__item {
    color: $white;
    background: rgba(255, 255, 255, 0.09);
    font-weight: 500;
    border-top: 0;
    border-radius: 3px;
    padding: 12px 14px;
  }
}

.side-menu {
  .slid.active .sub-slide.is-expanded .sub-slide-menu {
    max-height: 0;
  }

  .leftmenu-icon {
    font-size: 16px;
    line-height: 1;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sub-side-menu__item:before {
  content: "\e92f";
  margin-right: 8px;
  position: absolute;
  font-family: "feather" !important;
  opacity: 0.6;
  left: 18px;
}

.side-menu__item .side-menu__icon.ti {
  line-height: 1.69;
  font-size: 1.35rem;
  line-height: 13px;
  width: 40px;
  height: 20px;
  margin: 0 auto;
  text-align: center;
  margin-right: 6px;
}

.leftmenu-icon {
  font-size: 16px;
  line-height: 1;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.angle {
  color: #bec6d4;
}

.side-badge {
  right: 30px;
  position: absolute;
  padding: 2px 4px 4px !important;
}

.slide.active .angle {
  color: $white;
}

.slide-menu {
  .slide-item:before {
    content: "";
    margin-right: 8px;
    position: absolute;
    opacity: 0.6;
    left: 23px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid $white-6;
  }

  li:hover .slide-item:before {
    border: 1px solid $white-5;
    background: $primary;
    opacity: 1;
  }
}

.slide.is-expanded a:hover {
  color: $white;
}

.sidebar-mini.sidenav-toggled .side-menu__item .side-menu__icon.ti {
  line-height: 21px;
}

.slide.active .side-menu__item .side-menu__icon.ti {
  line-height: 20px;
}

.slide-menu li a {
  padding: 8px 8px 8px 45px;
  color: $white-6;
  font-weight: 400;
}

.side-menu .slide {
  position: relative;
  padding: 0px 12px;
}

.main-sidebar-header .desktop-logo {
  display: block;
}

.main-content.app-content {
  transition: left 0.3s ease;
}

@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    max-height: 100%;
  }
}

@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    width: 250px;
    max-height: 100%;
  }
  // .sidebar-mini.sidenav-toggled.sidenav-toggled-open.Light-mode .main-logo.dark-theme {
  //   display: block !important;
  // }
  // .sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.dark-theme {
  //   display: block;
  // }
  .sidebar-mini.sidenav-toggled{
    .main-sidebar-header{
      .desktop-logo.logo-dark{
        display: none;
      }
    } 
    &.sidenav-toggled-open{
      .main-sidebar-header{
        .logo-icon.icon-dark{
          display: none;
        }
        .desktop-logo.logo-dark{
          display: block;
        }
      } 
    }
  }
  .sidebar-mini.sidenav-toggled .main-sidebar-header .mobile-logo.icon-dark {
    display: block;
  }

  .sidebar-mini.sidenav-toggled.leftmenu-light.sidenav-toggled-open .main-sidebar-header .desktop-logo.logo-light {
    display: block !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidebar-header {
    width: 247px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu .slide .side-menu__item {
    padding: 12px 14px !important;
    margin: 2px 0 !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__label {
    display: block !important;
    top: auto;
    color: #d6deea;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu h3 {
    display: block !important;
  }
  // .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu .side-menu__icon {
  //   font-size: 18px !important;
  //   line-height: 0;
  //   margin-right: 14px !important;
  //   border-radius: 3px !important;
  //   text-align: center !important;
  // }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .slide.active .side-menu__item {
    background: rgba(255, 255, 255, 0.09);
    font-weight: 500;
    border-top: 0;
    border-radius: 3px;
    padding: 12px 14px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .fe {
    // display: block !important;
    // margin-left: auto;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide.is-expanded .slide-menu {
    position: relative;
    visibility: visible !important;
    display: block;
    left: 0 !important;
    margin-top: auto;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu li a {
    padding: 8px 8px 8px 45px;
    font-weight: 400;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu .slide-item:before {
    display: block !important;
    margin-top: 5px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu .slide-item {
    display: block !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-badge {
    position: absolute;
    right: 30px !important;
    top: auto !important;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    overflow: hidden;
  }
  // .dark-theme.sidenav-toggled.sidenav-toggled-open .main-logo.dark-theme {
  //   display: block !important;
  // }

  .main-body.app.sidebar-mini.sidenav-toggled.leftmenu-light.sidenav-toggled-open .main-logo {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .main-body.app.sidebar-mini.sidenav-toggled .app-sidebar .slide .side-menu__item {
    padding: 18px 14px;
  }
}

.sub-slide-menu .sub-slide-menu {
  display: none;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-slide.is-expanded {
  .sub-slide-menu .sub-slide-menu {
    display: none;
  }
}
.sub-slide .sub-angle2 {
  margin-left: 79px;
}
.sub-slide.is-expanded .sub-angle2 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sub-slide-menu .sub-slide-item2 {
  padding: 6px 0px 6px 60px !important;
  display: block;
}
@media (max-width: 575px) {
  .app-sidebar .breadcrumb-header {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
}

.desktop-logo.logo-light {
  display: none;
}

.side-menu-label1 {
  display: none;
}

.main-sidebar-header{
  .desktop-logo img{
    height: 2rem;
  }
}

// ICONTEXT SIDEMENU

@media (min-width: 992px) {
  .icontext-menu {
    &.sidenav-toggled .side-menu__item {
      display: block;
    }
    &.sidebar-mini.sidenav-toggled {
      .side-menu__label {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto;
      }
      .app-sidebar {
        width: 110px;
      }
      .app-sidebar .slide .side-menu__item {
        padding: 12px 14px !important;
      }
      .main-sidebar-header {
        width: 110px;
      }
      .main-content {
        margin-left: 110px;
      }
      .side-menu__item {
        text-align: center;
      }
      .main-sidebar-header {
        justify-content: center;
      }
      .side-menu .side-menu__icon {
        margin-right: 0;
      }
      .main-header.side-header {
        padding-left: 110px;
      }
      .side-badge {
        right: 20px;
      }
    }
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .side-menu__item {
        display: flex;
        text-align: inherit;
      }
      .main-sidebar-header {
        justify-content: inherit;
      }
      .app-sidebar .slide .side-menu__item {
        padding: 10px 14px !important;
      }
      .side-menu .side-menu__icon {
        margin-right: 14px;
      }
    }
  }
}

// CLOSED SIDEMENU

@media (min-width: 992px) {
  .closed-leftmenu {
    &.sidebar-mini.sidenav-toggled {
      .app-sidebar {
        display: none;
      }
      .main-header.side-header {
        padding-left: 0;
      }
      .main-content {
        margin-left: 0;
      }
    }
  }
}

// HOVER SUBMENU

@media (min-width: 992px) {
  .hover-submenu {
    &.sidebar-mini.sidenav-toggled,
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar {
        position: absolute;
        width: 110px;
        &:hover {
          overflow: visible !important;
        }
      }
      .main-sidebar-header {
        width: 110px;
      }
      .main-header.side-header {
        padding-left: 110px;
      }
      .main-content {
        margin-left: 110px;
      }
      .main-sidebar-header {
        justify-content: center;
      }
      .side-menu h3 {
        display: none !important;
      }
      .side-menu__item {
        display: block;
        text-align: center;
        padding: 12px 14px !important;
      }
      .main-sidebar-header{
        .logo-icon.icon-dark{
          display: block;
        }
        .desktop-logo {
          display: none;
        }
      } 
      // .main-sidebar-header .logo-icon.dark-theme {
      //   display: block;
      // }
      .side-menu__label {
        display: block;
        position: relative;
        left: 0;
        font-size: 12px;
        margin-left: 0;
      }
      .side-menu .side-menu__icon {
        margin-right: 0;
      }
      .angle {
        display: none;
      }
      .side-badge {
        right: 20px !important;
        top: 0 !important;
      }
      .side-menu .slide .side-menu__item {
        margin: 8px 0 !important;
      }
      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }
      .slide .slide-menu {
        position: absolute;
        left: 108px !important;
        min-width: 200px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        display: block;
        background-color: $primary;
        max-height: inherit !important;
				padding-bottom: 8px !important;
				padding-top: 8px !important;
        border-radius: 0 5px 5px 0;
      }
      .ps--active-x > .ps__rail-x,
      .ps--active-y > .ps__rail-y {
        display: none;
      }
      .slide-item {
        display: flex !important;
        &::before {
          margin-top: 0;
        }
      }
      .slide-menu li a {
        padding: 10px 10px 10px 50px;
      }
      .slide-menu .sub-slide-menu {
        padding: 0 10px 0 20px;
        .sub-slide-menu {
          padding: 0 10px;
          .sub-slide-item2 {
            padding: 6px 40px !important;
          }
        }
      }
      .sub-side-menu__item {
        padding: 10px 0px 10px 40px !important;
      }
    }
  }
}

// HOVER SUBMENU STYLE1

@media (min-width: 992px) {
  .hover-submenu1 {
    &.sidebar-mini.sidenav-toggled,
    &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar {
        position: absolute;
        width: 110px;
        &:hover {
          overflow: visible !important;
        }
      }
      .main-sidebar-header {
        width: 110px;
      }
      .main-header.side-header {
        padding-left: 110px;
      }
      .main-content {
        margin-left: 110px;
      }
      .main-sidebar-header {
        justify-content: center;
      }
      .side-menu h3 {
        display: none !important;
      }
      .side-menu__item {
        display: flex;
        text-align: center;
        justify-content: center;
      }
      .main-sidebar-header{
        .logo-icon.icon-dark{
          display: block;
        }
        .desktop-logo {
          display: none;
        }
      } 
      // .main-sidebar-header .logo-icon.dark-theme {
      //   display: block;
      // }
      .side-menu__label {
        display: none !important;
      }
      .side-menu .side-menu__icon {
        margin-right: 0;
      }
      .angle {
        display: none;
      }
      .side-badge {
        right: 20px !important;
        top: 0 !important;
      }
      .side-menu .slide .side-menu__item {
        margin: 8px 0 !important;
        padding: 25px 14px !important;
      }
      .slide:hover .slide-menu {
        visibility: visible;
        display: block !important;
        height: auto !important;
      }
      .slide .slide-menu {
        position: absolute;
        left: 108px !important;
        min-width: 200px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        display: block;
        max-height: inherit;
        background-color: $primary;
        padding: 12px 0;
        border-radius: 0 5px 5px 0;
      }
      .ps--active-x > .ps__rail-x,
      .ps--active-y > .ps__rail-y {
        display: none;
      }
      .slide-item {
        display: flex !important;
        &::before {
          margin-top: 0;
        }
      }
      .slide-menu li a {
        padding: 10px 10px 10px 50px;
      }
      .slide-menu .sub-slide-menu {
        padding: 0 10px 0 20px;
        .sub-slide-menu {
          padding: 0 10px;
          .sub-slide-item2 {
            padding: 6px 40px !important;
          }
        }
      }
      .sub-side-menu__item {
        padding: 10px 0px 10px 40px !important;
      }
      .side-menu-label1 {
        display: block !important;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid $white-2;
        padding-bottom: 10px !important;
        margin-bottom: 6px;
        a {
          color: $white;
        }
      }
    }
  }
}

.sub-slide.is-expanded .sub-side-menu__item.active{
  color: $white;
}

.sidebar-mini .side-menu {
  margin-left: 0 !important;
  margin-right: 0 !important;
}