//// RTL STYLES ////

body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {
    .alert-dismissible {
        padding-left: 3.8125rem;
        padding-right: inherit;

        .close {
            left: 0;
            right: inherit;
            float: right;
        }

        .btn-close {
            left: 0;
            right: inherit;
        }
    }

    .main-img-user::after {
        left: 5px;
        right: inherit;
    }

    .main-avatar::after {
        left: 3px;
        right: inherit;
    }

    .avatar-md::after {
        left: 2px !important;
        right: inherit !important;
    }

    .avatar-lg::after {
        left: 4px !important;
        right: inherit !important;
    }

    .avatar-xl::after {
        left: 5px !important;
        right: inherit !important;
    }

    .avatar-xxl::after {
        left: 7px !important;
        right: inherit !important;
    }

    .main-img-user1 {
        &.avatar-sm .number-badge {
            right: 58px;
            left: inherit;
        }

        &.avatar-md .number-badge {
            right: 128px;
            left: inherit;
        }

        &.avatar-lg .number-badge {
            right: 207px;
            left: inherit;
        }
    }

    .main-img-user3 {
        &.avatar-sm .icon-badgeavatar {
            right: 52px;
            left: inherit;
        }

        &.avatar-md .icon-badgeavatar {
            right: 123px;
            left: inherit;
        }

        &.avatar-lg .icon-badgeavatar {
            right: 202px;
            left: inherit;
        }
    }

    .demo-avatar-group .main-img-user::after {
        left: 6px;
        left: inherit;
    }

    .avatar-list-stacked .avatar1,
    .main-avatar-list-stacked .main-img-user {
        margin-left: -0.8em;
        margin-right: inherit;
    }

    .avatar-icons {
        left: -6%;
        right: inherit;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-right: 0.5rem;
        padding-left: inherit;

        // &::before {
        //     padding-left: 0;
        //     padding-right: inherit;
        // }
    }

    .breadcrumb-1 ol,
    .breadcrumb-2 ol,
    .breadcrumb-6 ol,
    .breadcrumb-1 li,
    .breadcrumb-2 li,
    .breadcrumb-6 li {
        text-align: left;
    }

    .btn-with-icon i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .btn-loading {
        &:after {
            border-left-color: transparent !important;
            border-right-color: inherit !important;
            right: calc(50% - 0.7em);
            left: inherit;
        }

        &.btn-sm:after {
            right: calc(50% - 0.5em);
            left: inherit;
        }
    }

    .btn-group-sm > .btn-loading.btn:after {
        right: calc(50% - 0.5em);
        left: inherit;
    }

    button.btn-close {
        left: 15px;
        right: inherit;
    }

    .btn-list > {
        .btn:not(:last-child),
        .dropdown:not(:last-child) {
            margin-left: 0.4rem !important;
            margin-right: 0;
        }
    }

    .btn-group > .btn.btn-rounded:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .btn-group > .btn-group:not(:first-child) > .btn.btn-rounded,
    .btn-group > .btn.btn-rounded:nth-child(n + 3),
    .btn-group > :not(.btn-check) + .btn.btn-rounded {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .card-link + .card-link {
        margin-left: 1.25rem;
        margin-right: inherit;
    }

    .card-header:first-child {
        border-radius: 0 0 2px 2px;
    }

    @media (min-width: 576px) {
        .card-group > .card {
            &:not(:last-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 0;
                    border-top-right-radius: inherit;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: inherit;
                }
            }

            &:not(:first-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;

                .card-img-top,
                .card-header {
                    border-top-right-radius: 0;
                    border-top-left-radius: inherit;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: inherit;
                }
            }
        }
    }

    .carousel-item {
        margin-right: -100%;
        margin-left: inherit;
    }

    .carousel-control-prev {
        right: 0;
        left: inherit;
    }

    .carousel-control-next {
        left: 0;
        right: inherit;
    }

    #thumbcarousel {
        .carousel-control-prev {
            right: 0;
            left: inherit;
        }

        .carousel-control-next {
            left: 0;
            right: inherit;
        }
    }

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .custom-control-label {
        &::before,
        &::after {
            right: -1.5rem;
            left: inherit;
        }
    }

    .custom-switch {
        padding-right: 2.25rem;
        padding-left: inherit;

        .custom-control-label {
            &::before {
                right: -2.25rem;
                left: inherit;
            }

            &::after {
                right: calc(-2.25rem + 2px);
                left: inherit;
            }
        }
    }

    .custom-select {
        &[multiple],
        &[size]:not([size="1"]) {
            padding-left: 0.75rem;
            padding-right: inherit;
        }
    }

    .custom-select-sm {
        padding-right: 0.5rem;
        padding-left: inherit;
    }

    .custom-select-lg {
        padding-right: 1rem;
        padding-left: inherit;
    }

    .custom-file-label::after {
        left: 0;
        right: inherit;
        border-right: inherit;
        border-left: inherit;
        border-radius: 3px 0 0 3px;
    }

    .dropdown-toggle {
        &::after {
            margin-right: 0.255em;
            margin-left: inherit;
        }

        &:empty::after {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .dropdown-menu {
        right: auto;
        left: inherit;
        float: right;
        text-align: right;
    }

    .dropdown-menu-start {
        left: auto;
        right: 0;
    }

    .dropdown-menu-end {
        left: 0;
        right: auto;
    }

    @media (min-width: 576px) {
        .dropdown-menu-sm-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-sm-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 768px) {
        .dropdown-menu-md-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-md-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 992px) {
        .dropdown-menu-lg-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-lg-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 1200px) {
        .dropdown-menu-xl-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-xl-right {
            left: 0;
            right: auto;
        }
    }

    .dropup .dropdown-toggle {
        &::after {
            margin-left: 0.255em;
            margin-right: inherit;
        }

        &:empty::after {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .dropright {
        .dropdown-menu {
            left: auto;
            right: 100%;
            margin-left: 0.125rem;
            margin-right: inherit;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0.255em;
                margin-right: inherit;
                border-left: 0;
                border-right: 0.3em solid;
            }

            &:empty::after {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .dropleft {
        .dropdown-menu {
            left: 100%;
            right: auto;
            margin-right: 0.125rem;
            margin-right: inherit;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0.255em;
                margin-right: inherit;
            }

            &::before {
                margin-right: 0.255em;
                margin-left: inherit;
                border-left: 0.3em solid;
                border-right: inherit;
            }

            &:empty::after {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .dropdown-menu {
        &[x-placement^="top"],
        &[x-placement^="right"],
        &[x-placement^="bottom"],
        &[x-placement^="left"] {
            left: auto;
            right: inherit;
        }
    }

    .offset-1 {
        margin-left: 8.33333%;
        margin-right: inherit;
    }

    .offset-2 {
        margin-left: 16.66667%;
        margin-right: inherit;
    }

    .offset-3 {
        margin-left: 25%;
        margin-right: inherit;
    }

    .offset-4 {
        margin-left: 33.33333%;
        margin-right: inherit;
    }

    .offset-5 {
        margin-left: 41.66667%;
        margin-right: inherit;
    }

    .offset-6 {
        margin-left: 50%;
        margin-right: inherit;
    }

    .offset-7 {
        margin-left: 58.33333%;
        margin-right: inherit;
    }

    .offset-8 {
        margin-left: 66.66667%;
        margin-right: inherit;
    }

    .offset-9 {
        margin-left: 75%;
        margin-right: inherit;
    }

    .offset-10 {
        margin-left: 83.33333%;
        margin-right: inherit;
    }

    .offset-11 {
        margin-left: 91.66667%;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .offset-sm-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-sm-1 {
            margin-left: 8.33333%;
            margin-right: inherit;
        }

        .offset-sm-2 {
            margin-left: 16.66667%;
            margin-right: inherit;
        }

        .offset-sm-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-sm-4 {
            margin-left: 33.33333%;
            margin-right: inherit;
        }

        .offset-sm-5 {
            margin-left: 41.66667%;
            margin-right: inherit;
        }

        .offset-sm-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-sm-7 {
            margin-left: 58.33333%;
            margin-right: inherit;
        }

        .offset-sm-8 {
            margin-left: 66.66667%;
            margin-right: inherit;
        }

        .offset-sm-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-sm-10 {
            margin-left: 83.33333%;
            margin-right: inherit;
        }

        .offset-sm-11 {
            margin-left: 91.66667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 768px) {
        .offset-md-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-md-1 {
            margin-left: 8.33333%;
            margin-right: inherit;
        }

        .offset-md-2 {
            margin-left: 16.66667%;
            margin-right: inherit;
        }

        .offset-md-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-md-4 {
            margin-left: 33.33333%;
            margin-right: inherit;
        }

        .offset-md-5 {
            margin-left: 41.66667%;
            margin-right: inherit;
        }

        .offset-md-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-md-7 {
            margin-left: 58.33333%;
            margin-right: inherit;
        }

        .offset-md-8 {
            margin-left: 66.66667%;
            margin-right: inherit;
        }

        .offset-md-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-md-10 {
            margin-left: 83.33333%;
            margin-right: inherit;
        }

        .offset-md-11 {
            margin-left: 91.66667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .offset-lg-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-lg-1 {
            margin-left: 8.33333%;
            margin-right: inherit;
        }

        .offset-lg-2 {
            margin-left: 16.66667%;
            margin-right: inherit;
        }

        .offset-lg-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-lg-4 {
            margin-left: 33.33333%;
            margin-right: inherit;
        }

        .offset-lg-5 {
            margin-left: 41.66667%;
            margin-right: inherit;
        }

        .offset-lg-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-lg-7 {
            margin-left: 58.33333%;
            margin-right: inherit;
        }

        .offset-lg-8 {
            margin-left: 66.66667%;
            margin-right: inherit;
        }

        .offset-lg-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-lg-10 {
            margin-left: 83.33333%;
            margin-right: inherit;
        }

        .offset-lg-11 {
            margin-left: 91.66667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .offset-xl-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-xl-1 {
            margin-left: 8.33333%;
            margin-right: inherit;
        }

        .offset-xl-2 {
            margin-left: 16.66667%;
            margin-right: inherit;
        }

        .offset-xl-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-xl-4 {
            margin-left: 33.33333%;
            margin-right: inherit;
        }

        .offset-xl-5 {
            margin-left: 41.66667%;
            margin-right: inherit;
        }

        .offset-xl-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-xl-7 {
            margin-left: 58.33333%;
            margin-right: inherit;
        }

        .offset-xl-8 {
            margin-left: 66.66667%;
            margin-right: inherit;
        }

        .offset-xl-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-xl-10 {
            margin-left: 83.33333%;
            margin-right: inherit;
        }

        .offset-xl-11 {
            margin-left: 91.66667%;
            margin-right: inherit;
        }
    }

    .btn-group > .btn:not(:first-child),
    .sp-container .btn-group > button:not(:first-child) {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group > {
        .btn-group:not(:first-child) {
            margin-left: -1px;
            margin-right: inherit;
        }

        .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn,
    .sp-container .btn-group > .btn-group:not(:last-child) > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropleft .dropdown-toggle-split::before {
        margin-right: 0;
        margin-left: inherit;
    }

    .input-group > {
        .form-control + {
            .form-control,
            .custom-select,
            .custom-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-control-plaintext + {
            .form-control,
            .custom-select,
            .custom-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .custom-select + {
            .form-control,
            .custom-select,
            .custom-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .custom-file + {
            .form-control,
            .custom-select,
            .custom-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-control:not(:last-child),
        .custom-select:not(:last-child) {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }

        .form-control:not(:first-child),
        .custom-select:not(:first-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        .custom-file {
            &:not(:last-child) .custom-file-label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;

                &::after {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: inherit;
                    border-bottom-right-radius: inherit;
                }
            }

            &:not(:first-child) .custom-file-label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
            }
        }
    }

    .input-group-text {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text button + .btn,
    .input-group-text .sp-container .btn + button,
    .sp-container .input-group-text .btn + button,
    .input-group-text .sp-container button + button,
    .sp-container .input-group-text button + button {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        .btn + .input-group-text,
        .sp-container button + .input-group-text {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text button + .input-group-text {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        .input-group-text + {
            .input-group-text,
            .btn {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .sp-container .input-group-text + button {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text .input-group-text + button {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text button + .btn,
    .input-group-text .sp-container .btn + button,
    .sp-container .input-group-text .btn + button,
    .input-group-text .sp-container button + button,
    .sp-container .input-group-text button + button {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        .btn + .input-group-text,
        .sp-container button + .input-group-text {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text button + .input-group-text {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        .input-group-text + {
            .input-group-text,
            .btn {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .sp-container .input-group-text + button {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .input-group-text .input-group-text + button {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-text {
        margin-right: -1px;
        margin-left: inherit;
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-lg > .custom-select,
    .input-group-sm > .custom-select {
        padding-left: 1.75rem;
        padding-right: inherit;
    }

    .input-group > .input-group-text > .btn,
    .sp-container .input-group > .input-group-text > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .input-group > .input-group-text {
        > .input-group-text,
        &:not(:last-child) > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .sp-container .input-group > .input-group-text:not(:last-child) > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .input-group > .input-group-text {
        &:not(:last-child) > .input-group-text,
        &:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .sp-container .input-group > .input-group-text:last-child > button:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .input-group > .input-group-text {
        &:last-child > .input-group-text:not(:last-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }

    .sp-container .input-group > .input-group-text > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .input-group > .input-group-text {
        > .input-group-text,
        &:not(:first-child) > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }

    .sp-container .input-group > .input-group-text:not(:first-child) > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .input-group > .input-group-text {
        &:not(:first-child) > .input-group-text,
        &:first-child > .btn:not(:first-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }

    .sp-container .input-group > .input-group-text:first-child > button:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .input-group > {
        .input-group-text:first-child > .input-group-text:not(:first-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        .form-control:not(:first-child),
        .custom-select:not(:first-child) {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-left-radius: inherit !important;
            border-bottom-left-radius: inherit !important;
        }
    }

    .list-group {
        padding-right: 0;
        padding-left: initial;
    }

    .list-group-horizontal .list-group-item {
        margin-right: -1px;
        margin-left: inherit;

        &:first-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: inherit;
            border-top-left-radius: 0;
        }

        &:last-child {
            margin-right: 0;
            margin-left: inherit;
            border-top-left-radius: 3px;
            border-top-right-radius: inherit;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }

    @media (min-width: 576px) {
        .list-group-horizontal-sm .list-group-item {
            margin-right: -1px;
            margin-left: inherit;

            &:first-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: inherit;
                border-top-left-radius: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-left: inherit;
                border-top-left-radius: 3px;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
            }
        }
    }

    @media (min-width: 768px) {
        .list-group-horizontal-md .list-group-item {
            margin-right: -1px;
            margin-left: inherit;

            &:first-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: inherit;
                border-top-left-radius: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-left: inherit;
                border-top-left-radius: 3px;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
            }
        }
    }

    @media (min-width: 992px) {
        .list-group-horizontal-lg .list-group-item {
            margin-right: -1px;
            margin-left: inherit;

            &:first-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: inherit;
                border-top-left-radius: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-left: inherit;
                border-top-left-radius: 3px;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
            }
        }
    }

    @media (min-width: 1200px) {
        .list-group-horizontal-xl .list-group-item {
            margin-right: -1px;
            margin-left: inherit;

            &:first-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: inherit;
                border-top-left-radius: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-left: inherit;
                border-top-left-radius: 3px;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
            }
        }
    }

    .main-list-item > div:last-child {
        text-align: left;
    }

    .close {
        float: left;
    }

    .modal,
    .modal-backdrop {
        right: 0;
        left: inherit;
    }

    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
    }

    .modal-footer > {
        :not(:first-child) {
            margin-right: 0.25rem;
            margin-left: inherit;
        }

        :not(:last-child) {
            margin-left: 0.25rem;
            margin-right: inherit;
        }
    }

    // .navbar-nav-right.responsive-navbar.nav {
    //     padding-right: 0;
    //     padding-left: inherit;
    // }

    // .main-profile-menu.nav-link{
    //     padding-right: 0;
    // }

    .main-profile-menu.nav.nav-item.nav-link {
        margin-left: 20px;
    }

    .navbar-brand {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .navbar-nav {
        padding-right: 0;
        padding-left: inherit;
    }

    .nav-tabs .nav-link + .nav-link {
        margin-right: 3px;
        margin-left: inherit;
    }

    .nav-item .mx-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
        margin-right: auto !important;
        margin-left: inherit !important;
    }

    .pagination {
        padding-right: 0;
        padding-left: inherit;
    }

    .page-link {
        margin-left: -3px !important;
        margin-right: inherit !important;
    }

    .page-item {
        &:first-child .page-link {
            margin-left: 1px;
            margin-right: inherit;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child .page-link {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .pagination-lg .page-item {
        &:first-child .page-link {
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child .page-link {
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .pagination-sm .page-item {
        &:first-child .page-link {
            border-top-right-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child .page-link {
            border-top-left-radius: 0.2rem;
            border-bottom-left-radius: 0.2rem;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    /* ###### 3.11 Pagination ###### */

    .page-item + .page-item {
        margin-left: 2px;
        margin-right: inherit;
    }

    .page-link {
        margin-left: 0;
        margin-right: inherit;
    }

    .pagination-circled .page-item + .page-item {
        margin-right: 5px;
        margin-left: inherit;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: -3px;
        right: inherit;
    }

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;
        border-right: 1px solid rgba(225, 225, 225, 0.2);
        border-left: inherit;

        &:last-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags > .tag:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .tag-radius {
        border-radius: 0 50px 50px 0 !important;
    }

    .tag-round::before {
        margin-left: 5px;
        margin-right: inherit;
    }

    /* ######### Res-Tabs #########*/

    .tabs-style-2 .main-nav-line .nav-link {
        margin: 0 0px 0px 5px;
    }

    .tabs-style-3 .nav.panel-tabs li a {
        margin: 0 0px 5px 5px;
    }

    .tab_wrapper {
        &.right_side {
            > ul {
                float: left;
                padding-right: 0;
                padding-left: inherit;

                li {
                    &:after {
                        left: 0;
                        right: inherit;
                    }

                    &.active {
                        &:after {
                            left: 0;
                            right: inherit;
                        }

                        &:before {
                            right: -2px;
                            left: inherit;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        > ul li {
            float: right;
            margin: 0 0px 0 5px;

            &.active:after {
                right: 0;
                left: inherit;
            }
        }

        .controller {
            left: 0;
            right: inherit;

            span.next {
                float: left;
                margin-left: 10px;
                margin-right: inherit;
            }
        }

        .content_wrapper {
            float: right;

            .accordian_header {
                &.active:after {
                    right: 0;
                    left: inherit;
                }

                .arrow {
                    float: left;
                    border-right: 3px solid #cdd2e0;
                    border-left: inherit;
                    transform: rotate(135deg);
                }
                &.active {
                    .arrow {
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &.left_side {
            > ul {
                float: right;
                padding-right: 0;
                padding-left: inherit;

                li {
                    &:after {
                        right: 0;
                        left: inherit;
                    }

                    &.active {
                        &:after {
                            right: 0;
                            left: inherit;
                        }

                        &:before {
                            left: -1px;
                            right: inherit;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        &.right_side {
            > ul {
                float: left;

                li {
                    text-align: left;

                    &:after {
                        left: 0;
                        right: inherit;
                    }

                    &.active {
                        &:after {
                            left: 0;
                            right: inherit;
                        }

                        &:before {
                            right: -2px;
                            left: inherit;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        .active_tab {
            padding-left: 50px;
            padding-right: inherit;

            .arrow {
                left: 6px;
                right: inherit;

                &:after {
                    border-left: 3px solid #000;
                    border-right: inherit;
                    right: 50%;
                    left: inherit;
                    margin-left: -6px;
                    margin-right: inherit;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .tab_wrapper.show-as-dropdown > .tab_list:before {
            right: 50%;
            left: inherit;
            margin-left: -7px;
            margin-right: inherit;
        }
    }

    &.dark-theme .tab_wrapper .content_wrapper .accordian_header .arrow {
        border-right: 3px solid rgba(240, 242, 247, 0.4);
        border-left: inherit;
    }

    .tabs-menu ul li a {
        padding: 10px 13px 11px 12px;
    }

    /* ######### Res-Tabs #########*/

    .tooltip {
        text-align: right;
        text-align: end;
    }

    .bs-tooltip-end .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
        left: 0;
        right: inherit;
    }

    .bs-tooltip-start .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
        right: 0;
        left: inherit;
    }

    .popover {
        right: 0;
        left: inherit;
        text-align: right;
        text-align: end;
    }

    // .bs-popover-end,
    // .bs-popover-auto[data-popper-placement^="right"] {
    //     margin-left: 0.5rem;
    //     margin-right: inherit;
    // }

    // .bs-popover-end > .tooltip-arrow,
    // .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow {
    //     right: calc((0.5rem + 1px) * -1);
    //     left: 50%;
    // }

    // .bs-popover-end > .tooltip-arrow::before,
    // .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 0.5rem 0rem 0.5rem 0.5rem;
    //     border-left-color: rgba(0, 0, 0, 0.25);
    //     border-right-color: inherit;
    // }

    // .bs-popover-end > .tooltip-arrow::after,
    // .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow::after {
    //     right: 1px;
    //     left: inherit;
    //     border-width: 0.5rem 0 0.5rem 0.5rem;
    //     border-left-color: #fff;
    //     border-right-color: inherit;
    // }

    // .bs-popover-bottom .popover-header::before,
    // .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    //     right: 50%;
    //     left: inherit;
    //     margin-left: -0.5rem;
    //     margin-right: inherit;
    // }

    // .bs-popover-start,
    // .bs-popover-auto[data-popper-placement^="left"] {
    //     margin-right: 0.5rem;
    //     margin-left: inherit;
    // }

    // .bs-popover-start > .tooltip-arrow,
    // .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: inherit;
    // }

    // .bs-popover-start > .tooltip-arrow::before,
    // .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 0.5rem 0.5rem 0.5rem 0;
    //     border-right-color: rgba(0, 0, 0, 0.25);
    //     border-left-color: inherit;
    // }

    // .bs-popover-start > .tooltip-arrow::after,
    // .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow::after {
    //     left: 1px;
    //     right: inherit;
    //     border-width: 0.5rem 0.5rem 0.5rem 0;
    //     border-right-color: #fff;
    //     border-left-color: inherit;
    // }

    // .popover-head-primary {
    //     &.bs-popover-start .tooltip-arrow,
    //     &.bs-popover-auto[data-popper-placement^="left"] .tooltip-arrow {
    //         left: -7px;
    //         right: inherit;
    //     }

    //     &.bs-popover-end .tooltip-arrow,
    //     &.bs-popover-auto[data-popper-placement^="right"] .tooltip-arrow {
    //         right: -7px;
    //         left: inherit;
    //     }
    // }

    // .popover-head-secondary {
    //     &.bs-popover-start .tooltip-arrow,
    //     &.bs-popover-auto[data-popper-placement^="left"] .tooltip-arrow {
    //         left: -7px;
    //         right: inherit;
    //     }

    //     &.bs-popover-end .tooltip-arrow,
    //     &.bs-popover-auto[data-popper-placement^="right"] .tooltip-arrow {
    //         right: -7px;
    //     }
    // }

    // .popover-primary {
    //     &.bs-popover-start .tooltip-arrow::after,
    //     &.bs-popover-auto[data-popper-placement^="left"] .tooltip-arrow::after {
    //         border-right-color: var(--primary-bg-color) !important;
    //         border-left-color: inherit;
    //     }

    //     &.bs-popover-end .tooltip-arrow::after,
    //     &.bs-popover-auto[data-popper-placement^="right"] .tooltip-arrow::after {
    //         border-left-color: var(--primary-bg-color) !important;
    //         border-right-color: inherit;
    //     }
    // }

    // .popover-secondary {
    //     &.bs-popover-start .tooltip-arrow::after,
    //     &.bs-popover-auto[data-popper-placement^="left"] .tooltip-arrow::after {
    //         border-right-color: #007bff !important;
    //         border-left-color: inherit;
    //     }

    //     &.bs-popover-end .tooltip-arrow::after,
    //     &.bs-popover-auto[data-popper-placement^="right"] .tooltip-arrow::after {
    //         border-left-color: #007bff !important;
    //         border-right-color: inherit;
    //     }
    // }

    .form-check {
        padding-right: 1.25rem;
        padding-left: inherit;
    }

    .form-check-input {
        margin-left: -1.25rem;
        margin-right: inherit;
    }

    .form-check-inline {
        padding-right: 0;
        padding-left: inherit;
        margin-right: 0.75rem;
        margin-left: inherit;

        .form-check-input {
            margin-right: 0.3125rem;
            margin-left: 0;
        }
    }

    .was-validated textarea.form-control:valid {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: inherit;
    }

    textarea.form-control {
        &.is-valid,
        &.parsley-success {
            padding-left: calc(1.5em + 0.75rem);
            padding-right: inherit;
        }
    }

    .was-validated .custom-select:valid,
    .custom-select.is-valid,
    input.custom-select.parsley-success,
    textarea.custom-select.parsley-success {
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        padding-right: inherit;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    input.form-control.parsley-error,
    textarea.form-control.parsley-error,
    .was-validated textarea.form-control:invalid {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: inherit;
        background-position: center left calc(0.375em + 0.1875rem);
    }

    textarea.form-control {
        &.is-invalid,
        &.parsley-error {
            padding-left: calc(1.5em + 0.75rem);
            padding-right: inherit;
        }
    }

    .was-validated .custom-select:invalid,
    .custom-select.is-invalid,
    input.custom-select.parsley-error,
    textarea.custom-select.parsley-error {
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        padding-right: inherit;
    }

    .form-check .form-check-input {
        float: left;
        margin-right: -1.5em;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .form-inline {
            .form-check {
                padding-right: 0;
                padding-left: inherit;
            }

            .form-check-input {
                margin-right: 0.25rem;
                margin-left: 0;
            }
        }
    }

    &.main-body::before {
        right: 70%;
        left: inherit;
    }

    @media (max-width: 991.98px) {
        .main-content-left-show .main-content-left {
            right: 0;
            left: inherit;
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }
    }

    .main-dashboard-date .date > div:last-child {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-dashboard-nav {
        .nav-link + .nav-link {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }

        .nav {
            &:first-child .nav-link:first-child {
                padding-right: 0;
                padding-left: inherit;
            }

            &:last-child .nav-link {
                i {
                    margin-right: 7px;
                    margin-left: inherit;
                }

                &:last-child {
                    padding-left: 0;
                    padding-right: inherit;

                    i {
                        margin-right: 0;
                        margin-left: inherit;
                    }
                }
            }
        }
    }

    .card-dashboard-one .card-header .btn-group {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .sp-container .card-dashboard-one .card-header .btn-group button + .btn,
    .card-dashboard-one .card-header .btn-group .sp-container .btn + button,
    .sp-container .card-dashboard-one .card-header .btn-group .btn + button,
    .card-dashboard-one .card-header .btn-group .sp-container button + button,
    .sp-container .card-dashboard-one .card-header .btn-group button + button {
        margin-left: -1px;
        margin-right: inherit;
    }

    .card-dashboard-one {
        .card-body .flot-chart-wrapper {
            margin-left: -28px;
            margin-right: -20px;
        }

        .card-body-top {
            padding-right: 20px;
            padding-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-one .card-body-top {
            right: 20px;
            left: inherit;
            padding-right: 0;
            padding-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-one .card-body-top > div + div {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    .card-dashboard-two .card-header h6 {
        i {
            margin-left: 10px;
            margin-right: inherit;
        }

        small {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .card-dashboard-three .card-header h6 small {
        margin-left: 5px;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .card-dashboard-five .card-chart {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .card-table-one .table {
        thead tr > {
            th,
            td {
                padding-left: 2px;
                padding-right: inherit;
            }
        }

        tbody tr > {
            th,
            td {
                padding-left: 2px;
                padding-right: inherit;
            }
        }

        thead tr > {
            th {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    text-align: left;
                }
            }

            td {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    text-align: left;
                }
            }
        }

        tbody tr > {
            th {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    text-align: left;
                }
            }

            td {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    text-align: left;
                }
            }
        }
    }

    .main-content-dashboard-two .main-header-menu-icon {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 768px) {
        .main-dashboard-header-right > div {
            text-align: left;
        }
    }

    @media (min-width: 576px) {
        .main-dashboard-header-right > div + div {
            margin-left: 25px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-dashboard-header-right > div + div {
            margin-left: 50px;
            margin-right: inherit;
        }
    }

    .main-star span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .card-dashboard-seven {
        .card-header {
            .media > div:first-child {
                margin-right: 5px;
                margin-left: inherit;
            }

            .date a {
                margin-left: 5px;
                margin-right: inherit;
            }
        }

        .card-body {
            .row > div + div::before {
                right: -1px;
                left: inherit;
                border-right: 1px dotted #e2e8f5;
                border-left: inherit;
            }

            .desc span {
                margin-left: 5px;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-seven .card-header .media > div:first-child {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    .card-dashboard-six .chart-legend > div span {
        &:last-child {
            margin-left: 5px;
            margin-right: inherit;
        }

        &:first-child {
            text-align: left;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-six .chart-legend > div span:last-child {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    .card-dashboard-eight .list-group-item {
        p {
            margin-left: 10px;
            margin-right: inherit;
        }

        span {
            margin-left: auto;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-dashboard-three {
            border-right: 1px solid #edecf7;
            border-left: inherit;
            margin-left: 64px;
            margin-right: inherit;
        }
    }

    .main-header-dashboard-three {
        .main-header-center {
            margin-left: 0;
            margin-right: inherit;
        }

        .main-header-right {
            margin-left: auto;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-ten + .card-dashboard-ten {
            margin-left: 18px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .card-dashboard-ten + .card-dashboard-ten {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .card-dashboard-eleven {
        .card-body {
            margin-left: 20px;
            margin-right: inherit;
        }

        .card-footer h6 span {
            margin-left: 5px;
            margin-right: inherit;
        }

        .jqvmap {
            right: 0;
            left: inherit;
        }
    }

    @media (min-width: 768px) {
        .card-dashboard-eleven .card-footer > div + div {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-content-dashboard-four > .media-dashboard > .media-body {
            padding: 0 0px 0 20px;
        }
    }

    .main-content-header-right {
        .media + .media {
            margin-left: 20px;
            margin-right: inherit;
            padding-right: 20px;
            padding-left: inherit;
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }

        .btn:first-of-type,
        .sp-container button:first-of-type {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    .sp-container .main-content-header-right button:first-of-type {
        margin-left: 30px;
        margin-right: inherit;
    }

    .main-content-header-right {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .sp-container .main-content-header-right button + .btn,
    .main-content-header-right .sp-container .btn + button,
    .sp-container .main-content-header-right .btn + button,
    .main-content-header-right .sp-container button + button,
    .sp-container .main-content-header-right button + button {
        margin-left: 5px;
        margin-right: inherit;
    }

    .card-dashboard-twelve .sales-overview {
        .media + .media {
            margin-left: 20px;
            margin-right: inherit;
        }

        .media-icon {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-twelve .sales-overview .media + .media {
            margin-left: 40px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .card-dashboard-twelve .sales-overview .media + .media {
            margin-left: 50px;
            margin-right: inherit;
        }
    }

    @media (max-width: 575.98px) {
        .card-dashboard-twelve .sales-overview .media:nth-child(3) {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .chart-legend {
        div + div {
            margin-left: 15px;
            margin-right: inherit;
        }

        span {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .chart-legend div + div {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .chart-legend span {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .chart-wrapper {
            margin-left: -10px;
            margin-right: -15px;
        }
    }

    .main-media-date .media-body {
        margin-left: 5px;
        margin-right: inherit;
    }

    .card-dashboard-thirteen .media-icon {
        margin-right: 10px;
        margin-left: inherit;
    }

    .card-dashboard-events {
        .list-group-item {
            padding: 15px 40px 15px 20px;
        }

        .event-indicator {
            right: 20px;
            left: inherit;
        }

        .event-user {
            .main-img-user + .main-img-user {
                margin-left: -6px;
                margin-right: inherit;
            }

            a:last-child {
                margin-left: 10px;
                margin-right: inherit;
            }
        }
    }

    .card-dashboard-fourteen .card-body h1 span {
        margin-right: 5px;
        margin-left: inherit;
    }

    /* ###### 8.5 Dashboard Five  ###### */

    .main-body-sidebar.main-light .main-sidebar {
        border-left-color: #e2e8f5;
        border-right-color: inherit;
    }

    .main-content-dashboard-five .main-header-menu-icon {
        margin-right: 0;
        margin-left: inherit;
    }

    .table-talk-time {
        thead > tr {
            th:first-child,
            td:first-child {
                padding-right: 20px;
                padding-left: inherit;
            }
        }

        tbody > tr {
            th:first-child,
            td:first-child {
                padding-right: 20px;
                padding-left: inherit;
            }
        }
    }

    .main-light .main-iconbar-body .nav,
    .main-content-dashboard-six {
        border-right-color: #e2e8f5;
        border-left-color: inherit;
    }

    @media (min-width: 992px) {
        .main-content-dashboard-six {
            border-right: 1px solid #edecf7;
            border-left: inherit;
            margin-left: 64px;
            margin-right: inherit;
        }
    }

    .main-header-dashboard-six .main-header-menu-icon {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        .main-header-dashboard-six .main-header-center {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-header-dashboard-six .main-header-center {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .card-dashboard-donut .main-donut-chart {
        &.chart1 .chart-center,
        &.chart2 .chart-center {
            right: 10px;
            left: inherit;
        }
    }

    .card-dashboard-balance {
        .fab {
            left: 20px;
            right: inherit;
        }

        .account-number span {
            + span {
                margin-left: 20px;
                margin-right: inherit;
            }

            i + i {
                margin-left: 5px;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-balance .account-number span + span {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .card-dashboard-balance .account-number span + span {
            margin-left: 50px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-ratio .card-body > div:last-child {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    .card-dashboard-finance h2 span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .card-dashboard-table-six .table {
        thead tr {
            &:first-child th:last-child {
                border-right: 1px solid #edecf7;
                border-left: inherit;
            }

            &:last-child th {
                text-align: left;

                &:nth-child(2),
                &:nth-child(6) {
                    border-right: 1px solid #edecf7;
                    border-left: inherit;
                }
            }
        }

        tbody tr td {
            text-align: left;

            &:first-child {
                text-align: right;
            }

            &:nth-child(2),
            &:nth-child(6) {
                border-right: 1px solid #edecf7;
                border-left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .media-dashboard-one .media-body {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    .card-minimal-one {
        .card-body > h6 span {
            margin-right: 3px;
            margin-left: inherit;
        }

        .value-info p span {
            margin-right: 2px;
            margin-left: inherit;
        }

        .open-info > div + div {
            margin-left: 25px;
            margin-right: inherit;
        }

        .chart-wrapper {
            right: -9px;
            left: -8px;
        }
    }

    .card-minimal-two {
        .card-header > .nav .nav-link + .nav-link,
        .card-header-right .nav-link + .nav-link {
            margin-left: 2px;
            margin-right: inherit;
        }

        .flot-wrapper {
            margin-right: -9px;
            margin-left: -31px;
        }

        .more-info {
            left: 10px;
            right: inherit;

            span {
                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    margin-left: 15px;
                    margin-right: inherit;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .card-minimal-two .flot-wrapper {
            margin-right: -9px;
            margin-left: 10px;
        }
    }

    @media (min-width: 576px) {
        .card-minimal-two .more-info {
            left: 20px;
            right: inherit;
        }
    }

    @media (min-width: 992px) {
        .card-minimal-two .more-info {
            left: 30px;
            right: inherit;
        }
    }

    .card-bitcoin .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    .card-minimal-three {
        .main-nav-tabs .lSAction > a.lSPrev {
            right: -35px;
            left: inherit;
        }

        .tab-pane-header > div:last-child a + a {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    .card-crypto-portfolio .table {
        thead tr {
            th,
            td {
                text-align: left;
            }
        }

        tbody tr {
            th,
            td {
                text-align: left;
            }
        }

        thead tr {
            th:first-child,
            td:first-child {
                text-align: right;
            }
        }

        tbody tr {
            th:first-child,
            td:first-child {
                text-align: right;
            }
        }
    }

    .card-minimal-four {
        .card-header .media-body {
            margin-left: 12px;
            margin-right: inherit;
        }

        .card-body-top {
            right: 10px;
            left: inherit;

            a {
                text-align: left;
            }
        }

        .chart-wrapper {
            margin-left: -20px;
            margin-right: -9px;
        }

        .card-footer .nav-link + .nav-link {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    .main-navbar-dashboard-eight .nav-sub-item > .nav-sub {
        right: 180px;
        left: inherit;

        &::before {
            right: -5px;
            left: auto;
        }
    }

    .card-dashboard-eighteen {
        .card-body h6 {
            padding-right: 23px;
            padding-left: inherit;

            &::before {
                right: 0;
                left: inherit;
            }
        }

        .chartjs-wrapper {
            margin-left: -10px;
            margin-right: -5px;
        }
    }

    @media (min-width: 375px) {
        .card-dashboard-nineteen .card-header {
            right: 0;
            left: inherit;
        }
    }

    .card-dashboard-nineteen {
        .chart-legend > div {
            &::before {
                margin-right: 5px;
                margin-left: inherit;
            }

            + div {
                margin-left: 10px;
                margin-right: inherit;
            }
        }

        .flot-chart-wrapper {
            margin: -30px -47px -16px -8px;
        }
    }

    @media (min-width: 768px) {
        .card-dashboard-nineteen .chart-legend {
            margin-left: 70px;
            margin-right: inherit;
        }
    }

    @media (min-width: 375px) {
        .card-dashboard-nineteen .chart-legend > div + div {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    .card-dashboard-progress {
        .progress-legend li {
            padding-right: 15px;
            padding-left: inherit;

            &::before {
                right: 0;
                left: inherit;
            }

            + li {
                margin-left: 20px;
                margin-right: inherit;
            }
        }

        .progress-bar + .progress-bar {
            margin-left: 1px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .card-dashboard-progress .media-body {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
            right: 0;
            left: inherit;
        }
    }

    .main-sidebar-indigo-dark {
        border-left-width: 0;
        border-right-width: inherit;

        .main-sidebar-body {
            .nav-sub {
                border-right-color: rgba(255, 255, 255, 0.1);
                border-left-color: inherit;
                padding-right: 0;
                padding-left: inherit;

                &::before {
                    right: -32px;
                    left: -20px;
                }
            }

            .nav-sub-link {
                padding-right: 21px;
                padding-left: inherit;
            }
        }
    }

    .main-header-dashboard-nine .main-header-menu-icon {
        margin-right: 0;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-header-dashboard-nine {
            right: 220px;
            left: 0;
        }
    }

    @media (min-width: 1200px) {
        .main-header-dashboard-nine {
            right: 240px;
            left: inherit;
        }
    }

    .main-content-1 .main-content-header .nav-link + .nav-link {
        margin-left: 25px;
        margin-right: inherit;
    }

    .card-dashboard-twentyone {
        .list-group-item {
            border-left-width: 0;
            border-right-width: inherit;

            span:last-of-type {
                text-align: left;
            }
        }

        .vmap-wrapper {
            .jqvmap-zoomin,
            .jqvmap-zoomout {
                right: 15px;
                left: inherit;
            }
        }
    }

    .card-dashboard-twentytwo .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    .card-dashboard-twentythree {
        .main-donut-chart.chart1 .chart-center {
            right: 15px;
            left: inherit;
        }

        label span {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    .card-dashboard-audience-metrics {
        .flot-chart .flot-x-axis > div span:last-child {
            padding-right: 2px;
            padding-left: inherit;
        }

        .card-body label span {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    .card-dashboard-twentyfour {
        .card-body-top > div + div {
            margin-left: 30px;
            margin-right: inherit;
        }

        .chart-legend {
            span {
                margin-right: 5px;
                margin-left: inherit;
            }

            > div + div {
                margin-left: 20px;
                margin-right: inherit;
            }
        }

        .chart-wrapper {
            margin-right: -10px;
            margin-left: inherit;
        }
    }

    .card-dashboard-twentyfive .col + .col {
        border-right: 1px solid #edecf7;
        border-left: inherit;
    }

    .card-dashboard-twentysix .chart-legend {
        > div + div {
            margin-left: 15px;
            margin-right: inherit;
        }

        span {
            margin-right: 2px;
            margin-left: inherit;
        }
    }

    .main-media-list-reviews .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-star-group span:last-child {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-star-item + .main-star-item {
        margin-left: 3px;
        margin-right: inherit;
    }

    .main-media-list-activity .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    .navbar-form.active .input-group-btn {
        left: 12px;
        right: inherit;
    }

    .main-header form[role="search"] {
        left: 0;
        right: inherit;
    }

    .horizontal-nav .mega-dropdown-menu:before,
    .mega-dropdown-menu:after {
        left: auto;
        right: 16px;
    }

    .drop-icon-wrap .drop-icon-item {
        float: right;
    }

    .flag > a::before {
        right: 50%;
        left: inherit;
        margin-left: -7px;
        margin-right: inherit;
        border-right-color: #e2e8f5;
        border-left-color: inherit;
    }

    .dropdown-toggle-1::after {
        left: 9px;
        right: inherit;
    }

    .dropdown-toggle-2::after {
        left: 0px;
        right: inherit;
    }

    .arrow-ribbon {
        right: 0px;
        left: inherit;

        &:before {
            left: 0;
            left: -15px;
            right: inherit;
            border-right: 15px solid #000;
            border-left: inherit;
        }
    }

    .mega-menu {
        &:before,
        &:after {
            right: 14px;
            left: inherit;
        }
    }

    .flag-text:before {
        left: 0;
        right: inherit;
    }

    .drop-flag {
        margin-right: 2.3rem !important;
        margin-left: inherit !important;

        .dropdown-menu {
            right: auto;
            left: -10px;
        }
    }

    .dropdown-item .content {
        margin-left: 15px;
        margin-right: inherit;
    }

    .dropdown-item i {
        float: right;
        margin-top: 3px;
    }

    .avatar-status {
        left: 5px;
        right: inherit;
    }

    .pulse,
    .pulse-danger {
        left: 0.9rem;
        right: inherit;
    }

    .main-message-list a {
        .desc,
        .name {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .notifyimg i {
        float: right;
    }

    @media (max-width: 767px) {
        .main-header > {
            .container,
            .container-fluid {
                padding-left: 6px !important;
                padding-right: inherit;
            }
        }
    }

    IE *::-ms-backdrop,
    .main-header-center .btn {
        right: 370px;
        left: inherit;
        padding-right: 100px;
        padding-left: inherit;
    }

    *::-ms-backdrop,
    .media-body {
        margin-left: 55px !important;
        margin-right: inherit !important;
    }

    /* ###### File Manager-Details ###### */

    .owl-nav {
        .owl-prev {
            right: -25px;
            left: -1.5em;
        }

        .owl-next {
            left: -20px;
            right: inherit;
        }

        .owl-prev {
            right: -20px;
            left: -1.5em;
        }
    }

    .file-image .icons {
        li .btn {
            &:after,
            &:before {
                content: attr(data-tip);
                right: 50%;
                left: inherit;
            }
        }

        text-align: left;
    }

    .file-image-1 .icons {
        text-align: left;
    }

    /* ###### BLOG-DETAILS###### */

    .product-label {
        float: left;
    }

    .custom-switch-indicator:before {
        right: 1px;
        left: inherit;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator {
        &.custom-switch-indicator-lg:before {
            right: calc(2rem + 1px);
            left: inherit;
        }

        &.custom-switch-indicator-md:before {
            right: calc(1.76rem + 1px) !important;
            left: inherit !important;
        }

        &:before {
            right: calc(1rem + 1px);
            left: inherit;
        }
    }

    .profile-icon {
        left: 0px;
        right: inherit;
    }

    /* ###### 6.4 Header  ###### */
    @media (max-width: 991.98px) {
        .main-header-menu-show .main-header-menu {
            border-left: 1px solid rgba(28, 39, 60, 0.08);
            border-right: inherit;
        }
    }

    .main-header.side-header {
        transition: right 0.3s ease;
    }

    @media (max-width: 633px) {
        .profile-name.ms-2 {
            margin-left: 0;
            margin-right: inherit;
        }

        .main-profile-menu {
            padding-left: 14px !important;
            padding-right: 11px !important;
        }
    }

    .main-header-left .list-items li i {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-header-menu-icon {
        margin-right: 10px;
        margin-left: inherit;

        span {
            &::before,
            &::after {
                right: 0;
                left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu-icon {
            margin-right: 25px;
            margin-left: inherit;
        }
    }

    .main-header-menu {
        right: 0;
        left: inherit;

        .nav-item {
            &::before {
                margin-left: 25px;
                margin-right: inherit;
                border-right: 2px solid rgba(28, 39, 60, 0.12);
                border-left: inherit;
            }

            > .nav-link {
                &.with-sub::after {
                    margin-left: auto;
                    margin-right: inherit;
                }

                .typcn {
                    margin-right: 15px;
                    margin-left: inherit;
                }
            }

            &.active > .nav-link::before {
                right: -20px;
                left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu {
            border-left: 0;
            border-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item + .nav-item {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link.with-sub::after {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link .typcn {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    .main-menu-sub {
        margin-left: 32px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .main-menu-sub {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .container > div + div {
            border-right: 1px solid rgba(28, 39, 60, 0.05);
            border-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .nav + .nav {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-menu-sub-mega .nav + .nav {
            margin-left: 25px;
            margin-right: inherit;
        }
    }

    .main-header-right {
        padding-left: 10px;
        margin-right: inherit;

        .btn-social + .btn-social {
            margin-left: 10px;
            margin-right: inherit;
        }

        .btn-buy {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    .main-header-search-link {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-header-notification {
        margin-left: 0;
        margin-right: inherit;
    }

    .main-header-message.right-toggle > a::after {
        border-right-color: transparent;
        border-left-color: inherit;
    }

    .main-profile-menu > .profile-name::after {
        right: 50%;
        left: inherit;
        margin-left: -7px;
        margin-right: inherit;
        border-right-color: var(--primary-bg-color);
        border-left-color: inherit;
    }

    .main-header-notification .dropdown-menu {
        right: 0;
        left: 0;
        right: auto;
        left: -10px;
    }

    .main-header-message .dropdown-menu {
        right: auto;
        left: -10px;
    }

    .main-notification-list .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-profile-menu {
        > .main-img-user::before {
            left: -90px;
            right: inherit;
            margin-left: -7px;
            margin-right: inherit;
            border-right-color: #e2e8f5;
            border-left-color: inherit;
        }

        .dropdown-menu {
            right: 0;
            left: 0;
            right: auto;
            left: -10px;
        }

        .dropdown-item i {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        &.horizontal {
            .horizontal-main .slide {
                .slide-menu .slide-item:before,
                .sub-slide-menu .slide-item:before,
                .sub-slide-menu2 .slide-item:before {
                    right: 12px;
                    left: inherit;
                    margin-right: 0px;
                    margin-left: inherit;
                }
            }

            .sub-slide {
                .sub-angle {
                    left: -15px;
                    right: auto;
                    margin-right: 0 !important;
                    margin-left: inherit !important;
                    transform: rotate(90deg);
                }

                .sub-angle2 {
                    margin-left: 0;
                    margin-right: inherit;
                }

                .sub-slide-menu,
                .sub-slide-menu2 {
                    right: 180px;
                    left: inherit;
                }
            }

            .main-sidemenu i.angle {
                left: -4px;
                right: inherit;
            }

            .side-menu .slide .side-menu__item.active {
                border-left: none;
                border-right: inherit;
            }
        }

        .main-sidemenu {
            .slide-right {
                left: 20px;
                right: inherit;
            }

            .slide-left {
                right: 23px;
                left: inherit;
            }
        }
    }

    &.app.sidenav-toggled .side-menu .side-menu__icon {
        margin-left: 14px;
        margin-right: 0;
    }

    @media (max-width: 991.98px) {
        &.horizontal .app-sidebar.horizontal-main {
            right: -300px;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        &.horizontal {
            .horizontal-main .slide .slide-menu .sub-side-menu__item:before {
                right: -15px;
                left: inherit;
                margin-right: 0px;
                margin-left: inherit;
            }

            .main-header.hor-header {
                padding-right: 0px;
                padding-left: inherit;
            }

            .horizontal-main .slide .slide-menu {
                .sub-side-menu__item:before {
                    right: 12px;
                    left: inherit;
                }

                .sub-slide-item2:before {
                    right: 12px;
                    left: inherit;
                    right: 16px;
                    left: inherit;
                }
            }
        }
    }

    .main-iconbar {
        right: 0;
        left: inherit;
    }

    .main-iconbar-logo::after {
        right: 20px;
        left: inherit;
    }

    .main-iconbar-aside {
        border-left: 1px solid #b4bdce;
        border-right: inherit;
        right: 64px;
        left: inherit;
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside {
            border-left: 0;
            border-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside.show + .main-content {
            margin-left: 294px;
            margin-right: inherit;
        }
    }

    .main-iconbar-header {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 1200px) {
        .main-iconbar-toggle-menu::before {
            right: -1px;
            left: inherit;
            border-right: 2px solid #1c273c;
            border-left: inherit;
        }
    }

    .main-iconbar-body {
        padding-right: 0;
        padding-left: inherit;

        .nav {
            border-right: 2px solid #e8e7ef;
            border-left: inherit;
            padding-right: 20px;
            padding-left: inherit;
        }

        .nav-link {
            i {
                margin-right: 10px;
                margin-left: inherit;
            }

            &.with-sub::after {
                margin-left: auto;
                margin-right: inherit;
            }
        }

        .nav-sub .nav-sub-item {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-iconbar-body .nav-link.with-sub::after {
            margin-left: 4px;
            margin-right: inherit;
        }
    }

    .main-navbar {
        right: 0;
        left: inherit;

        .nav-link {
            i {
                margin-right: 10px;
                margin-left: inherit;
            }

            &.with-sub {
                &::after {
                    margin-left: auto;
                    margin-right: inherit;
                }

                &::before {
                    right: 50%;
                    left: inherit;
                    margin-left: -7px;
                    margin-right: inherit;
                    border-right-color: rgba(28, 39, 60, 0.12);
                    border-left-color: inherit;
                }
            }
        }

        .nav-sub-item {
            margin-left: 30px;
            margin-right: inherit;

            > .nav-sub {
                right: 176px;
                left: inherit;
            }
        }

        .nav-sub-link.with-sub::after {
            margin-left: auto;
            margin-right: inherit;
        }

        .nav-sub-mega .nav li:not(.nav-sub-item) {
            margin-left: 30px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar {
            border-left: 0;
            border-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item + .nav-item {
            margin-left: 15px;
            margin-right: inherit;
            padding-right: 15px;
            padding-left: inherit;
            border-right: 1px dotted #e8e7ef;
            border-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-item + .nav-item {
            margin-left: 20px;
            margin-right: inherit;
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link i {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link.with-sub::after {
            margin-left: 4px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub {
            right: 0;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-item {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (max-width: 991.98px) {
        .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
            margin-left: 37px;
            margin-right: inherit;

            > .nav-sub-link {
                padding-right: 10px;
                padding-left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link.with-sub::after {
            margin-left: 4px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega {
            .container > div + div,
            .container-fluid > div + div {
                border-right: 1px solid rgba(28, 39, 60, 0.05);
                border-left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-left: 25px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .main-navbar-search {
        .btn,
        .sp-container button {
            left: 0;
            right: inherit;
        }
    }

    .sp-container .main-navbar-search button {
        left: 0;
        right: inherit;
    }

    .main-navbar-two .nav-sub-item > .nav-sub {
        right: 178px;
        left: inherit;
    }

    @media (min-width: 992px) {
        .main-navbar-two .nav-item.active::before {
            right: 0;
            left: 12px;
        }
    }

    .main-navbar-three {
        .nav-item + .nav-item {
            border-right-width: 0;
            border-left-width: inherit;
            padding-right: 0;
            padding-left: inherit;
        }

        .nav-sub-item .nav-sub {
            right: 182px;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-navbar-three .nav-item + .nav-item {
            margin-left: 25px;
            margin-right: inherit;
        }
    }

    .main-sidebar-body .nav-sub-item {
        margin-left: 55px;
        margin-right: inherit;
    }

    .main-body-sidebar .main-content {
        transition: margin-right 0.3s ease;
    }

    .main-sidebar {
        border-left: 0 solid #d8dfea;
        border-right: inherit;
    }

    @media (max-width: 991.98px) {
        .main-sidebar {
            right: 0;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar {
            border-left-width: 1px;
            border-right-width: inherit;
        }

        .main-sidebar-hide .main-sidebar-body .nav-link {
            margin-right: 0;
            margin-left: inherit;
        }
        &.sidebar-mini.sidenav-toggled .main-content {
            margin-right: 80px;
            margin-left: 0;
        }
        &.sidebar-mini.sidenav-toggled .main-header.side-header {
            padding-right: 80px;
            padding-left: inherit;
        }
    }

    .main-sidebar-sticky {
        right: 0;
        left: inherit;
    }

    @media (min-width: 992px) {
        .main-sidebar-sticky + .main-content {
            margin-left: 220px;
            margin-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-sidebar-sticky + .main-content {
            margin-left: 240px;
            margin-right: inherit;
        }
    }

    .main-sidebar-header {
        border-left: 0;
        border-right: in hidden;
    }

    .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
        right: -30px;
        left: inherit;

        .dropdown-item i {
            margin-right: 3px;
            margin-left: inherit;
        }
    }

    .main-sidebar-loggedin .media-body {
        margin-left: 12px;
        margin-right: inherit;
        transition: right 0.4s ease, width 0.4s ease;
    }

    .main-sidebar-body {
        .nav-item.active .nav-link .nav-badge {
            left: 12px !important;
            right: inherit !important;
        }

        .nav-sub {
            margin-left: 32px;
            margin-right: inherit;
        }

        .nav-item + .nav-item::before {
            right: 30px;
            left: 0;
        }
    }

    .nav-link.with-sub {
        .angle,
        .side-badge {
            left: 23px;
            right: inherit;
        }
    }

    .main-sidebar-body {
        .nav-sub {
            margin: 0 7px 0 0px;
        }

        .nav-sub-item .nav-sub {
            padding: 0 18px 0 0px;
            border-right-width: 1px;
            border-left-width: inherit;
            margin-left: 0;
            margin-right: inherit;
        }

        .nav-sub-link.with-sub::after {
            margin-left: auto;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-left: 4px;
            margin-right: inherit;
        }
    }

    .sidebar-right .search_btn {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border-top-right-radius: inherit !important;
        border-bottom-right-radius: inherit !important;
    }

    .app-sidebar .mCSB_draggerContainer {
        left: -11px !important;
        right: inherit;
    }

    @media (min-width: 992px) {
        &.app.sidenav-toggled {
            .app-content {
                margin-left: 0;
                margin-right: inherit;
            }

            .app-sidebar {
                right: 230px;
                left: inherit;
            }
        }
    }

    @media (max-width: 991px) {
        &.app {
            .app-sidebar {
                right: -250px;
                left: inherit;
            }

            &.sidenav-toggled {
                .app-content {
                    margin-left: 0;
                    margin-right: inherit;
                }

                .app-sidebar {
                    right: 0;
                    left: inherit;
                }
            }

            &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
                right: 0;
                left: inherit;
            }
        }
    }

    .app-content {
        -webkit-transition: margin-right 0.3s ease;
        -o-transition: margin-right 0.3s ease;
        transition: margin-right 0.3s ease;
    }

    @media (min-width: 992px) {
        .app-content {
            margin-left: 250px;
            margin-right: inherit;
        }
    }

    /*----- Componant: Top Navigation Bar ----- */

    .app-header {
        padding-left: 15px;
        padding-right: inherit;
    }

    @media (min-width: 992px) {
        .app-header {
            padding-left: 30px;
            padding-right: inherit;
        }
    }

    .app-sidebar__toggle {
        margin-left: 10px;
        margin-right: inherit;
    }

    .app-search {
        margin-right: 15px;
        margin-left: inherit;
    }

    .app-search__input {
        padding-left: 30px;
        padding-right: inherit;
    }

    .app-search__button {
        left: 0;
        right: inherit;
    }

    .app-notification__icon {
        padding-left: 10px;
        padding-right: inherit;
    }

    .app-sidebar {
        right: 0;
        left: inherit;
    }

    .app-sidebar__user-avatar {
        margin-right: 15px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        &.app.sidenav-toggled .side-menu__label {
            right: 0;
            left: inherit;
        }
    }

    .sub-slide .sub-angle {
        margin-left: auto;
        margin-right: inherit;
        float: left;
    }

    .slide.is-expanded .sub-side-menu__item:before {
        right: 16px;
        left: inherit;
        content: "\e92e";
    }

    .side-menu .side-menu__icon {
        margin-left: 14px;
        margin-right: inherit;
    }

    // .slide-menu {
    //     padding-right: 29px;
    //     padding-left: inherit;
    // }

    .slide-item .icon {
        margin-right: 5px;
        margin-left: inherit;
    }

    .dropdown-menu.dropdown-menu-right {
        right: auto;
        left: inherit;
    }

    &.app-breadcrumb {
        text-align: left;
        text-align: right;
    }

    &.sidenav-toggled {
        .main-wrapper .side-menu .side-menu__item .nav-badge {
            right: 57px;
            left: inherit;
        }

        .app-sidebar .side-header .header-brand-img.toggle-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .side-header {
        -webkit-transition: right 0.1s ease, width 0.1s ease;
        -o-transition: right 0.1s ease, width 0.1s ease;
        transition: right 0.1s ease, width 0.1s ease;
    }

    .sidebar-navs .nav li:last-child a {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .side-menu h3:after {
        right: 23px;
        left: inherit;
    }

    .sub-slide-item {
        padding: 0 25px 0 0px !important;
    }

    .sub-slide-menu {
        padding-right: 0;
        padding-left: inherit;
    }

    .sub-slide-item {
        padding-right: 42px !important;
        padding-left: inherit !important;
    }

    &.sidenav-toggled .app-content {
        transition: margin-right 0.1s ease;
    }

    @media (min-width: 992px) {
        &.sidebar-mini.sidenav-toggled {
            .app-content {
                margin-left: 70px;
                margin-right: inherit;
            }

            .app-sidebar {
                right: 0;
                left: inherit;
            }

            .side-badge {
                left: 8px;
                right: inherit;
            }

            .main-content {
                margin-right: 80px;
                margin-left: 0;
            }

            .side-menu__label {
                right: 50px;
                left: inherit;
                margin-left: -3px;
                margin-right: inherit;
            }

            .slide .side-menu__label {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: inherit;
            }

            .slide-menu {
                right: 50px;
                left: inherit;
            }
        }

        .app-content {
            margin-right: 240px;
            margin-left: inherit;

            &.toggle-content {
                margin-left: 240px;
                margin-right: inherit;
            }
        }

        &.sidenav-toggled {
            .side-menu__icon {
                margin-right: 0;
                margin-left: inherit;
            }

            .side-menu__item {
                margin-left: 0;
                margin-right: inherit;
            }
        }

        &.app.sidenav-toggled .main-content.app-content .main-header-center {
            margin-left: 70px;
            margin-right: inherit;
        }
    }

    .sub-slide-menu .active .sub-slide-item.active:before {
        margin-right: 8px;
        margin-left: inherit;
        right: 21px;
        left: inherit;
    }

    .sub-slide-item {
        padding: 0 25px 0 0px !important;
    }

    .sub-slide-menu {
        padding-right: 0;
        padding-left: inherit;
    }

    .sub-slide-item {
        padding-right: 60px !important;
        padding-left: inherit;
        padding: 19px 47px 19px 0px !important;
    }

    .sub-side-menu__item:before {
        margin-right: 8px;
        margin-left: inherit;
        right: 18px;
        left: inherit;
        content: "\e92e";
    }

    .side-menu__item .side-menu__icon.ti {
        margin-right: 6px;
        margin-left: inherit;
    }

    .side-badge {
        left: 30px;
        right: inherit;
    }

    .slide-menu {
        .slide-item:before {
            margin-left: 8px;
            margin-right: inherit;
            right: 23px;
            left: inherit;
        }

        li a {
            padding: 8px 45px 8px 8px;
        }
    }

    .main-content.app-content {
        transition: right 0.3s ease;
    }

    @media (min-width: 992px) {
        &.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
            .side-menu__label {
                margin-left: 5px;
                margin-right: inherit;
            }

            .slide.is-expanded .slide-menu {
                right: 0 !important;
                left: inherit !important;
            }

            .slide-menu li a {
                padding: 8px 45px 8px 8px;
            }

            .side-badge {
                left: 30px !important;
                right: inherit !important;
            }
        }
    }

    .sub-slide-menu .sub-slide-menu {
        padding-right: 0;
        padding-left: inherit;
    }

    .sub-slide .sub-angle2 {
        margin-right: 79px;
        margin-left: inherit;
    }

    .sub-slide-menu .sub-slide-item2 {
        padding: 6px 60px 6px 0px !important;
    }

    @media (min-width: 992px) {
        &.icontext-menu.sidebar-mini.sidenav-toggled {
            .side-menu__label {
                right: 0;
                left: inherit;
            }

            .main-content {
                margin-right: 110px;
                margin-left: 0;
            }

            .side-menu .side-menu__icon {
                margin-right: 0;
                margin-left: inherit;
            }

            .main-header.side-header {
                padding-right: 110px;
                padding-left: inherit;
            }

            .side-badge {
                left: 20px;
                right: inherit;
            }

            &.sidenav-toggled-open .side-menu .side-menu__icon {
                margin-right: 14px;
                margin-left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        &.closed-leftmenu.sidebar-mini.sidenav-toggled {
            .main-header.side-header {
                padding-right: 0;
                padding-left: inherit;
            }

            .main-content {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu.sidebar-mini.sidenav-toggled {
            .main-header.side-header,
            &.sidenav-toggled-open .main-header.side-header {
                padding-right: 110px;
                padding-left: inherit;
            }

            .main-content,
            &.sidenav-toggled-open .main-content {
                margin-right: 110px;
                margin-left: 0;
            }

            .side-menu__label,
            &.sidenav-toggled-open .side-menu__label {
                right: 0;
                left: inherit;
                margin-left: 0;
                margin-right: inherit;
            }

            .side-menu .side-menu__icon,
            &.sidenav-toggled-open .side-menu .side-menu__icon {
                margin-right: 0;
                margin-left: inherit;
            }

            .side-badge,
            &.sidenav-toggled-open .side-badge {
                left: 20px !important;
                right: inherit !important;
            }

            .slide .slide-menu,
            &.sidenav-toggled-open .slide .slide-menu {
                right: 108px !important;
                left: inherit !important;
                border-radius: 5px 0 0 5px;
            }

            .slide-menu li a,
            &.sidenav-toggled-open .slide-menu li a {
                padding: 10px 50px 10px 10px;
            }

            .slide-menu .sub-slide-menu,
            &.sidenav-toggled-open .slide-menu .sub-slide-menu {
                padding: 0 20px 0 10px;
            }

            .sub-side-menu__item,
            &.sidenav-toggled-open .sub-side-menu__item {
                padding: 10px 40px 10px 0px !important;
            }
        }
    }

    @media (min-width: 992px) {
        &.hover-submenu1.sidebar-mini.sidenav-toggled {
            .main-header.side-header,
            &.sidenav-toggled-open .main-header.side-header {
                padding-right: 110px;
                padding-left: inherit;
            }

            .main-content,
            &.sidenav-toggled-open .main-content {
                margin-right: 110px;
                margin-left: 0;
            }

            .side-menu .side-menu__icon,
            &.sidenav-toggled-open .side-menu .side-menu__icon {
                margin-right: 0;
                margin-left: inherit;
            }

            .side-badge,
            &.sidenav-toggled-open .side-badge {
                left: 20px !important;
                right: inherit !important;
            }

            .slide .slide-menu,
            &.sidenav-toggled-open .slide .slide-menu {
                right: 108px !important;
                left: inherit !important;
                border-radius: 5px 0 0 5px;
            }

            .slide-menu li a,
            &.sidenav-toggled-open .slide-menu li a {
                padding: 10px 50px 10px 0px;
            }

            .slide-menu .sub-slide-menu,
            &.sidenav-toggled-open .slide-menu .sub-slide-menu {
                padding: 0 20px 0 10px;
            }

            .sub-side-menu__item,
            &.sidenav-toggled-open .sub-side-menu__item {
                padding: 10px 40px 10px 0px !important;
            }
        }
    }

    .main-home-slider .card-columns {
        right: 24%;
        left: inherit;
    }

    @media (min-width: 576px) {
        .main-home-slider .card-columns {
            right: 16%;
            left: inherit;
        }
    }

    @media (min-width: 768px) {
        .main-home-slider .card-columns {
            right: auto;
            left: -45%;
        }
    }

    @media (min-width: 992px) {
        .main-home-slider .card-columns {
            left: -30%;
            right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-home-slider .card-columns {
            left: -15%;
            right: inherit;
        }
    }

    @media (min-width: 1300px) {
        .main-home-slider .card-columns {
            left: -5%;
            right: inherit;
        }
    }

    .main-home-content {
        .logo-group i + i {
            margin-left: 10px;
            margin-right: inherit;
        }

        p:last-of-type {
            .btn + .btn,
            .sp-container button + .btn {
                margin-left: 5px;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .main-home-content {
            right: 40px;
            left: auto;
        }
    }

    .sp-container .main-home-content p:last-of-type button + .btn,
    .main-home-content p:last-of-type .sp-container .btn + button,
    .sp-container .main-home-content p:last-of-type .btn + button,
    .main-home-content p:last-of-type .sp-container button + button,
    .sp-container .main-home-content p:last-of-type button + button {
        margin-left: 5px;
        margin-right: inherit;
    }

    .main-home-content nav:last-child a + a {
        margin-left: 8px;
        margin-right: inherit;
    }

    .btn-icon-list {
        .btn + .btn,
        .sp-container button + .btn {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    .sp-container .btn-icon-list button + .btn,
    .btn-icon-list .sp-container .btn + button,
    .sp-container .btn-icon-list .btn + button,
    .btn-icon-list .sp-container button + button,
    .sp-container .btn-icon-list button + button {
        margin-left: 5px;
        margin-right: inherit;
    }

    .tooltip-static-demo {
        .bs-tooltip-top .arrow,
        .bs-tooltip-auto[x-placement^="top"] .arrow,
        .bs-tooltip-bottom .arrow,
        .bs-tooltip-auto[x-placement^="bottom"] .arrow {
            right: 50%;
            left: inherit;
            margin-left: -2px;
            margin-right: inherit;
        }
    }

    // .popover-static-demo {
    //     .bs-popover-top .arrow,
    //     .bs-popover-auto[x-placement^="top"] .arrow,
    //     .bs-popover-bottom .arrow,
    //     .bs-popover-auto[x-placement^="bottom"] .arrow {
    //         right: 50%;
    //         left: inherit;
    //         margin-left: -5px;
    //         margin-right: inherit;
    //     }
    // }

    .main-toggle-group-demo .main-toggle + .main-toggle {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 768px) {
        .ql-scrolling-demo {
            padding-right: 15px;
            padding-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .ql-scrolling-demo .ql-container {
            padding-right: 35px;
            padding-left: inherit;
        }
    }

    .main-content-left-components {
        border-left: 1px solid #e8e7ef;
        border-right: inherit;
    }

    .main-breadcrumbs .breadcrumb-item + .breadcrumb-item {
        padding-right: 8px;
        padding-left: inherit;
    }

    .main-page-content-list li i {
        margin-right: 10px;
        margin-left: inherit;
    }

    .main-syntax-wrapper .badge {
        left: 5px;
        right: inherit;
    }

    .demo-avatar-group {
        .main-img-user + {
            .main-img-user,
            .main-avatar {
                margin-right: 5px;
                margin-left: inherit;
            }
        }

        .main-avatar + {
            .main-img-user,
            .main-avatar {
                margin-right: 5px;
                margin-left: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .demo-avatar-group {
            .main-img-user + {
                .main-img-user,
                .main-avatar {
                    margin-right: 10px;
                    margin-left: inherit;
                }
            }

            .main-avatar + {
                .main-img-user,
                .main-avatar {
                    margin-right: 10px;
                    margin-left: inherit;
                }
            }
        }
    }

    .main-donut-chart .slice {
        right: 0;
        left: inherit;
    }

    #count-down .clock-presenter {
        float: right;
        margin: 0 0px 0 20px;
    }

    table.dataTable {
        thead {
            .sorting_asc::after,
            .sorting_desc::after {
                left: 10px;
                right: inherit;
            }

            .sorting {
                &::before,
                &::after {
                    left: 10px;
                    right: inherit;
                }
            }
        }

        &.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
            right: 7px;
            left: inherit;
        }
    }

    .dataTables_wrapper {
        .dataTables_length {
            text-align: right;

            .select2-container--default {
                margin-left: 5px;
                margin-right: inherit;
            }
        }

        .dataTables_filter {
            text-align: right;

            input {
                margin-left: 0;
                margin-right: inherit;
            }
        }

        .dataTables_info {
            text-align: right;
        }

        .dataTables_paginate {
            text-align: right;

            .paginate_button {
                + .paginate_button {
                    margin-right: 3px;
                    margin-left: inherit;
                }

                &.previous {
                    margin-left: 3px;
                    margin-right: inherit;
                }

                &.next {
                    margin-right: 3px;
                    margin-left: inherit;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_length {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_filter {
            float: left;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_info {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_paginate {
            float: left;
        }
    }

    /* ###### 4.12 Datetimepicker ###### */
    /* Amaze UI Datetimepicker */

    .datetimepicker table th {
        &.prev span::before {
            right: 0;
            left: inherit;
        }

        &.next span::before {
            left: 0;
            right: inherit;
        }
    }

    .main-datetimepicker > {
        .datepicker_header a {
            &:nth-child(2)::before {
                right: 0;
                left: inherit;
            }

            &:last-child::before {
                left: 0;
                right: inherit;
            }
        }

        .datepicker_inner_container > .datepicker_timelist {
            border-right: 1px solid #b4bdce;
            border-left: inherit;

            > div.timelist_item {
                margin: 0 1px 0 0px;
            }
        }
    }

    .ckbox span {
        padding-right: 15px;
        padding-left: inherit;

        &:empty {
            float: right;
        }

        &:before,
        &:after {
            right: 0;
            left: inherit;
        }

        &:empty {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .rdiobox span {
        padding-right: 13px;
        padding-left: inherit;

        &:before {
            right: 0;
            left: inherit;
        }

        &:after {
            right: 5px;
            left: inherit;
        }

        &:empty {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .form-group-rdiobox .rdiobox {
        + .rdiobox {
            margin-left: 30px;
            margin-right: inherit;
        }

        span {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .main-toggle {
        span {
            right: 3px;
            left: inherit;

            &::before {
                right: -25px;
                left: inherit;
            }

            &::after {
                left: -29px;
                right: auto;
            }
        }

        &.on span {
            right: 37px;
            left: inherit;
        }
    }

    .pickr .pcr-button::after {
        right: 0;
        left: inherit;
    }

    .check-md .custom-control-label {
        &::before,
        &::after {
            right: 0px;
            left: inherit;
        }
    }

    .check-lg .custom-control-label {
        &::before,
        &::after {
            right: 0px;
            left: inherit;
        }
    }

    .rdiobox-md .custom-control-label {
        &::before {
            right: -16px;
            left: inherit;
        }

        &::after {
            right: -7px;
            left: inherit;
        }
    }

    .rdiobox-lg .custom-control-label {
        &::before {
            right: -15px;
            left: inherit;
        }

        &::after {
            right: -4px;
            left: inherit;
        }
    }

    .check-md .custom-control-label {
        padding-right: 33px;
        padding-left: inherit;
    }

    .check-lg .custom-control-label {
        padding-right: 41px;
        padding-left: inherit;
    }

    .rdiobox-md .custom-control-label {
        padding-right: 17px;
        padding-left: inherit;
    }

    .rdiobox-lg .custom-control-label {
        padding-right: 25px;
        padding-left: inherit;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator:before {
        right: calc(1rem + 3px);
        left: inherit;
    }

    .custom-switch-indicator-lg::before {
        right: 1px;
        left: inherit;
    }

    /* ###### Forms Validation  ###### */

    .form-control.is-valid,
    .was-validated .form-control:valid {
        padding-left: calc(1.5em + 0.75rem);
        padding-right: inherit;
        background-position: left calc(0.375em + 0.1875rem) center;
    }

    .form-file-label::after {
        left: 0;
        right: inherit;
        border-right: 1px solid #e9edf4;
        border-left: inherit;
        border-radius: 0 0px 3px 3px;
    }

    @media screen and (max-width: 480px) {
        .sw-theme-dots > ul.step-anchor > li > a:after {
            right: -22px !important;
            left: inherit;
            margin-left: 10px !important;
            margin-right: inherit !important;
        }
    }

    .sw-theme-dots > ul.step-anchor {
        > li > a:after {
            right: 48%;
            left: inherit;
        }

        &:before {
            right: 20px;
            left: inherit;
        }
    }

    @media (min-width: 768px) {
        .main-nav .nav-link + .nav-link {
            padding-right: 15px;
            padding-left: inherit;
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    .main-nav-column .nav-link {
        i {
            margin-left: 12px;
            margin-right: inherit;
        }

        span {
            margin-right: auto;
            margin-left: inherit;
        }

        &.active::before {
            right: -28px;
            left: inherit;
        }
    }

    .main-nav-line .nav-link.active::before {
        right: -20px;
        left: inherit;
    }

    .main-nav-tabs {
        .lSSlideOuter {
            padding-right: 32px;
            padding-left: 35px;
        }

        .lSAction > a {
            &.lSPrev {
                right: -32px;
                left: inherit;
            }

            &.lSNext {
                left: -35px;
                right: inherit;
            }
        }
    }

    .ps > .ps__rail-y > .ps__thumb-y {
        right: 4px;
        left: inherit;
    }

    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0px 0 8px;

        &:first-child {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .ql-bubble .ql-toolbar .ql-formats:first-child {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .ql-snow {
        &.ql-toolbar .ql-formats {
            margin-right: 5px;
            margin-left: inherit;
        }

        .ql-formats button + button {
            margin: 0 2px 0 0px;
        }

        .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
            left: 3px;
            right: inherit;
        }

        .ql-picker-label {
            padding-right: 10px;
            padding-left: inherit;
        }
    }

    .select2-container--default {
        .select2-selection--single .select2-selection__rendered {
            padding-right: 0.75rem;
            padding-left: inherit;
        }

        .select2-selection--multiple {
            .select2-selection__choice {
                margin-right: 4px;
                margin-left: inherit;
                padding: 3px 20px 3px 10px;
            }

            .select2-selection__choice__remove {
                right: 7px;
                left: inherit;
            }
        }

        .select2-search--inline .select2-search__field {
            padding-right: 7px;
            padding-left: inherit;
        }

        &.select2-container--disabled .select2-selection__choice {
            padding-right: 10px;
            padding-left: inherit;
        }
    }

    .sp-picker-container {
        border-right: 0;
        border-left: inherit;
    }

    .sp-dd::before {
        right: 0;
        left: inherit;
    }

    .vtimeline {
        &:before {
            right: 50%;
            left: inherit;
            margin-right: -1.5px;
            margin-left: inherit;
        }

        .timeline-wrapper {
            padding-left: 90px;
            padding-right: inherit;

            .timeline-panel {
                margin-right: 15%;
                margin-left: inherit;

                &:after {
                    left: -14px;
                    right: inherit;
                    border-right: 14px solid #f2f1f9;
                    border-left: 0 solid #f2f1f9;
                }
            }

            .timeline-badge {
                right: calc(50% - 7px);
                left: inherit;
            }

            &.timeline-inverted {
                padding-left: 0;
                padding-right: 90px;

                .timeline-panel {
                    margin-right: auto;
                    margin-left: 15%;

                    &:after {
                        border-right-width: 0;
                        border-left-width: 14px;
                        right: -14px;
                        left: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .vtimeline {
            &:before {
                right: 7px !important;
                left: inherit !important;
            }

            .timeline-wrapper {
                padding-left: 0 !important;
                padding-right: inherit !important;

                .timeline-badge {
                    right: 0px !important;
                    left: inherit !important;
                }

                .timeline-panel {
                    margin-right: 10% !important;
                    margin-left: inherit !important;

                    &:after {
                        border-left: 14px solid #f2f1f9 !important;
                        border-right: 0 solid #f2f1f9 !important;
                        right: -14px !important;
                        left: auto !important;
                    }
                }

                &.timeline-inverted {
                    padding-right: 0 !important;
                    padding-left: inherit !important;

                    .timeline-panel {
                        margin-right: 10% !important;
                        margin-left: inherit !important;
                    }
                }
            }
        }
    }

    /* ###### 3.2 Datepicker ###### */

    .ui-datepicker .ui-datepicker-header {
        .ui-datepicker-next:before {
            left: 5px;
            right: inherit;
            content: "\f3cf";
        }

        .ui-datepicker-prev:before {
            right: 5px;
            left: inherit;
            content: "\f3d1";
        }
    }

    .card--calendar .card--events .list-group-item {
        padding: 13px 40px 13px 20px;
        padding: 13px 40px 13px 20px;
    }

    .card--events {
        .list-group-item {
            padding: 15px 40px 15px 20px;
        }

        .event-indicator {
            right: 20px;
            left: inherit;
        }

        .event-user {
            .main-img-user + .main-img-user {
                margin-left: -6px;
                margin-right: inherit;
            }

            a:last-child {
                margin-left: 10px;
                margin-right: inherit;
            }
        }
    }

    .ui-datepicker .ui-datepicker-calendar td {
        text-align: left;
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            padding-left: 15px;
            padding-right: inherit;
            float: right;

            .ui-datepicker-prev::before {
                right: 10px;
                left: inherit;
                content: "\f3d1";
            }

            .ui-datepicker-next::before {
                left: 10px;
                right: inherit;
                content: "\f3cf";
            }
        }

        .ui-datepicker-group-last {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    #jqvmap1_ca_pin {
        margin-left: -2%;
        margin-right: inherit;
    }

    #jqvmap1_ct_pin {
        margin-left: -0.25%;
        margin-right: inherit;
    }

    #jqvmap1_fl_pin {
        margin-left: 5%;
        margin-right: inherit;
    }

    #jqvmap1_id_pin {
        margin-left: -1%;
        margin-right: inherit;
    }

    #jqvmap1_ky_pin {
        margin-left: 2%;
        margin-right: inherit;
    }

    #jqvmap1_la_pin {
        margin-left: -2%;
        margin-right: inherit;
    }

    #jqvmap1_mi_pin {
        margin-left: 3%;
        margin-right: inherit;
    }

    #jqvmap1_mn_pin {
        margin-left: -2%;
        margin-right: inherit;
    }

    #jqvmap1_nh_pin {
        margin-left: -0.25%;
        margin-right: inherit;
    }

    #jqvmap1_ok_pin,
    #jqvmap1_va_pin {
        margin-left: 2%;
        margin-right: inherit;
    }

    #jqvmap1_wv_pin {
        margin-left: -1%;
        margin-right: inherit;
    }

    .wizard {
        > .steps > ul li {
            .title,
            + li {
                margin-right: 5px;
                margin-left: inherit;
            }
        }

        &.vertical > .steps ul li + li {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li .title {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li + li {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .wizard > .steps > ul li + li {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps ul li + li {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .content {
            float: left;
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .actions {
            float: left;
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    .wizard-style-2 > .steps > ul a {
        .title,
        &:hover .title,
        &:active .title {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    /* ######### Switcher Styles #########*/
    @media (min-width: 1400px) {
        .layout-boxed {
            .app-sidebar {
                right: auto !important;
                left: inherit !important;
            }

            .main-sidemenu {
                .slide-right {
                    left: 6px;
                    right: inherit;
                }

                .slide-left {
                    right: 8px;
                    left: inherit;
                }
            }
        }
    }

    &.dark-mode {
        .side-header {
            border-left: 0;
            border-right: inherit;
        }

        .border-right {
            border-left: 1px solid rgba(234, 236, 241, 0.1) !important;
            border-right: inherit !important;
        }

        .border-left {
            border-right: 1px solid rgba(234, 236, 241, 0.1) !important;
            border-left: inherit !important;
        }

        .app-sidebar,
        .main-sidebar-header {
            border-left-color: rgba(222, 228, 236, 0.1);
            border-right: inherit;
        }

        .nav-search .input-group-text {
            border-right: 0;
            border-left: inherit;
        }

        .main-mail-header .btn-group {
            .btn:hover,
            .sp-container button:hover {
                border-right: 0;
                border-left: inherit;
            }
        }

        .sp-container .main-mail-header .btn-group button:hover {
            border-right: 0;
            border-left: inherit;
        }

        .main-mail-header .btn-group {
            .btn:focus,
            .sp-container button:focus {
                border-right: 0;
                border-left: inherit;
            }
        }

        .sp-container .main-mail-header .btn-group button:focus {
            border-right: 0;
            border-left: inherit;
        }

        .main-contact-item.selected {
            border-right-color: var(--primary-bg-color);
            border-left-color: inherit;
        }

        .tree ul:before {
            border-right: 1px solid rgba(227, 227, 227, 0.2);
            border-left: inherit;
        }

        // .bs-popover-left > .arrow::after,
        // .bs-popover-auto[x-placement^="left"] > .arrow::after {
        //     border-right-color: #373e52;
        //     border-left-color: inherit;
        // }

        // .bs-popover-left > .arrow::after,
        // .bs-popover-auto[x-placement^="left"] > .arrow::after {
        //     border-right-color: #191f3a;
        //     border-left-color: inherit;
        // }

        // .bs-popover-right > .arrow::after,
        // .bs-popover-auto[x-placement^="right"] > .arrow::after {
        //     border-left-color: #191f3a;
        //     border-right-color: inherit;
        // }

        // .bs-popover-right > .arrow::before,
        // .bs-popover-auto[x-placement^="right"] > .arrow::before {
        //     border-left-color: #2c3252;
        //     border-right-color: inherit;
        // }

        // .bs-popover-left > .arrow::before,
        // .bs-popover-auto[x-placement^="left"] > .arrow::before {
        //     border-right-color: #2c3252;
        //     border-left-color: inherit;
        // }

        .vtimeline .timeline-wrapper {
            &.timeline-inverted .timeline-panel:after {
                border-left: 14px solid $white-1;
                border-right: 0 solid $white-1;
            }

            .timeline-panel:after {
                border-right: 14px solid $white-1;
                border-left: 0 solid $white-1;
            }
        }

        @media (max-width: 768px) {
            .vtimeline .timeline-wrapper .timeline-panel:after {
                border-left: 14px solid $white-1 !important;
                border-right: 0 solid $white-1 !important;
            }
        }

        .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            border-left: inherit;
        }

        .main-invoice-list .selected {
            border-right-color: var(--primary-bg-color);
        }

        .bd-r {
            border-left: 2px solid #3c435a;
            border-right: inherit;
        }

        .bd-l {
            border-right: 2px solid #3c435a;
            border-left: inherit;
        }

        .horizontalMenu > .horizontalMenu-list > li > a {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            border-right: inherit;
        }

        .iti__selected-flag {
            border-left: 1px solid rgba(225, 230, 241, 0.1);
            border-right: inherit;
        }

        .ps > .ps__rail-y > .ps__thumb-y {
            right: 2px;
            left: inherit;
        }

        .crypto .card-footer .nav-link + .nav-link {
            border-right: 1px solid rgba(226, 232, 245, 0.1);
            border-left: inherit;
        }

        .border-start {
            border-right-color: rgba(255, 255, 255, 0.1) !important;
            border-left-color: inherit !important;
        }

        .border-end {
            border-left-color: rgba(255, 255, 255, 0.1) !important;
            border-right-color: inherit !important;
        }

        .bs-popover-end > .tooltip-arrow::after {
            border-left-color: #4a5162;
            border-right-color: inherit;
        }

        .bs-popover-auto[data-popper-placement^="right"] > {
            .tooltip-arrow::after,
            .popover-arrow::after {
                border-left-color: #4a5162;
                border-right-color: inherit;
            }
        }

        .bs-popover-end > .popover-arrow::after {
            border-left-color: #4a5162;
        }

        .bs-popover-start > .tooltip-arrow::after {
            border-right-color: #4a5162;
            border-left-color: inherit;
        }

        .bs-popover-auto[data-popper-placement^="left"] > {
            .tooltip-arrow::after,
            .popover-arrow::after {
                border-right-color: #4a5162;
                border-left-color: inherit;
            }
        }

        .bs-popover-start > .popover-arrow::after {
            border-right-color: #4a5162;
        }

        .tab_wrapper .content_wrapper .accordian_header .arrow,
        .sp-picker-container {
            border-right-color: $white-5;
            border-left-color: inherit;
        }

        .multi-wrapper .non-selected-wrapper {
            border-left-color: rgba(255, 255, 255, 0.1);
            border-right-color: inherit;
        }

        .richText .richText-editor {
            border-right-color: transparent;
        }

        .main-msg-wrapper.left:nth-child(1):after {
            border-left-color: rgba(255, 255, 255, 0.1);
            border-right-color: transparent;
        }
    }

    /*----- Left-Sidemenu -----*/

    @media (max-width: 767px) {
        &.dark-mode .vtimeline .timeline-wrapper .timeline-panel:after {
            border-left: 14px solid $white-1 !important;
            border-right: 0 solid #41485c !important;
        }
    }

    @media (min-width: 576px) {
        &.dark-mode .wizard.vertical > {
            .content,
            .actions {
                border-right: 1px solid rgba(226, 232, 245, 0.1);
                border-left: inherit;
            }
        }
    }

    @media (max-width: 991px) and (min-width: 568px) {
        &.dark-mode .horizontalMenucontainer .desktop-dark {
            margin-left: 1.4rem;
            margin-right: inherit;
        }
    }

    @media (max-width: 567px) {
        &.dark-mode .horizontalMenucontainer .desktop-logo-dark {
            margin-left: 2.5rem;
            margin-right: inherit;
        }
    }

    @media (min-width: 768px) {
        &.dark-mode .main-content-left-contacts {
            border-left: 0;
            border-right: inherit;
        }
    }

    &.light-menu {
        .app-sidebar,
        .main-sidebar-header {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }
    }

    &.color-menu .main-sidebar-header,
    &.dark-menu .main-sidebar-header {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: inherit;
    }

    .main-nav-calendar-event .nav-link i {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 992px) {
        .main-content-body-calendar {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    .main-calendar {
        .fc-view {
            &.fc-listMonth-view .fc-list-item,
            &.fc-listWeek-view .fc-list-item {
                border-right: 4px solid transparent;
                border-left: inherit;
            }

            &.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
            &.fc-listWeek-view .fc-list-item > td.fc-list-item-time,
            &.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
            &.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
                border-left: 1px solid #e8e7ef;
                border-right: inherit;
            }

            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: 20%;
                margin-right: inherit;
            }
        }

        .fc-time-grid-event {
            border-right-width: 0;
            border-left-width: inherit;
        }
    }

    @media (max-width: 575px) {
        .main-calendar .fc-header-toolbar button {
            &.fc-month-button::before,
            &.fc-agendaWeek-button::before,
            &.fc-agendaDay-button::before,
            &.fc-listWeek-button::before,
            &.fc-listMonth-button::before {
                right: 10px;
                left: inherit;
            }

            &.fc-listWeek-button::before,
            &.fc-listMonth-button::before {
                right: 11px;
                left: inherit;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > thead {
            th:first-child,
            td:first-child {
                border-right-width: 0;
                border-left-width: inherit;
            }

            th:last-child,
            td:last-child {
                border-left-width: 0;
                border-right-width: inherit;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > tbody > tr > td {
            &:first-child {
                border-right-width: 0;
                border-left-width: inherit;
            }

            &:last-child {
                border-left-width: 0;
                border-left-width: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: 15%;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 768px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: 12%;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: 10%;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 1200px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: 8%;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-event {
            border-right-width: 2px;
            border-left-width: inherit;
        }
    }

    .main-modal-calendar-schedule {
        .modal-content > .close {
            left: 25px;
            right: inherit;
        }

        .form-group-date > i {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .main-modal-calendar-event .nav-modal-event .nav-link + .nav-link {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-nav-line-chat {
        padding-right: 20px !important;
        padding-left: inherit;

        .nav-link + .nav-link {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-nav-line-chat {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .main-chat-contacts-wrapper {
        .main-content-label,
        .card-table-two .card-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-chat-contacts-wrapper {
            padding: 10px 0px 10px 15px;
        }
    }

    .card-table-two .main-chat-contacts-wrapper .card-title,
    .main-chat-contacts-wrapper .card-dashboard-eight .card-title,
    .card-dashboard-eight .main-chat-contacts-wrapper .card-title,
    .main-chat-contacts {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-chat-list {
        .media {
            &::after {
                right: -1px;
                left: inherit;
            }

            + .media::before {
                right: 65px;
                left: 0;
            }

            &.selected {
                border-right: 1px solid var(--primary-bg-color);
                border-left: inherit;
            }
        }

        .main-img-user span {
            right: -2px;
            left: inherit;
        }

        .media-body {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .main-chat-header {
        .nav {
            margin-right: auto;
            margin-left: inherit;
        }

        .nav-link + .nav-link {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-body {
        .media.flex-row-reverse .media-body {
            margin-right: 0;
            margin-left: 20px;
        }

        .media-body {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-chat-body .media.flex-row-reverse .media-body {
            margin-right: 55px;
        }
    }

    @media (min-width: 576px) {
        .main-chat-body .media-body {
            margin-left: 55px;
            margin-right: 20px;
        }
    }

    .main-chat-footer .nav-link {
        + .nav-link {
            margin-right: 10px;
            margin-left: inherit;
        }

        &:last-child {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-chat-footer .form-control {
            margin-right: 20px;
        }
    }

    @media (max-width: 575px) {
        .main-nav-line-chat .nav-link + .nav-link {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }
    }

    .main-msg-wrapper {
        &.right {
            &:before {
                right: 100%;
                left: inherit;
            }

            &:nth-child(1):after {
                border-right-color: var(--primary-bg-color);
                border-left-color: transparent;
            }

            &:after,
            &:before {
                right: 100%;
                left: inherit;
            }
        }

        &.left {
            &:nth-child(1):before {
                border-left-color: var(--primary02);
                border-left-color: inherit;
            }

            &:after,
            &:before {
                left: 100%;
                right: inherit;
            }

            &:nth-child(1):after {
                border-left-color: #e2e8f5;
                border-right-color: transparent;
            }

            &:after,
            &:before {
                left: 100%;
                right: inherit;
            }
        }
    }

    .main-content-left-contacts {
        .main-content-breadcrumb,
        .main-content-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .main-content-left-contacts {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-contacts {
            .main-content-breadcrumb,
            .main-content-title {
                padding-right: 0;
                padding-left: inherit;
            }
        }
    }

    .main-contact-label {
        padding-right: 20px;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .main-contact-label {
            padding-right: 30px;
            padding-left: inherit;
        }
    }

    .main-contact-item {
        border-left-width: 0;
        border-right-width: 1px;

        + .main-contact-item::before {
            right: 65px;
            left: 0;
        }

        &.selected {
            border-right-color: var(--primary-bg-color);
            border-left-color: inherit;
        }
    }

    .main-nav-line-chat .nav-link.active::before {
        left: 0;
        right: 0;
    }

    .main-contact-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-contact-info-header {
        padding-right: 20px;
        padding-left: inherit;

        .main-img-user a {
            left: 0;
            right: inherit;
        }

        .media-body .nav-link {
            i {
                margin-right: 5px;
                margin-left: inherit;
            }

            + .nav-link {
                margin-left: 10px;
                margin-right: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-contact-info-header {
            padding-right: 30px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-contact-info-header .media-body {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link i {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link + .nav-link {
            margin-left: 25px;
            margin-right: inherit;
        }
    }

    .main-contact-action {
        left: 20px;
        right: inherit;

        a + a {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-contact-action {
            left: 20px;
            right: inherit;
        }
    }

    .main-contact-info-body {
        padding-right: 20px;
        padding-left: inherit;

        .media + .media::before {
            right: -19px;
            left: 0;
        }

        .media-body {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-column-signup {
            border-right: 1px solid #e3e3e3;
            border-left: inherit;
        }
    }

    .main-signup-header .row > div {
        .btn i,
        .sp-container button i {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    .sp-container .main-signup-header .row > div button i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-content-left-invoice {
        .main-content-breadcrumb,
        .main-content-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {
            .main-content-breadcrumb,
            .main-content-title {
                padding-right: 0;
                padding-left: inherit;
            }
        }
    }

    .main-invoice-list {
        .media {
            border-left-width: 0;
            border-right-width: 1px;
        }

        .media-body {
            margin-right: 15px;
            margin-left: inherit;
        }

        .selected {
            border-right-color: var(--primary-bg-color);
        }
    }

    .table-invoice tbody > tr > {
        th:first-child .invoice-notes,
        td:first-child .invoice-notes {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-mail .btn-compose {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .main-mail-menu {
        padding-left: 0;
        padding-right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-menu {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    .main-mail-header {
        > div:last-child > span {
            margin-left: 10px;
            margin-right: inherit;
        }

        .btn-group {
            .btn + .btn,
            .sp-container button + .btn {
                margin-left: -2px;
                margin-right: inherit;
            }
        }
    }

    .sp-container .main-mail-header .btn-group button + .btn,
    .main-mail-header .btn-group .sp-container .btn + button,
    .sp-container .main-mail-header .btn-group .btn + button,
    .main-mail-header .btn-group .sp-container button + button,
    .sp-container .main-mail-header .btn-group button + button {
        margin-left: -2px;
        margin-right: inherit;
    }

    .main-mail-options {
        padding: 5px 20px 5px 10px;
    }

    @media (min-width: 992px) {
        .main-mail-options {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    .main-mail-item {
        .main-img-user,
        .main-avatar {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    .main-mail-star {
        margin-left: 15px;
        margin-right: inherit;
        left: 0;
        right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-body {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .main-mail-attachment {
        margin-left: 15px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .main-mail-attachment {
            left: 63px;
            right: inherit;
        }
    }

    .main-mail-checkbox {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-mail-date {
        left: 15px;
        right: inherit;
    }

    .main-mail-compose {
        right: 0;
        left: inherit;
    }

    .main-mail-compose-header .nav-link + .nav-link {
        margin-left: 15px;
        margin-right: inherit;
    }

    .main-mail-compose-body .form-group {
        > div {
            margin-left: 10px;
            margin-right: inherit;
        }

        .nav-link + .nav-link {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    .main-mail-compose-compress,
    .main-mail-compose-minimize {
        right: auto;
        left: 30px;
    }

    .main-mail-two .main-header-menu-icon {
        margin-right: 0;
        margin-left: inherit;
    }

    .main-mail-left {
        border-left: 1px solid #b4bdce;
        border-right: inherit;

        .main-mail-menu {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-profile {
            padding-left: 20px;
            padding-right: inherit;
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-content-left-profile {
            padding-left: 25px;
            padding-right: inherit;
        }
    }

    .main-profile-social-list .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    .main-content-body-profile .nav {
        padding: 20px 0px 20px 20px;
    }

    @media (min-width: 992px) {
        .main-content-body-profile .nav {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .main-content-body-profile .nav {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        .main-content-body-profile .main-nav-line .nav-link + .nav-link {
            margin-left: 2px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-profile-body {
            padding: 25px 20px 0 0px;
        }
    }

    @media (min-width: 1200px) {
        .main-profile-body {
            padding-right: 25px;
            padding-left: inherit;
        }
    }

    .main-profile-view-info {
        right: 0;
        left: inherit;

        span {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .main-profile-work-list .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    .main-profile-contact-list .media-body {
        margin-right: 25px;
        margin-left: inherit;
    }

    .bd-r {
        border-left: 1px solid #dad8e4;
    }

    .bd-l {
        border-right: 1px solid #dad8e4;
        border-left: inherit;
    }

    .bd-r-0 {
        border-left: 0;
        border-right: inherit;
    }

    .bd-l-0 {
        border-right: 0;
        border-left: inherit;
    }

    .bd-r-0-f {
        border-left: 0 !important;
        border-right: inherit !important;
    }

    .bd-l-0-f {
        border-right: 0 !important;
        border-left: inherit !important;
    }

    @media (min-width: 480px) {
        .bd-xs-r {
            border-left: 1px solid #edecf7;
            border-right: inherit !important;
        }

        .bd-xs-l {
            border-right: 1px solid #edecf7;
            border-left: inherit !important;
        }
    }

    @media (min-width: 576px) {
        .bd-sm-r {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }

        .bd-sm-l {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .bd-md-r {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }

        .bd-md-l {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .bd-lg-r {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }

        .bd-lg-l {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .bd-xl-r {
            border-left: 1px solid #edecf7;
            border-right: inherit;
        }

        .bd-xl-l {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }
    }

    .border-end {
        border-left: 1px solid #edecf7 !important;
        border-right: inherit !important;
    }

    .border-start {
        border-right: 1px solid #edecf7 !important;
        border-left: inherit !important;
    }

    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border !important;
    }

    .rounded-end {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border-top-right-radius: inherit !important;
        border-bottom-right-radius: inherit !important;
    }

    .rounded-start {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-top-left-radius: inherit !important;
        border-bottom-left-radius: inherit !important;
    }

    .mg-r-0 {
        margin-left: 0px;
        margin-right: inherit;
    }

    .mg-l-0 {
        margin-right: 0px;
        margin-left: inherit;
    }

    .mg-r-0-f {
        margin-left: 0px !important;
        margin-right: inherit !important;
    }

    .mg-l-0-f {
        margin-right: 0px !important;
        margin-left: inherit !important;
    }

    .mg-r-1 {
        margin-left: 1px;
        margin-right: inherit;
    }

    .mg-l-1 {
        margin-right: 1px;
        margin-left: inherit;
    }

    .mg-r-1-f {
        margin-left: 1px !important;
        margin-right: inherit !important;
    }

    .mg-l-1-f {
        margin-right: 1px !important;
        margin-left: inherit !important;
    }

    .mg-r-2 {
        margin-left: 2px;
        margin-right: inherit;
    }

    .mg-l-2 {
        margin-right: 2px;
        margin-left: inherit;
    }

    .mg-r-2-f {
        margin-left: 2px !important;
        margin-right: inherit !important;
    }

    .mg-l-2-f {
        margin-right: 2px !important;
        margin-left: inherit !important;
    }

    .mg-r-3 {
        margin-left: 3px;
        margin-right: inherit;
    }

    .mg-l-3 {
        margin-right: 3px;
        margin-left: inherit;
    }

    .mg-r-3-f {
        margin-left: 3px !important;
        margin-right: inherit !important;
    }

    .mg-l-3-f {
        margin-right: 3px !important;
        margin-left: inherit !important;
    }

    .mg-r-4 {
        margin-left: 4px;
        margin-right: inherit;
    }

    .mg-l-4 {
        margin-right: 4px;
        margin-left: inherit;
    }

    .mg-r-4-f {
        margin-left: 4px !important;
        margin-right: inherit !important;
    }

    .mg-l-4-f {
        margin-right: 4px !important;
        margin-left: inherit !important;
    }

    .mg-r-5 {
        margin-left: 5px;
        margin-right: inherit;
    }

    .mg-l-5 {
        margin-right: 5px;
        margin-left: inherit;
    }

    .mg-r-5-f {
        margin-left: 5px !important;
        margin-right: inherit !important;
    }

    .mg-l-5-f {
        margin-right: 5px !important;
        margin-left: inherit !important;
    }

    .mg-r-6 {
        margin-left: 6px;
        margin-right: inherit;
    }

    .mg-l-6 {
        margin-right: 6px;
        margin-left: inherit;
    }

    .mg-r-6-f {
        margin-left: 6px !important;
        margin-right: inherit !important;
    }

    .mg-l-6-f {
        margin-right: 6px !important;
        margin-left: inherit !important;
    }

    .mg-r-7 {
        margin-left: 7px;
        margin-right: inherit;
    }

    .mg-l-7 {
        margin-right: 7px;
        margin-left: inherit;
    }

    .mg-r-7-f {
        margin-left: 7px !important;
        margin-right: inherit !important;
    }

    .mg-l-7-f {
        margin-right: 7px !important;
        margin-left: inherit !important;
    }

    .mg-r-8 {
        margin-left: 8px;
        margin-right: inherit;
    }

    .mg-l-8 {
        margin-right: 8px;
        margin-left: inherit;
    }

    .mg-r-8-f {
        margin-left: 8px !important;
        margin-right: inherit !important;
    }

    .mg-l-8-f {
        margin-right: 8px !important;
        margin-left: inherit !important;
    }

    .mg-r-9 {
        margin-left: 9px;
        margin-right: inherit;
    }

    .mg-l-9 {
        margin-right: 9px;
        margin-left: inherit;
    }

    .mg-r-9-f {
        margin-left: 9px !important;
        margin-right: inherit !important;
    }

    .mg-l-9-f {
        margin-right: 9px !important;
        margin-left: inherit !important;
    }

    .mg-r-10 {
        margin-left: 10px;
        margin-right: inherit;
    }

    .mg-l-10 {
        margin-right: 10px;
        margin-left: inherit;
    }

    .mg-r-10-f {
        margin-left: 10px !important;
        margin-right: inherit !important;
    }

    .mg-l-10-f {
        margin-right: 10px !important;
        margin-left: inherit !important;
    }

    .mg-r-15 {
        margin-left: 15px;
        margin-right: inherit;
    }

    .mg-l-15 {
        margin-right: 15px;
        margin-left: inherit;
    }

    .mg-r-15-f {
        margin-left: 15px !important;
        margin-right: inherit !important;
    }

    .mg-l-15-f {
        margin-right: 15px !important;
        margin-left: inherit !important;
    }

    .mg-r-20 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .mg-l-20 {
        margin-right: 20px;
        margin-left: inherit;
    }

    .mg-r-20-f {
        margin-left: 20px !important;
        margin-right: inherit !important;
    }

    .mg-l-20-f {
        margin-right: 20px !important;
        margin-left: inherit !important;
    }

    .mg-r-25 {
        margin-left: 25px;
        margin-right: inherit;
    }

    .mg-l-25 {
        margin-right: 25px;
        margin-left: inherit;
    }

    .mg-r-25-f {
        margin-left: 25px !important;
        margin-right: inherit !important;
    }

    .mg-l-25-f {
        margin-right: 25px !important;
        margin-left: inherit !important;
    }

    .mg-r-30 {
        margin-left: 30px;
        margin-right: inherit;
    }

    .mg-l-30 {
        margin-right: 30px;
        margin-left: inherit;
    }

    .mg-r-30-f {
        margin-left: 30px !important;
        margin-right: inherit !important;
    }

    .mg-l-30-f {
        margin-right: 30px !important;
        margin-left: inherit !important;
    }

    .mg-r-35 {
        margin-left: 35px;
        margin-right: inherit;
    }

    .mg-l-35 {
        margin-right: 35px;
        margin-left: inherit;
    }

    .mg-r-35-f {
        margin-left: 35px !important;
        margin-right: inherit !important;
    }

    .mg-l-35-f {
        margin-right: 35px !important;
        margin-left: inherit !important;
    }

    .mg-r-40 {
        margin-left: 40px;
        margin-right: inherit;
    }

    .mg-l-40 {
        margin-right: 40px;
        margin-left: inherit;
    }

    .mg-r-40-f {
        margin-left: 40px !important;
        margin-right: inherit !important;
    }

    .mg-l-40-f {
        margin-right: 40px !important;
        margin-left: inherit !important;
    }

    .mg-r-45 {
        margin-left: 45px;
        margin-right: inherit;
    }

    .mg-l-45 {
        margin-right: 45px;
        margin-left: inherit;
    }

    .mg-r-45-f {
        margin-left: 45px !important;
        margin-right: inherit !important;
    }

    .mg-l-45-f {
        margin-right: 45px !important;
        margin-left: inherit !important;
    }

    .mg-r-50 {
        margin-left: 50px;
        margin-right: inherit;
    }

    .mg-l-50 {
        margin-right: 50px;
        margin-left: inherit;
    }

    .mg-r-50-f {
        margin-left: 50px !important;
        margin-right: inherit !important;
    }

    .mg-l-50-f {
        margin-right: 50px !important;
        margin-left: inherit !important;
    }

    .mg-r-55 {
        margin-left: 55px;
        margin-right: inherit;
    }

    .mg-l-55 {
        margin-right: 55px;
        margin-left: inherit;
    }

    .mg-r-55-f {
        margin-left: 55px !important;
        margin-right: inherit !important;
    }

    .mg-l-55-f {
        margin-right: 55px !important;
        margin-left: inherit !important;
    }

    .mg-r-60 {
        margin-left: 60px;
        margin-right: inherit;
    }

    .mg-l-60 {
        margin-right: 60px;
        margin-left: inherit;
    }

    .mg-r-60-f {
        margin-left: 60px !important;
        margin-right: inherit !important;
    }

    .mg-l-60-f {
        margin-right: 60px !important;
        margin-left: inherit !important;
    }

    .mg-r-65 {
        margin-left: 65px;
        margin-right: inherit;
    }

    .mg-l-65 {
        margin-right: 65px;
        margin-left: inherit;
    }

    .mg-r-65-f {
        margin-left: 65px !important;
        margin-right: inherit !important;
    }

    .mg-l-65-f {
        margin-right: 65px !important;
        margin-left: inherit !important;
    }

    .mg-r-70 {
        margin-left: 70px;
        margin-right: inherit;
    }

    .mg-l-70 {
        margin-right: 70px;
        margin-left: inherit;
    }

    .mg-r-70-f {
        margin-left: 70px !important;
        margin-right: inherit !important;
    }

    .mg-l-70-f {
        margin-right: 70px !important;
        margin-left: inherit !important;
    }

    .mg-r-75 {
        margin-left: 75px;
        margin-right: inherit;
    }

    .mg-l-75 {
        margin-right: 75px;
        margin-left: inherit;
    }

    .mg-r-75-f {
        margin-left: 75px !important;
        margin-right: inherit !important;
    }

    .mg-l-75-f {
        margin-right: 75px !important;
        margin-left: inherit !important;
    }

    .mg-r-80 {
        margin-left: 80px;
        margin-right: inherit;
    }

    .mg-l-80 {
        margin-right: 80px;
        margin-left: inherit;
    }

    .mg-r-80-f {
        margin-left: 80px !important;
        margin-right: inherit !important;
    }

    .mg-l-80-f {
        margin-right: 80px !important;
        margin-left: inherit !important;
    }

    .mg-r-85 {
        margin-left: 85px;
        margin-right: inherit;
    }

    .mg-l-85 {
        margin-right: 85px;
        margin-left: inherit;
    }

    .mg-r-85-f {
        margin-left: 85px !important;
        margin-right: inherit !important;
    }

    .mg-l-85-f {
        margin-right: 85px !important;
        margin-left: inherit !important;
    }

    .mg-r-90 {
        margin-left: 90px;
        margin-right: inherit;
    }

    .mg-l-90 {
        margin-right: 90px;
        margin-left: inherit;
    }

    .mg-r-90-f {
        margin-left: 90px !important;
        margin-right: inherit !important;
    }

    .mg-l-90-f {
        margin-right: 90px !important;
        margin-left: inherit !important;
    }

    .mg-r-95 {
        margin-left: 95px;
        margin-right: inherit;
    }

    .mg-l-95 {
        margin-right: 95px;
        margin-left: inherit;
    }

    .mg-r-95-f {
        margin-left: 95px !important;
        margin-right: inherit !important;
    }

    .mg-l-95-f {
        margin-right: 95px !important;
        margin-left: inherit !important;
    }

    .mg-r-100 {
        margin-left: 100px;
        margin-right: inherit;
    }

    .mg-l-100 {
        margin-right: 100px;
        margin-left: inherit;
    }

    .mg-r-100-f {
        margin-left: 100px !important;
        margin-right: inherit !important;
    }

    .mg-l-100-f {
        margin-right: 100px !important;
        margin-left: inherit !important;
    }

    .mg-r-105 {
        margin-left: 105px;
        margin-right: inherit;
    }

    .mg-l-105 {
        margin-right: 105px;
        margin-left: inherit;
    }

    .mg-r-105-f {
        margin-left: 105px !important;
        margin-right: inherit !important;
    }

    .mg-l-105-f {
        margin-right: 105px !important;
        margin-left: inherit !important;
    }

    .mg-r-110 {
        margin-left: 110px;
        margin-right: inherit;
    }

    .mg-l-110 {
        margin-right: 110px;
        margin-left: inherit;
    }

    .mg-r-110-f {
        margin-left: 110px !important;
        margin-right: inherit !important;
    }

    .mg-l-110-f {
        margin-right: 110px !important;
        margin-left: inherit !important;
    }

    .mg-r-115 {
        margin-left: 115px;
        margin-right: inherit;
    }

    .mg-l-115 {
        margin-right: 115px;
        margin-left: inherit;
    }

    .mg-r-115-f {
        margin-left: 115px !important;
        margin-right: inherit !important;
    }

    .mg-l-115-f {
        margin-right: 115px !important;
        margin-left: inherit !important;
    }

    .mg-r-120 {
        margin-left: 120px;
        margin-right: inherit;
    }

    .mg-l-120 {
        margin-right: 120px;
        margin-left: inherit;
    }

    .mg-r-120-f {
        margin-left: 120px !important;
        margin-right: inherit !important;
    }

    .mg-l-120-f {
        margin-right: 120px !important;
        margin-left: inherit !important;
    }

    .mg-r-auto {
        margin-left: auto;
        margin-right: inherit;
    }

    .mg-l-auto {
        margin-right: auto;
        margin-left: inherit;
    }

    @media (min-width: 480px) {
        .mg-xs-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-xs-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-xs-r-0-f {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-0-f {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-xs-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-xs-r-1-f {
            margin-left: 1px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-1-f {
            margin-right: 1px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-xs-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-xs-r-2-f {
            margin-left: 2px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-2-f {
            margin-right: 2px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-xs-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-xs-r-3-f {
            margin-left: 3px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-3-f {
            margin-right: 3px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-xs-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-xs-r-4-f {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-4-f {
            margin-right: 4px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-xs-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-xs-r-5-f {
            margin-left: 5px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-5-f {
            margin-right: 5px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-xs-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-xs-r-6-f {
            margin-left: 6px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-6-f {
            margin-right: 6px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-xs-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-xs-r-7-f {
            margin-left: 7px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-7-f {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-xs-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-xs-r-8-f {
            margin-left: 8px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-8-f {
            margin-right: 8px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-xs-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-xs-r-9-f {
            margin-left: 9px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-9-f {
            margin-right: 9px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-xs-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-xs-r-10-f {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-10-f {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-xs-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-xs-r-15-f {
            margin-left: 15px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-15-f {
            margin-right: 15px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-xs-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-xs-r-20-f {
            margin-left: 20px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-20-f {
            margin-right: 20px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-xs-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-xs-r-25-f {
            margin-left: 25px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-25-f {
            margin-right: 25px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-xs-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-xs-r-30-f {
            margin-left: 30px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-30-f {
            margin-right: 30px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-xs-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-xs-r-35-f {
            margin-left: 35px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-35-f {
            margin-right: 35px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-xs-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-xs-r-40-f {
            margin-left: 40px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-40-f {
            margin-right: 40px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-xs-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-xs-r-45-f {
            margin-left: 45px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-45-f {
            margin-right: 45px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-xs-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-xs-r-50-f {
            margin-left: 50px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-50-f {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-xs-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-xs-r-55-f {
            margin-left: 55px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-55-f {
            margin-right: 55px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-xs-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-xs-r-60-f {
            margin-left: 60px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-60-f {
            margin-right: 60px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-xs-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-xs-r-65-f {
            margin-left: 65px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-65-f {
            margin-right: 65px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-xs-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-xs-r-70-f {
            margin-left: 70px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-70-f {
            margin-right: 70px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-xs-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-xs-r-75-f {
            margin-left: 75px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-75-f {
            margin-right: 75px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-xs-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-xs-r-80-f {
            margin-left: 80px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-80-f {
            margin-right: 80px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-xs-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-xs-r-85-f {
            margin-left: 85px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-85-f {
            margin-right: 85px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-xs-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-xs-r-90-f {
            margin-left: 90px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-90-f {
            margin-right: 90px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-xs-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-xs-r-95-f {
            margin-left: 95px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-95-f {
            margin-right: 95px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-xs-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-xs-r-100-f {
            margin-left: 100px !important;
            margin-right: inherit !important;
        }

        .mg-xs-l-100-f {
            margin-right: 100px !important;
            margin-left: inherit !important;
        }

        .mg-xs-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-xs-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .mg-sm-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-sm-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-sm-r-0-f {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-0-f {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-sm-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-sm-r-1-f {
            margin-left: 1px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-1-f {
            margin-right: 1px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-sm-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-sm-r-2-f {
            margin-left: 2px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-2-f {
            margin-right: 2px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-sm-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-sm-r-3-f {
            margin-left: 3px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-3-f {
            margin-right: 3px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-sm-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-sm-r-4-f {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-4-f {
            margin-right: 4px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-sm-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-sm-r-5-f {
            margin-left: 5px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-5-f {
            margin-right: 5px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-sm-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-sm-r-6-f {
            margin-left: 6px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-6-f {
            margin-right: 6px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-sm-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-sm-r-7-f {
            margin-left: 7px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-7-f {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-sm-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-sm-r-8-f {
            margin-left: 8px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-8-f {
            margin-right: 8px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-sm-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-sm-r-9-f {
            margin-left: 9px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-9-f {
            margin-right: 9px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-sm-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-sm-r-10-f {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-10-f {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-sm-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-sm-r-15-f {
            margin-left: 15px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-15-f {
            margin-right: 15px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-sm-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-sm-r-20-f {
            margin-left: 20px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-20-f {
            margin-right: 20px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-sm-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-sm-r-25-f {
            margin-left: 25px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-25-f {
            margin-right: 25px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-sm-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-sm-r-30-f {
            margin-left: 30px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-30-f {
            margin-right: 30px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-sm-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-sm-r-35-f {
            margin-left: 35px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-35-f {
            margin-right: 35px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-sm-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-sm-r-40-f {
            margin-left: 40px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-40-f {
            margin-right: 40px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-sm-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-sm-r-45-f {
            margin-left: 45px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-45-f {
            margin-right: 45px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-sm-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-sm-r-50-f {
            margin-left: 50px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-50-f {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-sm-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-sm-r-55-f {
            margin-left: 55px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-55-f {
            margin-right: 55px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-sm-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-sm-r-60-f {
            margin-left: 60px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-60-f {
            margin-right: 60px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-sm-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-sm-r-65-f {
            margin-left: 65px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-65-f {
            margin-right: 65px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-sm-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-sm-r-70-f {
            margin-left: 70px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-70-f {
            margin-right: 70px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-sm-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-sm-r-75-f {
            margin-left: 75px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-75-f {
            margin-right: 75px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-sm-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-sm-r-80-f {
            margin-left: 80px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-80-f {
            margin-right: 80px !important;
            margin-left: inherit !important;
        }

        .mg-sm-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-sm-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-sm-r-85-f {
            margin-left: 85px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-85-f {
            margin-right: 85px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-sm-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-sm-r-90-f {
            margin-left: 90px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-90-f {
            margin-right: 90px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-sm-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-sm-r-95-f {
            margin-left: 95px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-95-f {
            margin-right: 95px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-sm-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-sm-r-100-f {
            margin-left: 100px !important;
            margin-right: inherit !important;
        }

        .mg-sm-l-100-f {
            margin-right: 100px !important;
            margin-left: inherit !important;
        }

        .mg-sm-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-sm-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .mg-md-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-md-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-md-r-0-f {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-0-f {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-md-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-md-r-1-f {
            margin-left: 1px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-1-f {
            margin-right: 1px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-md-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-md-r-2-f {
            margin-left: 2px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-2-f {
            margin-right: 2px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-md-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-md-r-3-f {
            margin-left: 3px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-3-f {
            margin-right: 3px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-md-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-md-r-4-f {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-4-f {
            margin-right: 4px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-md-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-md-r-5-f {
            margin-left: 5px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-5-f {
            margin-right: 5px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-md-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-md-r-6-f {
            margin-left: 6px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-6-f {
            margin-right: 6px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-md-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-md-r-7-f {
            margin-left: 7px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-7-f {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-md-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-md-r-8-f {
            margin-left: 8px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-8-f {
            margin-right: 8px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-md-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-md-r-9-f {
            margin-left: 9px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-9-f {
            margin-right: 9px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-md-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-md-r-10-f {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-10-f {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-md-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-md-r-15-f {
            margin-left: 15px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-15-f {
            margin-right: 15px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-md-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-md-r-20-f {
            margin-left: 20px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-20-f {
            margin-right: 20px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-md-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-md-r-25-f {
            margin-left: 25px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-25-f {
            margin-right: 25px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-md-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-md-r-30-f {
            margin-left: 30px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-30-f {
            margin-right: 30px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-md-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-md-r-35-f {
            margin-left: 35px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-35-f {
            margin-right: 35px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-md-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-md-r-40-f {
            margin-left: 40px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-40-f {
            margin-right: 40px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-md-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-md-r-45-f {
            margin-left: 45px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-45-f {
            margin-right: 45px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-md-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-md-r-50-f {
            margin-left: 50px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-50-f {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-md-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-md-r-55-f {
            margin-left: 55px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-55-f {
            margin-right: 55px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-md-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-md-r-60-f {
            margin-left: 60px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-60-f {
            margin-right: 60px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-md-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-md-r-65-f {
            margin-left: 65px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-65-f {
            margin-right: 65px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-md-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-md-r-70-f {
            margin-left: 70px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-70-f {
            margin-right: 70px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-md-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-md-r-75-f {
            margin-left: 75px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-75-f {
            margin-right: 75px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-md-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-md-r-80-f {
            margin-left: 80px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-80-f {
            margin-right: 80px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-md-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-md-r-85-f {
            margin-left: 85px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-85-f {
            margin-right: 85px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-md-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-md-r-90-f {
            margin-left: 90px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-90-f {
            margin-right: 90px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-md-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-md-r-95-f {
            margin-left: 95px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-95-f {
            margin-right: 95px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-md-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-md-r-100-f {
            margin-left: 100px !important;
            margin-right: inherit !important;
        }

        .mg-md-l-100-f {
            margin-right: 100px !important;
            margin-left: inherit !important;
        }

        .mg-md-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-md-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .mg-lg-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-lg-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-lg-r-0-f {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-0-f {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-lg-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-lg-r-1-f {
            margin-left: 1px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-1-f {
            margin-right: 1px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-lg-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-lg-r-2-f {
            margin-left: 2px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-2-f {
            margin-right: 2px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-lg-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-lg-r-3-f {
            margin-left: 3px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-3-f {
            margin-right: 3px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-lg-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-lg-r-4-f {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-4-f {
            margin-right: 4px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-lg-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-lg-r-5-f {
            margin-left: 5px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-5-f {
            margin-right: 5px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-lg-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-lg-r-6-f {
            margin-left: 6px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-6-f {
            margin-right: 6px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-lg-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-lg-r-7-f {
            margin-left: 7px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-7-f {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-lg-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-lg-r-8-f {
            margin-left: 8px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-8-f {
            margin-right: 8px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-lg-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-lg-r-9-f {
            margin-left: 9px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-9-f {
            margin-right: 9px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-lg-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-lg-r-10-f {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-10-f {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-lg-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-lg-r-15-f {
            margin-left: 15px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-15-f {
            margin-right: 15px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-lg-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-lg-r-20-f {
            margin-left: 20px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-20-f {
            margin-right: 20px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-lg-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-lg-r-25-f {
            margin-left: 25px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-25-f {
            margin-right: 25px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-lg-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-lg-r-30-f {
            margin-left: 30px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-30-f {
            margin-right: 30px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-lg-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-lg-r-35-f {
            margin-left: 35px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-35-f {
            margin-right: 35px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-lg-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-lg-r-40-f {
            margin-left: 40px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-40-f {
            margin-right: 40px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-lg-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-lg-r-45-f {
            margin-left: 45px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-45-f {
            margin-right: 45px !important;
            margin-left: inherit !important;
        }

        .mg-lg-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-lg-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-lg-r-50-f {
            margin-left: 50px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-50-f {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-lg-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-lg-r-55-f {
            margin-left: 55px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-55-f {
            margin-right: 55px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-lg-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-lg-r-60-f {
            margin-left: 60px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-60-f {
            margin-right: 60px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-lg-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-lg-r-65-f {
            margin-left: 65px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-65-f {
            margin-right: 65px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-lg-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-lg-r-70-f {
            margin-left: 70px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-70-f {
            margin-right: 70px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-lg-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-lg-r-75-f {
            margin-left: 75px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-75-f {
            margin-right: 75px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-lg-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-lg-r-80-f {
            margin-left: 80px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-80-f {
            margin-right: 80px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-lg-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-lg-r-85-f {
            margin-left: 85px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-85-f {
            margin-right: 85px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-lg-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-lg-r-90-f {
            margin-left: 90px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-90-f {
            margin-right: 90px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-lg-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-lg-r-95-f {
            margin-left: 95px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-95-f {
            margin-right: 95px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-lg-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-lg-r-100-f {
            margin-left: 100px !important;
            margin-right: inherit !important;
        }

        .mg-lg-l-100-f {
            margin-right: 100px !important;
            margin-left: inherit !important;
        }

        .mg-lg-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-lg-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .mg-xl-r-0 {
            margin-left: 0px;
            margin-right: inherit;
        }

        .mg-xl-l-0 {
            margin-right: 0px;
            margin-left: inherit;
        }

        .mg-xl-r-0-f {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-0-f {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-1 {
            margin-left: 1px;
            margin-right: inherit;
        }

        .mg-xl-l-1 {
            margin-right: 1px;
            margin-left: inherit;
        }

        .mg-xl-r-1-f {
            margin-left: 1px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-1-f {
            margin-right: 1px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-2 {
            margin-left: 2px;
            margin-right: inherit;
        }

        .mg-xl-l-2 {
            margin-right: 2px;
            margin-left: inherit;
        }

        .mg-xl-r-2-f {
            margin-left: 2px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-2-f {
            margin-right: 2px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-3 {
            margin-left: 3px;
            margin-right: inherit;
        }

        .mg-xl-l-3 {
            margin-right: 3px;
            margin-left: inherit;
        }

        .mg-xl-r-3-f {
            margin-left: 3px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-3-f {
            margin-right: 3px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-4 {
            margin-left: 4px;
            margin-right: inherit;
        }

        .mg-xl-l-4 {
            margin-right: 4px;
            margin-left: inherit;
        }

        .mg-xl-r-4-f {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-4-f {
            margin-right: 4px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-5 {
            margin-left: 5px;
            margin-right: inherit;
        }

        .mg-xl-l-5 {
            margin-right: 5px;
            margin-left: inherit;
        }

        .mg-xl-r-5-f {
            margin-left: 5px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-5-f {
            margin-right: 5px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-6 {
            margin-left: 6px;
            margin-right: inherit;
        }

        .mg-xl-l-6 {
            margin-right: 6px;
            margin-left: inherit;
        }

        .mg-xl-r-6-f {
            margin-left: 6px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-6-f {
            margin-right: 6px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-7 {
            margin-left: 7px;
            margin-right: inherit;
        }

        .mg-xl-l-7 {
            margin-right: 7px;
            margin-left: inherit;
        }

        .mg-xl-r-7-f {
            margin-left: 7px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-7-f {
            margin-right: 7px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-8 {
            margin-left: 8px;
            margin-right: inherit;
        }

        .mg-xl-l-8 {
            margin-right: 8px;
            margin-left: inherit;
        }

        .mg-xl-r-8-f {
            margin-left: 8px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-8-f {
            margin-right: 8px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-9 {
            margin-left: 9px;
            margin-right: inherit;
        }

        .mg-xl-l-9 {
            margin-right: 9px;
            margin-left: inherit;
        }

        .mg-xl-r-9-f {
            margin-left: 9px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-9-f {
            margin-right: 9px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-10 {
            margin-left: 10px;
            margin-right: inherit;
        }

        .mg-xl-l-10 {
            margin-right: 10px;
            margin-left: inherit;
        }

        .mg-xl-r-10-f {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-10-f {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-15 {
            margin-left: 15px;
            margin-right: inherit;
        }

        .mg-xl-l-15 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .mg-xl-r-15-f {
            margin-left: 15px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-15-f {
            margin-right: 15px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-20 {
            margin-left: 20px;
            margin-right: inherit;
        }

        .mg-xl-l-20 {
            margin-right: 20px;
            margin-left: inherit;
        }

        .mg-xl-r-20-f {
            margin-left: 20px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-20-f {
            margin-right: 20px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-25 {
            margin-left: 25px;
            margin-right: inherit;
        }

        .mg-xl-l-25 {
            margin-right: 25px;
            margin-left: inherit;
        }

        .mg-xl-r-25-f {
            margin-left: 25px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-25-f {
            margin-right: 25px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-30 {
            margin-left: 30px;
            margin-right: inherit;
        }

        .mg-xl-l-30 {
            margin-right: 30px;
            margin-left: inherit;
        }

        .mg-xl-r-30-f {
            margin-left: 30px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-30-f {
            margin-right: 30px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-35 {
            margin-left: 35px;
            margin-right: inherit;
        }

        .mg-xl-l-35 {
            margin-right: 35px;
            margin-left: inherit;
        }

        .mg-xl-r-35-f {
            margin-left: 35px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-35-f {
            margin-right: 35px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-40 {
            margin-left: 40px;
            margin-right: inherit;
        }

        .mg-xl-l-40 {
            margin-right: 40px;
            margin-left: inherit;
        }

        .mg-xl-r-40-f {
            margin-left: 40px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-40-f {
            margin-right: 40px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-45 {
            margin-left: 45px;
            margin-right: inherit;
        }

        .mg-xl-l-45 {
            margin-right: 45px;
            margin-left: inherit;
        }

        .mg-xl-r-45-f {
            margin-left: 45px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-45-f {
            margin-right: 45px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-50 {
            margin-left: 50px;
            margin-right: inherit;
        }

        .mg-xl-l-50 {
            margin-right: 50px;
            margin-left: inherit;
        }

        .mg-xl-r-50-f {
            margin-left: 50px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-50-f {
            margin-right: 50px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-55 {
            margin-left: 55px;
            margin-right: inherit;
        }

        .mg-xl-l-55 {
            margin-right: 55px;
            margin-left: inherit;
        }

        .mg-xl-r-55-f {
            margin-left: 55px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-55-f {
            margin-right: 55px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-60 {
            margin-left: 60px;
            margin-right: inherit;
        }

        .mg-xl-l-60 {
            margin-right: 60px;
            margin-left: inherit;
        }

        .mg-xl-r-60-f {
            margin-left: 60px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-60-f {
            margin-right: 60px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-65 {
            margin-left: 65px;
            margin-right: inherit;
        }

        .mg-xl-l-65 {
            margin-right: 65px;
            margin-left: inherit;
        }

        .mg-xl-r-65-f {
            margin-left: 65px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-65-f {
            margin-right: 65px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-70 {
            margin-left: 70px;
            margin-right: inherit;
        }

        .mg-xl-l-70 {
            margin-right: 70px;
            margin-left: inherit;
        }

        .mg-xl-r-70-f {
            margin-left: 70px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-70-f {
            margin-right: 70px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-75 {
            margin-left: 75px;
            margin-right: inherit;
        }

        .mg-xl-l-75 {
            margin-right: 75px;
            margin-left: inherit;
        }

        .mg-xl-r-75-f {
            margin-left: 75px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-75-f {
            margin-right: 75px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-80 {
            margin-left: 80px;
            margin-right: inherit;
        }

        .mg-xl-l-80 {
            margin-right: 80px;
            margin-left: inherit;
        }

        .mg-xl-r-80-f {
            margin-left: 80px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-80-f {
            margin-right: 80px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-85 {
            margin-left: 85px;
            margin-right: inherit;
        }

        .mg-xl-l-85 {
            margin-right: 85px;
            margin-left: inherit;
        }

        .mg-xl-r-85-f {
            margin-left: 85px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-85-f {
            margin-right: 85px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-90 {
            margin-left: 90px;
            margin-right: inherit;
        }

        .mg-xl-l-90 {
            margin-right: 90px;
            margin-left: inherit;
        }

        .mg-xl-r-90-f {
            margin-left: 90px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-90-f {
            margin-right: 90px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-95 {
            margin-left: 95px;
            margin-right: inherit;
        }

        .mg-xl-l-95 {
            margin-right: 95px;
            margin-left: inherit;
        }

        .mg-xl-r-95-f {
            margin-left: 95px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-95-f {
            margin-right: 95px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-100 {
            margin-left: 100px;
            margin-right: inherit;
        }

        .mg-xl-l-100 {
            margin-right: 100px;
            margin-left: inherit;
        }

        .mg-xl-r-100-f {
            margin-left: 100px !important;
            margin-right: inherit !important;
        }

        .mg-xl-l-100-f {
            margin-right: 100px !important;
            margin-left: inherit !important;
        }

        .mg-xl-r-auto {
            margin-left: auto;
            margin-right: inherit;
        }

        .mg-xl-l-auto {
            margin-right: auto;
            margin-left: inherit;
        }
    }

    .me-0 {
        margin-left: 0;
        margin-right: inherit;
    }

    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .me-2 {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: inherit !important;
    }

    .me-3 {
        margin-left: 1rem;
        margin-right: inherit !important;
    }

    .ms-3 {
        margin-right: 1rem !important;
        margin-left: inherit !important;
    }

    .me-4 {
        margin-left: 1.5rem !important;
        margin-right: initial !important;
    }

    .ms-4 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .me-5 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .ms-5 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .me-n1 {
        margin-left: -0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-n1 {
        margin-right: -0.25rem !important;
        margin-left: inherit !important;
    }

    .me-n2 {
        margin-left: -0.5rem !important;
        margin-right: inherit !important;
    }

    .ms-n2 {
        margin-right: -0.5rem !important;
        margin-left: inherit !important;
    }

    .me-n3 {
        margin-left: -1rem !important;
        margin-right: inherit !important;
    }

    .ms-n3 {
        margin-right: -1rem !important;
        margin-left: inherit !important;
    }

    .me-n4 {
        margin-left: -1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-n4 {
        margin-right: -1.5rem !important;
        margin-left: inherit !important;
    }

    .me-n5 {
        margin-left: -3rem !important;
        margin-right: inherit !important;
    }

    .ms-n5 {
        margin-right: -3rem !important;
        margin-left: inherit !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: initial !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-sm-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-sm-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .me-sm-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .me-sm-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-md-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-md-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .me-md-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .me-md-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-lg-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-lg-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .me-lg-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .me-lg-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-3 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-3 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-xl-4 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-4 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-5 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-5 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-xl-n1 {
            margin-left: -0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n1 {
            margin-right: -0.25rem !important;
            margin-left: inherit !important;
        }

        .me-xl-n2 {
            margin-left: -0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n2 {
            margin-right: -0.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-n3 {
            margin-left: -1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n3 {
            margin-right: -1rem !important;
            margin-left: inherit !important;
        }

        .me-xl-n4 {
            margin-left: -1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n4 {
            margin-right: -1.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-n5 {
            margin-left: -3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-n5 {
            margin-right: -3rem !important;
            margin-left: inherit !important;
        }

        .me-xl-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    .pd-r-0 {
        padding-left: 0px;
        padding-right: inherit;
    }

    .pd-l-0 {
        padding-right: 0px;
        padding-left: inherit;
    }

    .pd-r-0-f {
        padding-left: 0px !important;
        padding-right: inherit !important;
    }

    .pd-l-0-f {
        padding-right: 0px !important;
        padding-left: inherit !important;
    }

    .pd-r-1 {
        padding-left: 1px;
        padding-right: inherit;
    }

    .pd-l-1 {
        padding-right: 1px;
        padding-left: inherit;
    }

    .pd-r-1-f {
        padding-left: 1px !important;
        padding-right: inherit !important;
    }

    .pd-l-1-f {
        padding-right: 1px !important;
        padding-left: inherit !important;
    }

    .pd-r-2 {
        padding-left: 2px;
        padding-right: inherit;
    }

    .pd-l-2 {
        padding-right: 2px;
        padding-left: inherit;
    }

    .pd-r-2-f {
        padding-left: 2px !important;
        padding-right: inherit !important;
    }

    .pd-l-2-f {
        padding-right: 2px !important;
        padding-left: inherit !important;
    }

    .pd-r-3 {
        padding-left: 3px;
        padding-right: inherit;
    }

    .pd-l-3 {
        padding-right: 3px;
        padding-left: inherit;
    }

    .pd-r-3-f {
        padding-left: 3px !important;
        padding-right: inherit !important;
    }

    .pd-l-3-f {
        padding-right: 3px !important;
        padding-left: inherit !important;
    }

    .pd-r-4 {
        padding-left: 4px;
        padding-right: inherit;
    }

    .pd-l-4 {
        padding-right: 4px;
        padding-left: inherit;
    }

    .pd-r-4-f {
        padding-left: 4px !important;
        padding-right: inherit !important;
    }

    .pd-l-4-f {
        padding-right: 4px !important;
        padding-left: inherit !important;
    }

    .pd-r-5 {
        padding-left: 5px;
        padding-right: inherit;
    }

    .pd-l-5 {
        padding-right: 5px;
        padding-left: inherit;
    }

    .pd-r-5-f {
        padding-left: 5px !important;
        padding-right: inherit !important;
    }

    .pd-l-5-f {
        padding-right: 5px !important;
        padding-left: inherit !important;
    }

    .pd-r-6 {
        padding-left: 6px;
        padding-right: inherit;
    }

    .pd-l-6 {
        padding-right: 6px;
        padding-left: inherit;
    }

    .pd-r-6-f {
        padding-left: 6px !important;
        padding-right: inherit !important;
    }

    .pd-l-6-f {
        padding-right: 6px !important;
        padding-left: inherit !important;
    }

    .pd-r-7 {
        padding-left: 7px;
        padding-right: inherit;
    }

    .pd-l-7 {
        padding-right: 7px;
        padding-left: inherit;
    }

    .pd-r-7-f {
        padding-left: 7px !important;
        padding-right: inherit !important;
    }

    .pd-l-7-f {
        padding-right: 7px !important;
        padding-left: inherit !important;
    }

    .pd-r-8 {
        padding-left: 8px;
        padding-right: inherit;
    }

    .pd-l-8 {
        padding-right: 8px;
        padding-left: inherit;
    }

    .pd-r-8-f {
        padding-left: 8px !important;
        padding-right: inherit !important;
    }

    .pd-l-8-f {
        padding-right: 8px !important;
        padding-left: inherit !important;
    }

    .pd-r-9 {
        padding-left: 9px;
        padding-right: inherit;
    }

    .pd-l-9 {
        padding-right: 9px;
        padding-left: inherit;
    }

    .pd-r-9-f {
        padding-left: 9px !important;
        padding-right: inherit !important;
    }

    .pd-l-9-f {
        padding-right: 9px !important;
        padding-left: inherit !important;
    }

    .pd-r-10 {
        padding-left: 10px;
        padding-right: inherit;
    }

    .pd-l-10 {
        padding-right: 10px;
        padding-left: inherit;
    }

    .pd-r-10-f {
        padding-left: 10px !important;
        padding-right: inherit !important;
    }

    .pd-l-10-f {
        padding-right: 10px !important;
        padding-left: inherit !important;
    }

    .pd-r-15 {
        padding-left: 15px;
        padding-right: inherit;
    }

    .pd-l-15 {
        padding-right: 15px;
        padding-left: inherit;
    }

    .pd-r-15-f {
        padding-left: 15px !important;
        padding-right: inherit !important;
    }

    .pd-l-15-f {
        padding-right: 15px !important;
        padding-left: inherit !important;
    }

    .pd-r-20 {
        padding-left: 20px;
        padding-right: inherit !important;
    }

    .pd-l-20 {
        padding-right: 20px;
        padding-left: inherit !important;
    }

    .pd-r-20-f {
        padding-left: 20px !important;
        padding-right: inherit !important;
    }

    .pd-l-20-f {
        padding-right: 20px !important;
        padding-left: inherit !important;
    }

    .pd-r-25 {
        padding-left: 25px;
        padding-right: inherit !important;
    }

    .pd-l-25 {
        padding-right: 25px;
        padding-left: inherit !important;
    }

    .pd-r-25-f {
        padding-left: 25px !important;
        padding-right: inherit !important;
    }

    .pd-l-25-f {
        padding-right: 25px !important;
        padding-left: inherit !important;
    }

    .pd-r-30 {
        padding-left: 30px;
        padding-right: inherit !important;
    }

    .pd-l-30 {
        padding-right: 30px;
        padding-left: inherit !important;
    }

    .pd-r-30-f {
        padding-left: 30px !important;
        padding-right: inherit !important;
    }

    .pd-l-30-f {
        padding-right: 30px !important;
        padding-left: inherit !important;
    }

    .pd-r-35 {
        padding-left: 35px;
        padding-right: inherit !important;
    }

    .pd-l-35 {
        padding-right: 35px;
        padding-left: inherit !important;
    }

    .pd-r-35-f {
        padding-left: 35px !important;
        padding-right: inherit !important;
    }

    .pd-l-35-f {
        padding-right: 35px !important;
        padding-left: inherit !important;
    }

    .pd-r-40 {
        padding-left: 40px;
        padding-right: inherit !important;
    }

    .pd-l-40 {
        padding-right: 40px;
        padding-left: inherit !important;
    }

    .pd-r-40-f {
        padding-left: 40px !important;
        padding-right: inherit !important;
    }

    .pd-l-40-f {
        padding-right: 40px !important;
        padding-left: inherit !important;
    }

    .pd-r-45 {
        padding-left: 45px;
        padding-right: inherit !important;
    }

    .pd-l-45 {
        padding-right: 45px;
        padding-left: inherit !important;
    }

    .pd-r-45-f {
        padding-left: 45px !important;
        padding-right: inherit !important;
    }

    .pd-l-45-f {
        padding-right: 45px !important;
        padding-left: inherit !important;
    }

    .pd-r-50 {
        padding-left: 50px;
        padding-right: inherit !important;
    }

    .pd-l-50 {
        padding-right: 50px;
        padding-left: inherit !important;
    }

    .pd-r-50-f {
        padding-left: 50px !important;
        padding-right: inherit !important;
    }

    .pd-l-50-f {
        padding-right: 50px !important;
        padding-left: inherit !important;
    }

    .pd-r-55 {
        padding-left: 55px;
        padding-right: inherit !important;
    }

    .pd-l-55 {
        padding-right: 55px;
        padding-left: inherit !important;
    }

    .pd-r-55-f {
        padding-left: 55px !important;
        padding-right: inherit !important;
    }

    .pd-l-55-f {
        padding-right: 55px !important;
        padding-left: inherit !important;
    }

    .pd-r-60 {
        padding-left: 60px;
        padding-right: inherit !important;
    }

    .pd-l-60 {
        padding-right: 60px;
        padding-left: inherit !important;
    }

    .pd-r-60-f {
        padding-left: 60px !important;
        padding-right: inherit !important;
    }

    .pd-l-60-f {
        padding-right: 60px !important;
        padding-left: inherit !important;
    }

    .pd-r-65 {
        padding-left: 65px;
        padding-right: inherit !important;
    }

    .pd-l-65 {
        padding-right: 65px;
        padding-left: inherit !important;
    }

    .pd-r-65-f {
        padding-left: 65px !important;
        padding-right: inherit !important;
    }

    .pd-l-65-f {
        padding-right: 65px !important;
        padding-left: inherit !important;
    }

    .pd-r-70 {
        padding-left: 70px;
        padding-right: inherit !important;
    }

    .pd-l-70 {
        padding-right: 70px;
        padding-left: inherit !important;
    }

    .pd-r-70-f {
        padding-left: 70px !important;
        padding-right: inherit !important;
    }

    .pd-l-70-f {
        padding-right: 70px !important;
        padding-left: inherit !important;
    }

    .pd-r-75 {
        padding-left: 75px;
        padding-right: inherit !important;
    }

    .pd-l-75 {
        padding-right: 75px;
        padding-left: inherit !important;
    }

    .pd-r-75-f {
        padding-left: 75px !important;
        padding-right: inherit !important;
    }

    .pd-l-75-f {
        padding-right: 75px !important;
        padding-left: inherit !important;
    }

    .pd-r-80 {
        padding-left: 80px;
        padding-right: inherit !important;
    }

    .pd-l-80 {
        padding-right: 80px;
        padding-left: inherit !important;
    }

    .pd-r-80-f {
        padding-left: 80px !important;
        padding-right: inherit !important;
    }

    .pd-l-80-f {
        padding-right: 80px !important;
        padding-left: inherit !important;
    }

    .pd-r-85 {
        padding-left: 85px;
        padding-right: inherit !important;
    }

    .pd-l-85 {
        padding-right: 85px;
        padding-left: inherit !important;
    }

    .pd-r-85-f {
        padding-left: 85px !important;
        padding-right: inherit !important;
    }

    .pd-l-85-f {
        padding-right: 85px !important;
        padding-left: inherit !important;
    }

    .pd-r-90 {
        padding-left: 90px;
        padding-right: inherit !important;
    }

    .pd-l-90 {
        padding-right: 90px;
        padding-left: inherit !important;
    }

    .pd-r-90-f {
        padding-left: 90px !important;
        padding-right: inherit !important;
    }

    .pd-l-90-f {
        padding-right: 90px !important;
        padding-left: inherit !important;
    }

    .pd-r-95 {
        padding-left: 95px;
        padding-right: inherit !important;
    }

    .pd-l-95 {
        padding-right: 95px;
        padding-left: inherit !important;
    }

    .pd-r-95-f {
        padding-left: 95px !important;
        padding-right: inherit !important;
    }

    .pd-l-95-f {
        padding-right: 95px !important;
        padding-left: inherit !important;
    }

    .pd-r-100 {
        padding-left: 100px;
        padding-right: inherit !important;
    }

    .pd-l-100 {
        padding-right: 100px;
        padding-left: inherit !important;
    }

    .pd-r-100-f {
        padding-left: 100px !important;
        padding-right: inherit !important;
    }

    .pd-l-100-f {
        padding-right: 100px !important;
        padding-left: inherit !important;
    }

    .pd-r-110 {
        padding-left: 110px;
        padding-right: inherit !important;
    }

    .pd-l-110 {
        padding-right: 110px;
        padding-left: inherit !important;
    }

    .pd-r-110-f {
        padding-left: 110px !important;
        padding-right: inherit !important;
    }

    .pd-l-110-f {
        padding-right: 110px !important;
        padding-left: inherit !important;
    }

    .pd-r-120 {
        padding-left: 120px;
        padding-right: inherit !important;
    }

    .pd-l-120 {
        padding-right: 120px;
        padding-left: inherit !important;
    }

    .pd-r-120-f {
        padding-left: 120px !important;
        padding-right: inherit !important;
    }

    .pd-l-120-f {
        padding-right: 120px !important;
        padding-left: inherit !important;
    }

    .pd-r-130 {
        padding-left: 130px;
        padding-right: inherit !important;
    }

    .pd-l-130 {
        padding-right: 130px;
        padding-left: inherit !important;
    }

    .pd-r-130-f {
        padding-left: 130px !important;
        padding-right: inherit !important;
    }

    .pd-l-130-f {
        padding-right: 130px !important;
        padding-left: inherit !important;
    }

    .pd-r-140 {
        padding-left: 140px;
        padding-right: inherit !important;
    }

    .pd-l-140 {
        padding-right: 140px;
        padding-left: inherit !important;
    }

    .pd-r-140-f {
        padding-left: 140px !important;
        padding-right: inherit !important;
    }

    .pd-l-140-f {
        padding-right: 140px !important;
        padding-left: inherit !important;
    }

    .pd-r-150 {
        padding-left: 150px;
        padding-right: inherit !important;
    }

    .pd-l-150 {
        padding-right: 150px;
        padding-left: inherit !important;
    }

    .pd-r-150-f {
        padding-left: 150px !important;
        padding-right: inherit !important;
    }

    .pd-l-150-f {
        padding-right: 150px !important;
        padding-left: inherit !important;
    }

    .pd-r-160 {
        padding-left: 160px;
        padding-right: inherit !important;
    }

    .pd-l-160 {
        padding-right: 160px;
        padding-left: inherit !important;
    }

    .pd-r-160-f {
        padding-left: 160px !important;
        padding-right: inherit !important;
    }

    .pd-l-160-f {
        padding-right: 160px !important;
        padding-left: inherit !important;
    }

    .pd-r-170 {
        padding-left: 170px;
        padding-right: inherit;
    }

    .pd-l-170 {
        padding-right: 170px;
        padding-left: inherit;
    }

    .pd-r-170-f {
        padding-left: 170px !important;
        padding-right: inherit !important;
    }

    .pd-l-170-f {
        padding-right: 170px !important;
        padding-left: inherit !important;
    }

    .pd-r-180 {
        padding-left: 180px;
        padding-right: inherit;
    }

    .pd-l-180 {
        padding-right: 180px;
        padding-left: inherit;
    }

    .pd-r-180-f {
        padding-left: 180px !important;
        padding-right: inherit !important;
    }

    .pd-l-180-f {
        padding-right: 180px !important;
        padding-left: inherit !important;
    }

    .pd-r-190 {
        padding-left: 190px;
        padding-right: inherit;
    }

    .pd-l-190 {
        padding-right: 190px;
        padding-left: inherit;
    }

    .pd-r-190-f {
        padding-left: 190px !important;
        padding-right: inherit !important;
    }

    .pd-l-190-f {
        padding-right: 190px !important;
        padding-left: inherit !important;
    }

    .pd-r-200 {
        padding-left: 200px;
        padding-right: inherit;
    }

    .pd-l-200 {
        padding-right: 200px;
        padding-left: inherit;
    }

    .pd-r-200-f {
        padding-left: 200px !important;
        padding-right: inherit !important;
    }

    .pd-l-200-f {
        padding-right: 200px !important;
        padding-left: inherit !important;
    }

    .pd-r-12 {
        padding-left: 12px;
        padding-right: inherit;
    }

    .pd-l-12 {
        padding-right: 12px;
        padding-left: inherit;
    }

    .pd-r-12-f {
        padding-left: 12px !important;
        padding-right: inherit !important;
    }

    .pd-l-12-f {
        padding-right: 12px !important;
        padding-left: inherit !important;
    }

    .pd-r-13 {
        padding-left: 13px;
        padding-right: inherit;
    }

    .pd-l-13 {
        padding-right: 13px;
        padding-left: inherit;
    }

    .pd-r-13-f {
        padding-left: 13px !important;
        padding-right: inherit !important;
    }

    .pd-l-13-f {
        padding-right: 13px !important;
        padding-left: inherit !important;
    }

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .pe-1 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .pe-3 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }

    .ps-3 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }

    .pe-4 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }

    .ps-4 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }

    .pe-5 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }

    .ps-5 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }

    @media (min-width: 576px) {
        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-md-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-md-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-md-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 1200px) {
        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-3 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-3 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-4 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-4 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-5 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-5 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
    }

    .r-0 {
        left: 0px;
        right: inherit;
    }

    .l-0 {
        right: 0px;
        left: inherit;
    }

    .r--0 {
        left: 0px;
        right: inherit;
    }

    .l--0 {
        right: 0px;
        left: inherit;
    }

    .r-5 {
        left: 5px;
        right: inherit;
    }

    .l-5 {
        right: 5px;
        left: inherit;
    }

    .r--5 {
        left: -5px;
        right: inherit;
    }

    .l--5 {
        right: -5px;
        left: inherit;
    }

    .r-10 {
        left: 10px;
        right: inherit;
    }

    .l-10 {
        right: 10px;
        left: inherit;
    }

    .r--10 {
        left: -10px;
        right: inherit;
    }

    .l--10 {
        right: -10px;
        left: inherit;
    }

    .r-15 {
        left: 15px;
        right: inherit;
    }

    .l-15 {
        right: 15px;
        left: inherit;
    }

    .r--15 {
        left: -15px;
        right: inherit;
    }

    .l--15 {
        right: -15px;
        left: inherit;
    }

    .r-20 {
        left: 20px;
        right: inherit;
    }

    .l-20 {
        right: 20px;
        left: inherit;
    }

    .r--20 {
        left: -20px;
        right: inherit;
    }

    .l--20 {
        right: -20px;
        left: inherit;
    }

    .r-25 {
        left: 25px;
        right: inherit;
    }

    .l-25 {
        right: 25px;
        left: inherit;
    }

    .l--25 {
        right: -25px;
        left: inherit;
    }

    .r-30 {
        left: 30px;
        right: inherit;
    }

    .l-30 {
        right: 30px;
        left: inherit;
    }

    .r--30 {
        left: -30px;
        right: inherit;
    }

    .l--30 {
        right: -30px;
        left: inherit;
    }

    .r-35 {
        left: 35px;
        right: inherit;
    }

    .l-35 {
        right: 35px;
        left: inherit;
    }

    .r--35 {
        left: -35px;
        right: inherit;
    }

    .l--35 {
        right: -35px;
        left: inherit;
    }

    .r-40 {
        left: 40px;
        right: inherit;
    }

    .l-40 {
        right: 40px;
        left: inherit;
    }

    .r--40 {
        left: -40px;
        right: inherit;
    }

    .l--40 {
        right: -40px;
        left: inherit;
    }

    .r-45 {
        left: 45px;
        right: inherit;
    }

    .l-45 {
        right: 45px;
        left: inherit;
    }

    .r--45 {
        left: -45px;
        right: inherit;
    }

    .l--45 {
        right: -45px;
        left: inherit;
    }

    .r-50 {
        left: 50px;
        right: inherit;
    }

    .l-50 {
        right: 50px;
        left: inherit;
    }

    .r--50 {
        left: -50px;
        right: inherit;
    }

    .l--50 {
        right: -50px;
        left: inherit;
    }

    .r-55 {
        left: 55px;
        right: inherit;
    }

    .l-55 {
        right: 55px;
        left: inherit;
    }

    .r--55 {
        left: -55px;
        right: inherit;
    }

    .l--55 {
        right: -55px;
        left: inherit;
    }

    .r-60 {
        left: 60px;
        right: inherit;
    }

    .l-60 {
        right: 60px;
        left: inherit;
    }

    .r--60 {
        left: -60px;
        right: inherit;
    }

    .l--60 {
        right: -60px;
        left: inherit;
    }

    .r-65 {
        left: 65px;
        right: inherit;
    }

    .l-65 {
        right: 65px;
        left: inherit;
    }

    .r--65 {
        left: -65px;
        right: inherit;
    }

    .l--65 {
        right: -65px;
        left: inherit;
    }

    .r-70 {
        left: 70px !important;
        right: inherit !important;
    }

    .l-70 {
        right: 70px;
        left: inherit;
    }

    .r--70 {
        left: -70px;
        right: inherit;
    }

    .l--70 {
        right: -70px;
        left: inherit;
    }

    .r-75 {
        left: 75px;
        right: inherit;
    }

    .l-75 {
        right: 75px;
        left: inherit;
    }

    .r--75 {
        left: -75px;
        right: inherit;
    }

    .l--75 {
        right: -75px;
        left: inherit;
    }

    .r-80 {
        left: 80px;
        right: inherit;
    }

    .l-80 {
        right: 80px;
        left: inherit;
    }

    .r--80 {
        left: -80px;
        right: inherit;
    }

    .l--80 {
        right: -80px;
        left: inherit;
    }

    .r-85 {
        left: 85px;
        right: inherit;
    }

    .l-85 {
        right: 85px;
        left: inherit;
    }

    .r--85 {
        left: -85px;
        right: inherit;
    }

    .l--85 {
        right: -85px;
        left: inherit;
    }

    .r-90 {
        left: 90px;
        right: inherit;
    }

    .l-90 {
        right: 90px;
        left: inherit;
    }

    .r--90 {
        left: -90px;
        right: inherit;
    }

    .l--90 {
        right: -90px;
        left: inherit;
    }

    .r-95 {
        left: 95px;
        right: inherit;
    }

    .l-95 {
        right: 95px;
        left: inherit;
    }

    .r--95 {
        left: -95px;
        right: inherit;
    }

    .l--95 {
        right: -95px;
        left: inherit;
    }

    .r-100 {
        left: 100px;
        right: inherit;
    }

    .l-100 {
        right: 100px;
        left: inherit;
    }

    .r--100 {
        left: -100px;
        right: inherit;
    }

    .l--100 {
        right: -100px;
        left: inherit;
    }

    .r--5 {
        left: -5px;
        right: inherit;
    }

    @media (min-width: 480px) {
        .r-xs-auto {
            left: auto;
            right: inherit;
        }

        .l-xs-auto {
            right: auto;
            left: inherit;
        }
    }

    @media (min-width: 576px) {
        .r-sm-auto {
            left: auto;
            right: inherit;
        }

        .l-sm-auto {
            right: auto;
            left: inherit;
        }
    }

    @media (min-width: 768px) {
        .r-md-auto {
            left: auto;
            right: inherit;
        }

        .l-md-auto {
            right: auto;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        .r-lg-auto {
            left: auto;
            right: inherit;
        }

        .l-lg-auto {
            right: auto;
            left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .r-xl-auto {
            left: auto;
            right: inherit;
        }

        .l-xl-auto {
            right: auto;
            left: inherit;
        }
    }

    .tx-right {
        text-align: left;
    }

    .tx-left {
        text-align: right;
    }

    .tx-right-f {
        text-align: left !important;
    }

    .tx-left-f {
        text-align: right !important;
    }

    @media (min-width: 480px) {
        .tx-xs-right {
            text-align: left;
        }

        .tx-xs-left {
            text-align: right;
        }

        .tx-xs-right-f {
            text-align: left !important;
        }

        .tx-xs-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 576px) {
        .tx-sm-right {
            text-align: left;
        }

        .tx-sm-left {
            text-align: right;
        }

        .tx-sm-right-f {
            text-align: left !important;
        }

        .tx-sm-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 768px) {
        .tx-md-right {
            text-align: left;
        }

        .tx-md-left {
            text-align: right;
        }

        .tx-md-right-f {
            text-align: left !important;
        }

        .tx-md-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 992px) {
        .tx-lg-right {
            text-align: left;
        }

        .tx-lg-left {
            text-align: right;
        }

        .tx-lg-right-f {
            text-align: left !important;
        }

        .tx-lg-left-f {
            text-align: right !important;
        }
    }

    @media (min-width: 1200px) {
        .tx-xl-right {
            text-align: left;
        }

        .tx-xl-left {
            text-align: right;
        }

        .tx-xl-right-f {
            text-align: left !important;
        }

        .tx-xl-left-f {
            text-align: right !important;
        }
    }

    .embed-responsive {
        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            right: 0;
            left: inherit;
        }
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-left {
            float: right !important;
        }

        .float-sm-right {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-left {
            float: right !important;
        }

        .float-md-right {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-left {
            float: right !important;
        }

        .float-lg-right {
            float: left !important;
        }
    }

    @media (min-width: 1200px) {
        .float-xl-left {
            float: right !important;
        }

        .float-xl-right {
            float: left !important;
        }
    }

    .text-left {
        text-align: right !important;
    }

    .text-right {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-left {
            text-align: right !important;
        }

        .text-sm-right {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-left {
            text-align: right !important;
        }

        .text-md-right {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-left {
            text-align: right !important;
        }

        .text-lg-right {
            text-align: left !important;
        }
    }

    @media (min-width: 1200px) {
        .text-xl-right {
            text-align: left !important;
        }
    }

    dd {
        margin-left: 0;
        margin-right: inherit;
    }

    caption {
        text-align: right;
    }

    .list-unstyled,
    .list-inline {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .nav-badge {
        margin-left: auto !important;
        margin-right: inherit !important;
        left: 12px;
        right: inherit;
    }

    .nav-text-badge {
        margin-left: auto !important;
        margin-right: inherit !important;
        left: 7px;
        right: inherit;
    }

    .line-list {
        padding-right: 30px;
        padding-left: inherit;

        li {
            &:before {
                right: 0;
                left: inherit;
                margin-right: 15px;
                margin-left: inherit;
            }

            &:after {
                right: 0;
                left: inherit;
            }
        }
    }

    .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before {
        right: 0px;
        right: -27px;
        left: inherit;
    }

    .table-dashboard-one td:first-child {
        padding-right: 0;
        padding-left: inherit;
    }

    .sales-card .sales-icon {
        padding: 40px 20px 20px 40px;
        left: -30px;
        right: inherit;
    }

    .sales-session {
        margin-left: 0;
        margin-right: inherit;
        padding-right: 0;
        padding-left: inherit;
    }

    .product-timeline ul.timeline-1 {
        &:before {
            margin: 20px 22px 0 20px;
            right: -2px;
            left: inherit;
            border-right: 2px dotted #c0ccda;
            border-left: inherit;
        }

        > li {
            padding-right: 1rem;
            padding-left: inherit;

            .product-icon {
                right: 1px;
                left: inherit;
            }
        }
    }

    .order-list .list {
        padding-right: 0;
        padding-left: inherit;
    }

    .top-selling-product .table {
        th,
        td {
            padding: 7px 13px 7px 7px !important;
        }
    }

    .out-of-stock:before {
        right: 0;
        left: inherit;
    }

    .dot-label {
        right: 9px;
        left: inherit;
    }

    .legend {
        margin-right: 10px;
        margin-left: inherit;
    }

    .latest-timeline-1 ul.timeline-1 {
        &:before {
            right: 20px;
            left: inherit;
            border-right: 2px dotted #c0ccda;
            border-left: inherit;
        }

        > li {
            padding-right: 0.9rem;
            padding-left: inherit;

            .product-icon {
                right: 1px;
                left: inherit;
            }
        }
    }

    .latest-tasks {
        .time {
            margin-left: 1.3rem;
            margin-right: inherit;
        }

        .check-box .ckbox span:before {
            right: 0;
            left: inherit;
        }
    }

    .activity .item-activity {
        margin-left: 60px;
        margin-right: inherit;
    }

    .semi-circle {
        border-radius: 0 300px 300px 0px;
    }

    .project-card {
        i {
            margin: 0 -30px 0 24px;
        }

        .project-content ul span {
            float: left;
            text-align: left;
            margin-left: auto;
            margin-right: inherit;
        }
    }

    .crypto .card-footer .nav-link + .nav-link {
        border-right: 1px solid #edecf7;
        border-left: inherit;
    }

    .card-body-top {
        right: -5px;
        left: inherit;

        a {
            text-align: left;
        }
    }

    .crypto {
        .nav-link + .nav-link {
            border-right: 1px solid #edecf7;
            border-left: inherit;
        }

        .coin-logo {
            margin-left: 15px;
            margin-right: inherit;
        }
    }

    .main-sidebar .search .btn {
        left: 0;
        right: inherit;
        padding-left: 31px;
        padding-right: inherit;
    }

    @media (max-width: 992px) {
        .main-header form[role="search"].active {
            left: 0;
            border-right: 1px solid #e3e3f7;
            border-left: inherit;
            right: -1px;

            input {
                padding-right: 20px;
                padding-left: inherit;
                border-radius: 3px 0px 0 3px;
            }
        }
    }

    .project-list .list-unstyled li {
        border-right: 1px solid #000;
        border-left: inherit !important;

        .media-body {
            margin-left: 13px;
            margin-right: inherit;
        }
    }

    .border-start-primary {
        border-right-color: var(--primary-bg-color) !important;
        border-left-color: inherit !important;
    }

    .border-start-success {
        border-right-color: #08c18d !important;
        border-left-color: inherit !important;
    }

    .border-start-warning {
        border-right-color: #ffc107 !important;
        border-left-color: inherit !important;
    }

    .pricing-card .list-unstyled {
        padding-right: 0;
        padding-left: inherit;
    }

    .demo-gallery {
        > ul > li a {
            float: right;

            .demo-gallery-poster > img {
                right: 50%;
                left: inherit;
                margin-left: -10px;
                margin-right: inherit;
            }
        }

        .justified-gallery > a .demo-gallery-poster > img {
            right: 50%;
            left: inherit;
            margin-left: -10px;
            margin-right: inherit;
        }

        .video .demo-gallery-poster img {
            margin-left: -24px;
            margin-right: inherit;
        }
    }

    .product-details .media img {
        margin-left: 20px;
        margin-right: inherit;
    }

    .preview-thumbnail.nav-tabs li {
        margin-right: 2.5%;
        margin-left: inherit;

        &:last-of-type {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .size {
        margin-right: 10px;
        margin-left: inherit;

        &:first-of-type {
            margin-right: 40px;
            margin-left: inherit;
        }
    }

    .colorinput-color:before {
        right: 0.25rem;
        left: inherit;
    }

    .item-card .cardprice {
        left: 15px;
        right: inherit;
    }

    .br-tl-0 {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 5px !important;
    }

    .br-bl-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 5px !important;
    }

    .br-tr-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 5px !important;
    }

    .br-br-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 5px !important;
    }

    .icons-list {
        margin: 0 0px -1px -1px;
    }

    .breadcrumb-4 {
        ol,
        li {
            text-align: left;
        }
    }

    .main-sidebar-open .main-sidebar {
        transition: right 0.3s ease, width 0.3s ease;
    }

    .main-sidebar {
        border-left-color: #cdd4e0;
        border-right-color: inherit;
    }

    .main-sidebar-header {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: inherit;
    }

    .main-sidebar-body {
        .nav-label:after {
            right: 21px;
            left: inherit;
        }

        .nav-link i {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    .nav-link.with-sub {
        .angle {
            left: 20px;
            right: inherit;
            left: 15px;
            right: inherit;
        }

        .side-badge {
            left: 18px;
            right: inherit;
        }
    }

    .main-sidebar-body {
        .nav-link .leftmenu-icon {
            margin-right: 11px;
            margin-left: inherit;
        }

        .nav-sub-item {
            margin-left: 47px;
            margin-right: inherit;

            a:after {
                margin-right: 8px;
                margin-left: inherit;
                right: 13px;
                left: inherit;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu-icon {
            margin-right: 25px;
            margin-left: auto;
            margin-right: 8px !important;
        }

        .dashboard-5 {
            &.main-sidebar-hide .main-sidebar-header {
                border-left: 1px solid #dee4ec;
                border-right: inherit;
            }

            &.main-body.main-sidebar-hide .main-content {
                margin-left: 80px !important;
                margin-right: inherit !important;
            }
        }

        .main-content {
            margin-right: 240px;
            margin-left: 0;
        }
    }

    .main-header-center .btn {
        left: 0;
        right: inherit;
        padding-left: 13px;
        padding-right: inherit;
    }

    .main-content.horizontal-content {
        margin-right: 0;
    }

    .horizontalMenu > .horizontalMenu-list li .horizontal-icon {
        margin-right: 0;
        margin-left: 2px;
    }

    .profile-wrapper .profile-details {
        .name,
        .designation {
            margin-left: 1rem;
            margin-right: inherit;
        }
    }

    .chat .contacts {
        padding-right: 0;
        padding-left: inherit;
        margin-left: 0;
        margin-right: inherit;
    }

    .sidebar-right .demo-avatar-group {
        .main-img-user + {
            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 1rem;
            }
        }

        .main-avatar + {
            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }

    .main-avatar-list-stacked .main-img-user + {
        .main-img-user,
        .main-avatar {
            margin-right: 4px;
            margin-left: inherit;
        }
    }

    .sidebar-right {
        .user_info {
            margin-right: 1rem;
            margin-left: inherit;
        }

        .avatar-list ul {
            padding-right: 0;
            padding-left: inherit;
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .chips .chip {
        margin: 0 0rem 0.5rem 0.5rem;
    }

    .chip .avatar {
        float: right;
        margin: 0 -0.75rem 0 0.5rem;
    }

    .team i {
        margin-left: 10px;
        margin-right: inherit;
        float: left;
    }

    /*-----Back to Top-----*/

    #back-to-top {
        left: 20px;
        right: inherit;
    }

    .page-signin-style {
        right: 0;
        left: inherit;

        &:before {
            right: 0;
            left: inherit;
        }
    }

    @media (min-width: 992px) {
        *::-ms-backdrop,
        .main-header.side-header {
            padding-right: 240px;
            padding-left: inherit;
            right: 0;
            left: inherit;
        }

        *::-ms-backdrop,
        .main-header form[role="search"].active input {
            padding-right: 255px;
            padding-left: inherit;
        }
    }

    *::-ms-backdrop,
    .btn-icon-list .btn {
        margin-right: 10px;
        margin-left: inherit;
    }

    *::-ms-backdrop,
    .horizontalMenucontainer .main-header-left {
        margin-left: 0;
        margin-right: inherit;
    }

    .main-header-arrow {
        left: auto;
        right: inherit;
    }

    .right-toggle .nav-link {
        padding-left: 0;
        padding-right: inherit;
    }

    .profile-user .profile-edit,
    .drop-down-profile span.assigned-task {
        left: 0;
        right: inherit;
    }

    table.dataTable thead {
        .sorting:before,
        .sorting_asc:before,
        .sorting_desc:before,
        .sorting_asc_disabled:before,
        .sorting_desc_disabled:before {
            left: 1em;
            right: inherit;
        }
    }

    .card-crypto-scroll {
        &:before {
            right: 0;
            left: inherit;
        }

        &:after {
            left: 0;
            right: inherit;
        }
    }

    /* ######## Resposnive-header ######## */
    @media (max-width: 568px) {
        .header-settings {
            margin-right: 15px;
            margin-left: 10px;
        }
    }

    @media (max-width: 575px) {
        .main-nav-line .nav-link.active::before {
            right: 0 !important;
            left: inherit !important;
        }
    }

    .widget-user .widget-user-image {
        right: 50%;
        left: inherit;
        margin-right: -45px;
        margin-left: inherit;
    }

    .user-wideget .border-end {
        border-left: 1px solid #f4f4f4;
        border-right: inherit;
    }

    .socailicons .fa {
        text-align: left;
        left: 38px;
        right: inherit;
    }

    .card-img-absolute {
        left: 0;
        right: inherit;
        transform: rotate(180deg);
    }

    .socailicons .fab {
        text-align: left;
        left: 38px;
        right: inherit;
    }

    .widgets-cards .wrp.icon-circle {
        margin-left: 15px;
        margin-right: inherit;
    }

    .user-lock .dropdown {
        left: 20px;
        right: inherit;
    }

    @media (min-width: 992px) {
        .main-header.side-header {
            padding-left: 0px;
            padding-right: 245px;
        }

        .dropdown-menu-arrow:after {
            left: 24px;
            right: inherit;
        }

        &.sidebar-mini.sidenav-toggled {
            .main-header.side-header {
                padding-right: 80px;
                padding-left: inherit;
            }

            &.sidenav-toggled-open {
                .side-menu__label {
                    right: 0;
                    left: inherit;
                    margin-left: 0;
                    margin-right: inherit;
                }

                .side-badge {
                    left: 30px;
                    right: inherit;
                }

                .slide-menu {
                    right: 0;
                    left: inherit;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .navresponsive-toggler span {
            text-align: left;
            padding-left: 0;
            padding-right: inherit;
        }
    }
}

//// PLUGIN RTL STYLES ////

.rtl {
    .datetimepicker {
        &.datetimepicker-rtl table tr td span {
            float: left;
        }

        table th {
            &.next {
                border-top-left-radius: 2px;
                border-top-right-radius: inherit;
            }

            &.prev {
                border-top-right-radius: 2px;
                border-top-left-radius: inherit;
            }
        }
    }

    .datetimepicker-hours span {
        &.hour,
        &.minute,
        &.month,
        &.year {
            float: right;
        }
    }

    .datetimepicker-minutes span {
        &.hour,
        &.minute,
        &.month,
        &.year {
            float: right;
        }
    }

    .datetimepicker-months span {
        &.hour,
        &.minute,
        &.month,
        &.year {
            float: right;
        }
    }

    .datetimepicker-years span {
        &.hour,
        &.minute,
        &.month,
        &.year {
            float: right;
        }
    }

    .datetimepicker-dropdown-bottom-right:before {
        right: 10px;
        left: inherit;
    }

    .datetimepicker-dropdown-bottom-left:before,
    .datetimepicker-dropdown-top-left:before,
    .datetimepicker-dropdown-top-right:before {
        left: 10px;
        right: inherit;
    }

    .datepicker-rtl {
        &.dropdown-menu {
            right: auto;
            left: inherit;
        }

        table tr td span {
            float: left;
        }
    }

    .datepicker-dropdown {
        right: 0;
        left: inherit;

        &.datepicker-orient-left {
            &:before {
                right: 6px;
                left: inherit;
            }

            &:after {
                right: 7px;
                left: inherit;
            }
        }

        &.datepicker-orient-right {
            &:before {
                left: 6px;
                right: inherit;
            }

            &:after {
                left: 7px;
                right: inherit;
            }
        }
    }

    .datepicker table tr td span {
        float: right;
    }

    div {
        &.dt-button-info {
            right: 50%;
            left: inherit;
            margin-right: -200px;
            margin-left: inherit;
        }

        &.dt-button-collection.fixed {
            right: 50%;
            left: inherit;
            margin-right: -75px;
            margin-left: inherit;

            &.two-column {
                margin-right: -200px;
                margin-left: inherit;
            }

            &.three-column {
                margin-right: -225px;
                margin-left: inherit;
            }

            &.four-column {
                margin-right: -300px;
                margin-left: inherit;
            }
        }

        &.dt-button-background {
            right: 0;
            left: inherit;
        }

        &.dt-buttons {
            button.btn.processing:after,
            div.btn.processing:after,
            a.btn.processing:after {
                right: 50%;
                left: inherit;
                margin: -8px -8px 0 0px;
            }
        }

        &.dataTables_wrapper div {
            &.dataTables_filter input {
                margin-right: 0.5em;
                margin-left: inherit;
            }

            &.dataTables_paginate {
                text-align: left;
            }

            &.dataTables_processing {
                right: 50%;
                left: inherit;
                margin-right: -100px;
                margin-left: inherit;
            }
        }
    }

    table {
        &.dataTable {
            > thead {
                > tr > {
                    th:not(.sorting_disabled),
                    td:not(.sorting_disabled) {
                        padding-left: 30px;
                        padding-right: 10px;
                    }
                }

                .sorting:before,
                .sorting_asc:before,
                .sorting_desc:before,
                .sorting_asc_disabled:before,
                .sorting_desc_disabled:before {
                    left: 1em;
                    right: inherit;
                }

                .sorting:after,
                .sorting_asc:after,
                .sorting_desc:after,
                .sorting_asc_disabled:after,
                .sorting_desc_disabled:after {
                    left: 0.5em;
                    right: inherit;
                }
            }

            &.table-sm {
                .sorting:before,
                .sorting_asc:before,
                .sorting_desc:before {
                    left: 0.85em;
                    right: inherit;
                }
            }
        }

        &.table-bordered.dataTable {
            border-right-width: 0;
            border-left-width: inherit;

            th,
            td {
                border-left-width: 0;
                border-right-width: inherit;
            }

            th:first-child,
            td:first-child {
                border-left-width: 0px;
                border-right-width: inherit;
            }

            th:last-child,
            td:last-child {
                border-right-width: 1px;
                border-left-width: inherit;
            }
        }
    }

    div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"] {
        &:first-child {
            padding-right: 0;
            padding-left: inherit;
        }

        &:last-child {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    table.dataTable {
        &.dtr-inline.collapsed {
            > tbody > tr > {
                td.dtr-control,
                th.dtr-control {
                    padding-right: 30px !important;
                    padding-left: inherit !important;
                }

                td.dtr-control:before,
                th.dtr-control:before {
                    right: 5px;
                    left: inherit;
                }
            }

            &.compact > tbody > tr > {
                td.dtr-control,
                th.dtr-control {
                    padding-right: 27px;
                    padding-left: inherit;
                }

                td.dtr-control:before,
                th.dtr-control:before {
                    right: 4px;
                    left: inherit;
                }
            }
        }

        &.dtr-column > tbody > tr > {
            td.dtr-control:before,
            th.dtr-control:before,
            td.control:before,
            th.control:before {
                right: 50%;
                left: inherit;
            }
        }
    }

    div.dtr-modal {
        right: 0;
        left: inherit;

        div.dtr-modal-close {
            left: 6px;
            right: inherit;
        }
    }

    .ui-helper-zfix {
        right: 0;
        left: inherit;
    }

    .ui-widget-icon-block {
        right: 50%;
        left: inherit;
        margin-right: -8px;
        margin-left: inherit;
    }

    /* Misc visuals
        ----------------------------------*/
    /* Overlays */

    .ui-widget-overlay {
        right: 0;
        left: inherit;
    }

    .ui-accordion .ui-accordion-header {
        padding: 0.5em 0.7em 0.5em 0.5em;
    }

    .ui-autocomplete {
        right: 0;
        left: inherit;
    }

    .ui-menu .ui-menu-item-wrapper {
        padding: 3px 0.4em 3px 1em;
    }

    .ui-menu-icons .ui-menu-item-wrapper {
        padding-right: 2em;
        padding-left: inherit;
    }

    /* left-aligned */

    .ui-menu {
        .ui-icon {
            right: 0.2em;
            left: inherit;
        }

        .ui-menu-icon {
            right: auto;
            left: 0;
        }
    }

    /* right-aligned */

    .ui-button {
        margin-left: 0.1em;
        margin-right: inherit;
    }

    /* button icon element(s) */

    .ui-button-icon-only .ui-icon {
        right: 50%;
        left: inherit;
        margin-right: -8px;
        margin-left: inherit;
    }

    .ui-controlgroup-vertical > .ui-controlgroup-item {
        text-align: right;
    }

    .ui-controlgroup-horizontal .ui-controlgroup-label {
        + .ui-controlgroup-item {
            border-left: none;
            border-right: inherit;
        }

        &.ui-widget-content {
            border-right: none;
            border-left: inherit;
        }
    }

    .ui-datepicker {
        .ui-datepicker-prev {
            right: 2px;
            left: inherit;
        }

        .ui-datepicker-next {
            left: 2px;
            right: inherit;
        }

        .ui-datepicker-prev-hover {
            right: 1px;
            left: inherit;
        }

        .ui-datepicker-next-hover {
            left: 1px;
            right: inherit;
        }

        .ui-datepicker-prev span,
        .ui-datepicker-next span {
            right: 50%;
            left: inherit;
            margin-right: -8px;
            margin-left: inherit;
        }

        td {
            span,
            a {
                text-align: left;
            }
        }

        .ui-datepicker-buttonpane button {
            float: left;

            &.ui-datepicker-current {
                float: right;
            }
        }
    }

    /* with multiple calendars */

    .ui-datepicker-multi {
        .ui-datepicker-group {
            float: right;
        }

        .ui-datepicker-group-last .ui-datepicker-header,
        .ui-datepicker-group-middle .ui-datepicker-header {
            border-left-width: 0;
            border-right-width: inherit;
        }
    }

    .ui-datepicker-rtl {
        .ui-datepicker-prev {
            left: 2px;
            right: auto;
        }

        .ui-datepicker-next {
            right: 2px;
            left: auto;
        }

        .ui-datepicker-prev:hover {
            left: 1px;
            right: auto;
        }

        .ui-datepicker-next:hover {
            right: 1px;
            left: auto;
        }

        .ui-datepicker-buttonpane button {
            float: right;

            &.ui-datepicker-current {
                float: left;
            }
        }

        .ui-datepicker-group {
            float: left;
        }

        .ui-datepicker-group-last .ui-datepicker-header,
        .ui-datepicker-group-middle .ui-datepicker-header {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }

    /* Icons */

    .ui-datepicker .ui-icon {
        right: 0.5em;
        left: inherit;
    }

    .ui-dialog {
        right: 0;
        left: inherit;

        .ui-dialog-title {
            float: right;
        }

        .ui-dialog-titlebar-close {
            left: 0.3em;
            right: inherit;
        }

        .ui-dialog-buttonpane {
            text-align: right;
            padding: 0.3em 0.4em 0.5em 1em;

            .ui-dialog-buttonset {
                float: left;
            }

            button {
                margin: 0.5em 0em 0.5em 0.4em;
            }
        }

        .ui-resizable-e {
            left: 0;
            right: inherit;
        }

        .ui-resizable-w {
            right: 0;
            left: inherit;
        }

        .ui-resizable-se {
            left: 0;
            right: inherit;
        }

        .ui-resizable-sw {
            right: 0;
            left: inherit;
        }

        .ui-resizable-ne {
            left: 0;
            right: inherit;
        }

        .ui-resizable-nw {
            right: 0;
            left: inherit;
        }
    }

    .ui-resizable-n,
    .ui-resizable-s {
        right: 0;
        left: inherit;
    }

    .ui-resizable-e {
        left: -5px;
        right: inherit;
    }

    .ui-resizable-w {
        right: -5px;
        left: inherit;
    }

    .ui-resizable-se {
        left: 1px;
        right: inherit;
    }

    .ui-resizable-sw,
    .ui-resizable-nw {
        right: -5px;
        left: inherit;
    }

    .ui-resizable-ne {
        left: -5px;
        right: inherit;
    }

    .ui-progressbar {
        text-align: right;
    }

    .ui-selectmenu-menu {
        right: 0;
        left: inherit;
    }

    .ui-selectmenu-text {
        margin-left: 20px;
        margin-right: inherit;
    }

    .ui-selectmenu-button.ui-button {
        text-align: right;
    }

    .ui-selectmenu-icon.ui-icon {
        float: left;
    }

    .ui-slider {
        text-align: right;
    }

    .ui-slider-horizontal {
        .ui-slider-handle {
            margin-right: -0.6em;
            margin-left: inherit;
        }

        .ui-slider-range-min {
            right: 0;
            left: inherit;
        }

        .ui-slider-range-max {
            left: 0;
            right: inherit;
        }
    }

    .ui-slider-vertical {
        .ui-slider-handle {
            right: -0.3em;
            left: inherit;
            margin-right: 0;
            margin-left: inherit;
        }

        .ui-slider-range {
            right: 0;
            left: inherit;
        }
    }

    .ui-spinner-input {
        margin-right: 0.4em;
        margin-left: 2em;
    }

    .ui-spinner-button {
        left: 0;
        right: inherit;
    }

    /* more specificity required here to override default borders */

    .ui-spinner a.ui-spinner-button {
        border-right-style: none;
        border-left-style: inherit;
    }

    .ui-tabs .ui-tabs-nav {
        li {
            float: right;
            margin: 1px 0em 0 0.2em;
        }

        .ui-tabs-anchor {
            float: right;
        }
    }

    .ui-corner-all,
    .ui-corner-top,
    .ui-corner-left,
    .ui-corner-tl {
        border-top-right-radius: 3px;
        border-top-left-radius: inherit;
    }

    .ui-corner-all,
    .ui-corner-top,
    .ui-corner-right,
    .ui-corner-tr {
        border-top-left-radius: 3px;
        border-top-right-radius: inherit;
    }

    .ui-corner-all,
    .ui-corner-bottom,
    .ui-corner-left,
    .ui-corner-bl {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: inherit;
    }

    .ui-corner-all,
    .ui-corner-bottom,
    .ui-corner-right,
    .ui-corner-br {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: inherit;
    }

    @media (max-width: 360px) {
        .datepicker.dropdown-menu,
        .datetimepicker,
        .ui-widget.ui-widget-content {
            right: 5% !important;
            left: 5%;
        }
    }

    .ff_fileupload_wrap {
        .ff_fileupload_recordvideo_preview {
            left: 60px;
            right: inherit;
        }

        table.ff_fileupload_uploads td.ff_fileupload_actions {
            button.ff_fileupload_start_upload {
                margin-left: 0.5em;
                margin-right: inherit;
            }

            text-align: left;
        }
    }

    /* Colored buttons based on file extension for non-images. */
    @media (max-width: 420px) {
        .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    .ff_fileupload_dialog_background {
        right: 0;
        left: inherit;
    }

    .ff_fileupload_dialog_main {
        right: 10%;
        left: inherit;
    }

    .dropify-wrapper {
        .dropify-clear {
            left: 20px;
            right: inherit;
        }

        .dropify-preview {
            .dropify-render {
                i,
                .dropify-extension {
                    right: 50%;
                    left: inherit;
                }
            }

            .dropify-infos .dropify-infos-inner p.dropify-infos-message::before {
                right: 50%;
                left: inherit;
            }
        }

        &.touch-fallback .dropify-preview {
            .dropify-render .dropify-font-file {
                right: 0;
                left: inherit;
            }

            .dropify-infos .dropify-infos-inner {
                padding: 5px 5px 5px 90px;

                p {
                    text-align: right;
                }
            }
        }

        .dropify-loader {
            left: 15px;
            right: inherit;

            &::after {
                border-left: 1px solid #ccc;
                border-right: 1px solid #777;
            }
        }

        .dropify-errors-container ul {
            right: 0;
            left: inherit;

            li {
                margin-right: 20px;
                margin-left: inherit;
            }
        }

        ~ .dropify-errors-container ul li {
            margin-right: 20px;
            margin-left: inherit;
        }
    }

    .sw-main .sw-toolbar {
        margin-right: 0;
        margin-left: inherit;
    }

    .sw-theme-default {
        .step-content {
            text-align: right;
        }

        > ul.step-anchor > li {
            margin-left: 2px;
            margin-right: inherit;

            > a::after {
                right: 0px;
                left: inherit;
            }

            &.danger > a::after {
                border-left-color: #f8d7da;
                border-right-color: inherit;
            }
        }
    }

    /* Common Loader */

    .sw-loading {
        &::after {
            right: 0;
            left: inherit;
        }

        &::before {
            right: 50%;
            left: inherit;
            margin-right: -40px;
            margin-left: inherit;
        }
    }

    .sw-theme-arrows {
        .step-content {
            text-align: right;
        }

        > ul.step-anchor > li {
            > a {
                padding: 10px 45px 10px 0px;

                &:hover {
                    padding: 10px 45px 10px 0;
                }

                &:after {
                    border-left: 30px solid #f4f5f5;
                    border-right: inherit;
                    right: 100%;
                    left: inherit;
                }

                &:before {
                    border-left: 30px solid #dbdef1;
                    border-right: inherit;
                    margin-right: 1px;
                    margin-left: inherit;
                    right: 100%;
                    left: inherit;
                }
            }

            &:first-child > a {
                padding-right: 15px;
                padding-left: inherit;
            }

            > a:hover:after {
                border-left-color: #f4f5f5;
                border-right-color: inherit;
            }

            &.active > a:after {
                border-left: 30px solid #7673e6 !important;
                border-right: inherit;
            }

            &.done > a:after {
                border-left: 30px solid #f4f5f5;
                border-right: inherit;

                /* c3e6cb */
            }

            &.danger > a:after {
                border-left: 30px solid #d9534f !important;
                border-right: inherit;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .sw-theme-arrows > ul.step-anchor > li > a {
            padding-right: 15px;
            padding-left: inherit;
            margin-left: 0;
            margin-right: inherit;

            &:hover {
                padding-right: 15px;
                padding-left: inherit;
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .sw-theme-dots {
        .step-content {
            text-align: right;
        }

        > ul.step-anchor {
            &:before {
                right: 20px;
                left: inherit;
            }

            > li > a {
                &:before {
                    right: 41%;
                    left: inherit;
                }

                &:after {
                    right: 48%;
                    left: inherit;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .sw-theme-dots > ul.step-anchor {
            &:before {
                right: 19px;
                left: inherit;
                margin-left: 10px;
                margin-right: inherit;
            }

            > li {
                margin-right: 20px;
                margin-left: inherit;

                > a {
                    text-align: right;
                    margin-right: 0;
                    margin-left: inherit;

                    &:before {
                        right: -14px;
                        left: inherit;
                        margin-left: 10px;
                        margin-right: inherit;
                    }

                    &:after {
                        right: -36px;
                        left: inherit;
                        margin-left: 10px;
                        margin-right: inherit;
                    }
                }
            }
        }
    }

    .sw-theme-circles {
        .step-content {
            text-align: right;
        }

        > ul.step-anchor > li {
            margin-right: 40px;
            margin-left: inherit;
        }
    }

    .fc-direction-ltr .fc-button-group > .fc-button {
        &:not(:first-child) {
            margin-right: -1px;
            margin-left: inherit;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:not(:last-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .fc-direction-rtl .fc-button-group > .fc-button {
        &:not(:first-child) {
            margin-left: -1px;
            margin-right: inherit;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }

    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
        margin-right: 0.75em;
        margin-left: inherit;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .fc-direction-rtl .fc-toolbar > * > :not(:first-child) {
        margin-left: 0.75em;
        margin-right: inherit;

        /* space between */
    }

    .fc {
        .fc-scroller-harness {
            direction: rtl;
        }

        .fc-scrollgrid {
            border-right-width: 1px;
            border-left-width: 1px;
        }

        .fc-scrollgrid-section > * {
            border-left-width: 0;
            border-right-width: inherit;
        }
    }

    /* adjust border and border-radius (if there is any) for non-start/end */

    .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
    .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-left-width: 0;
        border-right-width: inherit;
    }

    .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
    .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        border-right-width: 0;
        border-left-width: inherit;
    }

    .fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
    .fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
        cursor: w-resize;
        right: -4px;
        right: calc(var(--fc-event-resizer-thickness, 8px) / -2);
        left: inherit;
    }

    .fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
    .fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
        cursor: e-resize;
        left: -4px;
        left: calc(var(--fc-event-resizer-thickness, 8px) / -2);
        right: inherit;
    }

    .fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
    .fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
        right: -4px;
        right: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
        left: inherit;
    }

    .fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
    .fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
        left: -4px;
        left: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
        right: inherit;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-start,
    .fc-direction-rtl .fc-daygrid-event.fc-event-end {
        margin-right: 2px;
        margin-left: inherit;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-end,
    .fc-direction-rtl .fc-daygrid-event.fc-event-start {
        margin-left: 2px;
        margin-right: inherit;
    }

    .fc-direction-ltr .fc-daygrid-week-number {
        right: 0;
        left: inherit;
        border-radius: 0 0 0 3px;
    }

    .fc-direction-rtl .fc-daygrid-week-number {
        left: 0;
        right: inherit;
        border-radius: 0 0 3px 0;
    }

    /* --- spacing between time and title --- */

    .fc-direction-ltr .fc-daygrid-event .fc-event-time {
        margin-left: 3px;
        margin-right: inherit;
    }

    .fc-direction-rtl .fc-daygrid-event .fc-event-time {
        margin-right: 3px;
        margin-left: inherit;
    }

    .fc-v-event.fc-event-selected .fc-event-resizer {
        right: 50%;
        left: inherit;
        margin-right: -4px;
        margin-right: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
        margin-left: inherit;
    }

    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0 2px 0 2.5%;
    }

    .fc-direction-rtl .fc-timegrid-col-events {
        margin: 0 2.5% 0 2px;
    }

    .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
        right: 0;
        left: inherit;
        border-width: 5px 0 5px 6px;
    }

    .fc-direction-rtl .fc-timegrid-now-indicator-arrow {
        left: 0;
        right: inherit;
        border-width: 5px 0px 5px 6px;
    }

    .fc-direction-ltr .fc-list-day-text,
    .fc-direction-rtl .fc-list-day-side-text {
        float: right;
    }

    .fc-direction-ltr .fc-list-day-side-text,
    .fc-direction-rtl .fc-list-day-text {
        float: left;
    }

    /* make the dot closer to the event title */

    .fc-direction-ltr .fc-list-table .fc-list-event-graphic {
        padding-left: 0;
        padding-right: inherit;
    }

    .fc-direction-rtl .fc-list-table .fc-list-event-graphic {
        padding-right: 0;
        padding-left: inherit;
    }

    #external-events,
    #custom-events {
        text-align: right;
    }

    @media (max-width: 767px) {
        .fc .fc-toolbar-title {
            left: 10px;
            right: inherit;
        }
    }

    .lg-actions {
        .lg-next {
            left: 20px;
            right: inherit;
        }

        .lg-prev {
            right: 20px;
            left: inherit;
        }
    }

    @-webkit-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-moz-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-ms-keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @keyframes lg-right-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: -30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-webkit-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-moz-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @-ms-keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    @keyframes lg-left-end {
        0% {
            right: 0;
            left: inherit;
        }

        50% {
            right: 30px;
            left: inherit;
        }

        100% {
            right: 0;
            left: inherit;
        }
    }

    .lg-toolbar {
        right: 0;
        left: inherit;

        .lg-icon {
            float: left;
        }
    }

    #lg-counter {
        padding-right: 20px;
        padding-left: inherit;
    }

    .lg-outer {
        .lg-thumb-item {
            float: right;
        }

        .lg-toggle-thumb {
            left: 20px;
            right: inherit;
        }

        .lg-video {
            .lg-object {
                right: 0;
                left: inherit;
            }

            .lg-video-play {
                right: 50%;
                left: inherit;
                margin-right: -42px;
                margin-left: inherit;
            }
        }

        .lg-has-html5 .lg-video-play {
            margin-right: -32px;
            margin-left: inherit;
        }

        .lg-video-object {
            right: 0;
            left: inherit;
        }
    }

    .lg-progress-bar {
        right: 0;
        left: inherit;
    }

    .lg-outer {
        .lg-pager-thumb-cont {
            right: 0;
            left: inherit;
            margin-right: -60px;
            margin-left: inherit;
        }

        .lg-caret {
            left: inherit;
            right: 50%;
            margin-right: -5px;
            margin-left: inherit;
        }

        .lg-dropdown {
            left: 0;
            right: inherit;
            text-align: right;

            &:after {
                left: 16px;
                right: inherit;
            }

            .lg-icon {
                margin-left: 8px;
                margin-right: inherit;
            }
        }

        right: 0;
        left: inherit;

        .lg-inner {
            right: 0;
            left: inherit;
        }

        .lg-item:before,
        .lg-img-wrap:before {
            margin-left: -1px;
            margin-right: inherit;
        }
    }

    .lg-css3.lg-slide.lg-use-left .lg-item {
        right: 0;
        left: inherit;

        &.lg-prev-slide {
            right: -100%;
            left: inherit;
        }

        &.lg-next-slide {
            right: 100%;
            left: inherit;
        }

        &.lg-current {
            right: 0;
            left: inherit;
        }
    }

    .bootstrap-tagsinput .badge {
        margin-left: 2px;
        margin-right: inherit;

        [data-role="remove"] {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    .nice-select {
        text-align: right !important;

        &:after {
            border-right: 2px solid #cbd2e9;
            border-left: inherit;
            left: 12px;
            right: inherit;
        }

        &.wide .list {
            right: 0 !important;
            left: 0 !important;
        }

        &.right {
            float: left;

            .list {
                right: auto;
                left: 0;
            }
        }

        .list {
            right: 0;
            left: inherit;
        }

        .option {
            padding-right: 18px;
            padding-left: 29px;
            text-align: right;
        }
    }

    .datepicker > .datepicker_inner_container > {
        .datepicker_calendar {
            margin-right: -1px;
            margin-left: inherit;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            -webkit-border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-bottomleft: 3px;
        }

        .datepicker_timelist {
            float: right;
            padding-right: 0px;
            padding-left: 0px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;

            &::-webkit-scrollbar {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                -webkit-border-top-left-radius: 3px;
                -webkit-border-bottom-left-radius: 3px;
                -moz-border-radius-topright: 3px;
                -moz-border-radius-bottomright: 3px;
            }

            > div.timelist_item {
                padding-right: 7px;
                padding-left: 25px;
            }
        }
    }

    .checkbox-group {
        label {
            padding-right: 25px;
            padding-left: inherit;
        }

        + .checkbox-group label {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .radio-group label:before {
        right: 0px;
        left: inherit;
    }

    /*?????radio???*/
    /* + ??????,??????label??*/

    .checkbox-group label:before {
        right: 0;
        left: inherit;
    }

    /*?????radio???*/
    /* + ??????,??????label??*/

    .multi-select-active:after {
        margin-right: 64%;
        margin-left: inherit;
    }

    .icon-single-add,
    .icon-double-add {
        margin-right: -30px;
        margin-left: inherit;
    }

    .transfer-double {
        right: 30%;
        left: inherit;
    }

    .transfer-double-content-left,
    .transfer-double-content-middle {
        float: right;
    }

    .transfer-double-content-tabs .tab-item-name {
        margin-right: 15%;
        margin-left: inherit;
    }

    .transfer-double-list-search-input {
        padding: 5px 8px 4px 0px;
    }

    .transfer-double-list-main {
        .transfer-double-list-ul {
            padding-right: 7px;
            padding-left: inherit;

            .transfer-double-list-li input {
                margin-left: 10px;
                margin-right: inherit;
            }
        }

        .transfer-double-group-list-ul {
            padding-right: 7px;
            padding-left: inherit;

            .transfer-double-group-list-li .transfer-double-group-list-li-ul {
                padding-right: 20px;
                padding-left: inherit;
            }
        }
    }

    .transfer-double-selected-list-search-input {
        padding: 5px 8px 4px 0px;
    }

    .transfer-double-selected-list-main .transfer-double-selected-list-ul {
        padding-right: 7px;
        padding-left: inherit;

        .transfer-double-selected-list-li input[type="checkbox"] {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    .transfer-double-list-footer {
        padding-right: 0px;
        padding-left: inherit;

        span {
            margin-right: 20px;
            margin-left: inherit;
        }

        input {
            margin-right: 75px;
            margin-left: inherit;
        }

        .btn-setting {
            margin-right: 5px;
            margin-left: inherit;
        }

        label {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .transfer-double-footer {
        text-align: left;
    }

    /** responsive **/
    @media (max-width: 480px) {
        .transfer-double-content-middle {
            margin: 15px 40% 15px 15px;
        }
    }

    @media (min-width: 481px) and (max-width: 992px) {
        .transfer-double-content-middle {
            margin: 15px 40% 15px 15px;
        }

        .transfer {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
    }

    .leaflet-pane > {
        svg,
        canvas {
            right: 0;
            left: inherit;
        }
    }

    .leaflet-zoom-box,
    .leaflet-image-layer,
    .leaflet-layer {
        right: 0;
        left: inherit;
    }

    .leaflet-right {
        left: 0;
        right: inherit;
    }

    .leaflet-left {
        right: 0;
        left: inherit;
    }

    .leaflet-control {
        float: right;
    }

    .leaflet-right .leaflet-control {
        float: left;
    }

    .leaflet-left .leaflet-control {
        margin-right: 10px;
        margin-left: inherit;
    }

    .leaflet-right .leaflet-control {
        margin-left: 10px;
        margin-right: inherit;
    }

    .leaflet-control-layers-expanded {
        padding: 6px 6px 6px 10px;
    }

    .leaflet-control-layers-separator {
        margin: 5px -6px 5px -10px;
    }

    .leaflet-left .leaflet-control-scale {
        margin-right: 5px;
        margin-left: inherit;
    }

    .leaflet-popup-content-wrapper {
        text-align: right;
    }

    .leaflet-popup-tip-container {
        right: 50%;
        left: inherit;
        margin-right: -20px;
        margin-left: inherit;
    }

    .leaflet-container a.leaflet-popup-close-button {
        left: 0;
        right: inherit;
        padding: 4px 0px 0 4px;
    }

    .leaflet-tooltip-bottom:before,
    .leaflet-tooltip-top:before {
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-bottom:before,
    .leaflet-tooltip-left {
        margin-right: -6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-right {
        margin-right: 6px;
        margin-left: inherit;
    }

    .leaflet-tooltip-left:before {
        left: 0;
        right: inherit;
        margin-left: -12px;
        margin-right: inherit;
        border-left-color: #fff;
        border-right-color: inherit;
    }

    .leaflet-tooltip-right:before {
        right: 0;
        left: inherit;
        margin-right: -12px;
        margin-left: inherit;
        border-right-color: #fff;
        border-left-color: inherit;
    }

    .multi-wrapper .non-selected-wrapper {
        border-right: 1px solid #e9edf4;
        border-left: inherit;
    }

    .ms-choice > {
        span {
            right: 0;
            left: inherit;
            padding-right: 8px;
            padding-left: inherit;
        }

        div {
            left: 7px;
            right: inherit;
        }
    }

    .ms-search input {
        padding: 0 5px 0 20px;
    }

    .ms-drop ul > li.multiple {
        float: right;
        float: right;
    }

    #mixedSlider {
        .MS-content .item {
            .imgTitle .blogTitle {
                text-align: right;
                padding: 0 10px 2px 0px;
            }

            p {
                margin: 2px 5px 0 10px;
            }

            a {
                float: left;
                margin: 0 0px 0 20px;
            }
        }

        .MS-controls {
            .MS-left {
                right: 0px;
                left: inherit;
            }

            .MS-right {
                left: 0px;
                right: inherit;
            }
        }
    }

    @media (max-width: 767px) {
        #mixedSlider .MS-controls .MS-left {
            right: -10px;
            left: inherit;
        }
    }

    @media (max-width: 767px) {
        #mixedSlider .MS-controls .MS-right {
            left: -10px;
            right: inherit;
        }
    }

    #basicSlider .MS-controls {
        .MS-left {
            right: 10px;
            left: inherit;
        }

        .MS-right {
            left: 10px;
            right: inherit;
        }
    }

    #basicSlider .MS-content {
        direction: ltr;
    }

    .jctkr-wrapper ul {
        right: 0;
        left: inherit;

        li .crypto-card {
            float: right;
        }
    }

    #ui_notifIt {
        left: 10px;
        right: inherit;
    }

    #notifIt_close {
        left: 0;
        right: inherit;
    }

    /* notifit confirm */

    .notifit_confirm_bg,
    .notifit_prompt_bg,
    .notifit_confirm,
    .notifit_prompt {
        right: 0;
        left: inherit;
    }

    .notifit_confirm_accept,
    .notifit_confirm_cancel,
    .notifit_prompt_accept,
    .notifit_prompt_cancel {
        margin-left: 5px;
        margin-right: inherit;
    }

    .owl-carousel {
        .owl-item {
            float: right;
        }

        &.owl-rtl .owl-item {
            float: left;
        }

        .owl-video-play-icon {
            right: 50%;
            left: inherit;
            margin-right: -40px;
            margin-left: inherit;
        }
    }

    .ps__rail-y {
        left: 0;
        right: inherit;
    }

    .ps__thumb-y {
        left: 2px;
        right: inherit;
    }

    .picker-close {
        left: 0;
        right: inherit;
    }

    .picker-cell + .picker-cell {
        border-left: 1px solid #eee;
        border-left: var(--border);
        border-right: inherit;
    }

    .picker-cell__control:before {
        right: 50%;
        left: inherit;
    }

    .picker-cell__control--prev:before {
        border-right-width: 1px;
        border-left-width: inherit;
    }

    .picker-cell__control--next:before {
        border-left-width: 1px;
        border-right-width: inherit;
    }

    .pickr .pcr-button {
        &::before,
        &::after {
            right: 0;
            left: inherit;
        }
    }

    .pcr-app {
        .pcr-swatches > button {
            &::before,
            &::after {
                right: 0;
                left: inherit;
            }
        }

        .pcr-interaction .pcr-result {
            text-align: right;
        }

        &[data-theme="classic"] .pcr-selection {
            .pcr-color-preview {
                margin-left: 0.75em;
                margin-right: inherit;

                &::before {
                    right: 0;
                    left: inherit;
                }
            }

            .pcr-color-palette .pcr-palette::before {
                right: 0;
                left: inherit;
            }

            .pcr-color-chooser,
            .pcr-color-opacity {
                margin-right: 0.75em;
                margin-left: inherit;
            }

            .pcr-color-chooser .pcr-picker,
            .pcr-color-opacity .pcr-picker {
                right: 50%;
                left: inherit;
            }
        }
    }

    .pickr .pcr-button {
        &::before,
        &::after {
            right: 0;
            left: inherit;
        }
    }

    .pcr-app {
        .pcr-swatches > button {
            &::before,
            &::after {
                right: 0;
                left: inherit;
            }
        }

        .pcr-interaction .pcr-result {
            text-align: right;
        }

        &[data-theme="monolith"] .pcr-selection {
            .pcr-color-preview::before,
            .pcr-color-palette .pcr-palette::before {
                right: 0;
                left: inherit;
            }
        }
    }

    .pickr .pcr-button {
        &::before,
        &::after {
            right: 0;
            left: inherit;
        }
    }

    .pcr-app {
        .pcr-swatches > button {
            &::before,
            &::after {
                right: 0;
                left: inherit;
            }
        }

        .pcr-interaction .pcr-result {
            text-align: right;
        }

        &[data-theme="nano"] .pcr-selection {
            .pcr-color-preview .pcr-current-color::before,
            .pcr-color-palette .pcr-palette::before {
                right: 0;
                left: inherit;
            }
        }
    }

    .ql-clipboard {
        right: -100000px;
        left: inherit;
    }

    .ql-editor {
        li {
            &:not(.ql-direction-rtl)::before {
                margin-right: -1.5em;
                margin-left: 0.3em;
                text-align: left;
            }

            &.ql-direction-rtl::before {
                margin-right: 0.3em;
                margin-left: -1.5em;
            }
        }

        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
            padding-right: 1.5em;
            padding-left: inherit;
        }

        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {
            padding-left: 1.5em;
            padding-right: inherit;
        }

        .ql-indent-1:not(.ql-direction-rtl) {
            padding-right: 3em;
            padding-left: inherit;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-right: 4.5em;
            padding-left: inherit;
        }

        .ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-left: 3em;
            padding-right: inherit;
        }

        li.ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-left: 4.5em;
            padding-right: inherit;
        }

        .ql-indent-2:not(.ql-direction-rtl) {
            padding-right: 6em;
            padding-left: inherit;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-right: 7.5em;
            padding-left: inherit;
        }

        .ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-left: 6em;
            padding-right: inherit;
        }

        li.ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-left: 7.5em;
            padding-right: inherit;
        }

        .ql-indent-3:not(.ql-direction-rtl) {
            padding-right: 9em;
            padding-left: inherit;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-right: 10.5em;
            padding-left: inherit;
        }

        .ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-left: 9em;
            padding-right: inherit;
        }

        li.ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-left: 10.5em;
            padding-right: inherit;
        }

        .ql-indent-4:not(.ql-direction-rtl) {
            padding-right: 12em;
            padding-left: inherit;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-right: 13.5em;
            padding-left: inherit;
        }

        .ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-left: 12em;
            padding-right: inherit;
        }

        li.ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-left: 13.5em;
            padding-right: inherit;
        }

        .ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 15em;
            padding-left: inherit;
        }

        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 16.5em;
            padding-left: inherit;
        }

        .ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-left: 15em;
            padding-right: inherit;
        }

        li.ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-left: 16.5em;
            padding-right: inherit;
        }

        .ql-indent-6:not(.ql-direction-rtl) {
            padding-right: 18em;
            padding-left: inherit;
        }

        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-right: 19.5em;
            padding-left: inherit;
        }

        .ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-left: 18em;
            padding-right: inherit;
        }

        li.ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-left: 19.5em;
            padding-right: inherit;
        }

        .ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 21em;
            padding-left: inherit;
        }

        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 22.5em;
            padding-left: inherit;
        }

        .ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-left: 21em;
            padding-right: inherit;
        }

        li.ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-left: 22.5em;
            padding-right: inherit;
        }

        .ql-indent-8:not(.ql-direction-rtl) {
            padding-right: 24em;
            padding-left: inherit;
        }

        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-right: 25.5em;
            padding-left: inherit;
        }

        .ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-left: 24em;
            padding-right: inherit;
        }

        li.ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-left: 25.5em;
            padding-right: inherit;
        }

        .ql-indent-9:not(.ql-direction-rtl) {
            padding-right: 27em;
            padding-left: inherit;
        }

        li.ql-indent-9:not(.ql-direction-rtl) {
            padding-right: 28.5em;
            padding-left: inherit;
        }

        .ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-left: 27em;
            padding-right: inherit;
        }

        li.ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-left: 28.5em;
            padding-right: inherit;
        }

        .ql-align-right {
            text-align: left;
        }
    }

    .ql-bubble {
        &.ql-toolbar button,
        .ql-toolbar button,
        &.ql-toolbar button svg,
        .ql-toolbar button svg {
            float: right;
        }

        .ql-editor blockquote {
            border-left: 4px solid #edecf7;
            border-right: inherit;
            padding-right: 16px;
            padding-left: inherit;
        }

        .ql-picker {
            float: right;
        }

        .ql-picker-label {
            padding-right: 8px;
            padding-left: 2px;
        }

        .ql-color-picker .ql-picker-label svg,
        .ql-icon-picker .ql-picker-label svg {
            left: 4px;
            right: inherit;
        }

        .ql-color-picker .ql-picker-item {
            float: right;
        }

        .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
            left: 0;
            right: inherit;
        }

        .ql-toolbar .ql-formats {
            margin: 8px 0px 8px 12px;

            &:first-child {
                margin-right: 12px;
                margin-left: inherit;
            }
        }

        .ql-tooltip-arrow {
            right: 50%;
            left: inherit;
            margin-right: -6px;
            margin-left: inherit;
        }

        .ql-tooltip-editor a {
            left: 20px;
            right: inherit;
        }
    }

    .ql-container.ql-bubble:not(.ql-disabled) a {
        &::before,
        &::after {
            right: 0;
            left: inherit;
            margin-right: 50%;
            margin-left: inherit;
        }
    }

    .ql-clipboard {
        right: -100000px;
        left: inherit;
    }

    .ql-editor {
        text-align: right;

        ol,
        ul {
            padding-right: 1.5em;
            padding-left: inherit;
        }

        li {
            &:not(.ql-direction-rtl)::before {
                margin-right: -1.5em;
                margin-left: 0.3em;
                text-align: left;
            }

            &.ql-direction-rtl::before {
                margin-right: 0.3em;
                margin-left: -1.5em;
            }
        }

        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
            padding-right: 1.5em;
            padding-left: inherit;
        }

        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {
            padding-left: 1.5em;
            padding-right: inherit;
        }

        .ql-indent-1:not(.ql-direction-rtl) {
            padding-right: 3em;
            padding-left: inherit;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-right: 4.5em;
            padding-left: inherit;
        }

        .ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-left: 3em;
            padding-right: inherit;
        }

        li.ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-left: 4.5em;
            padding-right: inherit;
        }

        .ql-indent-2:not(.ql-direction-rtl) {
            padding-right: 6em;
            padding-left: inherit;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-right: 7.5em;
            padding-left: inherit;
        }

        .ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-left: 6em;
            padding-right: inherit;
        }

        li.ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-left: 7.5em;
            padding-right: inherit;
        }

        .ql-indent-3:not(.ql-direction-rtl) {
            padding-right: 9em;
            padding-left: inherit;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-right: 10.5em;
            padding-left: inherit;
        }

        .ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-left: 9em;
            padding-right: inherit;
        }

        li.ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-left: 10.5em;
            padding-right: inherit;
        }

        .ql-indent-4:not(.ql-direction-rtl) {
            padding-right: 12em;
            padding-left: inherit;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-right: 13.5em;
            padding-left: inherit;
        }

        .ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-left: 12em;
            padding-right: inherit;
        }

        li.ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-left: 13.5em;
            padding-right: inherit;
        }

        .ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 15em;
            padding-left: inherit;
        }

        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 16.5em;
            padding-left: inherit;
        }

        .ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-left: 15em;
            padding-right: inherit;
        }

        li.ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-left: 16.5em;
            padding-right: inherit;
        }

        .ql-indent-6:not(.ql-direction-rtl) {
            padding-right: 18em;
            padding-left: inherit;
        }

        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-right: 19.5em;
            padding-left: inherit;
        }

        .ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-left: 18em;
            padding-right: inherit;
        }

        li.ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-left: 19.5em;
            padding-right: inherit;
        }

        .ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 21em;
            padding-left: inherit;
        }

        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 22.5em;
            padding-left: inherit;
        }

        .ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-left: 21em;
            padding-right: inherit;
        }

        li.ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-left: 22.5em;
            padding-right: inherit;
        }

        .ql-indent-8:not(.ql-direction-rtl) {
            padding-right: 24em;
            padding-left: inherit;
        }

        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-right: 25.5em;
            padding-left: inherit;
        }

        .ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-left: 24em;
            padding-right: inherit;
        }

        li.ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-left: 25.5em;
            padding-right: inherit;
        }

        .ql-indent-9:not(.ql-direction-rtl) {
            padding-right: 27em;
            padding-left: inherit;
        }

        li.ql-indent-9:not(.ql-direction-rtl) {
            padding-right: 28.5em;
            padding-left: inherit;
        }

        .ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-left: 27em;
            padding-right: inherit;
        }

        li.ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-left: 28.5em;
            padding-right: inherit;
        }
    }

    .ql-snow {
        &.ql-toolbar button,
        .ql-toolbar button,
        &.ql-toolbar button svg,
        .ql-toolbar button svg {
            float: right;
        }

        .ql-editor blockquote {
            border-left: 4px solid #edecf7;
            border-right: inherit;
            padding-right: 16px;
            padding-left: inherit;
        }

        .ql-picker {
            float: right;
        }

        .ql-picker-label {
            padding-right: 8px;
            padding-left: 2px;
        }

        .ql-color-picker .ql-picker-label svg,
        .ql-icon-picker .ql-picker-label svg {
            left: 4px;
            right: inherit;
        }

        .ql-color-picker .ql-picker-item {
            float: right;
        }

        .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
            left: 0;
            right: inherit;
        }
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-left: 15px;
    }

    .ql-snow .ql-tooltip {
        &::before {
            margin-left: 8px;
            margin-right: inherit;
        }

        a {
            &.ql-action::after {
                border-right: 1px solid #edecf7;
                border-left: inherit;
                margin-right: 16px;
                margin-left: inherit;
                padding-left: 8px;
                padding-right: inherit;
            }

            &.ql-remove::before {
                margin-right: 8px;
                margin-left: inherit;
            }
        }

        &.ql-editing a.ql-action::after {
            border-right: 0px;
            border-left: inherit;
            padding-left: 0px;
            padding-right: inherit;
        }
    }

    .jq-star-svg {
        padding-right: 3px;
        padding-left: inherit;
    }

    .select2-container {
        .select2-selection--single .select2-selection__rendered {
            padding-right: 8px;
            padding-left: 20px;
        }

        .select2-selection--multiple .select2-selection__rendered {
            padding-right: 8px;
            padding-left: inherit;
        }

        .select2-search--inline {
            float: right;
        }
    }

    // .select2-dropdown {
    //     right: -100000px;
    //     left: inherit;
    // }

    // .select2-container--open .select2-dropdown,
    // .select2-close-mask {
    //     right: 0;
    //     left: inherit;
    // }

    .select2-container--default {
        .select2-selection--single .select2-selection__arrow {
            left: 1px;
            right: inherit;

            b {
                right: 50%;
                margin-right: -4px;
                margin-left: inherit;
            }
        }

        .select2-selection--multiple {
            .select2-selection__placeholder {
                float: right;
            }

            .select2-selection__clear {
                float: left;
                margin-left: 10px;
                margin-right: inherit;
            }

            .select2-selection__choice {
                float: right;
                margin-left: 5px;
                margin-right: inherit;
            }

            .select2-selection__choice__remove {
                margin-left: 2px;
                margin-right: inherit;
            }
        }

        .select2-results__option .select2-results__option {
            padding-right: 1em;
            padding-left: inherit;

            .select2-results__group {
                padding-right: 0;
                padding-left: inherit;
            }

            .select2-results__option {
                margin-right: -1em;
                margin-left: inherit;
                padding-right: 2em;
                padding-left: inherit;

                .select2-results__option {
                    margin-right: -2em;
                    padding-right: 3em;
                    margin-left: inherit;
                    padding-left: inherit;

                    .select2-results__option {
                        margin-right: -3em;
                        margin-left: inherit;
                        padding-right: 4em;
                        padding-left: inherit;

                        .select2-results__option {
                            margin-right: -4em;
                            margin-left: inherit;
                            padding-right: 5em;
                            padding-left: inherit;

                            .select2-results__option {
                                margin-right: -5em;
                                margin-left: inherit;
                                padding-right: 6em;
                                padding-left: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-container--classic {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
                margin-left: 10px;
                margin-right: inherit;
            }

            .select2-selection__arrow {
                border-left: 1px solid #edecf7;
                border-right: inherit;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                left: 1px;
                right: inherit;

                b {
                    right: 50%;
                    margin-right: -4px;
                    margin-left: inherit;
                }
            }
        }

        .select2-selection--multiple {
            .select2-selection__choice {
                float: right;
                margin-left: 5px;
                margin-right: inherit;
            }

            .select2-selection__choice__remove {
                margin-left: 2px;
                margin-right: inherit;
            }
        }
    }

    .navbar {
        &.navbar-static .navbar-header {
            float: right;
        }

        .navbar-toggle {
            &.toggle-left {
                float: right;
                margin-right: 15px;
                margin-left: inherit;
            }

            &.toggle-right {
                float: left;
                margin-right: inherit;
                margin-left: 15px;
            }
        }
    }

    .sidebar-left {
        right: 0;
        left: inherit;
    }

    .sidebar-right {
        left: 0;
        right: inherit;
    }

    .sidebar.sidebar-right {
        -webkit-transform: translate(-100%, 0);
        -moz-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        -o-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
        border-right: 1px solid $border;
        border-left: inherit;
    }

    /* css to override hiding the sidebar according to different screen sizes */

    .row .sidebar {
        &.sidebar-left.sidebar-xs-show {
            right: 0;
            left: inherit;
        }

        &.sidebar-right.sidebar-xs-show {
            left: 0;
            right: inherit;
        }
    }

    /*right sidebar is untested */
    @media (min-width: 768px) {
        .row .sidebar {
            &.sidebar-left.sidebar-sm-show {
                right: 0;
                left: inherit;
            }

            &.sidebar-right.sidebar-sm-show {
                left: 0;
                right: inherit;
            }
        }

        /*right sidebar is untested */
    }

    @media (min-width: 992px) {
        .row .sidebar {
            &.sidebar-left.sidebar-md-show {
                right: 0;
                left: inherit;
            }

            &.sidebar-right.sidebar-md-show {
                left: 0;
                right: inherit;
            }
        }
    }

    @media (min-width: 1170px) {
        .row .sidebar {
            &.sidebar-left.sidebar-lg-show {
                right: 0;
                left: inherit;
            }

            &.sidebar-right.sidebar-lg-show {
                left: 0;
                right: inherit;
            }
        }
    }

    .sp-color {
        right: 0;
        left: 20%;
    }

    .sp-hue {
        left: 0;
        right: 84%;
    }

    .sp-alpha-handle {
        right: 50%;
        left: inherit;
    }

    .sp-clear-enabled .sp-clear {
        left: 0;
        right: 84%;
    }

    /* Mobile devices, make hue slider bigger so it is easier to slide */
    @media (max-device-width: 480px) {
        .sp-color {
            left: 40%;
            right: inherit;
        }

        .sp-hue {
            right: 63%;
            left: inherit;
        }
    }

    .sp-dragger {
        right: 0;
        left: inherit;
    }

    /* Input */

    .sp-input-container {
        float: left;
    }

    .sp-picker-container,
    .sp-palette-container {
        float: right;
    }

    .sp-picker-container {
        border-left: solid 1px #fff;
        border-right: inherit;
    }

    /* Palettes */

    .sp-palette-container {
        border-right: solid 1px #ccc;
        border-left: inherit;
    }

    .sp-palette .sp-thumb-el {
        float: right;
    }

    /* Initial */

    .sp-initial {
        float: right;

        span {
            float: right;
        }
    }

    /* Buttons */

    .sp-palette-button-container,
    .sp-button-container {
        float: left;
    }

    .sp-dd {
        float: right;
    }

    .sp-preview {
        margin-left: 5px;
        margin-right: inherit;
        float: right;
    }

    .sp-cancel {
        margin-left: 5px;
        margin-right: inherit;
    }

    .note-icon-pull-left {
        float: right;
    }

    .note-icon-pull-right {
        float: left;
    }

    .note-icon {
        &.note-icon-pull-left {
            margin-left: 0.3em;
            margin-right: inherit;
        }

        &.note-icon-pull-right {
            margin-right: 0.3em;
            margin-left: inherit;
        }
    }

    .note-editor {
        .note-editing-area .note-editable img {
            &.note-float-left {
                margin-left: 10px;
                margin-right: inherit;
            }

            &.note-float-right {
                margin-right: 10px;
                margin-left: inherit;
            }
        }

        &.note-airframe.fullscreen,
        &.note-frame.fullscreen {
            right: 0;
            left: inherit;
        }

        &.note-airframe .note-status-output .pull-right,
        &.note-frame .note-status-output .pull-right {
            float: left !important;
        }

        &.note-airframe .note-status-output .alert .note-icon,
        &.note-frame .note-status-output .alert .note-icon {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .note-popover.popover .arrow {
        right: 20px !important;
        left: inherit !important;
    }

    .note-editor .note-toolbar,
    .note-popover .popover-content {
        padding: 0 5px 5px 0px;
    }

    .note-editor .note-toolbar > .note-btn-group,
    .note-popover .popover-content > .note-btn-group {
        margin-right: 0;
        margin-left: 5px;
    }

    .note-editor .note-toolbar .note-color .dropdown-toggle,
    .note-popover .popover-content .note-color .dropdown-toggle {
        padding-right: 5px;
        padding-left: inherit;
    }

    .note-editor .note-toolbar .note-para .note-dropdown-menu > div + div,
    .note-popover .popover-content .note-para .note-dropdown-menu > div + div {
        margin-right: 5px;
        margin-left: inherit;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right,
    .note-popover .popover-content .note-dropdown-menu.right {
        left: 0;
        right: auto;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right:before,
    .note-popover .popover-content .note-dropdown-menu.right:before {
        left: 9px;
        right: auto !important;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right:after,
    .note-popover .popover-content .note-dropdown-menu.right:after {
        left: 10px;
        right: auto !important;
    }

    .note-dropdown-menu {
        right: auto !important;
        left: inherit !important;
    }

    .note-handle .note-control-selection {
        .note-control-nw {
            right: -5px;
            left: inherit;
            border-right: none;
            border-left: inherit;
        }

        .note-control-ne {
            left: -5px;
            right: inherit;
            border-left: none;
            border-right: inherit;
        }

        .note-control-sw {
            right: -5px;
            left: inherit;
            border-right: none;
            border-left: inherit;
        }

        .note-control-se {
            left: -5px;
            right: inherit;

            &.note-control-holder {
                border-left: none;
                border-right: inherit;
            }
        }

        .note-control-selection-info {
            left: 0;
            right: inherit;
        }
    }

    .note-editor .btn-group .btn + .btn-group {
        border-left: 0px solid rgba(0, 0, 0, 0.08) !important;
        border-right: inherit;
    }

    input {
        &[type="radio"],
        &[type="checkbox"] {
            margin-left: 7px;
            margin-right: inherit;
        }
    }

    .SumoSelect {
        &.open .search-txt {
            right: 0;
            left: inherit;
        }

        > {
            .CaptionCont > {
                span {
                    padding-left: 30px;
                    padding-right: inherit;
                }

                label {
                    left: 0;
                    right: inherit;

                    > i:before {
                        margin-right: 0.255em;
                        margin-left: inherit;
                    }
                }
            }

            .optWrapper {
                right: 0;
                left: inherit;

                > .options li ul li.opt {
                    padding-right: 22px;
                    padding-left: inherit;
                }

                &.multiple > .options li ul li.opt {
                    padding-right: 50px;
                    padding-left: inherit;
                }
            }
        }

        .select-all {
            > span {
                margin-right: -35px;
                margin-left: inherit;
            }

            padding: 8px 35px 3px 0px;
        }

        > .optWrapper.multiple {
            > {
                .options li.opt {
                    padding-right: 35px;
                    padding-left: inherit;
                }

                .MultiControls > p.btnOk {
                    border-right: 1px solid #dbdbdb;
                    border-left: inherit;
                    border-radius: 0 0 3px 0px;
                }
            }

            &.isFloating > .options li.opt {
                padding-right: 35px;
                padding-left: inherit;
            }
        }

        > .optWrapper > .options li.optGroup {
            padding-right: 5px;
            padding-left: inherit;
        }
    }

    .sweet-alert {
        right: 50%;
        left: inherit;
        margin-right: -256px;
        margin-left: inherit;

        .sa-error-container .icon {
            margin-left: 3px;
            margin-right: inherit;
        }

        .sa-input-error {
            left: 26px;
            right: inherit;

            &::before,
            &::after {
                right: 50%;
                left: inherit;
                margin-right: -9px;
                margin-left: inherit;
            }
        }

        .la-ball-fall {
            right: 50%;
            left: inherit;
            margin-right: -27px;
            margin-left: inherit;
        }

        .sa-icon {
            &.sa-error .sa-line {
                &.sa-left {
                    right: 17px;
                    left: inherit;
                }

                &.sa-right {
                    left: 16px;
                    right: inherit;
                }
            }

            &.sa-warning {
                .sa-body {
                    right: 50%;
                    left: inherit;
                    margin-right: -2px;
                    margin-left: inherit;
                }

                .sa-dot {
                    margin-right: -3px;
                    left: inherit;
                    right: 50%;
                    margin-left: inherit;
                }
            }

            &.sa-info {
                &::before {
                    right: 50%;
                    left: inherit;
                    margin-right: -2px;
                    margin-left: inherit;
                }

                &::after {
                    margin-right: -3px;
                    margin-left: inherit;
                }
            }
        }
    }

    @-webkit-keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0px;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    @keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0px;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    .iti input {
        padding-left: 36px;
        padding-right: inherit;
        margin-left: 0;
        margin-right: inherit;

        &[type="text"],
        &[type="tel"] {
            padding-left: 36px;
            padding-right: inherit;
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .iti__flag-container {
        left: 0;
        right: inherit;
    }

    .iti__selected-flag {
        padding: 0 8px 0 6px;
        border-right: 1px solid #e1e6f1;
        border-left: inherit;
    }

    .iti__arrow {
        margin-right: 6px;
        margin-left: inherit;
    }

    .iti__country-list {
        text-align: right;
    }

    .iti__flag-box,
    .iti__country-name {
        margin-left: 6px;
        margin-right: inherit;
    }

    .iti--allow-dropdown input {
        padding-left: 6px !important;
        padding-right: 52px !important;
        margin-right: 0;
        margin-left: inherit;

        &[type="text"],
        &[type="tel"] {
            padding-left: 6px !important;
            padding-right: 52px !important;
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .iti--separate-dial-code input {
        padding-left: 6px !important;
        padding-right: 52px !important;
        margin-right: 0;
        margin-left: inherit;

        &[type="text"],
        &[type="tel"] {
            padding-left: 6px !important;
            padding-right: 52px !important;
            margin-right: 0;
            margin-left: inherit;
        }
    }

    .iti--allow-dropdown .iti__flag-container {
        left: auto;
        right: 0;
    }

    .iti--separate-dial-code {
        .iti__flag-container {
            left: auto;
            right: 0;
        }

        .iti__selected-dial-code {
            margin-right: 6px;
            margin-left: inherit;
        }
    }

    .iti--container {
        right: -1000px;
        left: inherit;
    }

    .timeline:not(.timeline--horizontal):before {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .timeline__item {
        padding: 0.625rem 0rem 0.625rem 2.5rem;

        &:after {
            left: -10px;
            right: inherit;
        }
    }

    .timeline__item--left {
        right: 0;
        left: inherit;
    }

    .timeline__item--right {
        right: 50%;
        left: inherit;

        &:after {
            right: -10px;
            left: inherit;
        }

        .timeline__content {
            &:before {
                border-right: 12px solid #e1e6f1;
                border-left: none;
                right: -12px;
                left: inherit;
            }

            &:after {
                border-right: 11px solid #fff;
                border-left: none;
                right: -10px;
                left: inherit;
            }
        }
    }

    .timeline__content {
        &:before {
            border-left: 12px solid #e1e6f1;
            left: -12px;
            right: inherit;
        }

        &:after {
            border-bottom: 9px solid transparent;
            border-left: 11px solid #fff;
            left: -10px;
            right: inherit;
        }
    }

    .timeline--horizontal .timeline__item {
        right: 0;
        left: inherit;

        &:after {
            right: 50%;
            left: auto;
        }

        .timeline__content {
            &:before {
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                right: 50%;
                left: auto;
            }

            &:after {
                right: 50%;
                left: auto;
            }
        }
    }

    .timeline-nav-button:before {
        right: 50%;
        left: inherit;
    }

    .timeline-nav-button--prev {
        right: 0;
        left: inherit;
    }

    .timeline-nav-button--next {
        left: 0;
        right: inherit;
    }

    .timeline--mobile {
        &:before {
            right: 10px !important;
            left: inherit !important;
        }

        .timeline__item {
            right: 0;
            left: inherit;
            padding-right: 40px;
            padding-left: 0;

            &:after {
                right: 2px;
                left: inherit;
            }

            .timeline__content {
                &:before {
                    right: -12px;
                    left: inherit;
                    border-right: 12px solid #e1e6f1;
                    border-left: none;
                }

                &:after {
                    right: -10px;
                    left: inherit;
                    border-right: 10px solid #fff;
                    border-left: none;
                }
            }
        }
    }

    .tree {
        ul {
            margin-right: 1em;
            margin-left: inherit;

            ul {
                margin-right: 0.5em;
                margin-left: inherit;
            }

            &:before {
                right: 0;
                left: inherit;
                border-left: 1px solid #e3e3e3;
                border-right: inherit;
            }
        }

        li i {
            margin-left: 5px;
            margin-right: inherit;
            float: right;
            margin-top: 6px;
        }

        ul li:before {
            right: 0;
            left: inherit;
        }
    }

    .indicator {
        margin-left: 5px;
        margin-right: inherit;
    }

    .richText {
        .richText-toolbar ul {
            padding-right: 0;
            padding-left: inherit;

            li {
                float: right;

                a .richText-dropdown-outer {
                    .richText-dropdown .richText-dropdown-close {
                        left: 5px;
                        right: inherit;
                    }

                    ul.richText-dropdown li.inline {
                        float: right;
                    }
                }
            }
        }

        .richText-editor {
            border-left: #ffffff solid 2px;
            border-right: inherit;

            &:focus {
                border-left: transparent solid 2px;
                border-right: inherit;
            }
        }

        .richText-help {
            float: left;
        }

        .richText-undo,
        .richText-redo {
            float: left;
            border-right: #efefef solid 1px;
            border-left: inherit;
        }

        .richText-list.list-rightclick {
            border-right: #efefef solid 1px;
            border-left: inherit;
        }
    }
}

.rtl {
    .fe-chevron-right:before {
        content: "\e92e";
    }

    .fe-chevron-left:before {
        content: "\e92f";
    }
    .card-crypto-scroll:before {
        background: linear-gradient(to right, transparent 0%, #e4e3ef 100%);
    }
    .card-crypto-scroll:after {
        background: linear-gradient(to right, #e4e3ef 0%, transparent 100%);
    }
    .la-angle-right:before {
        content: "\f104";
    }
    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: 0;
    }
    .ion-ios-arrow-forward:before {
        content: "\f3cf";
    }
    .ion-ios-arrow-back:before {
        content: "\f3d1";
    }
    .ti-angle-double-right:before {
        content: "\e662";
    }
    .ti-angle-double-left:before {
        content: "\e661";
    }
    .ti-angle-left:before {
        content: "\e649";
    }
    .ti-angle-right:before {
        content: "\e64a";
    }
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
    .bs-popover-start > .popover-arrow {
        right: calc(-0.5rem - 1px);
        left: inherit;
    }
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
    .bs-popover-start > .popover-arrow::before {
        right: 0;
        left: inherit;
        border-width: 0.5rem 0rem 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25);
        border-right-color: inherit;
    }
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
    .bs-popover-start > .popover-arrow::after {
        right: 1px;
        left: inherit;
        border-width: 0.5rem 0rem 0.5rem 0.5rem;
        border-left-color: #fff;
        border-right-color: inherit;
    }
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
    .bs-popover-end > .popover-arrow::after {
        left: 1px;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0rem;
        border-right-color: #fff;
        border-left-color: inherit;
    }
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
    .bs-popover-end > .popover-arrow::before {
        left: 0;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0rem;
        border-right-color: rgba(0, 0, 0, 0.25);
        border-left-color: inherit;
    }
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
    .bs-popover-end > .popover-arrow {
        left: calc(-0.5rem - 1px);
        right: inherit;
    }
    .bs-popover-end > .tooltip-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow::after {
        right: 1px;
        left: inherit;
        border-width: 0.5rem 0rem 0.5rem 0.5rem;
        border-left-color: #fff;
        border-right-color: inherit;
    }
    .bs-popover-end > .tooltip-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow::before {
        right: 0;
        left: inherit;
        border-width: 0.5rem 0rem 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25);
        border-right-color: inherit;
    }
    .bs-popover-end > .tooltip-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow::after {
        right: 1px;
        left: inherit;
        border-width: 0.5rem 0rem 0.5rem 0.5rem;
        border-left-color: #fff;
        border-right-color: inherit;
    }
    .bs-popover-end > .tooltip-arrow,
    .bs-popover-auto[data-popper-placement^="right"] > .tooltip-arrow {
        right: calc((0.5rem + 1px) * -1);
        left: 50%;
    }
    .bs-popover-start > .tooltip-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow::after {
        left: 1px;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0rem;
        border-right-color: #fff;
        border-left-color: inherit;
    }
    .bs-popover-start > .tooltip-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow::before {
        left: 0;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0rem;
        border-right-color: rgba(0, 0, 0, 0.25);
        border-left-color: inherit;
    }
    .bs-popover-start > .tooltip-arrow,
    .bs-popover-auto[data-popper-placement^="left"] > .tooltip-arrow {
        left: calc((0.5rem + 1px) * -1);
        right: auto;
    }
    .fa-angle-right:before {
        content: "\f104";
    }
    .fa-angle-left:before {
        content: "\f105";
    }
    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }
    .counter-icon.num-counter {
        border-radius: 40% 0 40% 0;
    }
    .counter-style {
        border-radius: 20px 0 20px 0;
    }
    .lg-actions .lg-prev:after {
        content: "\e095";
    }
    .lg-actions .lg-next:before {
        content: "\e094";
    }
    .datetimepicker table th.next span::before {
        content: "\f3cf";
    }
    .datetimepicker table th.prev span::before {
        content: "\f3d1";
    }
    .datepicker {
        direction: rtl;
        right: auto;
        left: 0;
    }
    .main-datetimepicker > .datepicker_header a:last-child::before {
        content: "\f3cf";
    }
    .main-datetimepicker > .datepicker_header a:nth-child(2)::before {
        content: "\f3d1";
    }
    [type="email"],
    [type="number"],
    [type="tel"],
    [type="url"] {
        direction: rtl;
    }
    .SumoSelect > .optWrapper.multiple > .options li.opt span {
        margin-right: -35px;
        margin-left: inherit;
    }
    .was-validated .form-control:valid,
    .form-control.is-valid,
    input.form-control.parsley-success,
    textarea.form-control.parsley-success {
        background-position: center left calc(0.375em + 0.1875rem);
    }
    &.login-page .demo-icon {
        right: auto;
        left: 60px;
    }
    .btn-rounded {
        border-radius: 50px !important;
    }
    @media (min-width: 1400px) {
        &.layout-boxed .main-sidemenu .slide-right {
            left: 6px;
            right: inherit;
        }
        &.layout-boxed .main-sidemenu .slide-left {
            right: 8px;
            left: inherit;
        }
        &.layout-boxed .app-sidebar {
            right: auto !important;
            left: inherit !important;
        }
    }
    #slide-right svg,
    #slide-left svg {
        transform: rotate(180deg);
    }
    #checkoutsteps .item .purchase {
        margin-right: 21px;
        margin-left: inherit;
    }
    #checkoutsteps .total {
        float: left;
    }
    &.theme-style {
        .main-sidebar-header {
            border-left: 1px solid #d4d2e2;
            border-right: inherit;
        }
        .app-sidebar {
            border-left: 1px solid #d4d2e2;
            border-right: inherit;
        }
    }
    @media (max-width: 360px) {
        .vtimeline .timeline-wrapper .timeline-panel:after {
            right: -11px !important;
            left: inherit !important;
        }
        .datepicker.main-datetimepicker {
            right: -77px;
            left: inherit;
        }
    }
    .list-group-item {
        i {
            display: inline-block;
        }
    }
    .panel-heading1 a:before {
        left: 10px;
        right: inherit;
    }
    .fe-arrow-right:before {
        content: "\e90f";
    }
    .panel-heading1 a.collapsed:before {
        left: 10px;
        right: inherit;
    }
    .accor i, .accordion-toggle i{
        display: inline-block;
    }
}
