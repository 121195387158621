/* ###### 3.1 Accordion ###### */

.accordion {
  .card {
    border-width: 0;

    + .card {
      margin-top: 1px;
    }
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    font-weight: 500;

    a {
      display: block;
      padding: 14px 20px;
      color: $default-color;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      background-color: #eeedf7;

      &:hover, &:focus {
        color: $default-color;
      }

      &.collapsed {
        color: #596882;
        background-color: #eeedf7;
        border-bottom-color: transparent;

        &:hover, &:focus {
          color: $default-color;
          background-color: #e9e8f5;
        }
      }
    }
  }

  .card-body {
    /* padding: 0 20px 20px; */
    background-color: #eeedf7;
    transition: none;
  }
}

.accordion > .card {
  overflow: hidden;

  &:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 0;
      border-radius: 0;
    }
  }

  &:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-header {
    margin-bottom: -1px;
  }
}


.accordionjs {
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	box-shadow: 0px 0px 51px 0px rgba(122, 128, 144, 0.08), 0px 6px 18px 0px rgba(122, 128, 144, 0.05);

	.acc_section {
		border: 1px solid #eff2f7;
		position: relative;
		z-index: 10;
		margin-top: -1px;
		overflow: hidden;

		.acc_head {
			position: relative;
			background: $white;
			padding: 15px;
			display: block;
			cursor: pointer;

			h3 {
				line-height: 1;
				margin: 5px;
				font-size: 15px;
			}
		}

		.acc_content {
			padding: 15px;
			background: $white;
		}

		&:first-of-type {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			.acc_head {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
		}

		&:last-of-type {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;

			.acc_content {
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}

		&.acc_active {
			> {
				.acc_content {
					display: block;
				}

				.acc_head {
					border-bottom: 1px solid #eff4fc;
					color: #6a7882;
				}
			}

			.acc_head h3:before {
				content: "\e994";
				position: absolute;
				font-family: "feather" !important;
				right: 15px;
				top: 17px;
				font-size: 20px;
				transition: all 0.5s;
				transform: scale(1);
			}
		}

		.acc_head h3:before {
			content: "\e9af";
			position: absolute;
			font-family: "feather" !important;
			right: 15px;
			top: 17px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
}

.panel-heading1.bg-primary .panel-title1 a {
  display: block;
  color: #fff;
}

.panel-title1 a {
  display: block;
  color: #fff;
  padding: 15px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
}

.panel-title1 {
  margin-bottom: 0;
}

.panel-heading1 a:before {
  content: "\e994";
  position: absolute;
  font-family: "feather" !important;
  right: 10px;
  top: 12px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}

.panel-heading1 a.collapsed:before {
  content: "\e9af";
  position: absolute;
  font-family: "feather" !important;
  right: 10px;
  top: 12px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}

.accor h4 a {
  padding: 0.75rem 1.25rem;
  display: block;
  font-size: 15px;
  border-bottom: 1px solid rgba(154, 180, 202, 0.16);
}

.accor.bg-primary h4 a {
  color: #fff;
}

.collapse:not(.show) {
  display: none;
}


