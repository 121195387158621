/* ###### 5.3 Forms   ###### */

/* Checkbox */

.ckbox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 1;
	margin-bottom: 0;
  
	span {
	  padding-left: 15px;
  
	  &:empty {
		float: left;
	  }
  
	  &:before, &:after {
		
		position: absolute;
	  }
  
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid #dfe6f5;
		top: 1px;
		left: 0;
		line-height: 18px;
		border-radius: 3px;
	  }
  
	  &:after {
		top: 1px;
		left: 0;
		width: 16px;
		height: 16px;
		content: '';
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		background-size: 65%;
		background-repeat: no-repeat;
		background-position: 55% 45%;
		background-color: $primary;
		line-height: 17px;
		display: none;
		border-radius: 3px;
	  }
  
	  &:empty {
		padding-left: 0;
		width: 3px;
	  }
	}
  
	input[type='checkbox'] {
	  opacity: 0;
	  margin: 0;
  
	  &:checked + span:after {
		display: block;
	  }
  
	  &[disabled] + span {
		opacity: .75;
  
		&:before, &:after {
		  opacity: .75;
		}
	  }
	}
  }
  
  .ckbox-inline {
	display: inline-block;
  }
  
  /* Radio Box */
  
  .rdiobox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 18px;
  
	span {
	  padding-left: 13px;
  
	  &:before, &:after {
		line-height: 18px;
		position: absolute;
	  }
  
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid #97a3b9;
		border-radius: 50px;
		top: 2px;
		left: 0;
	  }
  
	  &:after {
		content: '';
		width: 6px;
		height: 6px;
		background-color: $white;
		border-radius: 50px;
		top: 7px;
		left: 5px;
		display: none;
	  }
  
	  &:empty {
		padding-left: 0;
		width: 16px;
		display: block;
	  }
	}
  
	input[type='radio'] {
	  opacity: 0;
	  margin: 0;
  
	  &:checked + span {
		&:before {
		  border-color: transparent;
		  background-color: $primary;
		}
  
		&:after {
		  display: block;
		}
	  }
  
	  &[disabled] + span {
		opacity: .75;
  
		&:before, &:after {
		  opacity: .75;
		}
	  }
	}
  }
  
  .rdiobox-inline {
	display: inline-block;
  }
  
  .form-group-rdiobox {
	display: flex;
	align-items: center;
  
	.rdiobox {
	  margin-bottom: 0;
  
	  + .rdiobox {
		margin-left: 30px;
	  }
  
	  span {
		padding-left: 0;
	  }
	}
  }
  
  .main-toggle {
	width: 60px;
	height: 25px;
	background-color: #b4bdce;
	padding: 2px;
	position: relative;
	overflow: hidden;
	border-radius: 2px;
  
	span {
	  position: absolute;
	  top: 3px;
	  bottom: 3px;
	  left: 3px;
	  display: block;
	  width: 20px;
	  background-color: $white;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;
  
	  &::before, &::after {
		position: absolute;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: .5px;
		text-transform: uppercase;
		color: $white;
		top: 2px;
		line-height: 1.38;
	  }
  
	  &::before {
		content: 'on';
		left: -25px;
	  }
  
	  &::after {
		content: 'off';
		right: -29px;
	  }
	}
  
	&.on {
	  background-color: $primary;
  
	  span {
		left: 37px;
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-toggle span {
	  transition: none;
	}
  }
  
  .main-toggle-secondary.on {
	background-color: $primary;
  }
  
  .main-toggle-success.on {
	background-color: $success;
  }
  
  .main-toggle-dark.on {
	background-color: $default-color;
  }
  
  .main-form-group {
	padding: 12px 15px;
	border: 1px solid $border;
	border-radius: 3px;
  
	&.focus {
	  border-color: #b4bdce;
	  box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
	}
  
	.form-label {
	  font-size: 13px;
	  margin-bottom: 2px;
	  font-weight: 400;
	  color: #97a3b9;
	}
  
	.form-control {
	  padding: 0;
	  border-width: 0;
	  height: 25px;
	  color: $default-color;
	  font-weight: 500;
  
	  &:focus {
		box-shadow: none;
	  }
	}
  }
  .theme-container .active {
    border: 1px solid $primary;
    padding: 5px 10px;
    background: white;
    border-radius: 3px;
    color: $primary;
	margin-bottom: 2px;
}
.pickr .pcr-button {
    position: relative;
    height: 2em;
    width: 2em;
    padding: 0.5em;
    cursor: pointer;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue",Arial,sans-serif;
    border-radius: 0.15em;
    // background: url(data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>) no-repeat center;
    // background-size: 0;
    // transition: all 0.3s;

 }

.pickr .pcr-button::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s;
    background: var(--pcr-color);
    border-radius: 0.15em;
}
.theme-container1 .active {
    border: 1px solid #fc5296;
    padding: 5px 10px;
    background: white;
    border-radius: 3px;
    color: #fc5296;
	margin-bottom: 2px;
}
.theme-container2 .active {
    border: 1px solid $secondary;
    padding: 5px 10px;
    background: white;
    border-radius: 3px;
    color: $secondary;
	margin-bottom: 2px;
}
.ms-choice>span.placeholder {
    display: none;
}
.check-md .custom-control-label::before{
	height: 1.5rem;
	width: 1.5rem;
	content: '';
	background-color: $white;
	border: 1px solid #dfe6f5;
	top: -3px;
	left: 0px;
	line-height: 18px;
	border-radius: 3px;
}
.check-md .custom-control-label::after{
	height: 1.5rem;
	width: 1.5rem;
	top: -3px;
	left: 0px;
	content: '';
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	background-size: 65%;
	background-repeat: no-repeat;
	background-position: 55% 45%;
	background-color: $primary;
	line-height: 17px;
	display: none;
	border-radius: 3px;
}
.check-lg .custom-control-label::before{
	height: 2rem;
	width: 2rem;
	content: '';
	background-color: $white;
	border: 1px solid #dfe6f5;
	top: -6px;
	left: 0px;
	line-height: 18px;
	border-radius: 3px;
	
}
.check-lg .custom-control-label::after{
	height: 2rem;
	width: 2rem;
	left: 0px;
	top: -7px;
	content: '';
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	background-size: 65%;
	background-repeat: no-repeat;
	background-position: 55% 45%;
	background-color: $primary;
	line-height: 17px;
	display: none;
	border-radius: 3px;
}
.rdiobox-md .custom-control-label::before{
	font-weight: normal;
	position: absolute;
	display: block;
	line-height: 18px;
	width: 1.5rem;
	height: 1.5rem;
	background-color: $white;
	border: 1px solid #dfe6f5;
	border-radius: 50px;
	top: -3px;
	left: -16px;
}
.rdiobox-md .custom-control-label::after{
	font-weight: normal;
	position: absolute;
	display: block;
	line-height: 18px;
	content: '';
	width: 6px;
	height: 6px;
	background-color: $white;
	border-radius: 50px;
	top: 6px;
	left: -7px;
	display: none;

}
.rdiobox-lg .custom-control-label::before{
	font-weight: normal;
	position: absolute;
	display: block;
	line-height: 18px;
	width: 2rem;
	height: 2rem;
	background-color: $white;
	border: 1px solid #dfe6f5;
	border-radius: 50px;
	top: -7px;
	left: -15px;
}
.rdiobox-lg .custom-control-label::after{
	font-weight: normal;
	position: absolute;
	display: block;
	line-height: 18px;
	content: '';
	width: 0.55rem;
	height: 0.55rem;
	background-color: $white;
	border-radius: 50px;
	top: 5px;
	left: -4px;
	display: none;
}
.check-md .custom-control-label{
padding-left: 33px;

}
.check-lg .custom-control-label{
	padding-left: 41px;
}
.rdiobox-md .custom-control-label{
   padding-left: 17px;
}
.rdiobox-lg .custom-control-label{
	padding-left: 25px;
 }
 .custom-switch-input:checked~.custom-switch-indicator:before {
    left: calc(1rem + 3px);
}
 .custom-switch-indicator-lg {
    display: inline-block;
    height: 2rem;
    width: 4rem;
	top: 6px;
    background: #f6f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #e9edf4;
    transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator-lg::before {
    content: "";
    position: absolute;
    height: calc(2rem - 4px);
    width: calc(2rem - 4px);
    top: 1px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
}
.custom-switch-indicator-md {
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    background: #f6f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #e9edf4;
    transition: 0.3s border-color, 0.3s background-color;
}
.custom-switch-indicator-md:before{
	position: absolute;
	top: 2.5px;

}

.select2-lg .select2-container .select2-selection--single {
    height: calc(2.6975rem + 2px) !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 100%;
}.select2-container .select2-selection--single {
    height: 2.375rem !important;
}
.select2-sm .select2-container .select2-selection--single {
    height: calc(1.7rem + 4px) !important;
    padding-top: 0.391rem;
    padding-bottom: 0.391rem;
    font-size: 0.75rem;
}
.form-control-sm {
    padding: 0.251rem 0.51rem;
    font-size: 0.75rem;
    line-height: 1.5;
	height: calc(1.7em + 0.5rem + 2px) !important;
    border-radius: 0.2rem;
}

/* ###### Forms Validation  ###### */
.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem);
    // background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #198754;
}

element.style {
}
.was-validated .form-file-input:invalid~.form-file-label, .form-file-input.is-invalid~.form-file-label {
    border-color: #e23e3d;
}
.form-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 38px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #76839a;
    background-color: #f6f6fb;
    border: 1px solid #e9edf4;
    border-radius: 3px;
}
.form-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 2.375rem;
}
.form-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 2.375rem;
    margin: 0;
    opacity: 0;
}
.form-file-input:lang(en)~.form-file-label::after {
    content: "Browse";
}
.form-file-label::after {
    background-color:$primary;
}
.form-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(2.375rem - 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: $white;
    content: "Browse";
    border-left: 1px solid #e9edf4;
    border-radius: 0 3px 3px 0;
}

/* ###### Forms Layouts ###### */
.card-pay .tabs-menu {
    margin-bottom: 25px;
    // border-radius: 7px;
    overflow: hidden;
}
.card-pay .tabs-menu li {
    width: 33.3%;
    display: block;
}
.card-pay .tabs-menu li a {
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
    border: 1px solid #e9edf4;
    color: #282f53;
	border-radius: 3px;
}
.card-pay .tabs-menu li a.active {
    background: $primary;
    color: $white;
    text-align: center;
}


@media screen and (max-width: 480px){
.sw-theme-dots>ul.step-anchor>li>a:after {
    top: -44px !important;
    left: -22px !important;
    margin-right: 10px !important;
    display: block !important;
}
}

@media screen and (max-width: 373px){
.sw-toolbar .sw-btn-group{
     margin-bottom: 4px;

}
}
.sw-theme-dots>ul.step-anchor>li>a:after {
    content: ' ';
    position: relative;
    left: 48%;
    bottom: 3px;
    margin-top: 24px;
    display: block;
    width: 16px;
    height: 16px;
    background: #b4bdce;
    border-radius: 50%;
    z-index: 99;
}
.sw-theme-dots>ul.step-anchor:before {
    content: " ";
    position: absolute;
    top: 58px;
    bottom: 0;
    left: 20px;
    width: 96%;
    height: 5px;
    background-color: #ebeff8;
    border-radius: 3px;
    z-order: 0;
    z-index: 9;
}
.note-editor.note-airframe, .note-editor.note-frame {
    border: 1px solid #e9edf4;
	border-radius: 3px;
}
.ql-toolbar.ql-snow {
    border-radius: 3px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.ql-container.ql-snow {
    border: 1px solid #ccc;
	border-radius: 3px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}