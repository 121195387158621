

:root {
    --primary-bg-color: #5965f9;
    --primary-bg-hover: #233ac5;
    --primary-bg-border: #5161c1;
	--dark-body: #0c1023;
	--dark-theme: #191d30;
	--dark-border: rgba(255, 255, 255, 0.15);
	--primary01: rgba(89,101,249, 0.1);
	--primary006 : rgba(89,101,249, 0.06);
	--primary015 : rgba(89,101,249, 0.15);
	--primary09 : rgba(89,101,249, 0.9);
	--blue:$primary;
	--indigo:$primary;
	--purple:$purple;
	--pink:$pink;
	--red:$danger;
	--orange:$orange;
	--yellow:$warning;
	--green:$success;
	--teal:$teal;
	--cyan:$info;
	--white:$white;
	--gray:#8587a7;
	--gray-dark:$dark;
	--primary:$primary;
	--secondary:#8587a7;
	--success:$success;
	--info:$info;
	--warning:$warning;
	--danger:$danger;
	--light:#f4f5f8;
	--dark:$dark;
	--breakpoint-xs:0;
	--breakpoint-sm:576px;
	--breakpoint-md:768px;
	--breakpoint-lg:992px;
	--breakpoint-xl:1200px;
	--font-family-sans-serif:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  * {
	box-sizing: border-box;
  
	&::before, &::after {
	  box-sizing: border-box;
	}
  }
  
  html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
  }
  
  body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #343a40;
	text-align: left;
	background-color: $background;
  }
  
  [tabindex="-1"]:focus {
	outline: 0 !important;
  }
  
  hr {
	box-sizing: content-box;
	height: 0 !important;
	overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
  }
  
  p {
	margin-top: 0;
	margin-bottom: 1rem;
  }
  
  abbr {
	&[title], &[data-original-title] {
	  text-decoration: underline;
	  text-decoration: underline dotted;
	  cursor: help;
	  border-bottom: 0;
	  text-decoration-skip-ink: none;
	}
  }
  
  address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
  }
  
  ol, ul, dl {
	margin-top: 0;
	margin-bottom: 1rem;
  }
  
  ol ol, ul ul, ol ul, ul ol {
	margin-bottom: 0;
  }
  
  dt {
	font-weight: 700;
  }
  
  dd {
	margin-bottom: .5rem;
	margin-left: 0;
  }
  
  blockquote {
	margin: 0 0 1rem;
  }
  
  b, strong {
	font-weight: bolder;
  }
  
  small {
	font-size: 80%;
  }
  
  sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
  }
  
  sub {
	bottom: -.25em;
  }
  
  sup {
	top: -.5em;
  }
  
  a {
	color: $primary;
	text-decoration: none;
	background-color: transparent;
	&:hover {
	  color: #0039e6;
	  text-decoration: none;
	}
  
	&:not([href]):not([tabindex]) {
	  color: inherit;
	  text-decoration: none;
  
	  &:hover {
		color: inherit;
		text-decoration: none;
	  }
  
	  &:focus {
		color: inherit;
		text-decoration: none;
		outline: 0;
	  }
	}
  }
  
  pre, code, kbd, samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
  }
  
  pre {
	margin-top: 0;
	margin-bottom: 0rem;
	max-width: 100%;
  }
  
  figure {
	margin: 0 0 1rem;
  }
  
  img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
	// border-radius: 6px;
  }
  
  svg {
	overflow: hidden;
	vertical-align: middle;
  }
  
  table {
	border-collapse: collapse;
  }
  
  caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #8587a7;
	text-align: left;
	caption-side: bottom;
  }
  
  th {
	text-align: inherit;
  }
  
  label {
	display: inline-block;
	margin-bottom: 0.5rem;
  }
  
  button {
	border-radius: 0;
  
	&:focus {
	  outline: 1px dotted;
	  outline: none;
	}
  }
  
  input, button, select, optgroup, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
  }
  
  button, input {
	overflow: visible;
  }
  
  button {
	text-transform: none;
  }
  
  select {
	text-transform: none;
	word-wrap: normal;
  }
  
  button, [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
  }
  
  button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
	cursor: pointer;
  }
  
  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
  }
  
  input {
	&[type="radio"], &[type="checkbox"] {
	  box-sizing: border-box;
	  padding: 0;
	}
  
	&[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
	  -webkit-appearance: listbox;
	}
  }
  
  textarea {
	overflow: auto;
	resize: vertical;
  }
  
  fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
  }
  
  legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
  }
  
  progress {
	vertical-align: baseline;
  }
  
  [type="number"] {
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
	  height: auto;
	}
  }
  
  [type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
  
	&::-webkit-search-decoration {
	  -webkit-appearance: none;
	}
  }
  
  ::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
  }
  
  output {
	display: inline-block;
  }
  
  summary {
	display: list-item;
	cursor: pointer;
  }
  
  template {
	display: none;
  }
  
  [hidden] {
	display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.66rem;
	font-weight: 500;
	line-height: 1.2;
	color: infinite;
	font-family: inherit;
  }
  
  h1, .h1 {
	font-size: 2.1875rem;
  }
  
  h2, .h2 {
	font-size: 1.75rem;
  }
  
  h3, .h3 {
	font-size: 1.53125rem;
  }
  
  h4, .h4 {
	// font-size: 14px !important;
	font-size: 1.3125rem;
  }
  
  h5, .h5 {
	font-size: 1rem;
  }
  
  h6, .h6 {
	font-size: 0.875rem;
  }
  
  .lead {
	font-size: 1.09375rem;
	font-weight: 300;
  }
  
  .display-1 {
	font-size: 5rem;
	font-weight: 500;
	line-height: 1.1;
  }
  
  .display-2 {
	font-size: 4rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-3 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-4 {
	font-size: 3rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
.display-5 {
    font-size: 2.5rem !important;
    font-weight: 300;
    line-height: 1.1;
}
.display-6 {
    font-size: 2.3rem;
    font-weight: 300;
    line-height: 1.1;
}
.display-7 {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.1;
}
.display-8 {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
}
  
  hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid $black-1;
	background-color: $black-3;
  }
  
  small, .small {
	font-size: 80%;
	font-weight: 400;
  }
  
  mark, .mark {
	padding: 0.2em;
	background-color: #fcf8e3;
  }
  
  .list-unstyled, .list-inline {
	padding-left: 0;
	list-style: none;
  }
  
  .list-inline-item {
	display: inline-block;
  
	&:not(:last-child) {
	  margin-right: 0.5rem;
	}
  }
  
  .initialism {
	font-size: 90%;
	text-transform: uppercase;
  }
  
  .blockquote {
	margin-bottom: 1rem;
	font-size: 1.09375rem;
  }
  
  .blockquote-footer {
	display: block;
	font-size: 80%;
	color: #8587a7;
  
	&::before {
	  content: "\2014\00A0";
	}
  }
  
  .img-fluid {
	max-width: 100%;
	height: auto;
  }
  
  .img-thumbnail {
	padding: 0.25rem;
	background-color: $white;
	border: 1px solid $border;
	max-width: 100%;
	height: auto;
	border-radius: 9px !important;
  }
  
  .figure {
	display: inline-block;
  }
  
  .figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
  }
  
  .figure-caption {
	font-size: 90%;
	color: #8587a7;
  }
  
  code {
	font-size: 87.5%;
    color: $secondary;
    word-break: break-word;
    background: #f6f6fb;
    border: 1px solid #e9edf4;
    border-radius: 3px 1px;
    padding: 0px 3px;
  }
  
  a > code {
	color: inherit;
  }
  
  kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: $white;
	background-color: $default-color;
	border-radius: 0.2rem;
  
	kbd {
	  padding: 0;
	  font-size: 100%;
	  font-weight: 700;
	}
  }
  
  pre {
	display: block;
	font-size: 87.5%;
	color: $default-color;
  
	code {
	  font-size: inherit;
	  color: inherit;
	  word-break: normal;
	}
  }
  
  .clearfix::after {
	display: block;
	clear: both;
	content: "";
  }
  
  .pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
  }
  
  @keyframes spinner-border {
	to {
	  transform: rotate(360deg);
	}
  }
  
  .spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border .75s linear infinite;
  }
  
  .spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
  }
  
  @keyframes spinner-grow {
	0% {
	  transform: scale(0);
	}
  
	50% {
	  opacity: 1;
	}
  }
  
  .spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: spinner-grow .75s linear infinite;
  }
  
  .spinner-grow-sm {
	width: 1rem;
	height: 1rem;
  }
  
  .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
  }
  
  .sr-only-focusable {
	&:active, &:focus {
	  position: static;
	  width: auto;
	  height: auto;
	  overflow: visible;
	  clip: auto;
	  white-space: normal;
	}
  }
  
  .stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0, 0, 0, 0);
  }
  
  /* ########## BOOTSTRAP OVERRIDES ########## */
  
  /* ###### 4.8 Box-shadows ###### */
  
  .box-shadow-primary {
	box-shadow: 0 5px 10px rgba(40, 92, 247, 0.25);
  }
  .default-shadow {
	box-shadow: 0 5px 10px  rgb(209 209 209);
  }
  
  .box-shadow-success {
	box-shadow: 0 5px 10px rgba(59, 176, 1, 0.25);
  }
  
  .box-shadow-warning {
	box-shadow: 0 5px 10px rgba(255, 193, 7, 0.25);
  }
  
  .box-shadow-danger {
	box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
  }
  
  .box-shadow-pink {
	box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
  }
  
  /* ###### 4.8 transparent colors ###### */
  
  .bg-success-transparent {
	background-color: #d4f2e1 !important;
  }
  
  .bg-danger-transparent {
	background-color: #f9e2e2 !important;
	border-color: orange !important;
  }
  
  .bg-primary-transparent {
	background-color: $primary-015 !important;
  }
  
  .bg-secondary-transparent {
	background-color: rgba(0, 123, 255, 0.13) !important;
  }
  
  .bg-warning-transparent {
	background-color: rgb(253, 246, 222) !important;
  }
  
  .bg-pink-transparent {
	background-color: #fee5f1 !important;
	border-color: pink !important;
  }
  
  .bg-purple-transparent {
	background-color: #e1defe !important;
  }
  
  .bg-teal-transparent {
	background-color: #d2f5f5 !important;
  }
  
  .bg-info-transparent {
	background-color: rgba(23, 162, 184, 0.2) !important;
  }
  
  .bg-orange-transparent {
	background-color: rgba(253, 126, 20, 0.2);
  }
  
  /* ###### 4.8 button-light ###### */
  
  .btn-warning-light {
	color: #FDAC41;
	background-color: rgba(253, 172, 65, 0.2);
	border-color: rgba(253, 172, 65, 0.2);
  
	&:hover {
	  color: $default-color;
	  background-color: #FDAC41;
	  border-color: #FDAC41;
	}
  }
  .btn-warning-light {
	&.disabled, &:disabled {
	  color: $default-color;
	  background-color: #FDAC41;
	  border-color: #FDAC41;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $default-color;
		background-color: #FDAC41;
		border-color: #FDAC41;
	  }
	}
  }
  
  .show > .btn-warning-light.dropdown-toggle {
	color: $default-color;
	background-color: #FDAC41;
	border-color: #FDAC41;
  }
  
  .btn-warning-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
	}
  }
  
  .show > .btn-warning-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
  }
  
  .main-notification-list {
	.las {
	  font-size: 13px;
	}
  
	a:hover {
	  background: #f6faff;
	  color: #45567b;
	}
  }
  
  .main-message-list a:hover {
	background: #f6faff;
	color: #45567b;
  }
  
  .page-header-icon i {
	margin: 0 auto;
	color: $primary;
	text-shadow: -2px 2px 2px rgba(34, 5, 191, 0.2);
  }
  
  .nav-badge {
	margin-left: auto !important;
	z-index: 9;
	padding: 5px 7px;
	font-size: 10.5px;
	border-radius: 50%;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.09), 0 1px 3px rgba(0, 0, 0, 0.08);
	position: absolute;
	right: 12px;
  }
  
  .nav-text-badge {
	margin-left: auto !important;
	z-index: 9;
	position: absolute;
	right: 7px;
  }
  
  .main-sidebar-hide .nav-badge {
	position: absolute;
	top: 5px;
	display: block !important;
	padding: 3px 5px !important;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.09), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .widget-icons {
	height: 45px;
	width: 45px;
	padding: 7px;
	border-radius: 50%;
	font-size: 23px;
	text-align: center;
	line-height: 28px;
  
	i {
	  margin: 0 auto;
	  text-align: center;
	  align-items: center;
	}
  }
  
  .line-list {
	li {
	  &:before {
		content: "";
		position: absolute;
		border-radius: 100%;
		width: 12px;
		height: 12px;
		left: 0;
		border: 3px solid $primary;
		margin-right: 15px;
		z-index: 2;
		background: $white;
	  }
  
	  &:after {
		content: "";
		border: 1px solid #e6edf7;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
	  }
	}
  
	padding-left: 30px;
	margin-bottom: 0;
	position: relative;
	list-style-type: none;
  }
  
  .image-grouped .profile-img {
	width: 30px;
	height: 30px;
  }
  
  .increase i, .decrease i {
	height: 35px;
	width: 35px;
	margin: 0 auto;
	text-align: center;
	line-height: 35px;
	font-size: 16px;
  }
  
  .sales-bar {
	min-height: 180px !important;
	position: relative;
	bottom: -21px;
  }
  
  .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before {
	content: "";
	position: absolute;
	left: 0px;
	display: block;
	height: 10%;
	width: 1px;
	background: $primary;
	left: -27px;
	z-index: 99;
	height: 34px;
  }
  
  .progress-sm {
	height: 16px;
  }
  
  .expansion-value strong {
	&:first-child {
	  color: $default-color;
	}
  
	&:last-child {
	  color: #8587a7;
	}
  }
  
  .card-chart {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	margin-top: 3px;
  }
  
  .sales-details {
	position: absolute;
	width: 100%;
  }
  
  .table-dashboard-one {
	td {
	  vertical-align: top;
	  line-height: 1;
	  padding: 9px 0;
  
	  &:first-child {
		padding-left: 0;
	  }
  
	  &:last-child {
		color: #8392a5;
	  }
	}
  
	width: 100%;
	font-size: 13px;
  
	tr + tr td {
	  border-top: 1px solid #e5e9f2;
	}
  }
  
  .img-sm {
	width: 43px;
	min-width: 43px;
	height: 43px;
  }
  
  .flotChart6 {
	width: 100%;
	height: 180px;
  }
  
  .browser-stats {
	i {
	  height: 32px;
	  width: 32px;
	  text-align: center;
	  border-radius: 50%;
	  line-height: 32px;
	}
  
	.item {
	  padding: 11px 1rem;
	}
  
	h6 {
	  font-size: 13px;
	  margin-bottom: 2px;
	}
  
	.sub-text {
	  display: block;
	  color: #97a3b9;
	  font-size: 10.6px;
	}
  }
  
  .sales-card {
	.badge {
	  border-radius: 30px;
	  background-color: #c6d8ff;
	  padding: 4px 15px;
	  color: #4680ff;
	  font-weight: 600;
	}
  
	.sales-icon {
	  color: $white;
	  font-size: 18px;
	  padding: 40px 40px 20px 20px;
	  border-radius: 50%;
	  position: absolute;
	  top: -30px;
	  right: -30px;
	  -webkit-transition: all .3s ease-in-out;
	  transition: all .3s ease-in-out;
	}
  
	&:hover .sales-icon {
	  font-size: 25px;
	}
  }
  
  .aligner-wrapper {
	.absolute.absolute-center {
	  top: 50%;
	  -webkit-transform: translateY(-50%);
	  transform: translateY(-50%);
	  bottom: 0;
	  right: 0;
	  left: 0;
	  margin: auto;
	  position: absolute;
	}
  
	position: relative;
  }
  
  .chart-icons {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
  }
  
  .sales-flot {
	.flot-chart .flot-x-axis > div span {
	  &:first-child {
		display: block;
		text-transform: uppercase;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-weight: 500;
		font-size: 10px;
		color: #8587a7;
		top: 10px;
		position: relative;
	  }
  
	  &:last-child {
		display: block;
		font-size: 16px;
		font-weight: 700;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: $default-color;
		line-height: 1;
		top: 15px;
		position: relative;
	  }
	}
  
	position: relative;
  }
  
  .media img {
	width: 35px;
	height: 35px;
  }
  
  .dashboard-carousel .btn-icons {
	display: inline-block;
	background: $gray-100 !important;
	background: no-repeat 50% / 100% 100%;
	text-align: center;
	line-height: 15px;
	margin: 0 auto;
	align-items: center;
	padding: 0.375rem 0.75rem;
	min-height: inherit;
  
	i {
	  font-size: 15px;
	  margin: 0 auto;
	  text-align: center;
	}
  }
  
  .sales-dash-header {
	position: absolute;
	left: 20px;
	right: 20px;
	padding: 0;
	background-color: transparent;
	z-index: 5;
  }
  
  .sales-bar-chart {
	position: relative;
	width: auto;
	height: 160px;
  }
  
  .sales-session {
	li {
	  list-style-type: none;
	  margin-bottom: 1.2rem;
	}
  
	margin-left: 0;
	padding-left: 0;
  }
  
  .stats .icon {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 45px;
	border-radius: 3px;
  
	i {
	  font-size: 20px;
	}
  }
  
  .border-primary-light {
	border: 1px solid #94aefb;
  }
  
  .border-pink-light {
	border: 1px solid #f3a7cc;
  }
  
  .ecommerce-card {
	.icons {
	  height: 50px;
	  width: 50px;
	  line-height: 63px;
	  border-radius: 50px;
	  text-align: center;
	  background: #e9eefe;
  
	  &.primary {
		background: $black-2;
  
		i {
		  color: $white-8 !important;
		}
	  }
  
	  i {
		color: $black-5;
		font-size: 30px;
	  }
  
	  &.pink {
		background: rgba(241, 0, 117, 0.08) !important;
		color: $pink;
  
		i {
		  color: $pink;
		  opacity: 0.8;
		}
	  }
	}
  
	&:hover .icons.pink {
	  i {
		color: $white;
		opacity: 1;
	  }
  
	  background: $pink !important;
	}
  
	.icons.success {
	  background: rgba(34, 192, 60, 0.1);
	  color: $success;
  
	  i {
		color: $success;
		opacity: 0.8;
	  }
	}
  
	&:hover .icons.success {
	  i {
		color: $white;
		opacity: 1;
	  }
  
	  background: $success !important;
	}
  
	.icons.warning {
	  background: rgba(255, 193, 7, 0.12);
	  color: $warning;
  
	  i {
		color: $warning;
		opacity: 0.8;
	  }
	}
  
	&:hover .icons.warning {
	  i {
		color: $white;
		opacity: 1;
	  }
  
	  background: $warning !important;
	}
  }
  
  .rating-table {
	&.table {
	  th, td {
		padding: 7px 6px !important;
		line-height: 1.462;
	  }
	}
  
	tr {
	  padding: 10px 0;
	}
  }
  
  .product-timeline {
	ul.timeline-1 {
	  list-style-type: none;
	  position: relative;
	}
  
	.name {
	  color: #031b4e;
	  font-size: 15px;
	}
  
	ul.timeline-1 {
	  &:before {
		content: ' ';
		margin: 20px 20px 0 22px;
		display: inline-block;
		position: absolute;
		left: -2px;
		height: 94%;
		z-index: 0;
		content: '';
		position: absolute;
		bottom: 0;
		border-left: 2px dotted #c0ccda;
	  }
  
	  > li {
		margin: 20.7px 0;
		padding-left: 1rem;
  
		.product-icon {
		  width: 40px;
		  height: 40px;
		  text-align: center;
		  border-radius: 50%;
		  position: absolute;
		  left: 1px;
		  background-color: #f3f6f7;
		  font-size: 20px;
		  line-height: 40px;
		  font-size: 21px;
		}
	  }
	}
  }
  
  .font-weight-semibold {
	font-weight: 500 !important;
  }
  
  .bounceIn {
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
  }
  
  @keyframes bounceIn {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
	  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
  
	0% {
	  opacity: 0;
	  -webkit-transform: scale3d(0.3, 0.3, 0.3);
	  transform: scale3d(0.3, 0.3, 0.3);
	}
  
	20% {
	  -webkit-transform: scale3d(1.1, 1.1, 1.1);
	  transform: scale3d(1.1, 1.1, 1.1);
	}
  
	40% {
	  -webkit-transform: scale3d(0.9, 0.9, 0.9);
	  transform: scale3d(0.9, 0.9, 0.9);
	}
  
	60% {
	  opacity: 1;
	  -webkit-transform: scale3d(1.03, 1.03, 1.03);
	  transform: scale3d(1.03, 1.03, 1.03);
	}
  
	80% {
	  -webkit-transform: scale3d(0.97, 0.97, 0.97);
	  transform: scale3d(0.97, 0.97, 0.97);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	}
  }
  
  .order-list {
	.list {
	  padding-left: 0;
  
	  .list-item:first-child {
		padding-top: 0;
	  }
  
	  &.list-noborders .list-item {
		border: none;
	  }
  
	  .list-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0 0 19px 0;
	  }
	}
  
	.list-item {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  width: 100%;
	  padding: 20px 0;
	  border-bottom: 1px solid #dee2e6;
	}
  }
  
  .top-selling-product {
	img {
	  border-radius: 6px;
	}
  
	.table {
	  th, td {
		padding: 7px 7px  7px 13px !important;
		vertical-align: baseline;
	  }
	}
  
	.table-bordered thead {
	  th, td {
		border-top-width: 1px;
		padding-top: 11px !important;
		padding-bottom: 11px !important;
	  }
	}
  }
  
  .out-of-stock {
	position: relative;
  
	&:before {
	  position: absolute;
	  content: '';
	  background: rgba(239, 242, 246, 0.8) !important;
	  top: 0;
	  left: 0;
	  width: 43px;
	  min-width: 43px;
	  height: 43px;
	  bottom: 0;
	  background-size: cover;
	  display: block;
	}
  }
  
  .top-selling-product .text-default {
	color: #cdd4e8 !important;
  }
  
  .order-list img {
	border-radius: 6px;
  }
  
  .traffic-resource {
	.table {
	  th, td {
		padding: 15px 0px !important;
		border-top: 0;
		border-bottom: 1px solid $border;
	  }
	}
  
	h6 {
	  margin-bottom: 0;
	}
  
	.table-hover tbody tr:hover {
	  color: #031b4e;
	  background-color: transparent;
	}
  }
  
  .dot-label {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	animation: ani 1s linear infinite;
	left: 9px;
	bottom: -8px;
	position: relative;
  }
  
  #summary-chart {
	height: 204px;
	width: 100%;
  }
  
  .summary.chart-legend {
	top: 169px;
  }
  
  @keyframes ani {
	0% {
	  transform: scale(1);
	}
  
	25% {
	  transform: scale(1);
	  transform: scale(1);
	}
  
	30% {
	  transform: scale(1.4);
	}
  
	50% {
	  transform: scale(1.2);
	}
  
	70% {
	  transform: scale(1.4);
	}
  
	90% {
	  transform: scale(1);
	}
  
	100% {
	  transform: scale(1);
	}
  }
  
  #ecom-chart {
	height: 200px !important;
  }
  
  .legend {
	width: 8px;
	height: 8px;
	display: block;
	border-radius: 0;
	margin-right: 10px;
	margin-top: 6px;
  }
  
  .list-unstyled {
	.legend-content {
	  display: inline-block;
	  vertical-align: top;
	}
  
	li {
	  display: flex;
	  margin-bottom: 20px;
	}
  }
  
  /*latetes news timeline */
  
  .latest-timeline-1 ul.timeline-1 {
	list-style-type: none;
	position: relative;
  
	&:before {
	  content: ' ';
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 20px;
	  border-left: 2px dotted #c0ccda;
	}
  
	> li {
	  a {
		color: $black;
	  }
  
	  .date {
		font-size: 10px;
	  }
  
	  p {
		font-size: 13px;
	  }
  
	  margin: 25px 0;
	  padding-left: 0.90rem;
  
	  .product-icon {
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 50%;
		position: absolute;
		left: 1px;
		background-color: #f3f6f7;
		font-size: 20px;
		line-height: 37px;
		font-size: 21px;
	  }
  
	  &:nth-child(2):before {
		border: 3px solid #fb1c52;
	  }
  
	  &:nth-child(3):before {
		border: 3px solid #e9b307;
	  }
  
	  &:nth-child(4):before {
		border: 3px solid #4481eb;
	  }
  
	  &:last-child:before {
		border: 3px solid #27af06;
	  }
	}
  }
  
  .projects-stat {
	.table-bordered {
	  th, td {
		border: transparent;
	  }
	}
  
	.table {
	  th, td {
		padding: 7px 7px !important;
		vertical-align: baseline;
	  }
	}
  
	.project-names h6 {
	  width: 38px;
	  height: 38px;
	  border-radius: 50%;
	  color: $white;
	  line-height: 2.5;
	  margin-bottom: 0;
	  font-weight: 600;
	  padding: 3px;
	}
  }
  
  .review-project {
	.project-contain {
	  display: inline-block;
	}
  
	.table {
	  th, td {
		vertical-align: middle;
		padding: 7px 15px;
	  }
	}
  
	&.card tbody > tr > {
	  th:not(:first-child), td:not(:first-child) {
		font-family: roboto;
		font-size: 13px !important;
	  }
	}
  
	img {
	  vertical-align: top;
	  width: 35px;
	  min-width: 32px;
	  height: 35px;
	}
  }
  
  .latest-tasks {
	.tasks {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-box-pack: justify;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	  position: relative;
	  margin: 0 0 1.15rem 0;
  
	  .span {
		margin-left: 1.3rem;
		font-weight: 500;
		color: $default-color;
	  }
	}
  
	.task-line:before {
	  position: absolute;
	  display: block;
	  width: .2rem;
	  top: .2rem;
	  content: "";
	  border-radius: .2rem;
	  height: 2rem;
	}
  
	.label {
	  color: #031b4e;
	  margin-left: 1.3rem;
	  font-weight: 500;
	}
  
	.time {
	  margin-left: 1.3rem;
	  font-size: 13px;
	  color: #8587a7;
	}
  
	.task-line {
	  &.primary:before {
		background: $primary;
	  }
  
	  &.pink:before {
		background: $pink;
	  }
  
	  &.success:before {
		background: $success;
	  }
  
	  &.teal:before {
		background: $teal;
	  }
  
	  &.warning:before {
		background: $warning;
	  }
  
	  &.orange:before {
		background: $orange;
	  }
  
	  &.info:before {
		background: $info;
	  }
  
	  &.purple:before {
		background: $purple;
	  }
  
	  &.danger:before {
		background: $danger;
	  }
	}
  
	.check-box .ckbox span {
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: rgba(190, 206, 255, 0.05);
		border: 1px solid #d9e2ff;
		top: 1px;
		left: 0;
		border-radius: 2px;
	  }
  
	  &:after {
		border-radius: 2px;
	  }
	}
  
	.nav-tabs .nav-link {
	  &.active, &:hover, &:focus {
		color: $primary;
	  }
  
	  color: #8587a7;
	}
  }
  
  .image-grouped img {
	margin-right: -.6em !important;
  }
  
  .card-progress {
	display: flex;
	align-items: center;
  
	.progress {
	  height: 3px;
	  flex: 1;
	  margin: 0 5px;
	}
  }
  
  .background-text {
	position: relative;
	z-index: 10;
  }
  
  .project-countdown {
	#launch_date {
	  margin: 0;
	}
  
	.countdown {
	  padding: 0;
	  margin: 0;
  
	  li {
		width: 65px;
		height: 65px;
		z-index: 0;
		padding: 0px;
		line-height: 30px;
	  }
  
	  .number {
		font-size: 20px;
		font-weight: 700;
		line-height: 14px;
		padding-top: 11px;
	  }
  
	  .time {
		padding-top: 0px;
		font-size: 11px !important;
	  }
  
	  span {
		display: block;
		color: $white;
	  }
  
	  li {
		background: $primary;
		border: 5px solid $primary;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.project-countdown .countdown li {
	  margin: 2px 5px 7px 5px;
	}
  }
  
  .Activity-scroll {
	max-height: 349px;
  }
  
  .activity .added-project {
	font-weight: 700;
	font-size: 12px;
	color: $primary;
  }
  
  .sparkline .tooltip {
	width: 20px;
	height: 20px;
  }
  
  .Project-scroll {
	max-height: 367px;
	position: relative;
  }
  
  .rating-scroll {
	max-height: 344px;
	position: relative;
  }
  
  .btn-sm, .btn-group-sm > .btn {
	padding: .2rem .7rem;
	font-size: .7rem;
  }
  
  .coming-events {
	.icon {
	  height: 47px;
	  width: 47px;
	  border-radius: 50%;
	  text-align: center;
  
	  span {
		display: block;
		font-size: 12px;
	  }
  
	  .date {
		line-height: 10px;
		padding-top: 11px;
	  }
  
	  .month {
		font-size: 11px;
	  }
	}
  
	.pb-3 {
	  padding-bottom: 0.8rem !important;
	}
  
	.pt-3 {
	  padding-top: 0.8rem !important;
	}
  }
  
  /*////////////////////badgelight//////////////////*/
  
  .bg-success-transparent {
	background-color: rgb(212, 242, 225) !important;
	color: $success;
  }
  
  .bg-teal-transparent {
	background-color: #d2f5f5 !important;
	color: $teal;
  }
  
  .bg-warning-transparent {
	background-color: rgba(255, 193, 7, 0.15) !important;
	color: $warning;
  }
  
  .bg-danger-transparent {
	color: $danger;
	background-color: #f9e2e2;
  }
  
  .bg-purple-transparent {
	color: #673ab7;
	background-color: #e1defe;
  }
  
  .bg-info-transparent {
	color: #0a7ffb;
	background-color: rgba(3, 85, 208, 0.1);
  }
  
  .bg-pink-transparent {
	color: $pink;
	background-color: rgb(254, 229, 241);
  }
  
  .activity {
	position: relative;
	padding: 1.25rem 1.25rem 0 1.25rem;
  }
  
  .activity-list {
	position: relative;
  
	&:before {
	  content: ' ';
	  border: 1px solid #eaf2f9;
	  margin: 20px 20px 0 22px;
	  display: inline-block;
	  position: absolute;
	  width: 2px;
	  height: 100%;
	  z-index: 0;
	}
  }
  
  .activity {
	.img-activity {
	  width: 42px;
	  height: 42px;
	  text-align: center;
	  line-height: 34px;
	  border-radius: 50%;
	  position: absolute;
	  -webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
	  box-shadow: 0 0 0 0.5px #f8f9fa;
	}
  
	.item-activity {
	  margin-left: 60px;
	  margin-bottom: 22px;
	}
  }
  
  .row-cards > {
	.col, [class*='col-'] {
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-direction: column;
	  flex-direction: column;
	}
  }
  
  .row-deck > {
	.col, [class*='col-'] {
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-align: stretch;
	  align-items: stretch;
	}
  
	.col .card, [class*='col-'] .card {
	  -ms-flex: 1 1 auto;
	  flex: 1 1 auto;
	}
  }
  
  .bg-gradient-blue {
	background-image: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
  }
  
  .semi-circle {
	background: rgba(234, 239, 254, 0.5);
	border-radius: 0 0 300px 300px;
  }
  
  .project-card {
	overflow: hidden;
  
	&:hover i {
	  color: $primary;
	  opacity: .16;
	}
  
	i {
	  font-size: 65px;
	  margin: 0 24px 0 -30px;
	  position: relative;
	  opacity: .07;
	  -webkit-transition: all .3s ease-in-out;
	  transition: all .3s ease-in-out;
	}
  
	.project-content {
	  width: 100%;
  
	  ul {
		strong {
		  color: #97a3b9;
		  font-size: 12px;
		  font-weight: 400;
		}
  
		li {
		  line-height: 20px;
		}
  
		list-style: none;
		margin: 0;
		padding: 0;
  
		span {
		  color: #323232;
		  float: right;
		  font-weight: 600;
		  text-align: right;
		  margin-left: auto;
		}
	  }
	}
  
	.primary {
	  fill: $primary;
	}
  
	&:hover .primary {
	  fill: $primary;
	  opacity: 1;
	}
  
	.teal, &:hover .teal {
	  fill: $teal;
	}
  
	.pink {
	  fill: $pink;
	}
  
	&:hover .pink {
	  fill: $pink;
	  opacity: 1;
	}
  
	.purple {
	  fill: #673ab7;
	}
  
	&:hover .purple {
	  fill: #673ab7;
	  opacity: 1;
	}
  }
  
  .area.chart-legend {
	top: 125px;
  }
  
  .recent-operations-card .operation-icon {
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 0;
	font-size: 18px;
	color: $white;
	border-radius: 5px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
  }
  
  .crypto-scroll {
	max-height: 291px;
	position: relative;
  }
  
  .transcation-scroll {
	max-height: 400px;
	position: relative;
  
	.list p a {
	  color: #596882;
	}
  }
  
  .table.table-clean {
	td {
	  .value {
		font-size: .9rem;
		line-height: 1.6;
		font-weight: 500;
	  }
  
	  .sub-value {
		font-size: .72rem;
		color: #8587a7;
	  }
	}
  
	tr:first-child td {
	  border-top: none;
	}
  
	td {
	  padding-left: 0px;
	  padding-right: 0px;
	  border-top-color: $black-05;
	}
  
	th, td {
	  padding: 7px 20px !important;
	}
  }
  
  .crypto {
	#flotChart3, #flotChart5, #flotChart1 {
	  position: relative;
	  margin: 0 -40px;
	  margin-bottom: -40px;
	}
  
	.media {
	  position: absolute;
	}
  }
  
  .card-minimal-four .card-footer .nav-link {
	flex: 1;
	display: block;
	text-align: center;
	background-color: #e8e7ef;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
  }
  
  .crypto {
	.card-footer {
	  .nav-link {
		&:hover, &:focus {
		  background-color: #eeeff4;
		}
  
		span {
		  display: block;
  
		  &:first-child {
			color: #556888;
			font-weight: 300;
			font-size: 11px;
		  }
  
		  &:last-child {
			color: #031b4e;
			font-weight: 500;
			font-size: 11px;
			font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		  }
		}
  
		+ .nav-link {
		  border-left: 1px solid $border;
		}
	  }
  
	  background-color: $white;
	  padding: 0;
	}
  
	&.card-footer .nav-link.active {
	  background-color: #fcfcfc;
	}
  
	.card-footer .nav-link.active::before {
	  content: '';
	  position: absolute;
	  top: -1px;
	  left: 0;
	  right: 0;
	  z-index: 5;
	}
  }
  
  .card-body-top {
	a {
	  color: $default-color;
	  font-weight: 700;
	  width: 20px;
	  text-align: right;
	  display: inline-block;
	}
  
	top: 63px;
	left: -5px;
	position: absolute;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	z-index: 5;
  }
  
  .crypto {
	.card-footer .nav-link {
	  display: block;
	  padding: 0.5rem 1.49rem;
	}
  
	.nav-link + .nav-link {
	  border-left: 1px solid $border;
	}
  
	.card-footer .nav-link {
	  flex: 1;
	  display: block;
	  text-align: center;
	  background-color: rgba(236, 238, 249, 0.6);
	  position: relative;
	  padding-left: 10px;
	  padding-right: 10px;
	}
  
	.coin-logo {
	  width: 42px;
	  height: 42px;
	  color: $white;
	  border-radius: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size: 24px;
	  margin-right: 15px;
	}
  
	h6 {
	  font-size: 14px;
	  font-weight: 700;
	  margin-bottom: 2px;
	  color: $default-color;
	}
  }
  
  @media (min-width: 992px) {
	.main-content.horizontal-content {
	  padding-top: 0 !important;
	//   margin-top: 118px;
	}
  }
  
  .nav-wrapper.crypto .label {
	font-weight: 500;
  }
  
  .card-minimal-two {
	.nav-pills .nav-link.active {
	  border-radius: 2px;
	  background-color: $primary;
	}
  
	.nav-link {
	  display: block;
	  padding: 8px 30px;
	  margin: 2px;
	  background-color: #efeff5;
	}
  }
  
  .market-values .btn {
	min-height: initial;
  }
  
  .fs-name {
	font-size: .92rem;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 3px;
  }
  
  .main-sidebar .search {
	position: relative;
	padding: 0 10px;
  
	.form-control {
	  display: block;
	  width: 214px;
	  height: 40px;
	  border-radius: 25px;
	}
  
	.btn {
	  position: absolute;
	  top: 0;
	  right: 0;
	  background-color: transparent;
	  height: 40px;
	  color: #b4bdce;
	  transition: none;
	  font-size: 14px;
	  padding-right: 31px;
	}
  }
  
  .text-nowrap {
	white-space: nowrap !important;
  }
  
  @media (max-width: 576px) {
	.text-sm-nowrap {
	  white-space: nowrap !important;
	}
  }
  
  @media (max-width: 997px) {
	.text-md-nowrap {
	  white-space: nowrap !important;
	}
  }
  
  @media (max-width: 992px) {
	.text-lg-nowrap {
	  white-space: nowrap !important;
	}
  
	.main-header form[role="search"] {
	  width: 38px;
  
	  input {
		font-size: 16px;
		opacity: 0;
		display: none;
		height: 66px;
		transition: all 05s;
	  }
  
	  &.active {
		width: 100%;
		z-index: 9999;
		right: 0;
		border-left: 1px solid #e3e3f7;
		position: absolute;
		left: -1px;
  
		button {
		  display: table-cell;
		  opacity: 1;
		}
  
		input {
		  display: table-cell;
		  opacity: 1;
		  width: 100%;
		  height: 70px;
		  background: $white;
		  padding-left: 20px;
		  border-radius: 3px 3px 0 0;
		  transition: all 05s;
		  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.1);
		}
	  }
	}
  }
  
  @media (max-width: 992px) {
	.main-header-center {
	  .form-control, .fe-search {
		display: none;
	  }
	}
  }
  
  @media (max-width: 1000px) {
	.text-xl-nowrap {
	  white-space: nowrap !important;
	}
  }
  
  .card-dashboard-audience-metrics .flot-chart .flot-x-axis > div {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
	color: #8587a7;
	display: none;
  }
  
  .task-box {
	background-color: $gray-100;
	margin-bottom: 15px;
	padding: 10px;
  
	&.primary p, &.pink p {
	  text-transform: uppercase;
	  font-weight: 700;
	  font-size: 10px;
	  margin-bottom: 5px;
	  letter-spacing: .5px;
	}
  
	&.primary {
	  background-color: rgba(217, 232, 254, 0.6) !important;
	  color: $primary !important;
	  border: 1px dashed #7da7e4;
	}
  
	&.pink {
	  background-color: rgba(254, 229, 241, 0.5) !important;
	  color: $pink !important;
	  border: 1px dashed #f196c3;
	}
  }
  
  .task-stat .tasks {
	color: #031b4e;
	padding: 0.65rem 1.25rem;
	font-weight: 500;
	border-bottom: 1px solid #e7ebf3;
  }
  
  .breadcrumb-header {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
  
	.breadcrumb {
	  background: transparent !important;
	  padding: 0;
	  margin-bottom: 0;
	  font-size: 13px;
	}
  }
  
  .svg-icons {
	fill: #031b4e;
	height: 20px;
  }
  
  .eve-icon {
	height: 20px;
  }
  
  .ui-bottom-data {
	position: relative;
	bottom: -10px;
	border-radius: 5px;
	-webkit-box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
	box-shadow: 0 1px 10px rgba(24, 28, 33, 0.2);
  }
  
  .card .card-header .card-header-right .card-option {
	width: 35px;
	height: 20px;
	overflow: hidden;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  
	li {
	  display: inline-block;
	}
  }
  
  .dataTables_paginate {
	.pagination .page-link {
	  width: inherit;
	  height: inherit;
	}
  
	.page-item.active .page-link {
	  background-color: inherit;
	}
  }
  
  .dataTables_wrapper .dataTables_paginate {
	.paginate_button.focus {
	  background: $primary;
	}
  
	.page-item.disabled .page-link {
	  background-color: transparent;
	  border-color: transparent;
	}
  }
  
  table.dataTable tfoot {
	th, td {
	  padding: 10px 18px 6px 18px;
	  border-top: 1px solid $border;
	}
  }
  
  .project-list .list-unstyled li {
	display: flex;
	margin-bottom: 20px;
	padding: 14px;
	border: 1px solid #dee4ec;
	border-left: 1px solid $black;
  
	.media-body {
	  margin-left: 13px;
	}
  }
  
  .border-start-primary {
	border-left-color: $primary !important;
  }
  
  .border-start-success {
	border-left-color: $success !important;
  }
  
  .border-start-warning {
	border-left-color: $warning !important;
  }
  
  .card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: $gray-100;
  }
  
  .pricing-card {
	.list-unstyled {
	  padding-left: 0;
	  list-style: none;
	}
  
	.display-5 {
	  font-size: 2.5rem;
	  font-weight: 300;
	  line-height: 1.1;
	}
  
	.list-unstyled li {
	  border-bottom: 1px solid #eaedf1;
	  display: flow-root;
	  margin-bottom: 0;
	  padding: 9px 0;
	}
  }
  
  /*------ Pricing Styles ---------*/
  
  .panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
  
	> .panel-heading {
	  -moz-transition: all .3s ease;
	  -o-transition: all .3s ease;
	  -webkit-transition: all .3s ease;
	}
  
	h3 {
	  margin-bottom: 0;
	  padding: 20px 0;
	}
  }
  
  .panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid $white-2;
	background: $white;
  }
  
  .panel.price {
	> .panel-heading {
	  color: $white;
	}
  
	.list-group-item {
	  &:last-child {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	  }
  
	  &:first-child {
		border-top-right-radius: 0px;
		border-top-left-radius: 0px;
	  }
	}
  
	margin-bottom: 1.5rem;
  }
  
  .price {
	.panel-footer {
	  border-bottom: 0px;
	  background-color: $white;
	  border-left: 0;
	  border-right: 0;
	}
  
	&.panel-color > .panel-body {
	  background-color: $white;
	}
  }
  
  .panel-body {
	padding: 15px;
  
	.lead {
	  strong {
		font-size: 40px;
		margin-bottom: 0;
	  }
  
	  font-size: 20px;
	  margin-bottom: 0;
	  padding: 10px 0;
	}
  }
  
  .panel-footer {
	padding: 10px 15px;
	background-color: $white;
	border-top: 1px solid #eaedf1;
	border-left: 1px solid #eaedf1;
	border-right: 1px solid #eaedf1;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
  }
  
  .panel.price .btn {
	border: 0px;
  }
  
  .pricing .list-unstyled li {
	border-bottom: 1px solid #eaedf1;
	display: flow-root;
	margin-bottom: 0;
	padding: 9px 0;
  }
  
  .demo-gallery {
	> ul > li a {
	  border-radius: 3px;
	  display: block;
	  overflow: hidden;
	  position: relative;
	  float: left;
	  height: 100%;
	  width: 100%;
  
	  > img {
		opacity: 1;
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
		width: 100% !important;
	  }
  
	  &:hover {
		> img, .demo-gallery-poster > img {
		  opacity: .5;
		}
	  }
  
	  .demo-gallery-poster {
		background-color: $black-1;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: background-color 0.15s ease 0s;
		-o-transition: background-color 0.15s ease 0s;
		transition: background-color 0.15s ease 0s;
  
		> img {
		  left: 50%;
		  margin-left: -10px;
		  margin-top: -10px;
		  opacity: 0;
		  position: absolute;
		  top: 50%;
		  -webkit-transition: opacity 0.3s ease 0s;
		  -o-transition: opacity 0.3s ease 0s;
		  transition: opacity 0.3s ease 0s;
		}
	  }
  
	  &:hover .demo-gallery-poster {
		background-color: $black-5;
	  }
	}
  
	.justified-gallery > a {
	  > img {
		-webkit-transition: -webkit-transform 0.15s ease 0s;
		-moz-transition: -moz-transform 0.15s ease 0s;
		-o-transition: -o-transform 0.15s ease 0s;
		transition: transform 0.15s ease 0s;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		height: 100%;
		width: 100%;
	  }
  
	  &:hover {
		> img {
		  -webkit-transform: scale3d(1.1, 1.1, 1.1);
		  transform: scale3d(1.1, 1.1, 1.1);
		}
  
		.demo-gallery-poster > img {
		  opacity: 1;
		}
	  }
  
	  .demo-gallery-poster {
		background-color: $black-1;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: background-color 0.15s ease 0s;
		-o-transition: background-color 0.15s ease 0s;
		transition: background-color 0.15s ease 0s;
  
		> img {
		  left: 50%;
		  margin-left: -10px;
		  margin-top: -10px;
		  opacity: 0;
		  position: absolute;
		  top: 50%;
		  -webkit-transition: opacity 0.3s ease 0s;
		  -o-transition: opacity 0.3s ease 0s;
		  transition: opacity 0.3s ease 0s;
		}
	  }
  
	  &:hover .demo-gallery-poster {
		background-color: $black-5;
	  }
	}
  
	.video .demo-gallery-poster img {
	  height: 48px;
	  margin-left: -24px;
	  margin-top: -24px;
	  opacity: 0.8;
	  width: 48px;
	}
  
	&.dark > ul > li a {
	  border: 3px solid #04070a;
	}
  }
  
  .card-body + .card-body {
	border-top: 1px solid #eaedf1;
  }
  
  .left-side-product-box {
	img {
	  width: 100%;
	}
  
	.sub-img img {
	  margin-top: 5px;
	  width: 83px;
	  height: 100px;
	}
  }
  
  .right-side-pro-detail {
	span {
	  font-size: 15px;
	}
  
	p {
	  font-size: 25px;
	  color: #a1a1a1;
	}
  
	.price-pro {
	  color: #E45641;
	}
  
	.tag-section {
	  font-size: 18px;
	  color: #5D4C46;
	}
  }
  
  .pro-box-section .pro-box img {
	width: 100%;
	height: 200px;
  }
  
  @media (min-width: 360px) and (max-width: 640px) {
	.pro-box-section .pro-box img {
	  height: auto;
	}
  }
  
  .product-details .media img {
	width: 60px;
	height: 60px;
	margin-right: 20px;
	border-radius: 5px;
  }
  
  .card-item-desc .card-item-desc-1 dt, dd {
	display: inline-block;
  }
  
  .card-item-desc {
	.card-item-desc-1 {
	  dt {
		font-weight: 500;
		font-size: 13px;
	  }
  
	  dd {
		font-size: 13px;
		color: #8587a7;
		margin-bottom: 0;
	  }
	}
  
	dl {
	  margin-bottom: 0;
	}
  }
  
  .preview-pic {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }
  
  .preview-thumbnail.nav-tabs {
	border: none;
	margin-top: 15px;
  
	li {
	  width: 18%;
	  margin-right: 2.5%;
	  border-radius: 5px;
	  border: 1px solid $border;
	  padding: 5px;
  
	  img {
		max-width: 100%;
		display: block;
	  }
  
	  a {
		padding: 0;
		margin: 0;
	  }
  
	  &:last-of-type {
		margin-right: 0;
	  }
	}
  }
  
  .tab-content {
	overflow: hidden;
  }
  
  .details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
  }
  
  .colors {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }
  
  .product-title, .price, .sizes, .colors {
	text-transform: UPPERCASE;
	font-weight: bold;
  }
  
  .checked, .price span {
	color: #ff9f1a;
  }
  
  .product-title, .rating, .product-description, .price, .vote, .sizes {
	margin-bottom: 15px;
  }
  
  .product-title {
	margin-top: 0;
  }
  
  .size {
	margin-right: 10px;
  
	&:first-of-type {
	  margin-left: 40px;
	}
  }
  
  .details .rdiobox span {
	&:after {
	  top: 5px;
	}
  
	&:before {
	  top: 0px;
	}
  }
  
  .colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
  }
  
  .colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
  }
  
  .colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 3px;
	border: 1px solid #eaf0f7;
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
  
	&:before {
	  content: '';
	  opacity: 0;
	  position: absolute;
	  top: .25rem;
	  left: .25rem;
	  height: 1.25rem;
	  width: 1.25rem;
	  transition: .3s opacity;
	  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
  }
  
  .colorinput-input:checked ~ .colorinput-color:before {
	opacity: 1;
  }
  
  .feature2 i {
	line-height: 3rem;
	font-size: 20px;
	margin: 0 0 15px 0;
  }
  
  .item-card {
	.cardtitle {
	  span {
		display: block;
		font-size: .75rem;
	  }
  
	  a {
		color: #1f252d;
		font-weight: 500;
		text-decoration: none;
	  }
	}
  
	.cardprice {
	  position: absolute;
	  top: 17px;
	  right: 15px;
  
	  span {
		&.type--strikethrough {
		  opacity: .7;
		  text-decoration: line-through;
		}
  
		display: block;
		color: #1f252d;
	  }
	}
  
	.relative {
	  position: relative;
	}
  }
  
  .br-tl-0 {
	border-top-left-radius: 0 !important;
  }
  
  .br-bl-0 {
	border-bottom-left-radius: 0 !important;
  }
  
  .br-tr-0 {
	border-top-right-radius: 0 !important;
  }
  
  .br-br-0 {
	border-bottom-right-radius: 0 !important;
  }
  
  /*------icons-list---------*/
  
  .main-icon-list {
	padding: 10px;
	border: 1px solid #e1e6f1;
  }
  
  .icons-list {
	list-style: none;
	margin: 0 -1px -1px 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
  }
  
  .icons-list-item {
	flex: 1 0 2rem;
	height: 2rem;
	text-align: center;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  }
  
  .icons-list .flag-icon {
	border-radius: 0;
  }
  
  /*------icons-list---------*/
  
  .example {
	padding: 1rem !important;
	border: 1px solid #e8e7ef;
	font-size: 0.9375rem;
  
	+ .highlight {
	  border-top: none;
	  margin-top: 0;
	}
  }
  
  .text-wrap > :last-child {
	margin-bottom: 0;
  }
  
  .highlight {
	margin: 1rem 0 2rem;
	border: 1px solid #e1e6f1;
	border-radius: 0px;
	font-size: 0.9375rem;
	background: #edeff7;
	position: relative !important;
  
	pre {
	  margin-bottom: 0;
	  background-color: rgb(232, 231, 239);
	  max-height: 20rem;
	}
  }
  
  pre {
	color: #3d3f4e;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #edeff7;
	border-radius: 3px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	text-shadow: 0 1px white;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
  }
  
  /*------icons-list---------*/
  
  .clip-widget {
	position: relative;
  }
  
 
  
  .breadcrumb-3 {
	ol {
	  position: relative;
	  display: block;
	  width: 100%;
	  text-align: center;
	}
  
	li {
	  display: contents;
	  text-align: center;
	}
  }
  
  .breadcrumb-4 {
	ol {
	  position: relative;
	  display: block;
	  width: 100%;
	  text-align: right;
	}
  
	li {
	  display: contents;
	  text-align: right;
	}
  }
  
  .img-thumbnail .caption {
	padding: 15px;
  }
  
  *::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	transition: .3s background;
  }
  
  ::-webkit-scrollbar-thumb {
	background: #e1e6f1;
  }
  
  *:hover::-webkit-scrollbar-thumb {
	background: #adb5bd;
  }
  
  .construction {
	.form-control {
	  height: 44px;
	}
  
	.btn.btn-icon {
	  border: 1px solid rgb(221, 230, 241);
	  text-align: center;
	  padding: 0;
	  background: 0 0;
	  font-size: 20px;
	  color: $black-9;
	  margin: 0 5px;
	  border-radius: 3px;
	}
  }
  
  .sidebar-right .main-nav-line .nav-link {
	padding: 10px 18px 10px 21px;
	background: #e8e7ef;
  
	&.active::before {
	  bottom: 0;
	}
  }
  
  .main-sidebar-open .main-sidebar {
	width: 240px;
	transition: left 0.3s ease, width 0.3s ease;
  }
  
  .main-header-center {
	display: flex;
  }
  
  
  .main-sidebar {
	background-color: #fcfcfc;
	border-right-color: #cdd4e0;
	box-shadow: 0 20px 40px 0 #c8c8de;
  }
  
  .main-sidebar-header {
	background: $primary;
	border-bottom: 1px solid $white-1;
	border-right: 1px solid $white-1;
    transition: all ease 0.2s;
  }
  
  .main-sidebar-body {
	.nav-label {
	  color: #7c808a;
	  margin-bottom: 0;
	  text-transform: uppercase;
	  font-size: 10px;
	  font-weight: 500;
	  letter-spacing: .5px;
	  padding: 7px 38px;
	  border-radius: 1px;
	  margin-top: 1.1rem;
	  position: relative;
  
	  &:after {
		content: "--";
		position: absolute;
		left: 21px;
		top: 7px;
	  }
	}
  
	.nav-link {
	  height: 40px;
	  display: flex;
	  align-items: center;
	  padding: 0 12px;
	  font-weight: 500;
	  font-size: 14px;
	  color: #334151;
	}
  
	.nav-item.active {
	  .nav-link, .leftmenu-icon {
		color: $primary;
	  }
	}
  
	.nav-link i {
	  font-size: 18px;
	  line-height: 0;
	  margin-right: 5px;
	}
  }
  
  .main-logo {
	margin: 0;
	height: 2rem;
  }
  
  .card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: $white;
  }
  
  .nav-link.with-sub .angle {
	right: 20px;
	top: 14px;
  }
  
  .main-sidebar-body {
	.nav-sub-link {
	  height: 34px;
	  display: flex;
	  align-items: center;
	  color: #5e7cac;
	  font-size: 13px;
	}
  
	.nav-link {
	  height: 40px;
	  display: flex;
	  align-items: center;
	  font-weight: 500;
	  font-size: 14px;
	  color: #3e4b5b;
	  position: relative;
	}
  }
  
  .nav-link.with-sub {
	.angle {
	  right: 15px;
	  top: 20px;
	  font-size: 12px;
	}
  
	.side-badge {
	  right: 18px;
	  position: absolute;
	}
  }
  
  .main-img-user {
	.avatar {
	  width: 38px !important;
	  height: 38px !important;
	  border-radius: 4px !important;
	}
  
	&::after {
	  display: none;
	}
  }
  
  .main-sidebar-body {
	.nav-item + .nav-item::before {
	  border-top: 0;
	}
  
	.nav-link .leftmenu-icon {
	  font-size: 16px;
	  line-height: 0;
	  margin-right: 11px;
	  width: 30px;
	  height: 30px;
	  color: #3e4b5b;
	  line-height: 30px;
	  text-align: center;
	  border-radius: 3px;
	}
  }
  
  .main-content-1 .main-content-header {
	display: block;
	padding: 20px;
	background-color: transparent;
	border: 0;
  }
  
  .main-content .header {
	// border-radius: 8px;
	// margin: 20px;
  }
  
  .main-sidebar-body {
	.nav-item.show > .nav-sub {
	  position: relative;
	  max-height: 100vh;
	  -webkit-transition: max-height 2s ease;
	  -o-transition: max-height 2s ease;
	  transition: max-height 2s ease;
	  transition: all 2s;
	}
  
	.nav-sub-item {
	  margin-left: 47px;
  
	  a:after {
		content: "\e92f";
		margin-right: 8px;
		position: absolute;
		font-family: 'feather' !important;
		opacity: 0.6;
		left: 13px;
	  }
	}
  }
  
  .form-control {
	color: #596882;
	border: 1px solid #e8e7ef;
  }
  
  .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a {
	padding: 0.5rem 0.90rem;
  }
  
  .main-sidebar-body .nav-item.show .nav-link.with-sub .angle {
	transform: rotate(90deg);
  }
  
  .header-logo {
	width: 230px;
  }
  
  @media (min-width: 992px) {
	.main-header-menu-icon {
	  margin-right: 25px;
	  margin-left: auto;
	  margin-right: 8px !important;
	}
  
	.dashboard-5.main-sidebar-hide {
	  .main-header-menu-icon {
		margin: 0 auto !important;
		text-align: center;
	  }
  
	  .main-sidebar-body {
		.nav-item {
		  border-top: 0;
  
		  &.active .nav-link {
			color: $primary;
		  }
		}
  
		.nav-link {
		  border-radius: 0;
		  margin-right: 0;
		  width: 38px;
		  height: 38px;
		  margin: 3px 0;
		  border-radius: 5px;
		  height: 40px;
		  display: flex;
		  align-items: center;
		  padding: 2px 10px;
		}
	  }
	}
  
	.animated-arrow span {
	  transition: all 500ms ease-in-out;
  
	  &:before, &:after, &:before, &:after {
		transition: all 500ms ease-in-out;
	  }
  
	  transition: all 500ms ease-in-out;
	}
  
	.dashboard-5 {
	  &.main-sidebar-hide .main-sidebar-header {
		border-right: 1px solid #dee4ec;
		width: 80px;
	  }
  
	  &.main-body.main-sidebar-hide {
		.main-sidebar {
		  width: 80px;
		}
  
		.main-sidebar-body .sidemenu-label {
		  display: none;
		}
  
		.main-content {
		  margin-left: 80px !important;
		}
  
		.main-sidebar-body .nav-item.show > .nav-sub, .nav-link.with-sub .angle {
		  display: none;
		}
  
		.main-sidebar-body {
		  .nav-label {
			display: none;
		  }
  
		  .nav-item {
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			width: 40px;
		  }
		}
	  }
  
	  &.main-sidebar-hide .main-sidebar-body .nav-link {
		height: 40px;
		display: flex;
		align-items: center;
	  }
	}
  
	.main-content {
	  margin-left: 240px;
	}
  
	.user-pic img {
	  width: 60px;
	  height: 60px;
	  font-size: 36px;
	}
  
	.main-sidebar-hide .main-sidebar-body {
	  margin-top: 90px;
	}
  }
  
  .main-header-center {
	margin: 0;
	position: relative;
  
	.form-control {
	  border: 2px solid #e8e6f7;
	  border-width: 1px;
	  background-color: #f8f7fd;
	  transition: none;
	  height: 41px;
	  color: #2d2b38;
	}
  
	.btn {
	  position: absolute;
	  top: 0;
	  right: 0;
	  background-color: transparent;
	  height: 40px;
	  color: #b4bdce;
	  transition: none;
	  font-size: 16px;
	  padding-right: 13px;
	}
  }
  
  .main-sidebar-body .nav-sub-item:hover .nav-sub-link {
	color: $primary;
  }
  
  .horizontal-mainwrapper.container {
	margin-right: auto;
	margin-left: auto;
  }
  
  .main-header.hor-header {
	> .container-fluid {
	  padding-left: 25px;
	  padding-right: 25px;
	  width: 1200px;
	}
  
	border-bottom: 0;
	box-shadow: none;
  }
  
  .mega-menubg.hor-mega-menu li a:before {
	display: none;
  }
  
  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li {
	position: relative;
  }
  
  .main-content.horizontal-content {
	padding-top: 0 !important;
	margin-left: 0;
  }
  
  .main-header.hor-header {
	margin-bottom: 0;
  }
  
  .horizontal-main.hor-menu {
	background: $bg-primary-gradient;
  }
  
  @media (min-width: 992px) {
	.hor-menu.sticky-pin {
	  position: fixed !important;
	  top: 0;
	  width: 100%;
	  z-index: 99999999;
	  box-shadow: 0 2px 10px rgba(32, 43, 64, 0.9);
	  height: 58px;
	}
  }
  
  .horizontalMenu > .horizontalMenu-list {
	> li > a i {
	  font-size: 15px !important;
	}
  
	li .horizontal-icon {
	  font-size: 10px !important;
	  margin-right: 0;
	  margin-left: 2px;
	}
  }
  
  .sub-menu li a:before {
	display: none;
  }
  
  .main-content.horizontal-content:after {
	height: 260px;
  }
  
  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
	padding: 8px 29px;
  }
  
  #global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	text-align: center;
  }
  
  .loader-img {
	position: absolute;
	right: 0;
	bottom: 0;
	top: 43%;
	left: 0;
	margin: 0 auto;
	text-align: center;
  }
  
  #particles-js {
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
  }
  
  .profile-wrapper {
	padding: 1.4rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(129, 125, 161, 0.17);
	display: flex;
  
	img {
	  border-radius: 50%;
	  width: 3.5rem;
	  height: 3.5rem;
	}
  
	.profile-details {
	  .name {
		font-weight: normal;
		margin-left: 1rem;
		color: $white;
		margin-bottom: 0;
	  }
  
	  .designation {
		color: #817da1;
		margin-left: 1rem;
	  }
	}
  }
  
  input[type="search"] {
	&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
	  -webkit-appearance: none;
	}
  }
  
  @media (min-width: 1190px) {
	.main-header-center .form-control {
	  width: 500px;
	}
  }
  
  @media (max-width: 1189px) {
	.main-header-center .form-control {
	  width: 300px;
	}
  }
  
  @media (max-width: 991px) and (min-width: 574px) {
	.responsive-logo {
	  .logo-2 {
		dispLay: none;
	  }
  
	  .logo-1 {
		height: 2rem;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
	  &.active {
		color: $white;
		background: $white-1;
		border-bottom: 0;
		border-radius: 0;
	  }
  
	  &:hover {
		color: $white;
		background: $white-1;
	  }
	}
  }
  
  @media (max-width: 991px) {
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
	  color: $white;
	  background: $bg-primary-gradient;
	  border-bottom: 0;
	  border-radius: 0;
	}
  
	.card-bitcoin .media {
	  display: flex;
	  align-items: flex-start;
	  overflow-x: scroll;
	}
  
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
	  color: $white;
	  background: $bg-primary-gradient;
	}
  
	.main-content-body-show .main-header-arrow {
	  display: block;
	  padding: 10px 20px;
	  background: $primary;
	  color: $white;
	}
  }
  
  @media (max-width: 574px) {
	.responsive-logo {
	  .logo-2 {
		dispLay: block;
		height: 2.5rem;
	  }
  
	  .logo-1 {
		dispLay: none;
	  }
	}
  }
  
  @media (max-width: 576px) {
	.breadcrumb-right {
	  margin-top: 15px !important;
	}
  }
  
  @media (max-width: 839px) {
	.breadcrumb-header {
	  display: block;
  
	  .right-content.breadcrumb-right {
		margin-top: 7px !important;
	  }
	}
  }
  
  .sidebar {
	background: $white;
  }
  
  .followers {
	a {
	  display: inline-block;
	  display: inline-block;
	}
  
	.follwers {
	  margin-top: 7px !important;
	  position: absolute;
	  line-height: 2.1;
	  font-size: 1.4rem !important;
	}
  }
  
  .sidebar {
	.tabs-menu ul li .active {
	  background: $primary;
	  color: $white !important;
	  border-radius: 3px;
	}
  
	&.sidebar-right {
	  &.sidebar-open {
		box-shadow: 5px 7px 50px -5px #9f9fbb !important;
	  }
  
	  .tab-content img {
		animation: none;
	  }
  
	  .tabs-menu ul li a {
		padding: 9px 14px 9px 14px;
		color: $black;
	  }
  
	  .tab-menu-heading {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1;
		display: flex;
		margin-bottom: 27px;
		background: #f0eff4;
	  }
  
	  .tabs-menu {
		margin-top: 33px;
		padding: 10px;
	  }
	}
  }
  
  .chat .contacts {
	padding-left: 0;
	margin-left: 0;
  
	li {
	  width: 100% !important;
	  padding: 13px 15px;
	  border-bottom: 1px solid rgba(200, 200, 209, 0.5);
	  list-style-type: none;
	}
  }
  
  .sidebar-right .demo-avatar-group {
	display: inline-block !important;
  
	.main-img-user + {
	  .main-img-user, .main-avatar {
		margin-left: 0;
		margin-right: 1rem;
	  }
	}
  
	.main-avatar + {
	  .main-img-user, .main-avatar {
		margin-left: 0;
		margin-right: 1rem;
	  }
	}
  }
  
  .main-avatar-list-stacked .main-img-user + {
	.main-img-user, .main-avatar {
	  margin-left: 4px;
	}
  }
  
  .sidebar-right .user_info {
	margin-left: 1rem;
  
	h5 {
	  font-size: 15px;
	}
  }
  
  .user_info small {
	font-size: 13px;
  }
  
  .sidebar-right {
	.custom-control-label {
	  font-weight: 500;
	}
  
	.avatar-list {
	  ul {
		padding-left: 0;
		margin-left: 0;
	  }
  
	  .avatar-lg {
		height: 70px !important;
		width: 70px !important;
		border-radius: 4px;
	  }
	}
  
	.img_cont {
	  height: 50px !important;
	  width: 50px !important;
	}
  
	.dropdown-item {
	  display: block;
	  width: 100%;
	  padding: 0.8rem 1.8rem;
	  clear: both;
	  font-weight: 400;
	  color: #3c4858;
	  text-align: inherit;
	  white-space: nowrap;
	  background-color: transparent;
	  border: 0;
	  font-size: 14px;
	}
  
	.dropdown-icon {
	  font-size: 20px !important;
	}
  }
  
  .crypto-buy-sell .nice-select .option {
	text-align: center;
  }
  
  .chips .chip {
	margin: 0 .5rem .5rem 0;
  }
  
  .chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #6e7687;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: #f5f6fb;
	transition: .3s background;
  
	.avatar {
	  float: left;
	  margin: 0 .5rem 0 -.75rem;
	  height: 2rem;
	  width: 2rem;
	  border-radius: 50%;
	}
  }
  
  .team i {
	margin-left: 10px;
	float: right;
  }
  
  .feature .project {
	display: flex;
	width: 45px;
	height: 45px;
	line-height: 2em;
	vertical-align: middle;
	padding-top: 0px;
	color: $white;
	font-size: 20px;
	border-radius: 50px;
	position: relative;
	align-items: center;
	text-align: center;
	left: 0;
	right: 0;
	justify-content: center;
	font-weight: 500;
  }
  
  .plan-icon {
	font-size: 25px;
	width: 80px;
	height: 80px;
	line-height: 80px !important;
	overflow: hidden;
	border: 1px solid #ebeaf1;
	border-radius: 50%;
	background: #f5f6fb;
	transition: all .3s;
  }
  
  .country-table .table th, .table td {
	padding: 9px 15px;
	line-height: 1.462;
  }
  
  .sidebar {
	position: fixed;
	display: block;
	top: 0px;
	width: 300px;
	bottom: 0;
	z-index: 99999;
	height: 100%;
	background: $white;
	box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
	overflow: hidden;
  
	.card {
	  box-shadow: none;
	  border: 0 !important;
	}
  }
  
  /*-----Back to Top-----*/
  
  #back-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-o-border-radius: 2px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color 0.1s linear;
	-moz-transition: background-color 0.1s linear;
	-webkit-transition: background-color 0.1s linear;
	-o-transition: background-color 0.1s linear;
  
	i {
	  padding-top: 15px;
	  font-size: 16px;
	  line-height: 1.3;
	}
  
	background: linear-gradient(135deg, $primary 0%, #764ba2 100%);
	color: $white;
  }
  
  .page-signin-style {
	height: auto;
	background: url(../img/media/4.jpg);
	left: 0;
	top: 0;
	bottom: 0;
	position: relative;
  
	&:before {
	  content: '';
	  background: linear-gradient(135deg, rgba(67, 95, 224, 0.9) 0%, rgba(128, 57, 202, 0.9) 100%);
	  position: absolute;
	  height: 100%;
	  width: 100%;
	  z-index: 1;
	  left: 0;
	  top: 0;
	}
  }
  
  .authentication-pages {
	position: relative;
	z-index: 99;
  }
  
  *::-ms-backdrop, .sidebar-right.sidebar .tab-menu-heading {
	position: absolute;
  }
  
  *::-ms-backdrop, .crypto .media {
	display: flex;
  }
  
  *::-ms-backdrop, .card-bitcoin .media {
	display: block;
  }
  
  @media (min-width: 992px) {
	*::-ms-backdrop, .card-img-top {
	  height: 250px;
	}
  }
  
  *::-ms-backdrop, .main-mail-subject strong {
	display: flex;
  }
  
  @media (min-width: 991px) {
	*::-ms-backdrop, .main-header-right .nav-link.btn-default.btn {
	  margin-top: 10px;
	}
  }
  
  @media (min-width: 992px) {
	*::-ms-backdrop, .main-header.side-header {
	  padding-left: 240px;
	  left: 0;
	}
  
	*::-ms-backdrop, .main-header-center {
	  display: inline-table;
	}
  
	*::-ms-backdrop, .main-header form[role='search'].active input {
	  padding-left: 255px;
	}
  }
  
  @media (max-width: 991px) {
	*::-ms-backdrop, .card-img-top {
	  height: 300px;
	}
  
	*::-ms-backdrop, .main-header-right .nav-link.btn-default.btn {
	  margin-top: 7px;
	}
  
	*::-ms-backdrop, .main-header-center .btn {
	  display: none;
	}
  
	*::-ms-backdrop, .breadcrumb-header h4, *::-ms-backdrop, .breadcrumb-header .text-muted {
	  line-height: 2.2;
	}
  }
  
  *::-ms-backdrop, .btn-icon-list .btn {
	display: table;
	vertical-align: middle;
	text-align: center;
	margin-right: 10px;
  }
  
  *::-ms-backdrop, .pricing .list-unstyled li {
	dispLay: block;
  }
  
  *::-ms-backdrop, .page-h {
	height: 100%;
  }
  
  *::-ms-backdrop, .main-content-body.main-content-body-mail, *::-ms-backdrop, .main-content-body.main-content-body-chat {
	flex: inherit;
  }
  
  *::-ms-backdrop, .side-badge {
	top: 14px;
  }
  
  *::-ms-backdrop, .slide-menu .slide-item::before {
	top: 18px;
  }
  
  *::-ms-backdrop, .breadcrumb-3 li, *::-ms-backdrop, .breadcrumb-4 li {
	display: inline-block;
  }
  
  *::-ms-backdrop, .main-footer {
	width: 100%;
	position: relative;
	bottom: -5px;
	margin-top: 1.5rem;
	margin-bottom: -10px;
	height: inherit  !important;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
  }
  
  *::-ms-backdrop, .media.d-sm-flex {
	display: initial;
  }
  
  *::-ms-backdrop, .btn, .sp-container button {
	padding: 8px;
  }
  
  *::-ms-backdrop, .page-h {
	height: 100%;
  }
  
  *::-ms-backdrop, p {
	font-size: 13px;
  }
  
  *::-ms-backdrop, .horizontalMenucontainer .main-header-left {
	margin-left: 0;
  }
  
  *::-ms-backdrop, .main-content {
	height: 100%;
  }
  
  *::-ms-backdrop, .horizontalMenucontainer .main-footer- {
	padding-bottom: 10px;
  }
  
  *::-ms-backdrop, .media-list.media.d-block, *::-ms-backdrop, .d-block.media.media-list {
	display: inline !important;
  }
  
  .main-header-arrow {
	display: none;
	position: relative;
	right: auto;
  }
  
  .main-contact-info-header .main-img-user {
	width: 100px;
	height: 100px;
  }
  
  .contact-icon {
	display: inline-flex;
	width: 35px;
	height: 35px;
	text-align: center;
	font-size: 1rem;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
  }
  
  @media (min-width: 992px) {
	.main-contacts-list {
	  height: 680px;
	  position: relative;
	}
  }
  
  .right-toggle .nav-link {
	padding-right: 0;
	  cursor: pointer;
  }
  
  .main-profile-work-list .media-logo {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	color: $white;
	font-size: 21px;
	position: relative;
	top: 2px;
	border-radius: 100%;
  }
  
  .profile-user .profile-edit {
	position: absolute;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	line-height: 30px;
	right: 0;
	background: #d5d4e0;
	margin: 0 auto;
	text-align: center;
  }
  
  .main-profile-social-list .media-icon, .main-profile-contact-list .media-icon {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	font-size: 21px;
	position: relative;
	top: 2px;
	border-radius: 100%;
  }
  
//   .profile-footer a {
// 	width: 34px;
// 	height: 34px;
// 	background: #efeff5;
// 	color: $black;
// 	margin: 1px auto;
// 	text-align: center;
// 	line-height: 34px;
// 	display: inline-block;
// 	border-radius: 50%;
// 	font-size: 12px;
//   }
  
  .main-profile-body .card-header {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
  }
  
  .drop-down-profile {
	position: relative;
  
	span.assigned-task {
	  position: absolute;
	  font-size: 12px;
	  width: 18px;
	  height: 18px;
	  line-height: 18px;
	  color: $white;
	  top: 0;
	  margin: 0 auto;
	  text-align: center;
	  border-radius: 50%;
	  right: 0;
	}
  }
  
  @media (max-width: 1134px) {
	.main-profile-body .media.profile-footer {
	  display: block !important;
  
	  .media-body {
		padding: 3px 0 15px 0;
	  }
	}
  }
  
  table.dataTable tbody tr.selected {
	background-color: #ebedf9;
  }
  
  .card .card {
	box-shadow: none;
  }
  
  @media (min-width: 568px) {
	.Light-mode .horizontalMenucontainer {
	  .desktop-logo {
		display: flex;
	  }
  
	  .desktop-dark {
		display: none;
	  }
	}
  
	.dark-theme .horizontalMenucontainer {
	  .desktop-dark {
		display: flex;
	  }
  
	  .desktop-logo {
		display: none;
	  }
	}
  
	// .horizontalMenucontainer {
	//   .desktop-logo-1, .desktop-logo-dark, .desktop-logo, .main-header-center .responsive-logo {
	// 	display: none;
	//   }
	// }
  }
  
  @media (max-width: 567px) {
	.Light-mode .horizontalMenucontainer {
	  .desktop-logo-1 {
		display: flex;
	  }
  
	  .desktop-logo-dark {
		display: none;
	  }
	}
  
	.dark-theme .horizontalMenucontainer {
	  .desktop-logo-dark {
		display: flex;
	  }
  
	  .desktop-logo-1 {
		display: none;
	  }
	}
  
	.horizontalMenucontainer .desktop-dark {
	  display: none;
	}
  }
  
  table.dataTable thead {
	.sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting_asc_disabled:before, .sorting_desc_disabled:before {
	  right: 1em;
	  content: '\f3d8';
	  display: none;
	}
  }
  
  .Light-mode {
	.main-logo, .logo-1, .desktop-logo.active.logo-light {
	  display: none;
	}
  
	.main-sidebar-header .icon-light .logo-icon {
	  display: none;
	}
  }
  
  .card-crypto-scroll {
	&:before {
	  content: '';
	  display: block;
	  left: 0;
	  background: linear-gradient(to left, transparent 0%, #e4e3ef 100%);
	  bottom: 0px;
	  position: absolute;
	  width: 100px;
	  height: 100%;
	  z-index: 5;
	  top: -10px;
	}
  
	&:after {
	  content: '';
	  display: block;
	  right: 0;
	  background: linear-gradient(to left,#e4e3ef  0%, transparent 100%);
	  bottom: 0px;
	  position: absolute;
	  width: 100px;
	  height: 100%;
	  z-index: 5;
	  top: -10px;
	}
  }
  
  /* ######## Resposnive-header ######## */
  
  
  @media (max-width: 568px) {
	.header-settings {
	  margin-right: 15px;
	  margin-left: 10px;
	}
  
	.responsive-navbar.navbar .navbar-collapse .icon.navsearch {
	  i {
		line-height: 20px;
	  }
  
	  padding: 7px 10px !important;
	  border: 1px solid #efefff;
	  margin: 2px;
	  border-radius: 25px;
	}
  }
  
  @media (max-width: 575px) {
	// .main-header {
	//   &.side-header {
	// 	.btn.btn-default.nav-link, .fullscreen-button, .main-header-message, .main-header-notification, .main-profile-menu, .main-header-message.right-toggle {
	// 	  display: none;
	// 	}
	//   }
  
	//   &.hor-header {
	// 	.btn.btn-default.nav-link, .fullscreen-button, .main-header-message, .main-header-notification, .main-profile-menu, .main-header-message.right-toggle {
	// 	  display: none;
	// 	}
	//   }
	// }
  
	.main-header-center {
	  margin: 0 auto;
	  position: relative;
	  justify-content: center;
	}
  
	.side-header.main-header {
	  padding: 0 !important;
	  position: fixed;
	  border-bottom: 1px solid #c1c0cc;
	}
  
	.Light-mode {
	  .horizontalMenucontainer .desktop-logo {
		display: none;
	  }
  
	//   .responsive-logo .mobile-logo {
	// 	display: block !important;
	// 	height: 2.1rem;
	//   }
	}
  
	.horizontalMenucontainer .main-header-center .responsive-logo {
	  display: block !important;
	}
  
	.main-header-center .fas.fa-search {
	  display: none;
	}
  
	*::-ms-backdrop, .responsive.main-header .nav-link.btn-default.btn {
	  line-height: 3;
	}
  
	.main-nav-line .nav-link.active::before {
	  left: 0 !important;
	}
  }
  
  .mobile-logo {
	height: 2.1rem;
  }
  
  @media (max-width: 991px){
	.responsive.main-header {
		background: transparent !important;
		box-shadow: none !important;
		border: 0 !important;
	  
		.dropdown-menu {
		  left: 5% !important;
		  right: 5% !important;
		  top: 60px;
		  width: 90%;
		}
	  } 
	  
	  .responsive-navbar.navbar {
		position: relative !important;
		display: -ms-flexbox;
		display: flex;
		top: 65px;
		margin: 0;
		margin-bottom: 0 !important;
		padding: 0;
	  
		.navbar-collapse {
			padding: 0px 5px;
			position: fixed;
			width: 100%;
			background: $white;
			margin-top: 0;
			z-index: 99;
			top: 69px;
			border-bottom: 1px solid #d4d2e2;
			/* box-shadow: 5px 7px 26px -5px #bebdcc; */
			border-top: 1px solid #d4d2e2;
			left: 0;
			right: 0;

		}
	  
		.dropdown-menu.dropdown-menu-end {
		  left: -1px;
		  right: -1px;
		  position: absolute;
		}
	  
		.nav-link.icon {
		  color: #151948 !important;
		}
	  
		.dropdown {
		  position: initial;
		}
	  
		.nav-link.icon {
		  padding: 10px !important;
		  border: 1px solid #efefff;
		  border-radius: 25px;
		}
	  }
	  .responsive.main-header{
	  	height:0
	  }
  } 

.main-header.side-header {
	border-bottom: 1px solid #d4d2e2;
	box-shadow: none;
	box-shadow: 5px 7px 26px -5px #bebdcc;
	-webkit-box-shadow: 5px 7px 26px -5px #bebdcc;
 }

 .responsive.main-header {
	box-shadow: none;
 }
 
  
  /* ######## Resposnive-header ######## */
  
  .main-header-right .navbar-toggler-icon {
	line-height: 1.8rem;
  }
  
  .card .card {
	box-shadow: none;
  }
  
  #modaldemo8 {
	.modal-header, .modal-body, .modal-footer {
	  padding-left: 20px;
	  padding-right: 20px;
	}
  
	.modal-content.modal-content-demo {
	  border: 1px solid $white-1;
	}
  }
  @media (max-width: 991px) {
	  .main-content-left {
			padding: 2px;
		}
	}
	
	.feature {
  .border {
    position: relative;
    display: inline-block;
    width: 3em;
    height: 3em;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 8px;
    border-radius: 50%;
    color: $white;
  }

  p {
    margin-bottom: 0;
  }
}

.card .card-block {
  padding: 15px;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%;
}

.widget-user {
  .widget-user-header {
    padding: 20px;
    height: 140px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
  }

  .widget-user-username {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px $black-2;
    color: $white;
  }

  .widget-user-desc {
    margin-top: 0;
    color: $white;
  }

  .widget-user-image {
    position: absolute;
    top: 85px;
    left: 50%;
    margin-left: -45px;
    height: 90px;
    width: 90px;
    border-radius: 50%;

    > img {
      width: 90px;
      height: auto;
      border: 3px solid $white;
    }
  }

  .user-wideget-footer {
    padding-top: 30px;
	border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.user-wideget-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: $white;
}

.user-wideget .border-end {
  border-right: 1px solid #f4f4f4;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;

  > {
    .description-header {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 16px;
    }

    .description-text {
      text-transform: uppercase;
      color: #a3b1c9;
    }
  }
}

.socailicons {
  .fa {
    font-size: 70px;
    text-align: right;
    position: absolute;
    top: 25px;
    right: 38px;
    outline: none;
  }

  color: $white;
  text-shadow: 1px 1px 2px $black-5;

  a {
    text-decoration: none;
  }
}

.user-card .card-block .user-social-link i {
  font-size: 30px;
}

.card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.socailicons {
  .fab {
    font-size: 60px;
    text-align: right;
    position: absolute;
    top: 21px;
    right: 38px;
    outline: none;
  }

  color: $white;
  text-shadow: 1px 1px 2px $black-5;

  a {
    text-decoration: none;
  }
}

.twitter1 {
  background-color: #00acee !important;
}

.google-plus1 {
  background-color: #dd4b39 !important;
}

.facebook-like1 {
  background-color: #3b5998 !important;
}

.user-social-link .fab {
  color: #cacbd8;
}

.pinterest1 {
  background-color: #bd081c !important;
}

.tilebox-one i {
  font-size: 40px;
}

.widgets-cards .wrp {
  &.icon-circle {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    margin-right: 15px;

    i {
      font-size: 1.6rem;
      color: $white;
    }
  }

  p {
    margin-bottom: 0;
    line-height: 1;
    margin-bottom: 10px;

    &:first-child {
      font-weight: 600;
      font-size: 30px;
    }
  }
}

.icon-size {
  font-size: 2.5rem !important;
}

.stamp {
  color: $white;
  background: #868e96;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 .25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
}

.stamp-md {
  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.stamp-lg {
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
@media (max-width: 568px){
	#media-object .example, #media-object2 .example, #media-object3 .example, #media-object4 .example, #media-object5 .example {
	  padding: 1rem !important;
	}
	.sign-up-body{
	padding:1.5rem;
	}
}
.user-lock .dropdown {
    position: absolute;
    right: 20px;
}
.qunatity-list li{
list-style-type:none;}

@media only screen and (max-width: 991px){
	.main-sidebar-header .desktop-logo{
	    margin: 0 !important;
	}
	.pos-absolute p.mg-b-0{
	 font-size: 10px;
	}
	.pos-absolute h6{
	font-size: 11px;
	margin-bottom:5px;
	}
	
}

@media (max-width: 575px){
.main-calendar .fc-header-toolbar button.fc-month-button, .main-calendar .fc-header-toolbar button.fc-agendaWeek-button, .main-calendar .fc-header-toolbar button.fc-agendaDay-button, .main-calendar .fc-header-toolbar button.fc-listWeek-button, .main-calendar .fc-header-toolbar button.fc-listMonth-button {
    font-size: 0;
}
}

*::-ms-backdrop, .media-list.media.d-block {
  display: inline !important; }

*::-ms-backdrop, .media-list.media.d-sm-flex {
  display: initial; }

  .pos-relative .hidden{
   overflow: hidden;
  }

@media (min-width:992px){
	.main-header.side-header{
		padding-right: 0px;
		padding-left: 245px;
	}
	.dropdown-menu-arrow:after {
	  content: '';
	  position: absolute;
	  top: -8.5px;
	  right: 24px;
	  border-bottom: 9px solid #763fcd;
	  border-left: 9px solid transparent;
	  border-right: 9px solid transparent;
	}
	.sidebar-mini.sidenav-toggled{
		.main-header.side-header {
			padding-left: 80px;
		}
		&.sidenav-toggled-open{
			.app-sidebar {
				width: 240px;
			}
			.main-sidebar-header {
				width: 240px;
			}
			.side-menu__label {
				display: block !important;
				position: relative;
				left: 0;
				font-size: 0.875rem;
				margin-left: 0;
				min-width: inherit;
				color: #d6deea;
			}
			.main-sidebar-header{
				.logo-icon.dark-theme{
					display: none;
				}
				.main-logo.dark-theme{
					display: block;
				}
			} 
			.side-menu h3{
				display: block;
			}
			.side-menu .slide .side-menu__item {
				padding: 12px 14px !important;
				margin: 2px 0;
			}
			.angle {
				display: block;
			}
			.side-badge {
				right: 30px;
				top: 11px;
			}
			.slide-menu {
				position: relative;
				visibility: inherit;
				padding: 0;
				left: 0;
			}
		}
	} 
}
@media (max-width: 991px){
	.responsive-logo {
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
	}

	.navresponsive-toggler {
		span {
			margin-top: 10px;
			text-align: right;
			padding-right: 0;
			position: relative;
		}
  
		padding: 2px 0;
	}
	.main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a {
		padding: 0.7rem 0.9rem;
	}
	.main-profile-menu.nav-link {
		padding: 0.7rem 0.5rem;
	}
	.main-body .app-sidebar {
		top: 70px !important;
	}
	.main-sidebar-header{
		display: none;
	}
	.main-sidebar-body {
		margin-top: 15px;
	}
}

a:hover {
    color: $primary-09;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a{
	color: inherit;
}

.preview-thumbnail.nav-tabs li a.active{
	border-color: $primary;
}
.owl-theme {
	.card{
		border-radius: 3px;
	}
	.crypto-card{
		padding: 11px 7px;
	}
}
.main-dropdown-form-demo{
	.dropdown-menu{
		z-index: 9999;
	}
}
.panel.price.panel-color{
	.panel-heading{
		border-radius: 5px 5px 0 0;
	}
}

.icons-list>li {
    -ms-flex: 1 0 3rem;
    flex: 1 0 3rem;
}
.icons-list-item {
    text-align: center;
    height: 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e9ebfa;
    margin: 4px;
    border-radius: 5px;
}
.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.icons-list-wrap {
    overflow: hidden;
}
.icons-list-item i {
    font-size: 1.05rem;
    color: #656585;
}