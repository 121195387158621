@media (min-width: 1400px) {
    .horizontal.layout-boxed {
        .stickyClass {
            .app-sidebar {
                max-width: 1400px;
            }
        }
    }

    .layout-boxed.horizontal .hor-header .container,
    .layout-boxed.horizontal .horizontal-main .container,
    .layout-boxed.horizontal .main-content.hor-content .container {
        max-width: 95% !important;
    }

    body.layout-boxed {
        background: #cfd1e2;
    }
    body.transparent-mode.layout-boxed {
        background: var(--transparent-body);
        .page {
            background: var(--transparent-body);
            box-shadow: $black-3 0px 5px 0px 0px, $black-3 0px 5px 25px 0px;
        }
    }
    body.layout-boxed.dark-mode {
        background: $dark-body;
    }
    .layout-boxed {
        .page {
            width: 1400px;
            margin: 0 auto;
            background: #f2f3f9;
            position: relative;
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: column;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            -webkit-box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
            box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
        }
        .main-header.side-header {
            width: 1400px !important;
            margin: 0 auto;
        }
        .app-sidebar {
            left: auto !important;
        }
        .side-header {
            left: auto !important;
            right: auto !important;
        }
        h3.number-font {
            font-size: 1.8rem;
        }
        .sticky-pin .horizontal-main.hor-menu {
            width: 1400px !important;
        }
        .hor-content .container,
        .hor-header .container,
        .horizontal-main.hor-menu .container {
            max-width: 95%;
        }
        .main-sidemenu .slide-right {
            right: 6px;
        }
        .main-sidemenu .slide-left {
            left: 8px;
        }
        .browser-stats img {
            padding: 5px;
            width: 30px;
            height: 30px;
        }
    }
    .layout-boxed .media.media-xs.overflow-visible {
        display: inline-block;
    }
    .dark-mode.layout-boxed .page {
        background: $dark-body;
        -webkit-box-shadow: $black-3 0px 5px 0px 0px, $black-3 0px 5px 25px 0px;
        box-shadow: $black-3 0px 5px 0px 0px, $black-3 0px 5px 25px 0px;
    }
}

// SCOLLABLE LAYOUT STYLES

.scrollable-layout{
    .app-sidebar {
        position: absolute;
    }
    .main-sidebar-header{
        position: absolute;
    }
    .main-header.side-header {
        position: absolute;
    }
    &.horizontal .main-header.side-header.hor-header {
        position: absolute;
    }
    @media (max-width: 991px){
        .responsive-navbar.navbar .navbar-collapse{
            position: absolute;
        }
        .responsive-navbar.navbar{
            position: inherit !important;
        }
    }
} 
    