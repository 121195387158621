/*--- Vertical --*/

.vtimeline {
	list-style: none;
	padding: 0;
	position: relative;
	margin-bottom: 20px;
  
	&:before {
	  top: 0;
	  bottom: 0;
	  position: absolute;
	  content: " ";
	  width: 3px;
	  background-color: #d1d4e4;
	  left: 50%;
	  margin-left: -1.5px;
	}
  
	.timeline-wrapper {
	  display: block;
	  margin-bottom: 20px;
	  position: relative;
	  width: 100%;
	  padding-right: 90px;
  
	  &:before {
		content: " ";
		display: table;
	  }
  
	  &:after {
		content: " ";
		display: table;
		clear: both;
	  }
  
	  .timeline-panel {
		border-radius: 2px;
		padding: 20px;
		position: relative;
		background: $gray-100;
		border-radius: 0px;
		box-shadow: 0 0px 3px rgba(162, 169, 204, 0.24);
		width: 35%;
		margin-left: 15%;
  
		&:before {
		  position: absolute;
		  top: 0;
		  width: 100%;
		  height: 2px;
		  content: "";
		  left: 0;
		  right: 0;
		}
  
		&:after {
		  position: absolute;
		  top: 10px;
		  right: -14px;
		  display: inline-block;
		  border-top: 14px solid transparent;
		  border-left: 14px solid $gray-100;
		  border-right: 0 solid $gray-100;
		  border-bottom: 14px solid transparent;
		  content: " ";
		}
  
		.timeline-title {
		  font-size: 1rem;
		  font-weight: 700;
		  margin: 0 0 .625rem;
		}
  
		.timeline-body {
		  p + p {
			margin-top: 5px;
		  }
  
		  ul {
			margin-bottom: 0;
		  }
		}
  
		.timeline-footer span {
		  font-size: .6875rem;
		}
	  }
  
	  .timeline-badge {
		width: 14px;
		height: 14px;
		position: absolute;
		top: 16px;
		left: calc(50% - 7px);
		z-index: 0;
		border-top-right-radius: 50%;
		border-top-left-radius: 50%;
		border-bottom-right-radius: 50%;
		border-bottom-left-radius: 50%;
		border: 2px solid $white;
  
		i {
		  color: $white;
		}
	  }
  
	  &.timeline-inverted {
		padding-right: 0;
		padding-left: 90px;
  
		.timeline-panel {
		  margin-left: auto;
		  margin-right: 15%;
  
		  &:after {
			border-left-width: 0;
			border-right-width: 14px;
			left: -14px;
			right: auto;
		  }
		}
	  }
	}
  }
  
  .timeline-wrapper-primary {
	.timeline-panel:before, .timeline-badge {
	  background: $primary;
	}
  }
  
  .timeline-wrapper-secondary {
	.timeline-panel:before, .timeline-badge {
	  background: #eb6f33;
	}
  }
  
  .timeline-wrapper-success {
	.timeline-panel:before, .timeline-badge {
	  background: $success;
	}
  }
  
  .timeline-wrapper-info {
	.timeline-panel:before, .timeline-badge {
	  background: #01b8ff;
	}
  }
  
  .timeline-wrapper-warning {
	.timeline-panel:before, .timeline-badge {
	  background: $warning;
	}
  }
  
  .timeline-wrapper-danger {
	.timeline-panel:before, .timeline-badge {
	  background: $danger;
	}
  }
  
  .timeline-wrapper-light {
	.timeline-panel:before, .timeline-badge {
	  background: #f1f2f9;
	}
  }
  
  .timeline-wrapper-dark {
	.timeline-panel:before, .timeline-badge {
	  background: $default-color;
	}
  }
  
  @media (max-width: 768px) {
	.timeline .btn {
	  font-size: 12px;
	}
  
	.vtimeline {
	  &:before {
		left: 7px !important;
	  }
  
	  .timeline-wrapper {
		.timeline-badge {
		  left: 0px !important;
		}
  
		padding-right: 0 !important;
  
		.timeline-panel {
		  width: auto !important;
		  margin-left: 10% !important;
  
		  &:after {
			border-right: 14px solid $gray-100  !important;
			border-left: 0 solid $gray-100  !important;
			left: -14px !important;
			right: auto !important;
		  }
		}
  
		&.timeline-inverted {
		  padding-left: 0 !important;
  
		  .timeline-panel {
			margin-right: 0 !important;
			width: auto !important;
		  }
		}
	  }
	}
  }

  @media(max-width: 360px){
	.vtimeline .timeline-wrapper .timeline-panel:after {
		left: -11px !important;
	}
  }